import { fetchHandler } from "../../Providers/Api";
import { LOADS } from "../ActionTypes";

export function fetchLoadList() {
	const fetchOptions = {
		url: `loads`,
		method: "GET",
		secure: true,
		actionType: LOADS.FETCH_LIST
	};

	return fetchHandler(fetchOptions);
}

export function storeSearchFilter(data, token) {
	const fetchOptions = {
		url: `store/searchfilter`,
		method: "POST",
		body: JSON.stringify(data),
		userToken: token,
		secure: true,
		actionType: LOADS.STORE_FILTER
	};

	return fetchHandler(fetchOptions);
}

export function fetchSearchFilter(id) {
	const fetchOptions = {
		url: `fetch/filter/${id}`,
		method: "GET",
		secure: true,
		actionType: LOADS.FETCH_FILTER
	};

	return fetchHandler(fetchOptions);
}

export function updateField(field, val) {
	return {
		type: LOADS.UPDATE_FIELD,
		field,
		val
	};
}
