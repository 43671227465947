import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { updateField } from "../../Redux/Action/Auth";

class NavPanel extends Component {
	getActiveMenu = (className) => {
		return this.props.pageClassName === className ? "active" : "";
	};

	render() {
		return (
			<aside className="left-nav-wrapper">
				<div className="left-nav-container">
					<nav>
						<ul className="nav-panel">
							{/* <li className={this.getActiveMenu("dispatch")}>
								<Link to="/">
									<i className="nav-icon icon icon-delivery-truck" />
									<span className="nav-text">Dispatch</span>
								</Link>
							</li> */}
                            <li className={this.getActiveMenu("dashboard")}>
								<Link to="/">
									<i className="nav-icon icon icon-package" />
									<span className="nav-text">Dashboard</span>
								</Link>
							</li>
							{/* <li className={this.getActiveMenu("test")}>
								<Link to="/test">
									<i className="nav-icon icon icon-package" />
									<span className="nav-text">Test</span>
								</Link>
							</li> */}
							<li className={this.getActiveMenu("sniper")}>
								<Link to="/sniper">
									<i className="nav-icon icon icon-delivery-cart" />
									<span className="nav-text">Sniper</span>
								</Link>
							</li>
							<li className={this.getActiveMenu("user")}>
								<Link to="/users">
									<i className="nav-icon icon icon-user" />
									<span className="nav-text">Users</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
			</aside>
		);
	}
}

const mapStateToProps = (state) => ( {
   pageClassName: state.Auth.activePage
});

const mapDispatchToProps = { updateField };

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(NavPanel)
);