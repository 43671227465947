import React, { useState, useEffect } from 'react';
import moment from "moment";
import { fetchTruckInformation, fetchBillingDetails } from "./../../../../Redux/Action/Dashboard";

function Billingdetails({ carrierData, loadType, sniperId }) {
  const [truckInfo, SetTruckInfo] = useState([]);
  const [billingDetails, setBillingDetails] = useState([]);
  const [billDetails,setBillDetails] = useState({
    total_revenue:'',
    total_truck_cost:''
  });
  const [margin,setMargin] = useState('');
  const [marginPercent,setMarginPercent] = useState('');
  var orderNo = carrierData && carrierData.id ? carrierData.id : ''

  useEffect(() => {
    if (sniperId) {
      fetchTruckInformation(loadType, sniperId).then(result => {
        if (result.message === "success") {
          SetTruckInfo((prevState) => prevState = result.data);
        } else {
          SetTruckInfo([]);
        }
      }).catch(error => {
        // console.log(error)
      });

      fetchBillingDetails(orderNo).then((result) => {
        if (result.message === 'success') {
          setBillingDetails(result.data.billing_details_order[0])
        }
      }).catch((error) => {
        // console.log(error) 
      })
    } else {
      SetTruckInfo([]);
      return;
    }
  }, [loadType, sniperId])

  useEffect(() => {
    if(carrierData){
      setMargin(carrierData.charges - carrierData.cost)
      setMarginPercent((carrierData.charges - carrierData.cost)/carrierData.charges * 100)
    }    
  }, [carrierData])
  
  //  useEffect(() => {
  //    if(billDetails.total_truck_cost && billDetails.total_revenue){
  //      var marginValue = billDetails.total_revenue - billDetails.total_truck_cost;
  //      var marginPercentValue = marginValue/billDetails.total_revenue *100;
  //      setMargin(marginValue);
  //      setMarginPercent(marginPercentValue);      
  //    }else{
  //     setMargin('');
  //     setMarginPercent('');  
  //    }
  //  }, [billDetails])

  return (
    <div className="row">
      <div className="col">
        <table class="table truck-table table-bordered mb-0">
          <tbody>
            <tr>
              <th width="34%">Load Details</th>
              <th width="22%">Origin</th>
              <th width="22%">Destination</th>
              <th width="22%">Line Items</th>
            </tr>
            <tr>
              <td className="border-right">

                <ul className="list-unstyled load-details-ul">
                  <li>
                    <div className="title">Bill To:</div> <div className="value">{billingDetails && billingDetails.bill_to ? billingDetails.bill_to : 'NA'}</div>
                  </li>
                  <li>
                    <div className="title">Customer:</div> <div className="value">{carrierData && carrierData.customer ? carrierData.customer : 'NA'}</div>
                  </li>
                  <li>
                    <div className="title">Reference No:</div> <div className="value">{billingDetails && billingDetails.reference_no ? billingDetails.reference_no : 'NA'}</div>
                  </li>
                  <li>
                    <div className="title">PickUp No:</div> <div className="value">{billingDetails && billingDetails.pick_up ? billingDetails.pick_up : 'NA'}</div>
                  </li>
                  <li>
                    <div className="title">Delivery No:</div> <div className="value">{billingDetails && billingDetails.delivery_no ? billingDetails.delivery_no : 'NA'}</div>
                  </li>
                  <li><div className="title">Carrier Name:</div> <div className="value">{billingDetails && billingDetails.carrier_name ? billingDetails.carrier_name : "NA"}</div>
                  </li>
                  <li> <div className="title">Truck Empty Location:</div> <div className="value">{truckInfo? `${truckInfo.final_city ? truckInfo.final_city : ''}, ${truckInfo.final_state ? truckInfo.final_state : ''}, ${truckInfo.final_zip ?truckInfo.final_zip :''}`:'NA'}</div></li>
                  <li>
                    <div className="title">Vehicle Type:</div> <div className="value">{truckInfo && truckInfo.equiptype ? truckInfo.equiptype : "NA"}</div>
                  </li>
                  <li>
                    <div className="title">Commodity:</div> <div className="value">{truckInfo && truckInfo.commodity ? truckInfo.commodity : "NA"}</div>
                  </li>
                  <li>
                    <div className="title">Weight:</div> <div className="value">{carrierData && carrierData.weight ? carrierData.weight : "NA"}</div>
                  </li>
                  <li>
                    <div className="title">Miles:</div> <div className="value">{truckInfo && truckInfo.miles ? truckInfo.miles : "NA"}</div>
                  </li>
                </ul>
              </td>
              <td>
                <h6>{carrierData && carrierData.picks?carrierData.picks[0].name:'NA'},</h6>
                <dl>
                  <dt>{carrierData && carrierData.picks?carrierData.picks[0].address1:'NA'}</dt>
                  <dt>{carrierData && carrierData.picks?`${carrierData.picks[0].city},${carrierData.picks[0].state}`:'NA'}</dt>
                  <dd className={`${carrierData && carrierData.picks ? '' : 'd-none'}`}>on {carrierData && carrierData.picks? `${moment(carrierData.picks[0].pickStartDate).format('MM/DD/YYYY')} @ ${moment(carrierData.picks[0].pickStartDate).format('HH:mm')} ${carrierData.picks[0].pickStartDate===carrierData.picks[0].pickEndTime ?'':carrierData.picks[0].pickEndTime!==null?'-' + moment(carrierData.picks[0].pickEndTime).format('HH:mm'):''}`:'NA'}</dd>
                </dl>
              </td>
              <td>
                <h6>{carrierData && carrierData.deliveries?carrierData.deliveries[0].name:'NA'},</h6>
                <dl>
                  <dt>{carrierData && carrierData.deliveries?carrierData.deliveries[0].address1:'NA'}</dt>
                  <dt>{carrierData && carrierData.deliveries?`${carrierData.deliveries[0].city},${carrierData.deliveries[0].state}`:'NA'}</dt>
                  <dd className={`${carrierData && carrierData.deliveries ? '' : 'd-none'}`}>on {carrierData && carrierData.deliveries? `${moment(carrierData.deliveries[0].delvStartDate).format('MM/DD/YYYY')} @ ${moment(carrierData.deliveries[0].delvStartDate).format('HH:mm')} ${carrierData.deliveries[0].delvEndTime===carrierData.deliveries[0].delvStartDate?'':carrierData.deliveries[0].delvEndTime!==null?'-' + moment(carrierData.deliveries[0].delvEndTime).format('HH:mm'):''}`:'NA'}</dd>
                </dl>
              </td>
              <td>
                <dl className="line-items-dl">
                  <dt className="w-50">Total Revenue:</dt> <dd className="w-50">{carrierData && carrierData.charges?carrierData.charges:'NA'}</dd>
                  <dt className="w-50">Total Truck Cost:</dt> <dd className="w-50">{carrierData && carrierData.cost?carrierData.cost:'NA'}</dd>
                  <dt className="w-50">Margin:</dt> <dd className="w-50">{margin?margin.toFixed(2):'NA'}</dd>
                  <dt className="w-50">Margin %:</dt> <dd className="w-50 text-success">{marginPercent?marginPercent.toFixed(2): 'NA'} %</dd>
                </dl>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Billingdetails
