export const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export default function fetchResponseHandler(promise, sucessHandler, errorHandler) {
    const errorResponse = { hasError: true };
    return promise
        .then((res) => {
            if (sucessHandler) {
                return sucessHandler(res, errorResponse);
            }
            return defaultSucessHandler(res, errorResponse);
        })
        .catch((error) => {
            if (errorHandler) {
                return errorHandler(errorResponse);
            }
            return defaultErrorHandler(errorResponse);
        });
}


export function dynamicFetchHandler(
	{ url, method, actionType, fileUpload, headers, body, secure, userToken, shouldDispatch = true },
	successHandler,
	errorHandler
) {
	return (dispatch, getState) => {
		const token = userToken
			? userToken
			: localStorage.getItem("CHARIOT_USER_DATA")
				? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).token
				: null;

		let headersData = {
			...headers,
			Accept: "application/json",
			"Content-Type": "application/json"
		};

		if (secure) {
			headersData = {
				...headersData,
				Authorization: `Bearer ${token}`
			};
		}

		const promise = fetch(`${API_URL}${url}`, {
			method,
			headers: !fileUpload
				? {
						...headersData
				  }
				: {},
			body
		});

		const request = 
		fetchResponseHandler(promise, successHandler, errorHandler);

		if (shouldDispatch) {
			dispatch({
				type: actionType,
				payload: request
			});
		}
	};
}

export function fetchHandler(
    { url, method, actionType, fileUpload, headers, body, secure, userToken, shouldDispatch = true, signal },
    successHandler,
    errorHandler
) {
    const token = userToken
        ? userToken
        : localStorage.getItem("CHARIOT_USER_DATA")
            ? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).token
            : null;

    let headersData = {
        ...headers,
        Accept: "application/json",
        "Content-Type": "application/json"
    };

    if (secure) {
        headersData = {
            ...headersData,
            Authorization: `Bearer ${token}`
        };
    }

    const promise = fetch(`${API_URL}${url}`, {
        signal:signal,
        method,
        headers: !fileUpload
            ? {
                ...headersData
            }
            : {},
        body
    });

    const request = fetchResponseHandler(promise, successHandler, errorHandler);
    return request;
}

function defaultSucessHandler(res, errorResponse) {
    if (200 <= res.status && res.status < 300) {
        return res.json();
    } else {
        return { ...errorResponse, status: res.status, res:res };
    }
}

function defaultErrorHandler(errorResponse) {
    return errorResponse;
}
