import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Logo from "./../../images/logo.png";
// import profilePicture from "../images/profile_pictures/AlbertNavarra.jpg";
import { Dropdown } from "react-bootstrap";
import jwtDecode from "jwt-decode";
import ChangePassword from "./../PageContainer/ChangePassword/ChangePassword";
import { updateActivePage } from "../../Redux/Action/Auth";

class Header extends Component {
	componentDidMount = () => {
		let tokenExpiry = jwtDecode(JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).token).exp;
		setTimeout(() => {
			window.location.reload();
		}, (tokenExpiry - Date.now() / 1000) * 1000);
	};

	logout = () => {
		localStorage.removeItem("CHARIOT_USER_DATA");
		this.props.history.push("/signin");
	};

	toggleMenu = () => {
		document.body.classList.toggle("hide-menu");
	};

	render() {
		let userDetails = JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user;
		let userName = userDetails.username ? userDetails.username : `${userDetails.firstname} ${userDetails.lastname}`;
		return (
			<header className="bg-primary">
				<nav className="navbar main-navbar navbar-expand-lg navbar-light w-100">
					<Link to="/" className="navbar-brand">
						<img src={Logo} alt="Chariot" />
					</Link>
					<button className="navbar-toggler order-3 ml-lg-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" onClick={() => this.toggleMobMenu()} aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse order-2" id="navbarSupportedContent">
						<ul className={`navbar-nav ml-auto ${userDetails.restricted_access?'':'d-none'}`}>
							<li className="nav-item active">
								<Link to="/" className="nav-link d-flex">
									<i className="nav-icon icon icon-package mr-3" />
									<span className="nav-text">Dashboard</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/sniper" className="nav-link d-flex">
									<i className="nav-icon icon icon-delivery-cart mr-3" />
									<span className="nav-text">Sniper</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/users" className="nav-link d-flex">
									<i className="nav-icon icon icon-user mr-3" />
									<span className="nav-text">Users</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/carrierusers" className="nav-link d-flex">
									<i className="nav-icon icon icon-user mr-3" />
									<span className="nav-text">Carrier Users</span>
								</Link>
							</li>
						</ul>
					</div>
					<ChangePassword />
					<Dropdown id="profile-dropdown" className="ml-auto order-1 order-lg-3 dropdown">
						<Dropdown.Toggle className="btn btn-white profile-btn">
							{/* <img className="profile-image" src={profilePicture} alt="User Profile" /> */}
							<label className="profile-name pointer mr-2">
								{userName.charAt(0).toUpperCase() + userName.slice(1)}
							</label>
							<i className="icon icon-arrow-down" />
						</Dropdown.Toggle>
						<Dropdown.Menu className="super-colors">
							<Dropdown.Item
								eventKey="1"
								onClick={() => this.props.updateActivePage("showChangePasswordModal", true)}>
								Change Password
							</Dropdown.Item>
							<Dropdown.Item eventKey="1" onClick={this.logout}>
								Logout
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>

				</nav>

			</header>
		);
	}
}

const mapDispatchToProps = {
	updateActivePage
};

export default connect(
	null,
	mapDispatchToProps
)(withRouter(Header));
