import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { rules } from "../../Utils/Validator";
import { sendLoginCredentials } from "../../../Redux/Action/Auth";
import AuthWrap from "./AuthWrapper";
import { renderField } from "../../Utils/Helper";

class Login extends Component {
	state = {
		submitting: false,
		mcVerification: false,
		MCNo:''
	};

	handleSubmit = (values) => {
		this.setState({ submitting: true });
		const successHandler = (res) => {
			res.json().then((value) => {
				this.setState({ submitting: false });

				if (value.description === "failure") {
					NotificationManager.error(value.errors, "", 5000);
				} else {
					if(value.data.user.carrier_id==0){
						this.setState({submitting:false,mcVerification:false})
						value.data.user['restricted_access']=true;
						localStorage.setItem("CHARIOT_USER_DATA", JSON.stringify(value.data));
						this.props.history.push("/");
					}else{
						this.setState({submitting:false,mcVerification:true})
						value.data.user['restricted_access']=false;
						var payLoad = {
							carrier_id:value.data.user.carrier_id
						}
						const url=`${process.env.REACT_APP_BACKEND_API_URL}get_mc`
						var requestOptions = {
							method:"POST",
							headers:{ 'Content-Type':'application/json'},
							body:JSON.stringify(payLoad)
						}
						fetch(url,requestOptions).then(res=>{return res.json();}).then(res=>{
							this.setState({MCNo:res.data.mc_nbr});
						}).catch(err=>console.log(err));
						if(values.mcNumber){
							this.setState({mcVerification:true})
							if(values.mcNumber===this.state.MCNo){
								localStorage.setItem("CHARIOT_USER_DATA", JSON.stringify(value.data));
								this.props.history.push("/");
							}else{
								NotificationManager.error('MC Number does not match','Error',3500)
							}
						}	
					}
				}
			});
		};
		const errorHandler = (err) => {
			console.log("errorHandler ", err);
			this.setState({ submitting: false });
		};
		let payload = {
			email: values.email.trim(),
			password: values.password
		};
		this.props.sendLoginCredentials(payload, successHandler, errorHandler);
		// this.setState({mcVerification:false})
	};

	renderLoginForm = () => {
		const { email, required } = rules;
		const { submitting } = this.state;
		return (
			<form>
				<Field name="email" component={renderField} type="text" label="User Name" validate={[required, email]} />
				<Field name="password" component={renderField} type="password" label="Password" validate={required} />
				{this.state.mcVerification && <Field name="mcNumber" id="mc-confirmation" component={renderField} type="number" label="MC Number" validate={required} />}
				<div className=" align-items-center mb-3 mt-3">
					<div className=" text-center">
						<button
							disabled={submitting}
							type="submit"
							onClick={this.props.handleSubmit(this.handleSubmit)}
							className="btn btn-primary text-uppercase login-btn w-100 mb-2">
							{submitting ? "Submitting..." : "Login"}
						</button>
						<p className="pointer" onClick={() => this.props.history.push("/forgot/password")}>
							Forgot password?
						</p>
					</div>
				</div>
			</form>
		);
	};

	render() {
		return <AuthWrap>{this.renderLoginForm()}</AuthWrap>;
	}
}

const mapDispatchToProps = {
	sendLoginCredentials
};

export default reduxForm({
	form: "login"
})(
	connect(
		null,
		mapDispatchToProps
	)(Login)
);
