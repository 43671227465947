import { AUTH } from "../ActionTypes";
import { fetchHandler } from "../../Providers/Api";

export function sendLoginCredentials(values, successHandler, errorHandler) {
	const fetchOptions = {
		url: `auth/login`,
		method: "POST",
		body: JSON.stringify(values),
		actionType: AUTH.POST_LOGIN_CREDENTIALS
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
}

export function setPassword(values, successHandler) {
	const fetchOptions = {
		url: `set/password`,
		method: "POST",
		body: JSON.stringify(values),
		actionType: AUTH.SET_PASSWORD
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function resetPassword(values, successHandler) {
	const fetchOptions = {
		url: `resetpassword`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: AUTH.RESET_PASSWORD
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function validateToken(values, successHandler) {
	const fetchOptions = {
		url: "validate/token",
		method: "POST",
		body: JSON.stringify(values),
		actionType: AUTH.VALIDATE_TOKEN
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function forgotPassword(values, successHandler) {
	const fetchOptions = {
		url: `auth/forgotpassword`,
		method: "POST",
		body: JSON.stringify(values),
		actionType: AUTH.FORGOT_PASSWORD
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function updateLoginCredentials(credentials) {
	return {
		type: AUTH.UPDATE_LOGIN_CREDENTIALS,
		payload: credentials
	};
}

export function updateActivePage(field, val) {
	return {
		type: AUTH.UPDATE_FIELD,
		field,
		val
	};
}

export function updateField(field, val) {
	return {
		type: AUTH.UPDATE_FIELD,
		field,
		val
    }
}