import { fetchHandler } from "../../Providers/Api";
import { CARRIERUSERS } from  "../ActionTypes";

export function fetchCarrierUsersList() {
	const fetchOptions = {
		url: `carrierusers`,
		method: "get",
		secure: true,
		actionType: CARRIERUSERS.FETCH_CARRIERUSERS_LIST
	};
	return fetchHandler(fetchOptions);
}

export function fetchCarrierUserDetails(id) {
	
	const fetchOptions = {
		url: `carrieruser/edit/${id}`,
		method: "get",
		secure: true,
		actionType: CARRIERUSERS.FETCH_CARRIERUSER_DETAILS
	};
	console.log(fetchOptions);
	return fetchHandler(fetchOptions);
}

export function fetchCarrierUserRoles() {
	const fetchOptions = {
		url: `carrieruserroles`,
		method: "get",
		secure: true,
		actionType: CARRIERUSERS.FETCH_CARRIERUSER_ROLES
	};

	return fetchHandler(fetchOptions);
}

export function fetchUserDivisions() {
	const fetchOptions = {
		url: `divisions`,
		method: "get",
		secure: true,
		actionType: CARRIERUSERS.FETCH_DIVISIONS
	};

	return fetchHandler(fetchOptions);
}

// export function updateUser(data, successHandler) {
// 	const fetchOptions = {
// 		url: `carrieruser/update`,
// 		method: "POST",
// 		secure: true,
// 		body: JSON.stringify(data),
// 		actionType: CARRIERUSERS.UPDATE
// 	};

// 	return fetchHandler(fetchOptions, successHandler);
// }

export function deleteUser(id, successHandler) {
	const fetchOptions = {
		url: `carrieruser/delete/${id}`,
		method: "DELETE",
		secure: true,
		actionType: CARRIERUSERS.UPDATE
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function updateField(field, val) {
	return {
		type: CARRIERUSERS.UPDATE_FIELD,
		field,
		val
	};
}
