import React, { useEffect, useState } from "react";
import RouteDetails from './OrderDetails/RouteDetails'
import CustomerActivityDetails from './OrderDetails/CustomerActivityDetails'
import TransportationDetails from './OrderDetails/TransportationDetails'
import CarrierLoadStatusDetails from './OrderDetails/CarrierLoadStatusDetails'
import Billingdetails from './OrderDetails/BillingDetails'
import SpecialNotesSection from './OrderDetails/SpecialNotesSection'
import Document from './Document'
import AllRate from '../Dashboard/SniperSection/AllRate'
import { saveBidRates, fetchCarriersList, fetchSniperList, fetchCarrierDetails, fetchDeliveryStatus, fetchWaterfallDetails, fetchCarrierData, fetchOrderStatus, fetchLoadDeliveryStatus, saveLoadDeliveryStatus } from "../../../Redux/Action/Dashboard"
import { NotificationManager } from "react-notifications";
import BidModule from "../Dashboard/SniperSection/BIDModule/Index";
import LoadOfferHistory from "../Dashboard/SniperSection/LoadOfferHistory";
import AverageCostLane from "../Dashboard/SniperSection/AverageCostLane";
import ProgressStatus from "./ProgressBar/ProgressStatus";
import PreloaderInner from "./../../Utils/PreloaderInner";
import PerfectScrollbar from "react-perfect-scrollbar";

const statusArray = [
  {code:'CL',status:'Planned'},
  {code:'ST',status:'Dispatch'},
  {code:'PE',status:'Arrived@Shipper'},
  {code:'PX',status:'PickedUp'},
  {code:'DE',status:'Arrived@Delivery'},
  {code:'DX',status:'Delivered'}

]
export default function Index(props) {
  const [sniperDetail, setSniperDetail] = useState({});
  const [carrierName, setCarrierName] = useState('');
  const [carrierData, setCarrierData] = useState({});
  const [carrierInfo, setCarrierInfo] = useState({});
  const [error, setError] = useState();
  const [AllInRates, setAllInRates] = useState({})
  const [isReload, setReload] = useState(false);
  const [activeTab, setActiveTab] = useState('nav-orderDetails-tab')
  const [deliveryStatus, setDeliveryStatus] = useState('')
  const [isLoader, SetLoader] = useState(true);
  const [loadStatus,setLoadStatus]=useState('');
  const [ratesReload, setRatesReload] = useState({
		DashboardRatesReload: false,
		SplitScreenRatesReload: false
	});
  const [loadAvailable, setLoadAvailable] = useState({
		offerNo: '',
		isAvailable: false
	});

  useEffect(() => {
    if (props.match.params) {
      setSniperDetail(props.match.params);
    }
  }, [props.match.params]);

  useEffect(() => {
    fetchCarriersList(/*sniperDetail.mcno*/).then((result) => {
      if (result.message === 'success') {
        const name = result.data.filter((carrier) => carrier.icc_mc_nbr === sniperDetail.mcno);
        name.map((data) => setCarrierName(data.name))
      }
    }).catch(err => {
      // console.log(err)
    })

    /*--------------------------- FETCH LOAD DATA USING LOAD API -----------------------------*/ 

      fetchCarrierData(sniperDetail.loadType,sniperDetail.orderid).then((result)=>{
        if(result.message==='success'){
          setCarrierData(result.data);
          setLoadStatus(result.data.status);
        }
      }).catch(err=>console.log(err))
    

    /*---------------------------------------------------------------------------------------*/

    fetchCarrierDetails(/*sniperDetail.mcno*/).then(result => {
      if (result.message === 'success') {
        setCarrierInfo(result.data)
      }
    }).catch(err => {
      // console.log(err)
    })

  }, [sniperDetail]);

  useEffect(() => {
    if(carrierData.id){
      const payload = {
        order_nbr:carrierData.id
      }
      fetchOrderStatus(payload).then(result=>{
        if(result.message==='success'){
          setDeliveryStatus(result.data.sniper_ltrack_status)
        }}
      ).catch(err=>console.log(err))
      SetLoader(false);
    }
  }, [carrierData]);

  useEffect(()=>{
    if(carrierData.load_nbr){
      const payload = {
        load_nbr:carrierData.load_nbr,
        order_nbr:carrierData.id
      }

      fetchLoadDeliveryStatus(payload).then(result=>{
        if(result.message==='success'){
          const statusData = result.data;
          const sortedData = statusData.sort((b,a) => (b.id) - (a.id));
          sortedData.map(sd=>{
            const data = JSON.parse(sd.latest_status)
            statusArray.map(sa=>{              
              if(sa.code===data.code){
                if(deliveryStatus==='Available'){
                  const payLoad = {
                    order_status:sa.status,
                    order_nbr:carrierData.id
                  }
                  saveLoadDeliveryStatus(payLoad).then(result=>{}).catch(err=>{console.log(err)})
                  return;
                }
              }
            })
          });
        }
      }).catch(err=>{console.log(err)})
    }
  },[carrierData])

  const updateBidPass = (id, bidCount, passCount) => {}
  
  const tabToggle = (e) => {
    setActiveTab(e.target.id);
  }

  const setAllInRateValues = (rates) => {
    setAllInRates(rates);
  }

  const saveRateValues = (rates) => {
    saveBidRates(rates).then((result) => {
      if (result.message === 'success') {
        NotificationManager.success("Rates Saved", "", 5000)
      }
    }).catch(err => {
      // console.log(err)
    }
    )
  }

  const renderMapSection = () => {
  return (activeTab === 'nav-profile-tab') && <ProgressStatus sniperId={carrierData.p_key} loadType={sniperDetail.loadType} carrierData={carrierData} />
  }

  return (
    <div className="order-details-wrapper">
      <PreloaderInner hidden={!isLoader} />
      <div className="od-tabs-wrapper">
        <nav>
          <div className="nav od-tabs nav-tabs" id="nav-tab" role="tablist">
            <a className={`nav-link ${activeTab === 'nav-orderDetails-tab' ? 'active' : ''}`} id="nav-orderDetails-tab" data-toggle="tab" data-target="#nav-orderDetails" href role="tab" aria-controls="nav-home" aria-selected="true" onClick={tabToggle}>Order Details</a>
            <a className={`nav-link ${activeTab === 'nav-profile-tab' ? 'active' : ''}`} id="nav-profile-tab" data-toggle="tab" data-target="#nav-progress" href role="tab" aria-controls="nav-profile" aria-selected="false" onClick={tabToggle}>Progress</a>
            <a className={`nav-link ${activeTab === 'nav-document-tab' ? 'active' : ''}`} id="nav-document-tab" data-toggle="tab" data-target="#nav-document" href role="tab" aria-controls="nav-contact" aria-selected="false" onClick={tabToggle}>Documents</a>
          </div>
        </nav>
        {carrierData && <RouteDetails carrierData={carrierData} deliveryStatus={deliveryStatus} orderNo={carrierData.id} />}
      </div>
      <PerfectScrollbar>
      <div className="tab-content" id="nav-tabContent">
      
        <div className={`tab-pane fade ${activeTab === 'nav-orderDetails-tab' ? 'show active' : ''}`} id="nav-orderDetails" role="tabpanel" aria-labelledby="nav-orderDetails-tab">
          <div className="orderDetails-Section1 py-3">
            <div className="container-fluid">
              {carrierInfo && carrierData && <CustomerActivityDetails offerNo={carrierData.id} carrierData={carrierData} CarrierName={carrierName} carrierInfo={carrierInfo} />}
            </div>
          </div>
          <div className="orderDetails-Section2">
            <div className="container-fluid pt-3">
              {carrierData && <TransportationDetails carrierData={carrierData} orderno={carrierData.id} />}
            </div>
          </div>
          <div className="orderDetails-Section3">
            <div className="container-fluid pt-3">
              {carrierData && <CarrierLoadStatusDetails deliveryStatus={deliveryStatus} carrierData={carrierData} setDeliveryStatus={setDeliveryStatus}/>}
            </div>
          </div>
          <div className="orderDetails-Section4">
            <div className="container-fluid pt-3">
              {carrierData && <Billingdetails carrierData={carrierData} loadType={sniperDetail.loadType} sniperId={carrierData.p_key} />}
            </div>
          </div>
          <div className="orderDetails-Section5 ">
            <div className="container-fluid pt-3">
              <SpecialNotesSection offerNo={carrierData.id} />
            </div>
          </div>
          <div className="orderDetails-Section6 ">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-12 col-xl-6">
                  <AverageCostLane data={carrierData} />
                </div>
                <div className="col-12 col-xl-6">
                  {carrierData &&
                    <AllRate error={error} setError={setError} sendRateValues={saveRateValues}
                      setAllInRateValues={setAllInRateValues} orderNo={carrierData.id} loadType={sniperDetail.loadType}
                      sniperId={carrierData.p_key} ratesReload={ratesReload} setRatesReload={setRatesReload} loadStatus={loadStatus} />}
                </div>
              </div>
            </div>
          </div>
          <div className="orderDetails-Section7 mt-3 text-md-center">
            {sniperDetail && AllInRates && carrierInfo && carrierData &&
              <BidModule MCNo={sniperDetail.mcno && sniperDetail.mcno} CarrierName={carrierName} Rates={AllInRates}
                carrierInfo={carrierInfo} setError={setError} setReload={setReload} carrierData={carrierData} loadStatus={loadStatus}
                />}
          </div>
          <div className="orderDetails-Section8 mb-3">
            <div className="container-fluid pt-3">
              {carrierData && <LoadOfferHistory isReload={isReload} orderNo={carrierData.id} sniperId={carrierData.p_key} loadType={sniperDetail.loadType} setReload={setReload} setLoadAvailable={setLoadAvailable} updateBidPass={updateBidPass}/>}
            </div>
          </div>
        </div>
        <div className={`tab-pane fade ${activeTab === 'nav-profile-tab' ? 'show active' : ''}`} id="nav-progress" role="tabpanel" aria-labelledby="nav-progress-tab">
          {renderMapSection()}
        </div>
        <div className={`tab-pane fade ${activeTab === 'nav-document-tab' ? 'show active' : ''}`} id="nav-document" role="tabpanel" aria-labelledby="nav-document-tab">
          {carrierData && <Document orderNo={carrierData.id}/>}
        </div>
      </div></PerfectScrollbar>
    </div>
  );
}

