import React, { useState, useEffect } from 'react'
import NextLoadDestination from '../../Dashboard/SniperSection/NextLoadDestination'
import PerfectScrollbar from "react-perfect-scrollbar";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { fetchWaterfallDetails, fetchCarriersList, removeWaterFallCarriers, saveWaterFallCarrierData } from "./../../../../Redux/Action/Dashboard"
import { Multiselect } from 'multiselect-react-dropdown';
import data from '../../Dashboard/StaticValues.json'
import { Modal } from "react-bootstrap";
import Search from "./../../Dashboard/MCSection/AutoCompleteSearch";
import { NotificationManager } from 'react-notifications';
import AddCarrierRoute from './AddCarrierRoute';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const headers = [
  { accessor: "", Header: "", sortField: false },
  { accessor: "ranking", Header: "Ranking", sortField: false },
  { accessor: "carrier_name", Header: "Carrier Name", sortField: true },
  { accessor: "email", Header: "Carrier Email", sortField: true },
  { accessor: "truck_cost", Header: "Truck Cost", sortField: true },
  { accessor: "expiration", Header: "Expiration", sortField: true },
  { accessor: "capacity", Header: "Capacity", sortField: true },
  { accessor: "", Header: "", sortField: false },
];

function TransportationDetails({ carrierData }) {
  const [isModalShow, SetModal] = useState(false);
  const [WaterFallData, setWaterFallData] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState([]);
  const [selectedCarrierListData, setSelectedCarrierListData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [NextLoadData, setNextLoadData] = useState({});
  const [isNextLoadChanged, setNextLoadChanged] = useState(false);
  const [deleteData, setDeleteData] = useState({
    index: '',
    data: {}
  });
  const [sortData, setSortData] = useState(false);
  const [apiFieldName, setApiFieldName] = useState('');
  const [descending, setDescending] = useState(false);


  useEffect(() => {
    fetchWaterfallDetails(carrierData.id).then((result) => {
      if (result.message === 'success') {
        setWaterFallData(result.data)
      }
    }).catch((error) => {
      // console.log(error)
    })
  }, [carrierData.id])

  const handleonDragEnd = (result) => {
    if (result.destination) {
      const items = Array.from(WaterFallData);
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)
      setWaterFallData(items)
    } else {
      return
    }
  }

  const removeRecord = (indexReceived) => {
    const deleteData = WaterFallData.filter((item, index) => { return indexReceived === index })
    const newData = WaterFallData.filter((item, index) => { return indexReceived !== index; });
    setWaterFallData(newData);
    deleteData.map((data) => {
      removeWaterFallCarriers(data.id, carrierData.id).then((result) => {
        if (result.message === 'success') {
          NotificationManager.success('Deleted Successfully', deleteData.carrier_name, 3500)
        }
      }).catch((error) => {
        // console.log(error)
      })
    })
  };

  const setValues = (carrier) => {
    var found = false;
    selectedCarrier.map((data) => {
      if (data.id === carrier.id) {
        found = true;
      }
    });

    if (!found) {
      setSelectedCarrier((prevState) => [...prevState, carrier]);
      setSelectedCarrierListData((prevState) => [...prevState, carrier]);
    }
  }

  const saveWaterFallData = () => {
    var carrierIdstoSave = []
    var truckCosttoSave = []
    var expirationtoSave = []
    var capacitytoSave = []
    var ratingtoSave = []
    selectedCarrier.map((carrier) => {
      carrierIdstoSave.unshift(carrier.id)
      truckCosttoSave.unshift(carrier.truck_cost?carrier.truck_cost:'0.00')
      capacitytoSave.unshift(carrier.capacity)
      expirationtoSave.unshift(moment(carrier.expiration).format('YYYY-MM-DD HH:mm:ss'))
      ratingtoSave.unshift(carrier.rating?carrier.rating:'1')
    })

    var payLoad = {
      order_offer_id: carrierData.id,
      carriers_id: carrierIdstoSave.join(','),
      truck_cost: truckCosttoSave.join(','),
      expiration: expirationtoSave.join(','),
      capacity: capacitytoSave.join(','),
      rating: ratingtoSave.join(',')
    }
    saveWaterFallCarrierData(payLoad).then(result => {
      if (result.message === 'success') {
        if (selectedCarrier.length > 0) {
          NotificationManager.success('Successful', 'Routes Added', 3500)
        } else {
          NotificationManager.error('No Carriers Selected', 'Routes Not Added', 3500)
        }
        setWaterFallData([...selectedCarrier])
        modalClose()
      }
      if (result.message === 'failure') {
        NotificationManager.error('Failure', 'Error Saving Routes', 3500)
        modalClose()
      }
    }).catch((error) => {
      // console.log(error)
    })
  }

  const removeCarriersfromModal = (carrierdata) => {
    var customData = selectedCarrier.filter((data) => { return data.id !== carrierdata.id })
    removeWaterFallCarriers(carrierdata.id, carrierData.id).then((result) => {
      if (result.message === 'success') {
        NotificationManager.success('Deleted Successfully', deleteData.carrier_name, 3500)
      }
    }).catch((error) => {
      // console.log(error)
    })
    setSelectedCarrier(customData)
    setSelectedCarrierListData(customData)
  }

  const modalClose = () => {
    SetModal(false);
    setSelectedCarrier([]);
    setSelectedCarrierListData([])
  }

  const setDatePickerValue = (date, id) => {
    var selectedCarrierData = selectedCarrierListData.map((data) => ({
      ...data,
      expiration: (data.id === id) ? date : data.expiration
    }))
    setSelectedCarrier(selectedCarrierData)
    setSelectedCarrierListData(selectedCarrierData)
  }

  const setInputValues = (id, e) => {
    const { name, value } = e.target
    var selectedCarrierData = selectedCarrierListData.map((data) => ({
      ...data,
      rating: (name==='rating' && data.id===id)?value:data.rating,
      capacity: (name === 'capacity' && data.id === id) ? value : data.capacity,
      truck_cost: (name === 'truck_cost' && data.id === id) ? value : data.truck_cost
    }))
    setSelectedCarrier(selectedCarrierData)
    setSelectedCarrierListData(selectedCarrierData)
  }

  const ModalPopUp = () => {
    SetModal(!isModalShow)
    if (WaterFallData && WaterFallData.length > 0) {
      setSelectedCarrier(WaterFallData)
      setSelectedCarrierListData(WaterFallData)
    }
  }

  const deleteModalClose = () => {
    setShowDeleteModal(false)
  }

  const deleteModalPopUp = (index, data) => {
    setShowDeleteModal(true);
    setDeleteData(() => ({
      index: index,
      data: data
    }))
  }

  const deleteDataModal = () => {
    if (deleteData && deleteData.index === null && deleteData.data) {
      removeCarriersfromModal(deleteData.data)
      setShowDeleteModal(false)
    } else {
      removeRecord(deleteData.index)
      setShowDeleteModal(false)
    }
  }

  const changeNextLoadOrder = (isClicked, dataObject) => {
    {
      setNextLoadChanged(isClicked)
      setNextLoadData(dataObject)
    }
  }

  const sortingData = (field) => {
    if (field === apiFieldName) {
      setDescending((prevState) => !prevState)
    } else {
      setDescending(false)
    }
    setSortData(true);
    setApiFieldName(field);
  }

  useEffect(() => {
    const sortTextData = ['carrier_name', 'email', 'truck_cost', 'expiration']
    if (sortData) {
      if (!descending) {
        sortTextData.map((field) => {
          if (field === apiFieldName) {
            var sortedData = WaterFallData.sort((a, b) => (a[field] !== null ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
            setSortData(false);
            setWaterFallData(sortedData);
          } else {
            setSortData(false);
            var sortedData = WaterFallData.sort((a, b) => (a[apiFieldName] !== null ? a[apiFieldName] : 'zzz') - (b[apiFieldName] !== null ? b[apiFieldName] : 'zzz'));
            setWaterFallData(sortedData);
          }
        })
      } else {
        sortTextData.map((field) => {
          if (field === apiFieldName) {
            var sortedData = WaterFallData.sort((a, b) => (b[field] !== null ? b[field] : 'zzz').localeCompare(a[field] !== null ? a[field] : 'zzz'));
            setSortData(false);
            setWaterFallData(sortedData);
          } else {
            setSortData(false);
            var sortedData = WaterFallData.sort((a, b) => (b[apiFieldName] !== null ? b[apiFieldName] : 'zzz') - (a[apiFieldName] !== null ? a[apiFieldName] : 'zzz'));
            setWaterFallData(sortedData);
          }
        })
      }
    }
  }, [sortData])
 

  return (
    <div className="row">
      <div className="col-md-6 col-lg-6 col-xl-6 mb-3">
        <div className="outLineBox rounded p-2 h-100">
          <h2 >Waterfall ({WaterFallData && WaterFallData.length})</h2>
          <div className="table-responsive auto-scroll">
            <PerfectScrollbar>
              <table className="table truck-table mb-0">
                <DragDropContext onDragEnd={handleonDragEnd}>
                  <Droppable droppableId="list">
                    {(provided) => {
                      return <tbody {...provided.droppableProps} ref={provided.innerRef}>
                        {/* <tr>
                        <th></th>
                        <th>Ranking</th>
                        <th>Carrier Name</th>
                        <th>Carrier Email</th>
                        <th>Truck Cost</th>
                        <th>Expiration</th>
                        <th>Capacity</th>
                        <th>&nbsp;</th>
                      </tr> */}

                        <tr>{headers.map((headerData) => {
                          return <th>{headerData.Header} <div className={`d-inline-block ml-1`}><img onClick={() => sortingData(headerData.accessor)} className={`${headerData.sortField ? '' : 'd-none'}`} src={`${headerData.accessor === apiFieldName ? descending ? '/images/ascending-arw.png' : '/images/descending-arw.png' : '/images/updown-arrow.png'}`} /></div></th>//
                        })}
                        </tr>
                        {!WaterFallData && WaterFallData.length === 0 ? (
                          <tr className="text-center text-danger">
                            <td colSpan="18">No Record Found</td>
                          </tr>
                        ) :
                          WaterFallData && WaterFallData.map((item, index) =>
                            <Draggable key={item.id} index={index} draggableId={item.id}>
                              {(provided) => {
                                return <tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                  <td>
                                    <img src="/images/move.svg" />
                                  </td>
                                  <td>{item.rating?item.rating:index+1}</td>
                                  <td>{item.carrier_name ? item.carrier_name : 'NA'}</td>
                                  <td>{item.email ? item.email : 'NA'}</td>
                                  <td>{item.truck_cost ? item.truck_cost : 'NA'}</td>
                                  <td>{item.expiration ? moment(item.expiration).format('MM/DD/YYYY HH:mm') : 'NA'}</td>
                                  <td>{item.capacity ? item.capacity : 'NA'}</td>
                                  <td>
                                    <button class="btn btn-danger btn-circle btn-24" onClick={() => deleteModalPopUp(index, item)}>
                                      <i class="ch ch-close"></i>
                                    </button>
                                  </td>
                                </tr>
                              }}
                              {/* {deleteModal(index)} */}
                            </Draggable>
                          )
                        }
                        {provided.placeholder}
                      </tbody>
                    }}
                  </Droppable>
                </DragDropContext>
              </table>
              <button className="btn btn-pill btn-success my-3 w-25" onClick={() => ModalPopUp()}>Add Routing Option</button>
            </PerfectScrollbar>
          </div>

        </div>
        <Modal className="addRoutingModal" show={isModalShow} onHide={() => modalClose()} >
          <Modal.Header closeButton>
            <Modal.Title>Add Routing Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddCarrierRoute setValues={setValues} />

            <PerfectScrollbar>
              <table class="mt-3 table truck-table mb-3 center">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <th>Carrier Name</th>
                    <th>Carrier Email</th>
                    <th>Truck Cost</th>
                    <th>Rating</th>
                    <th>Expiration</th>
                    <th>Capacity</th>
                    <th>&nbsp;</th>
                  </tr>
                  {selectedCarrier ? selectedCarrier.map((data) => {
                    return <tr>
                      <td>{data.id}</td>
                      <td>{data.carrier_name}</td>
                      <td>{data.email}</td>
                      {/* <td>{data.truck_cost}</td>
                          <td>{data.expiration}</td>
                          <td>{data.capacity}</td> */}
                      <td><input type="number" placeholder="0.00" className="form-control" name='truck_cost' id="" value={data.truck_cost} onChange={(e) => setInputValues(data.id, e)} /></td>
                      {/* <td><input type="text" className="form-control" id="" /></td> */}
                      
                      {/* <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true">
                          Rating
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">1</Dropdown.Item>
                          <Dropdown.Item href="#">2</Dropdown.Item>
                          <Dropdown.Item href="#">3</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}

                      <td>
                      <select id="" name="rating" value={data.rating?data.rating:'N/A'} className="form-control m-2" onChange={(e)=>setInputValues(data.id,e)}>
                        <option value="N/A">N/A</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select></td>
                      
                      <td>
                        <DatePicker
                          name='expiration'
                          onChange={(date) => setDatePickerValue(date, data.id)}
                          selected={data.expiration ? moment(data.expiration).toDate() : moment(Date.now()).toDate()}
                          className="form-control m-1"
                          disabledKeyboardNavigation={true}
                          onKeyDown={(e) => { e.preventDefault() }}
                          autoComplete='off'
                          minDate={Date.now()}
                          isClearable
                          shouldCloseOnSelect={true}
                          placeholderText='Select expiry Date'
                          timeInputLabel="Time:"
                          dateFormat="MM/dd/yyyy HH:mm"
                          showTimeInput
                        /></td>
                      <td><input type="text" className="form-control" name='capacity' id="" value={data.capacity} onChange={(e) => setInputValues(data.id, e)} /></td>
                      <td>
                        <button class="btn btn-danger btn-circle btn-24" onClick={() => deleteModalPopUp(null, data)}>
                          <i class="ch ch-close"></i>
                        </button>
                      </td>
                    </tr>
                  }) :
                    <tr className="text-danger text-center" ><td colSpan='18'>No Data Selected</td></tr>
                  }
                </tbody>
              </table>
            </PerfectScrollbar>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" class="btn btn-success" onClick={() => saveWaterFallData()}>ADD ROUTE</button>
            <button type="button" class="btn btn-danger" onClick={() => modalClose()}>Close</button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal className="" show={showDeleteModal} onHide={() => deleteModalClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to Delete {deleteData.data.carrier_name} ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" class="btn btn-danger " onClick={() => deleteDataModal()}>DELETE</button>
            <button type="button" class="btn btn-secondary" onClick={() => deleteModalClose()}>Close</button>
          </Modal.Footer>
        </Modal>
      </div>
      <NextLoadDestination data={carrierData} orderNo={carrierData.id} city={carrierData.final_city} state={carrierData.final_state}
        isNextLoadChanged={isNextLoadChanged} setNextLoadChanged={setNextLoadChanged} changeNextLoadOrder={changeNextLoadOrder} customData={carrierData}
      />
    </div>

  )
}

export default TransportationDetails
