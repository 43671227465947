import { TRUCK_DETAILS } from "../ActionTypes";

export function addNewTruck() {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.CREATE_NEW_TRUCK
        });
    }
}

export function addNewEmptyLocation(truckId) {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.ADD_TRUCKEMPTYLOCATION,
            payload: truckId
        });
    }
}

export function resetCloned(truckId) {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.RESET_CLONE,
            payload: truckId
        });
    }
}

export function removeTruck(truckId) {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.REMOVE_TRUCK,
            payload: truckId
        });
    }
}

export function changeFilterStatus(status) {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.CHANGE_STATUS,
            status
        });
    }
}

export function changeActiveTab(tabID) {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.CHANGE_ACTIVE_TAB,
            payload: tabID
        });
    }
}

export function changeTruckDetail(truckId, MCNo, CarrierName, SearchCarrierName) {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.ADD_MCNO,
            truckId: truckId,
            MCNo: MCNo,
            CarrierName: CarrierName,
            SearchCarrierName: SearchCarrierName
        });
    }
}

export function changeCarrierInfo(truckId, carrierInfo) {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.SET_CARRIERINFO,
            truckId: truckId,
            carrierInfo: carrierInfo
        });
    }
}

export function setCollapsible(truckId) {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.SET_COLLAPSIBLE,
            payload: truckId
        });
    }
}

export function setTruckEmptyLocation(collection) {
    return dispatch => {
        return dispatch({
            type: TRUCK_DETAILS.SET_TRUCKEMPTYLOCATIONS,
            payload: collection
        });
    }
}

export function fetchUsersList() {
    const fetchOptions = {
        url: `users`,
        method: "get",
        secure: true,
        actionType: USERS.FETCH_USERS_LIST
    };

    return fetchHandler(fetchOptions);
}