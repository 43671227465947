import { result } from 'lodash';
import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { fetchLoadDeliveryStatus, fetchOrderStatus, saveLoadDeliveryStatus } from '../../../../Redux/Action/Dashboard';

const statusHelper = [
  {code:'CL',status:'Planned'},
  {code:'ST',status:'Dispatch'},
  {code:'PE',status:'Arrived @ Shipper'},
  {code:'PX',status:'Picked Up'},
  {code:'DE',status:'Arrived @ Delivery'},
  {code:'DX',status:'Delivered'}
]


function CarrierLoadStatusDetails({ deliveryStatus,carrierData,setDeliveryStatus }) {
  const loadDeliveryStatus = ["Available", "Planned", "Dispatch", "Arrived @ Shipper", "Picked Up", "Arrived @ Delivery", "Delivered", "Accessorial", "Finished"];
  const [statusValue,setStatusValue] = useState('');

  useEffect(()=>{
    if(carrierData.id){
    const payload = {
      order_nbr:carrierData.id
    }
    fetchOrderStatus(payload).then(result=>{
      if(result.message==='success'){
        setStatusValue(result.data.sniper_ltrack_status);
      }
  })
  .catch(err=>console.log(err));
}
  },[carrierData])
 
 
  const saveStatusData = (status) => {
    const payload = {
      order_status:status,
      order_nbr:carrierData.id
    }
    saveLoadDeliveryStatus(payload).then(result=>{
      if(result.message==='success'){
        setDeliveryStatus(status)
        NotificationManager.success(`Status for load ${carrierData.id} Updated to ${status}`,'Status Updated',3500)
      }else{
        NotificationManager.error('Status not Updated. Please try again','Error Updating Status',3500)
      }
    }
    ).catch(err=>{console.log(err)})
    setStatusValue(status)
   }
  return (
    <div className="row">
      <div className="col">
        <ul className="detaieldStatusBar">
          {loadDeliveryStatus.map((status, index) => {
            return <li key={index} className={`${status === statusValue ? 'active badge-success' : ''}`} onClick={()=>{saveStatusData(status)}}>{status}</li>
          })}
        </ul>
      </div>
    </div>
  )
}

export default CarrierLoadStatusDetails
