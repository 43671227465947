import { rules } from "../../../../Utils/Validator";
import { createTextMask } from "redux-form-input-masks";

const phoneMask = createTextMask({
    pattern: '( 999 ) 999-9999',
	guide:false,
	allowEmpty:true
  });

export const orderDetails = [
	{ name: "Division", label: "division" },
	{ name: "Customer", label: "customer" },
	{ name: "Load Type", label: "load_type" },
	{ name: "Load Miles", label: "miles" },
	{ name: "Weight", label: "weight" },
	{ name: "Commodity", label: "commodity" },
	{ name: "Special 1", label: "special1" },
	{ name: "Special 2", label: "special2" },
	{ name: "Temperature ", label: "temperature" },
	{ name: "Equip Type", label: "equiptype" }
];

export const passReasons = [
	{ value: "Commodity", label: "Commodity" },
	{ value: "Weight", label: "Weight" },
	{ value: "Times", label: "Times" },
	{ value: "Shipper", label: "Shipper" },
	{ value: "Bad Destination", label: "Bad Destination" },
	{ value: "Other", label: "Other" }
];

export const formButtons = [
	{ label: "ADD BID", value: "bid", class: "btn-primary" },
	{ label: "BOOK", value: "book", class: "btn-success" },
	{ label: "PASS", value: "pass", class: "btn-danger" }
];

export const requiredFields = {
	bid: ["mcNo", "carrierName", "quotedRate"],
	book: [
		"mcNo",
		"carrierName",
		"truckLocation",
		"dispatcherName",
		"dispatcherPhoneNumber",
		"dispatcherEmail",
		"carrierSafetyRating",
		"carrierInsurance",
		"driverName",
		"driverPhoneNumber",
		"equipmentType",
		"trailerLength",
		"emptyTime",
		"quotedRate"
	],
	pass: ["mcNo"]
};

export const fieldValidations = {
	bid: {},
	book: {
		dispatcherPhoneNumber: [rules.required, rules.PhoneNumber],
		driverPhoneNumber: [rules.required, rules.PhoneNumber],
		dispatcherEmail: [rules.required, rules.email],
		emptyTime: [rules.required, rules.greaterThanETA]
	},
	pass: {}
};

export const loadLaneFields = [
	{
		name: "icc_mc_nbr",
		type: "text",
		placeHolder: "MC No",
		minWidth: 120,
		default: ""
	},
	{
		name: "carrier",
		type: "text",
		placeHolder: "Carrier Name",
		minWidth: 120,
		default: ""
	},
	{
		name: "carrier_abrv",
		type: "text",
		placeHolder: "Carrier Abr",
		minWidth: 70,
		default: ""
	},
	{
		name: "phone",
		type: "text",
		placeHolder: "Carrier Phone",
		minWidth: 70,
		default: ""
	},
	{
		name: "email",
		type: "text",
		placeHolder: "Email",
		minWidth: 70,
		default: ""
	},

	{
		name: "contact",
		type: "text",
		placeHolder: "Contact",
		minWidth: 70,
		default: ""
	},
	{
		name: "count",
		type: "text",
		placeHolder: "Number of Loads",
		minWidth: 70,
		default: ""
	},
	{
		name: "quoted_rate",
		type: "text",
		placeHolder: "Price",
		minWidth: 70,
		default: ""
	}
];

export const targetRateFields = [
	{
		percentage: 20,
		amount: "$250.3",
		profit: "$ 62.2",
		class: "green"
	},
	{
		percentage: 15,
		amount: "$352.0",
		profit: "$ 22.2",
		class: "blue"
	},
	{
		percentage: 13,
		amount: "$656.3",
		profit: "$ 10",
		class: "orange"
	},
	{
		percentage: 10,
		amount: "$723.96",
		profit: "$ 0.6",
		class: "red"
	}
];

export const carrierInfoFields = [
	{
		name: "mcNo",
		type: "text",
		label: "MC No.",
		placeholder: "Enter Number",
		readOnly: true,
		validation:[rules.required]
	},
	{
		name: "carrierName",
		type: "text",
		label: "Carrier Name",
		placeholder: "Enter Name",
		readOnly: true,
		validation:[rules.required]

	},
	{
		name: "truckLocation",
		type: "location",
		label: "Truck Location",
		placeholder: "Enter Location",
		latLng: "locationLatLng"
	},
	{
		name: "dispatcherName",
		type: "text",
		label: "Dispatcher Name",
		placeholder: "Enter Name",
		readOnly: true,
		validation:[rules.required]
	},
	{
		name: "dispatcherPhoneNumber",
		type: "text",
		label: "Dispatcher Phone Number",
		placeholder: "Enter Phone Number",
		validation: [rules.required],
		readOnly: true,
		mask: phoneMask
	},
	{
		name: "dispatcherPhoneExternsion",
		type: "number",
		label: "Dispatcher Phone Externsion",
		placeholder: "Enter Phone Externsion",
		// validation:[rules.required]
	},
	{
		name: "dispatcherEmail",
		type: "text",
		label: "Dispatcher Email",
		placeholder: "Enter Email",
		validation: [rules.email,rules.required],
		readOnly: true
	},
	{
		name: "carrierSafetyRating",
		type: "text",
		label: "Carrier Safety Rating",
		placeholder: "Enter Rate",
		readOnly: true
	},
	{
		name: "carrierInsurance",
		type: "text",
		label: "Carrier Insurance",
		placeholder: "Insurance",
		readOnly: true
	}
];
export const additionalInfoFields = [
	{
		name: "driverName",
		type: "text",
		label: "Driver Name",
		placeholder: "Enter Name"
	},
	{
		name: "driverPhoneNumber",
		type: "tel",
		label: "Driver Phone Number",
		placeholder: "Enter Phone Number",
		validation: [rules.PhoneNumber],
		mask: phoneMask
	}, {
		name: "truckNumber",
		type: "text",
		label: "Truck Number",
		placeholder: "Enter Truck Number"
	},
	{
		name: "trailerNumber",
		type: "text",
		label: "Trailer Number",
		placeholder: "Enter Trailer Number"
	},

	{
		name: "driverPhoneExtension",
		type: "number",
		label: "Driver Phone Extension",
		placeholder: "Enter Phone Extension"
	},
	{
		name: "equipmentType",
		type: "select",
		label: "Equipment Type",
		placeholder: "Enter Equipment",
		options: ["Van", "Van"]
	},
	{
		name: "trailerLength",
		type: "number",
		label: "Trailer Length (ft)",
		placeholder: "Enter Trailer Length"
	},
	{
		name: "driverEmpty",
		type: "select",
		label: "Driver Empty",
		placeholder: "Select",
		options: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }]
	},
	{
		name: "etaForPickup",
		type: "time",
		label: "ETA For Pickup",
		placeholder: "Enter Time"
	},
	{
		name: "emptyTime",
		type: "time",
		label: "Empty Time",
		placeholder: "Enter Time",
		validation: [rules.greaterThanETA]
	}
];

export const activityFields = [
	{
		name: "status",
		type: "text",
		placeHolder: "Bid/Pass",
		minWidth: 100,
		filter: "select",
		options: ["Bid", "Pass"],
		default: ""
	},
	{
		name: "brokerName",
		type: "text",
		placeHolder: "Broker Name",
		minWidth: 120,
		default: ""
	},
	{
		name: "timeEntered",
		type: "text",
		placeHolder: "Time Entered",
		minWidth: 120,
		default: ""
	},
	{
		name: "amount",
		type: "number",
		placeHolder: "Amount",
		minWidth: 100,
		default: ""
	},
	{
		name: "carrier",
		type: "text",
		placeHolder: "Carrier",
		minWidth: 100,
		default: ""
	},
	{
		name: "contact",
		type: "text",
		placeHolder: "Contact",
		minWidth: 110,
		default: ""
	},
	{
		name: "location",
		type: "text",
		placeHolder: "Location",
		minWidth: 100,
		default: ""
	},
	{
		name: "pickDate",
		type: "date",
		placeHolder: "Pick Date",
		filter: "date",
		minWidth: 100,
		default: null
	},
	{
		name: "driverEmpty",
		type: "text",
		placeHolder: "Driver Empty",
		minWidth: 120,
		default: ""
	},

	{
		name: "etaPickup",
		type: "text",
		placeHolder: "ETA for Pickup",
		minWidth: 130,
		default: ""
	},
	{
		name: "emptyTime",
		type: "text",
		placeHolder: "Empty Time",
		minWidth: 120,
		default: ""
	}
];

export const bidListFields = [
	{
		name: "id",
		type: "text",
		placeHolder: "ID",
		minWidth: 40,
		default: ""
	},
	{
		name: "created_at",
		type: "daterange",
		placeHolder: "Created Date",
		filter: "daterange",
		default: null
	},
	{
		name: "broker_name",
		type: "text",
		placeHolder: "Broker Name",
		minWidth: 120,
		default: ""
	},
	{
		name: "carrier_name",
		type: "text",
		placeHolder: "Carrier Name",
		minWidth: 120,
		default: ""
	},
	{
		name: "mc_number",
		type: "text",
		placeHolder: "MC No",
		minWidth: 120,
		default: ""
	},
	{
		name: "carrier_abrv",
		type: "text",
		placeHolder: "Carrier Abr",
		minWidth: 140,
		default: ""
	},
	{
		name: "quoted_rate",
		type: "text",
		placeHolder: "Quoted Rate",
		minWidth: 140,
		default: ""
	},
	{
		name: "dispatcher_name",
		type: "text",
		placeHolder: "Dispatcher Name",
		minWidth: 120,
		default: ""
	},
	{
		name: "dispatcher_phone",
		type: "text",
		placeHolder: "Dispatcher Phone",
		minWidth: 90,
		default: ""
	},
	{
		name: "dispatcher_email",
		type: "text",
		placeHolder: "Dispatcher Email",
		minWidth: 160,
		default: ""
	},
	{
		name: "driver_name",
		type: "text",
		placeHolder: "Driver Name",
		minWidth: 140,
		default: ""
	},
	{
		name: "driver_phone",
		type: "text",
		placeHolder: "Driver Number",
		minWidth: 140,
		default: ""
	},

	{
		name: "location",
		type: "text",
		placeHolder: "Location",
		minWidth: 120,
		default: ""
	},
	// {
	// 	name: "driver_empty",
	// 	type: "text",
	// 	placeHolder: "Driver Empty",
	// 	minWidth: 100,
	// 	default: ""
	// },
	{
		name: "eta_for_pickup",
		type: "time",
		placeHolder: "ETA for Pickup",
		minWidth: 120,
		default: "",
		filter: "time"
	},
	{
		name: "empty_time",
		type: "time",
		placeHolder: "Empty Time",
		minWidth: 70,
		default: "",
		filter: "time"
	}
];

export const passListFields = [
	{
		name: "id",
		type: "text",
		placeHolder: "ID",
		minWidth: 40,
		default: ""
	},
	{
		name: "created_at",
		type: "daterange",
		placeHolder: "Created Date",
		filter: "daterange",
		default: null
	},
	{
		name: "broker_name",
		type: "text",
		placeHolder: "Broker Name",
		minWidth: 120,
		default: ""
	},
	{
		name: "carrier_name",
		type: "text",
		placeHolder: "Carrier Name",
		minWidth: 120,
		default: ""
	},
	{
		name: "carrier_phone",
		type: "text",
		placeHolder: "Carrier Phone",
		minWidth: 70,
		default: ""
	}
];
