import React, { useState, useEffect, useRef } from 'react'
import { NotificationManager } from 'react-notifications'
import { uploadFilesonServer } from './../../../Redux/Action/Dashboard'
import axios from 'axios';
import FileViewer from 'react-file-viewer';
import sample_bitmap from './Documents/samplefiles/sample_bitmap.bmp';
import sample_docx from './Documents/samplefiles/sample_docx.docx';
import sample_xlsx from './Documents/samplefiles/sample_xlsx.xlsx';
import sample_pdf from './Documents/samplefiles/sample_pdf.pdf';
import sample_csv from './Documents/samplefiles/sample_csv.csv';
import sample_jpeg from './Documents/samplefiles/sample_jpeg.jpeg';

const downloadFiles = [sample_bitmap, sample_jpeg, sample_xlsx, sample_csv, sample_docx, sample_pdf]
const EventNames = ['dragenter', 'dragover', 'dragleave', 'drop']
function Document({ orderNo }) {

  const selectFiles = useRef(null)
  const [files, setFiles] = useState([])
  const [fileData, setFileData] = useState([])
  const [previewLink, setPreviewLink] = useState('');

  useEffect(() => {
    let dropArea = document.getElementById('droparea')
    dropArea.addEventListener('dragenter', dragenter_handler, false)
    dropArea.addEventListener('dragleave', dragleave_handler, false)
    dropArea.addEventListener('dragover', dragover_handler, false)
    dropArea.addEventListener('drop', drop_handler, false)

    EventNames.map((eventName) => { dropArea.addEventListener(eventName, preventDefaults, false) })
  }, [])


  const preventDefaults = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const dragenter_handler = (e) => {
    e.preventDefault();
  }

  const dragleave_handler = (e) => {
    e.preventDefault();
    e.stopPropagation()
  }

  const dragover_handler = (e) => {
    e.preventDefault();
    e.stopPropagation()
    e.dataTransfer.dropEffect = "move"
  }

  function drop_handler(e) {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      selectedFiles(e.dataTransfer.files)
      e.stopPropagation()
    }
  }

  useEffect(() => {
    uploadFiles()
  }, [files])


  const selectFilestoUpload = () => {
    selectFiles.current.click()
  }

  const selectedFiles = (fileList) => {

    var newFiles = fileList
    var filterArray = files

    var customData = []
    for (var i = 0; i < newFiles.length; i++) {
      var exists = false
      // for(var j=i+1;j<newFiles.length;j++){
      //   if(newFiles[i].name==newFiles[j].name){
      //     exists = true
      //   }
      // }
      for (var index = 0; index < filterArray.length; index++) {
        if (newFiles[i].name == filterArray[index].name) {
          exists = true
        }
      }
      if (!exists) {
        customData.push(newFiles[i])
      }
    }

    // var customData = []
    // for (var i = 0; i < newFiles.length; i++) {
    //   customData.push(newFiles[i])
    // }
    setFiles(customData)
  }

  const uploadFiles = () => {
    var customData = [...fileData];
    var hasValue = false;
    for (var i = 0; i < files.length; i++) {
      //Restrict upload file with same and type
      const existingFile = customData.filter(m => m.name === files[i].name && m.type === files[i].type);
      if (existingFile.length === 0) {
        hasValue = true;
        customData.push(files[i]);
      }
    }
    if (hasValue)
      setFileData(customData)
  }

  const removeFiles = (e) => {
    const name = e.target.name
    var customData = fileData.filter((file) => { return file.name !== name })
    setFileData(customData)
  }

  const uploadFilestoServer = (e) => {
    const token = localStorage.getItem("CHARIOT_USER_DATA")
      ? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).token
      : null;

    const name = e.target.name
    const fileToUpload = fileData.filter((file) => { return file.name === name })

    var formData = new FormData();
    formData.append('order_offer_id', orderNo)
    formData.append('file', fileToUpload[0])

    axios.post(`${process.env.REACT_APP_BACKEND_API_URL}viewdetail/document_add`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then((result) => {
      if (result.data.success) {
        NotificationManager.success('Document Uploaded Successfully', '', 2000)
        var customData = fileData.filter((file) => { return file.name !== name })
        setFileData(customData)
      }
    }).catch((error) => {
      // console.log(error) 
    })
  }



  const setUploadData = (file) => {
    return (
      <div className="document-progess-item d-flex flex-sm-row flex-column justify-content-between align-items-center mb-3">
        <div className="document-thumb d-flex m-1"></div>
        <div className="document-progress m-1 flex-fill">
          <div className="document-name">{file.name}</div>
          <div class="progress">
            <div class="progress-bar" role="progressbar" valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div className="document-size">{(file.size / 1024).toFixed(2)} MB</div>
        </div>
        <div className="document-buttons m-1">
          <button class="btn btn-info btn-pill mw-104 m-1" name={file.name} onClick={(e) => { uploadFilestoServer(e) }}>Upload</button>
          <button class="btn btn-danger btn-pill mw-104 m-1" name={file.name} onClick={(e) => removeFiles(e)}>Cancel</button>
        </div>
      </div>
    )
  }

  const setDownloadData = (fileLink) => {
    const fileName = fileLink.slice((fileLink.lastIndexOf("/") - 1 >>> 0) + 2);
    return (
      <div className="uploaded-docuemnt-item d-flex flex-sm-row flex-column justify-content-between align-items-center mb-1">
        <div className="document-name flex-fill m-1">{fileName}</div>
        <div className="document-info d-flex justify-content-end align-items-center">
          <div className="document-size m-1">323 KB</div>
          <a className="btn btn-light2 btn-circle btn-32 m-1" onClick={() => setPreviewLink(fileLink)}>
            <i class="ch ch-eye-icon"></i>
          </a>
          <a className="btn btn-light2 btn-circle btn-32 m-1" href={fileLink} download target='_blank'>
            <i class="ch ch-direct-download"></i>
          </a>
        </div>
      </div>
    )
  }

  // const getFileName = (fname) => {
  //   return 
  // }

  // const getFileType = (fname) => {
  //   return 
  // }


  const previewFiles = () => {
    if (previewLink) {
      const fileType = previewLink.slice((previewLink.lastIndexOf(".") - 1 >>> 0) + 2);
      return (
        <div className="no-document d-flex justify-content-center align-items-center h-100">
          <div className="no-docuemnt-box text-center" style={{ height: '100vh', width: '100%' }}>
            <FileViewer
              fileType={fileType}
              filePath={previewLink}
              onError={(error) => console.log(error)} />
            {/* <iframe src={previewLink} alt="" style={{height:'100%',width:'100%'}} allowFullScreen frameborder='0'/> */}
          </div>
        </div>
      )
    } else {
      return (
        <div className="no-document d-flex justify-content-center align-items-center h-100">
          <div className="no-docuemnt-box text-center" >
            <img src="../images/no-document.png" alt="" />
            <p className="h5 text-primary mt-2">Currently No Preview</p>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="document-Section">
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-12 col-xl-6">
            <div className="drag-document-box p-3 mb-3" id='droparea' onClick={selectFilestoUpload}>
              <input name='document' type='file' value='' id='fileInput' multiple='multiple' ref={selectFiles} onChange={(e) => selectedFiles(e.target.files)} hidden />
              <div className="mt-auto text-primary"><p className="mb-0">Drag your files here. <a className="text-primary font-weight-bold">Or select a file from your computer.</a></p></div>
            </div>
            <div className="document-progress-wrapper">
              {
                fileData.map((file) => setUploadData(file))
              }
            </div>
            <h3>Uploaded Documents</h3>
            <div className="uploaded-docuemnt-item-wrapper">
              {
                downloadFiles.map((fileLink) => setDownloadData(fileLink))
              }
            </div>
          </div>
          <div className="col-12 col-xl-6">
            {previewFiles()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Document
