import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

export const renderField = ({
	input,
	label,
	options,
	fileldSize,
	readOnly,
	placeholder,
	type,
	isMulti,
	updateField,
	fieldToUpdate,
	latLng,
	meta: { touched, error, warning }
}) => {
	switch (type) {
		case "select":
			return selectField(fileldSize, label, input, options, isMulti, touched, error, warning);
		case "location":
			return placeAutoCompleteField(
				fileldSize,
				label,
				input,
				touched,
				error,
				warning,
				fieldToUpdate,
				latLng,
				updateField
			);
		case "time":
			return timePicker(fileldSize, label, input, placeholder, error, warning);
		case "number":
			return numberField(fileldSize, label, input, placeholder, type, readOnly, touched, error, warning);
		default:
			return defaultField(fileldSize, label, input, placeholder, type, readOnly, touched, error, warning);
	}
};

const selectField = (fileldSize, label, input, options, isMulti, touched, error, warning) => (
	<div className={`${fileldSize ? fileldSize : "col-md-4"} mb-3 info-select`}>
		<label>{label}</label>
		<Select value={input.value ? input.value : null} options={options} onChange={input.onChange} isMulti={isMulti} />
		{touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
	</div>
);

const handlePlaceSelect = (address, input, updateField, fieldToUpdate) => {
	input.onChange(address);
	geocodeByAddress(address)
		.then((results) => getLatLng(results[0]))
		.then((latLng) => {
			updateField(fieldToUpdate, latLng);
		})
		.catch((error) => console.error("Error", error));
};

const handlePlaceInputOnBlur = (fieldToUpdate, latLng, updateField, input) => {
	if (!latLng) {
		updateField(fieldToUpdate, null);
		input.onChange("");
	}
};

const handlePlaceChange = (val, input, updateField, fieldToUpdate) => {
	input.onChange(val);
	updateField(fieldToUpdate, null);
};

const placeAutoCompleteField = (fileldSize, label, input, touched, error, warning, fieldToUpdate, latLng, updateField) => (
	<div className={`${fileldSize ? fileldSize : "col-md-4"} mb-3 info-select`}>
		<label>{label}</label>
		<PlacesAutocomplete
			value={input.value}
			onChange={(e) => handlePlaceChange(e, input, updateField, fieldToUpdate)}
			onSelect={(address) => handlePlaceSelect(address, input, updateField, fieldToUpdate)}>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
				<div className="autocomplete-container">
					<input
						{...getInputProps({
							placeholder: "Search Places ...",
							className: "location-search-input form-control",
							onBlur: () => handlePlaceInputOnBlur(fieldToUpdate, latLng, updateField, input)
						})}
					/>
					<div className="autocomplete-dropdown-container">
						{loading && <div>Loading...</div>}
						{suggestions.map((suggestion) => {
							return (
								<div className="suggestion-item--active" {...getSuggestionItemProps(suggestion)}>
									<span>{suggestion.description}</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</PlacesAutocomplete>
		{touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
	</div>
);

const timePicker = (fileldSize, label, input, placeholder, error, warning) => (
	<div className={`${fileldSize ? fileldSize : "col-md-4"} mb-3 info-time`}>
		<label>{label}</label>
		<DatePicker
			className="form-control"
			selected={input.value ? input.value : null}
			onChange={input.onChange}
			placeholderText={placeholder}
			isClearable
			showTimeSelect
			showTimeSelectOnly
			timeIntervals={5}
			dateFormat="HH:mm "
			timeFormat="HH:mm"
			timeCaption="Time"
			onChangeRaw={(e) => {
				e.preventDefault();
			}}
		/>
		{(error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>)}
	</div>
);

const numberField = (fileldSize, label, input, placeholder, type, readOnly, touched, error, warning) => (
	<div className={`${fileldSize ? fileldSize : "col-md-4"} mb-3`}>
		<label>{label}</label>
		<input
			{...input}
			autoComplete="off"
			className="form-control "
			placeholder={placeholder}
			type={type}
			disabled={readOnly}
			onKeyDown={keyPress}
		/>
		{touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
	</div>
);

const defaultField = (fileldSize, label, input, placeholder, type, readOnly, touched, error, warning) => (
	<div className={`${fileldSize ? fileldSize : "col-md-4"} mb-3`}>
		<label>{label}</label>
		<input {...input} className="form-control " placeholder={placeholder} type={type} disabled={readOnly} />
		{touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
	</div>
);

const keyPress = (e) => {
	const badValues = [69, 107, 109, 187, 189];
	if (badValues.includes(e.keyCode)) {
		e.preventDefault();
	}
};
