import React, { useState, useEffect } from 'react';
import CollapsibleSection from './CollapsibleSection';
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapsible from "./../../../Utils/Collapse/Collapsible";
import PreloaderInner from '../../../Utils/PreloaderInner';
import { fetchSplitScreenData } from './../../../../Redux/Action/Dashboard'
import { Multiselect } from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import moment from 'moment';
// import { data } from './StaticSplitScreenData.json'


const staticFilterObject = [
    { value: "", type: "text", fieldName: "sourcetype", isFilterOption: false, placeholder: "Source Type", /*isFilterEnabled: true,*/ apiField: "sourcetype", sortField: true },
    { value: "", type: "text", fieldName: "age", isFilterOption: false, placeholder: "Age", /*isFilterEnabled: true,*/ apiField: "age", sortField: true },
    { value: "", type: "datetime", fieldName: "availableTime", isFilterOption: false, placeholder: "Avail", /*isFilterEnabled: true,*/ apiField: "availableTime", sortField: true },
    { value: "", type: "text", fieldName: "equipmentType", isFilterOption: false, placeholder: "Truck", /*isFilterEnabled: true,*/ apiField: "equipmentType", sortField: true },
    { value: "", type: "text", fieldName: "fp", isFilterOption: false, placeholder: "F/P", /*isFilterEnabled: true,*/ apiField: "fp", sortField: true },
    { value: "", type: "text", fieldName: "dho", isFilterOption: false, placeholder: "DH-O", /*isFilterEnabled: true,*/ apiField: "dho", sortField: true },
    { value: "", type: "text", fieldName: "availableCity", isFilterOption: false, placeholder: "Origin", /*isFilterEnabled: true,*/ apiField: "availableCity", sortField: true },
    // { value: "", type: "text", fieldName: "trip", /*isFilterOption: false,*/ placeholder: "Trip", /*isFilterEnabled: true,*/ apiField: "trip", sortField: true },
    { value: "", type: "text", fieldName: "destination", isFilterOption: false, placeholder: "Destination", /*isFilterEnabled: true,*/ apiField: "destination", sortField: true },
    // { value: "", type: "text", fieldName: "dhd", isFilterOption: true, placeholder: "DH-D", /*isFilterEnabled: true,*/ apiField: "dhd", sortField: false },
    { value: "", type: "datetime", fieldName: "eta", isFilterOption: false, placeholder: "ETA", /*isFilterEnabled: true,*/ apiField: "eta", sortField: true },
    { value: "", type: "text", fieldName: "companyName", isFilterOption: false, placeholder: "Company", /*isFilterEnabled: true,*/ apiField: "companyName", sortField: true },
    { value: "", type: "text", fieldName: "contactPhone", isFilterOption: false, placeholder: "Contact", /*isFilterEnabled: true,*/ apiField: "contactPhone", sortField: true },
    { value: "", type: "text", fieldName: "contactEmail", isFilterOption: false, placeholder: "Email", /*isFilterEnabled: true,*/ apiField: "contactEmail", sortField: true },
    { value: "", type: "text", fieldName: "company_pieces", isFilterOption: false, placeholder: "Company Pieces", /*isFilterEnabled: true,*/ apiField: "company_pieces", sortField: true },
    { value: "", type: "text", fieldName: "weight", isFilterOption: false, placeholder: "Weight", /*isFilterEnabled: true,*/ apiField: "weight", sortField: true },
    { value: "", type: "text", fieldName: "length", isFilterOption: false, placeholder: "Length", /*isFilterEnabled: true,*/ apiField: "length", sortField: true },
];

const options = ['TruckerTool', 'DAT', 'Sniper'];


function SplitScreen({ splitScreenData, carrierInfo, setReload, setError, setSplitScreenInitialSize, setRatesReload, ratesReload, setLoadBooked, loadStatus, viewBookData }) {
    const [SplitScreenData, setSplitScreenData] = useState([])
    const [SplitScreenCollection, setSplitScreenCollection] = useState([])
    const [filterObject, setFilterObject] = useState(staticFilterObject);
    const [rowId, selectRow] = useState();
    const [selected, SetSelected] = useState({});
    const [selectAll, setSelectAll] = useState(0);
    const [isLoader, SetLoader] = useState(false);
    const [sortData, setSortData] = useState(false);
    const [apiFieldName, setApiFieldName] = useState('');
    const [descending, setDescending] = useState(false);
    const [dropdownFilterOptions, setDropdownFilterOptions] = useState([]);
    const [availableDate, setAvailableDate] = useState('');

    useEffect(() => {
        setSplitScreenInitialSize('');
    }, [])

    useEffect(() => {
        if(!viewBookData)
{        var payLoad = {
            loadNumber: splitScreenData.id,
            origin_latitude: splitScreenData.latitude,
            origin_longitude: splitScreenData.longitude,
            destination_latitude: splitScreenData.latitude_destination,
            destination_longitude: splitScreenData.longitude_destination,
            city_origin: splitScreenData.current_city, 
            state_origin: splitScreenData.current_state, 
            city_destination: splitScreenData.final_city, 
            state_destination: splitScreenData.final_state,
            pickupdatetime: splitScreenData.pickDate, 
            deliverydatetime: splitScreenData.deliveryDate,
            equiptypecode: splitScreenData.equiptypecode
        }

        if (splitScreenData && carrierInfo && splitScreenData.id) {
            setApiFieldName('');
            SetLoader(prevState => prevState = true);
            fetchSplitScreenData(payLoad).then((result) => {
                if (result.message === 'success') {
                    const customData = result.data.map((rowData)=>({
                        ...rowData,
                        destination:rowData.preferredDestinations.split(',')[0],
                        fp:'F',
                        age:moment(Date.now()).to(rowData.lastVerified)
                    }))
                    setSplitScreenData(customData);
                    setSplitScreenCollection(customData);
                    SetLoader(prevState => prevState = false);
                }
            }).catch(error => {
                // console.log(error)
            })            
        } 
}
        /*---------------------STATIC SPLIT SCREEN DATA ------------------------------------------*/
        // if (splitScreenData && carrierInfo && splitScreenData.id) {
        //     setApiFieldName('');
        //     const customData = data.map((rowData) => ({
        //         ...rowData,
        //         destination: rowData.preferredDestinations.split(',')[0],
        //         fp: 'F',
        //         age: moment(Date.now()).to(rowData.lastVerified)
        //     }))
        //     setSplitScreenData(customData);
        //     setSplitScreenCollection(customData);
        // }
        /*---------------------STATIC SPLIT SCREEN DATA ENDS -------------------------------------*/

        else {
            // setSplitScreenData([])
            SetLoader(prevState => prevState = false);
        }
        return () => {
            setSplitScreenData([])
        }
    }, [splitScreenData])

    const toggleRow = (id) => {
        const newSelected = Object.assign({}, selected);
        newSelected[id] = !selected[id];
        SetSelected(newSelected);
        setSelectAll((prev) => (prev = 0));
    };

    const toggleSelectAll = () => {
        let newSelected = {};
        SplitScreenData.map((x, index) => {
            newSelected[x.id] = selectAll === 0;
        });
        SetSelected(newSelected);
        setSelectAll((prev) => (prev === 0 ? 1 : 0));
    };

    const sortingData = (field) => {
        selectRow('')
        if (field === apiFieldName) {
            setDescending((prevState) => !prevState)
        } else {
            setDescending(false)
        }
        setApiFieldName(field);
        setSortData(true);
    }

    useEffect(() => {
        var sortTextData = ['age', 'sourcetype', 'availableTime', 'equipmentType', 'fp', 'availableCity', 'destination', 'eta', 'companyName', 'contactPhone', 'contactEmail', 'company_pieces'];
        if (sortData) {
            if (!descending) {
                sortTextData.map((field) => {
                    if (field === apiFieldName) {
                        var sortedData = SplitScreenData.sort((a, b) => (a[field] ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
                        setSortData(false);
                        setSplitScreenData(sortedData);
                    } else {
                        setSortData(false);
                        var sortedData = SplitScreenData.sort((a, b) => (a[apiFieldName] !== null ? a[apiFieldName] : 'zzz') - (b[apiFieldName] !== null ? b[apiFieldName] : 'zzz'));
                        setSplitScreenData(sortedData);
                    }
                })
            } else {
                sortTextData.map((field) => {
                    if (field === apiFieldName) {
                        var sortedData = SplitScreenData.sort((a, b) => (b[field] ? b[field] : 'zzz').localeCompare(a[field] !== null ? a[field] : 'zzz'));
                        setSortData(false);
                        setSplitScreenData(sortedData);
                    } else {
                        setSortData(false);
                        var sortedData = SplitScreenData.sort((a, b) => (b[apiFieldName] !== null ? b[apiFieldName] : 'zzz') - (a[apiFieldName] !== null ? a[apiFieldName] : 'zzz'));
                        setSplitScreenData(sortedData);
                    }
                })
            }
        }
    }, [sortData])


    const displaySearchBox = (fieldName) => {
        const updatedFilterObject = filterObject.map((data) => ({
            ...data,
            isFilterOption: data.fieldName === fieldName ? true : false
        }));
        setFilterObject(updatedFilterObject)
    }

    const setTextBoxValue = (e) => {
        const updatedCollection = filterObject.map((data) => ({
            ...data,
            value: data.fieldName === e.target.name ? e.target.value : data.value,
        }));
        setFilterObject(updatedCollection);
        applyFilter(updatedCollection)
    }

    const applyFilter = (currentFilterObject) => {
        let searchData = [];
        const filterValues = currentFilterObject.filter(
            (m) => m.value && m.value.length > 0
        );
        var existingSplitScreenCollection = SplitScreenCollection
        if (dropdownFilterOptions && dropdownFilterOptions.length > 0) {
            var existingSplitScreenCollection = SplitScreenCollection.filter((m) => dropdownFilterOptions && dropdownFilterOptions.indexOf(m.sourcetype) > -1);
        }

        // if (filterDates) {
        //   if (filterDates.startDate && (filterDates.endDate && filterDates.endDate !== "Invalid date")) {
        //     existingSniperCollection = existingSniperCollection.filter(m => m.pickDate && moment(m.pickDate).format("MM/DD/YYYY") >= moment(filterDates.startDate).format("MM/DD/YYYY") && moment(m.pickDate).format("MM/DD/YYYY") <= moment(filterDates.endDate).format("MM/DD/YYYY"));
        //   } else if (filterDates.startDate && (!filterDates.endDate || (filterDates.endDate && filterDates.endDate === "Invalid date"))) {
        //     existingSniperCollection = existingSniperCollection.filter(m => m.pickDate && moment(m.pickDate).format("MM/DD/YYYY") == moment(filterDates.startDate).format("MM/DD/YYYY"));
        //   }
        // }       

        if (filterValues && filterValues.length > 0) {
            existingSplitScreenCollection
                .map((data) => {
                    var isFound = true;
                    for (let index = 0; index < filterValues.length; index++) {
                        const sdata = filterValues[index];
                        if (sdata.type === "datetime" && data[sdata.apiField]) {
                            var existingString = data[sdata.apiField];
                            if (existingString && moment(existingString).format("MM/DD/YYYY") !== sdata.value) {
                                isFound = false;
                            }
                        } else {
                            var existingString = data[sdata.apiField];
                            if (!existingString) {
                                isFound = false;
                            }
                            else if (existingString && existingString.toString().toLowerCase().indexOf(sdata.value.toLowerCase()) === -1) {
                                isFound = false;
                            }
                        }
                        if (index === (filterValues.length - 1)) {
                            if (isFound) {
                                searchData.push(data);
                            }
                        }
                    }
                });
            setSplitScreenData(searchData);
        } else {
            setSplitScreenData(existingSplitScreenCollection);
        }
    };

    /*--------------------------- DROPDOWN FILTER -----------------------------------------------------*/
    const saveFilterOptions = (selectedData) => {
        setDropdownFilterOptions(selectedData)
    }

    useEffect(() => {
        applyFilter(filterObject)
    }, [dropdownFilterOptions]);

    const setDatePickerValue = (dateValue, fieldName) => {
        setAvailableDate(dateValue)
        const updatedCollection = filterObject.map((data) => ({
            ...data,
            value: (data.fieldName === fieldName) ? dateValue ? moment(dateValue).format("MM/DD/YYYY") : null : data.value
        }));
        setFilterObject(updatedCollection);
        applyFilter(updatedCollection);
    }

    /*--------------------------- DROPDOWN FILTER ENDS ------------------------------------------------*/

    const getHeader = () => {
        return (
            <tr>
                <th className="checkboxCol" style={{ position: 'sticky', top: 0, 'z-index': '2' }}>
                    <input
                        type="checkbox"
                        checked={selectAll === 1}
                        onChange={() => toggleSelectAll()}
                    />
                </th>
                {filterObject.map((data, index) => {
                    return (
                        <th scope="col" className="" style={{ position: 'sticky', top: 0, 'z-index': '2' }}>
                            <div className={`d-inline-block`} onClick={() => { displaySearchBox(data.fieldName) }}>
                                {data.isFilterOption ? '' : data.placeholder}
                            </div>

                            {data.fieldName === 'sourcetype' && data.isFilterOption ?
                                <Multiselect
                                    className='d-inline-block'
                                    options={options}
                                    isObject={false}
                                    showCheckbox
                                    selectedValues={dropdownFilterOptions}
                                    onSelect={(selectedData) => saveFilterOptions(selectedData)}
                                    onRemove={(selectedData) => saveFilterOptions(selectedData)}
                                /> : ''}
                            {data.type === 'text' ? <input
                                className={`${data.isFilterOption ? data.fieldName === 'sourcetype' ? 'd-none' : 'd-inline-block' : 'd-none'}`}
                                placeholder={data.placeholder}
                                type='text'
                                value={data.value}
                                onChange={(e) => setTextBoxValue(e)}
                                name={data.fieldName}
                            /> : ''}
                            {data.type === 'datetime' && data.isFilterOption ? <div style={{ 'z-index': '3' }}>
                                <DatePicker
                                    name={data.fieldName}
                                    onChange={(date) => setDatePickerValue(date, data.fieldName)}
                                    selected={availableDate ? availableDate : ''}
                                    dateFormat='MM/dd/yyyy'
                                    className="form-control"
                                    disabledKeyboardNavigation={true}
                                    onKeyDown={(e) => { e.preventDefault() }}
                                    autoComplete='off'
                                    isClearable
                                    shouldCloseOnSelect={true}
                                    placeholderText={data.placeholder}
                                    popperProps={{
                                        positionFixed: true
                                    }}
                                />
                            </div> : ''}
                            <div className={`d-inline-block ml-1`}><img src={`${splitScreenData && data.apiField === apiFieldName ? descending ? 'images/descending-arw.png' : 'images/ascending-arw.png' : 'images/updown-arrow.png'}`} className={`${data.sortField ? '' : 'd-none'}`} onClick={() => sortingData(data.fieldName)} /></div>
                        </th>
                    );
                })}
            </tr>
        );
    };
    const getBody = (data, index) => {
        return (
            <>
                <tr key={data.rowId} className={`${rowId === data.id ? 'selected' : ''}`}>
                    <td className={`checkboxCol load-blocked `}>
                        <input
                            name={"row_" + data.id}
                            type="checkbox"
                            checked={selected[data.id] === true}
                            onChange={() => toggleRow(data.id)}
                        />
                    </td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}
                    >{data.sourcetype ? data.sourcetype : 'DAT'}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.age ? data.age : '---'}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.availableTime ? moment(data.availableTime).format('MM/DD/YYYY') : moment(new Date()).format('MM/DD/YYYY')}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.equipmentType ? data.equipmentType : 'NA'}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.fp ? data.fp : 'F'}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.dho ? data.dho : '---'}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.availableCity ? data.availableCity : '---'}{data.availableState ? ', ' + data.availableState : '---'}</td>
                    {/* <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.trip ? data.trip.toFixed(2) : '---'}</td> */}
                    <td /*className="company-hover-box"*/ onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.destination ? data.destination : data.preferredDestinations.split(',')[0]}
                        {/* <div className="company-hover-box-text">{data.preferredDestinations ? data.preferredDestinations : 'NA'}</div> */}
                    </td>
                    {/* <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.dhd ? data.dhd : '---'}</td> */}
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.eta ? moment(data.eta).format('MM/DD/YYYY HH:mm') : '---'}</td>

                    <td className="company-hover-box" onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.companyName ? data.companyName : 'NA'}
                        <div className="company-hover-box-text">{data.mc ? (data.mc).slice(2) : 'NA'}</div>
                    </td>

                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.contactPhone ? data.contactPhone : 'NA'}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.contactEmail ? data.contactEmail : '---'}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.company_pieces ? data.company_pieces : '---'}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.weight ? data.weight : '---'}</td>
                    <td onClick={() =>
                        selectRow((prev) => (prev === data.id ? "" : data.id))}>{data.length ? data.length : '---'}</td>
                </tr>
                <tr key={"hiddenTr" + data.id} className="hide-table-padding">
                    <td colSpan="18">
                        <Collapsible open={rowId === data.id}>
                            <React.Fragment>
                                {rowId === data.id &&
                                    <CollapsibleSection
                                        truckerToolData={data}
                                        carrierData={splitScreenData}
                                        carrierInfo={carrierInfo}
                                        setReload={setReload}
                                        setError={setError}
                                        ratesReload={ratesReload}
                                        setRatesReload={setRatesReload}
                                        setLoadBooked={setLoadBooked}
                                        loadStatus={loadStatus}
                                    />}
                            </React.Fragment>
                        </Collapsible>
                    </td>
                </tr>
            </>
        );
    };

    return (
        <div className="split-screen-wrapper h-100">
            <div className="split-screen-inner h-100 p-3">
                <PerfectScrollbar>
                    <PreloaderInner hidden={!isLoader} />
                    <table className="table truck-table table-hover company-hover">
                        <tbody>
                            {getHeader()}
                            {SplitScreenData && SplitScreenData.length !== 0 ?
                                SplitScreenData.map((data, index) => { return (getBody(data, index)) })
                                : (<tr className="text-center text-danger">
                                    <td colSpan="18"><div className='extrapadding'>No Record Found</div></td>
                                </tr>)}
                        </tbody>
                    </table>
                </PerfectScrollbar>
            </div>
        </div>
    )
}

export default SplitScreen
