import React from "react";
import LoadOfferHistory from "./../Dashboard/SniperSection/LoadOfferHistory";
import NextLoadDestination from "./../Dashboard/SniperSection/NextLoadDestination";
import TruckInformation from "./../Dashboard/SniperSection/TruckInformation";
import AverageCostLane from "./../Dashboard/SniperSection/AverageCostLane";
import AllRate from "./../Dashboard/SniperSection/AllRate";
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from "moment";
import { fetchSniperList } from "./../../../Redux/Action/Dashboard";
import ReactTable from "react-table";
import { fieldFilters } from "./../CustomUser/Helper";
import { renderColumn, renderDataCount } from "./../../Utils/CustomFilter";
import { Dropdown } from "react-bootstrap";

class SniperList extends React.Component {
    state = {
        selected: {},
		selectAll: 0,
        MCNo: "",
        sniperList: [],
        filteredSniperList: [],
        nonFilterColumns: [
            {
                name: "stops",
                placeHolder: "Stops",
                notSortable: true,
                notFilterable: true,
                width: 60
            },
            {
                placeHolder: "No. of Loads",
                name: "loads",
                notSortable: true,
                notFilterable: true,
                width: 100
            },
            {
                name: "bids",
                placeHolder: "Bids",
                notSortable: true,
                notFilterable: true,
                width: 70
            },
            {
                name: "passes",
                placeHolder: "Passes",
                notSortable: true,
                notFilterable: true,
                width: 80
            },
            {
                name: "actions",
                placeHolder: "",
                notSortable: true,
                notFilterable: true,
                width: 80
            }
		],
		pageSize: 100,
		pageNumber: 0,
		filteredDataLength: 0,
		showPagination: true,
		endDate: null,
		targetRate: "",
		submitting: false,
		id: 0,
		load_type: "",
		target_rate: ""
    }

    static getDerivedStateFromProps(props, state) {
        if(props.MCNo && props.MCNo !== state.MCNo){
            return {
                MCNo : props.MCNo
            }
        }
        return null;
    }

    componentDidMount(){
        fetchSniperList(this.state.MCNo).then(result => {
            if(result.message === "success"){
                this.setState({
                    sniperList : result.data,
                    filteredSniperList : result.data
                });
            }
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.MCNo !== this.state.MCNo) {
            fetchSniperList(this.state.MCNo).then(result => {
                if(result.message === "success"){
                    this.setState({
                        sniperList : result.data,
                        filteredSniperList : result.data
                    });
                }
            });
        }
    }

    childElement = (sniperId) => {
        return <div id="dataRow-1" className="dataRow p-3">
            <div className="row">
                <NextLoadDestination sniperId={sniperId} />
                <LoadOfferHistory sniperId={sniperId} />
            </div>
            <div className="row">
                <TruckInformation />
                <AverageCostLane />
                <AllRate />
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 col-xl-6 mb-3 mb-lg-0">
                    <div className="row ">
                        <div className="col-auto d-flex align-items-center"><h2>Carrier Progress</h2></div>
                        <div className="col-md-10">
                            <ul className="carrierProgress">
                                <li>Waterfall</li>
                                <li className="active">SniperList Eco System</li>
                                <li>Load Boards</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-2 text-left text-md-right px-1">
                    <button className="btn btn-bluegray btn-pill mw-104 m-1">View Details</button>
                    <button className="btn btn-info btn-pill mw-104 m-1">Add Bid</button>
                    <button className="btn btn-success btn-pill mw-104 m-1">Book</button>
                    <button className="btn btn-danger btn-pill mw-104 m-1">Pass</button>
                </div>
            </div>
        </div>
    }

    renderCell = (field, props) => {
		switch (field.name) {
            case "loads":
                 return (props.original && props.original.bids && <span className="badge badge-loads">{props.original.bids.count}</span>) 
            case "bids":
                 return (props.original && props.original.bids && <span className="badge badge-bids">{props.original.bids.count}</span>) 
			case "stops":
                 return (props.original && props.original.stops && <span className="badge badge-stops">{props.original.stops}</span>) 
			case "passes":
                return (props.original && props.original.passes && <span className="badge badge-pass">{props.original.passes.count}</span>)
			case "target_rate":
                return this.renderTargetRate(props);
            case "pickDateTime":
                return props.original.pickDate && props.original.pickTime ? <div>{moment(props.original.pickDate).format("MM/DD/YYYY") + " - " + moment(props.original.pickTime).format("HH:mm")}</div> : "-----------";
            case "deliveryDateTime":
                return props.original.deliveryDate && props.original.deliveryTime ? <div>{moment(props.original.deliveryDate).format("MM/DD/YYYY") + " - " + moment(props.original.deliveryTime).format("HH:mm")}</div> : "-----------";
            case "actions":
                return <Dropdown>
                    <Dropdown.Toggle variant="light btn-circle btn-32">
                        <i className="ch-more-icon"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Add Bid</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Book</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Pass</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">View Details</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            default:
                return props.value || props.value === 0 ? <div>{props.value}</div> : "-----------";
		}
	};

	renderBidDetails = (props) => {
		return (
			<React.Fragment>
				<div className="bid-cell">
					{props.value.count ? (
						<OverlayTrigger placement="left" trigger="focus" overlay={this.showNamesTooltip(props.value)}>
							<button className="btn btn-cell btn-xs btn-secondary" type="button">
								{props.value.count}
							</button>
						</OverlayTrigger>
					) : (
						props.value.count
					)}
				</div>
			</React.Fragment>
		);
	};

	renderTargetRate = (props) => {
		return (
			<React.Fragment>
				<div className="bid-cell">
					{props.original.status === "Available" ? (
						<OverlayTrigger placement="left" trigger={["hover"]} overlay={tooltip("Update Target Rate")}>
							{props.value || props.value === 0 ? <div>{props.value}</div> : "-----------"}
						</OverlayTrigger>
					) : props.value || props.value === 0 ? (
						<div>{props.value}</div>
					) : (
						"-----------"
					)}
				</div>
			</React.Fragment>
		);
	};

    toggleRow = (id) => {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[id] = !this.state.selected[id];
        this.setState({
            selected: newSelected,
            selectAll: 2
        });
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.sniperList.forEach(x => {
                newSelected[x.id] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    resetFilters = (e) => {
		e.preventDefault();
		this.props.updateField("filtered", []);
	};

	columns = [
        {
            Filter: ({ filter, onChange }) => <div className="filter-input col-filter-reset">
                <button className="btn btn-cell btn-sm btn-reset" onClick={this.resetFilters}>
                    <i className="icon icon-refresh" />
                </button>
            </div>,
			Cell: ( rowInfo ) => {
				return (
					<input
						type="checkbox"
						className="checkbox"
					    checked={this.state.selected[rowInfo.original.id] === true}
						onChange={() => this.toggleRow(rowInfo.original.id)}
					/>
				);
			},
			Header: title => {
				return (
					<input
						type="checkbox"
						className="checkbox"
						checked={this.state.selectAll === 1}
						ref={input => {
							if (input) {
								input.indeterminate = this.state.selectAll === 2;
							}
						}}
						onChange={() => this.toggleSelectAll()}
					/>
				);
			},
			sortable: false,
			width: 60
		},  
		...fieldFilters.map((field) =>
			renderColumn(field, this.renderCell, this.resetFilters, this.handleDate, this.handleDateRange)
		),
		...this.state.nonFilterColumns.map((field) => renderColumn(field, this.renderCell, this.resetFilters, this.handleDate))
	];

    render() {
		const { sniperList, pageSize, filtered, showPagination, filteredDataLength, pageNumber } = this.state;
        return <div className="outLineBox rounded p-3">
            <section className="load-list-table-container">
                <PerfectScrollbar>
                    <div className="load-list-table-block">
                        <ReactTable
                            expandedRows={true}
                            hasCheckBox={true}
                            className="table-primary"
                            ref={(r) => (this.loadTable = r)}
                            data={sniperList ? sniperList : []}
                            columns={this.columns}
                            minRows={0}
                            showPagination={showPagination}
                            showPageSizeOptions={false}
                            pageSize={pageSize}
                            page={pageNumber}
                            onPageChange={(pageNumber) => {
                                this.setState({ pageNumber });
                            }}
                            filterable={true}
                            filtered={filtered}
                            onFilteredChange={(filtered) => {
                                this.setState({ filtered, pageNumber: 0 });
                            }}
                            noDataText={(sniperList && filteredDataLength) === 0 ? "No results found!" : ""}
                            previousText=""
                            nextText=""
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick: (e, handleOriginal) => {
                                        if (column.id === "edit") {
                                            this.props.history.push(`/user/${rowInfo.original.id}`);
                                        }
                                        // if (column.id === "checkbox") {
                                        // 	this.toggleRow(rowInfo.original.id);
                                        // }
                                        if (handleOriginal) {
                                            handleOriginal()
                                        }
                                    }
                                }
                            }}
                            SubComponent={(v) => this.childElement(v)}
                        />
                    </div>
                    {filteredDataLength > 0 && (
                        <span className={showPagination ? "results-count" : "results-length"}>
                            {renderDataCount(pageNumber, filteredDataLength, pageSize)}
                        </span>
                    )}
                </PerfectScrollbar>
            </section>
        </div>
    }
}

export default SniperList;