import React, { useState, useEffect } from "react";
import CarrierInformation from "./CarrierInformation";
import Search from "./AutoCompleteSearch";
import { fetchCarrierDetails, fetchEquipmenttypesList } from "./../../../../Redux/Action/Dashboard";
import { changeFilterStatus, changeCarrierInfo } from "./../../../../Redux/Action/TruckDetails";
import { connect } from "react-redux";
import moment from "moment";

const carrierObject = {
  dispatcher_email: '',
  contact_name: '',
  carrier_phone: '',
  home_city_state: '',
  truck_type: 'Van',
  truck_empty_location: '',
  truck_Id: '',
  zip: '',
  available_date_start: moment(new Date()).format('MM/DD/YYYY'),
  available_date_end: moment(new Date()).format('MM/DD/YYYY'),
  carrier_name: '',
  longitude: '',
  latitude: ''
};

function MCSection({ MCNo, carrierInfo, isCloned, toggleSection, truckId, spot, go, hot, changeFilterStatus, changeCarrierInfo, availableDateFilter, SearchCarrierName, cloneEmptyLocation, setTruckPostedReload, setDateSelected, setLatLong, setReloadTruckPosted,isReset,setReset, viewBookData }) {
  const restrictedAccess = JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.restricted_access;
  const [truckTypeList, settrukTypeList] = useState([]);

  useEffect(() => {
    if (MCNo) {
      fetchCarrierDetails(MCNo)
        .then((result) => {
          if (result.message === "success") {
            var objCollection = {
              carrier_name: result.data.carrier_name,
              truck_Id: truckId,
              dispatcher_email: result.data.email,
              contact_name: result.data.contact_name,
              carrier_phone: result.data.phone_number,
              home_city_state: result.data.home_city_state,
              available_date_start: moment(new Date()).format('MM/DD/YYYY'),
              available_date_end: moment(new Date()).format('MM/DD/YYYY'),
              truck_type: 'Van',
              truck_empty_location: isCloned && carrierInfo.truck_empty_location ? carrierInfo.truck_empty_location : '',
              zip: result.data.zip,
              longitude: '',
              latitude: ''
            };
            changeCarrierInfo(truckId, objCollection);
          }
        })
        .catch((error) => {
          //  console.log(error)
        });
    } else {
      changeCarrierInfo(truckId, carrierObject);
      return;
    }
  }, [MCNo]);

  useEffect(() => {
    fetchEquipmenttypesList().then((result) => {
      if (result.message === 'success') {
        settrukTypeList(result.data)
      }
    })
  }, [])

  return (
    <div className="row">
      <div className="col-md-12 col-lg-12 col-xl-3 mb-3 mb-xl-0">
        <div className="row h-100">
          <Search truckId={truckId} SearchCarrierName={SearchCarrierName} />
          <div className={`col-12 pt-2 ${restrictedAccess?'':'d-none'}`}>
            <div className={`hotspot-box d-flex h-100`}>
              <div className="custom-control custom-radio mb-1 mr-1">
                <input
                  onChange={() => changeFilterStatus("spot")}
                  checked={spot}
                  type="checkbox"
                  id="spot"
                  name="spot"
                  className="custom-control-input"
                />
                <label
                  className="custom-control-label sphogo spot-radio"
                  htmlFor="spot"
                >
                  {" "}
                  Spot{" "}
                </label>
              </div>
              <div className="custom-control custom-radio mb-1 mr-1">
                <input
                  onChange={() => changeFilterStatus("go")}
                  checked={go}
                  type="checkbox"
                  id="go"
                  name="go"
                  className="custom-control-input"
                />
                <label
                  className="custom-control-label sphogo go-radio"
                  htmlFor="go"
                >
                  {" "}
                  Go{" "}
                </label>
              </div>
              <div className="custom-control custom-radio mb-1 mr-1">
                <input
                  onChange={() => changeFilterStatus("hot")}
                  checked={hot}
                  type="checkbox"
                  id="hot"
                  name="hot"
                  className="custom-control-input"
                />
                <label
                  className="custom-control-label sphogo hot-radio"
                  htmlFor="hot"
                >
                  {" "}
                  Hot{" "}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CarrierInformation
        cloneEmptyLocation={cloneEmptyLocation}
        truckId={truckId}
        isCloned={isCloned}
        MCNo={MCNo}
        carrierInfo={carrierInfo}
        availableDateFilter={availableDateFilter}
        truckTypeList={truckTypeList}
        setTruckPostedReload={setTruckPostedReload}
        SearchCarrierName={SearchCarrierName}
        setDateSelected={setDateSelected}
        setLatLong={setLatLong}
        setReloadTruckPosted={setReloadTruckPosted}
        isReset={isReset}
        setReset={setReset}
        viewBookData = {viewBookData}
      />

      <div className="col-md-1 col-lg-1 d-flex align-items-center justify-content-md-end">
        <button
          className="btn btn-circle btn-light btn-32"
          onClick={toggleSection}
        >
          <i className="ch ch-arrow_drop_down"></i>
        </button>
        <span className="d-block d-sm-none mx-2">Show More</span>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  changeFilterStatus,
  changeCarrierInfo
};

export default connect(null, mapDispatchToProps)(MCSection);