/* eslint-disable no-unused-expressions */
import React from "react";
import DatePicker from "react-datepicker";
import { Portal } from "react-overlays";
import moment from "moment";
import { extendMoment } from "moment-range";
import Select from "react-select";

const Moment = extendMoment(moment);

export const renderColumn = (field, renderCell, resetFilters, handleDate, handleDateRange, getOptions) => {
	return {
		Header: () => (
			<React.Fragment>
				<span className="td-data">{field.placeHolder}</span>
				<i className="icon icon-sort" />
				<i className="icon icon-download-arrow" />
				<i className="icon icon-upload-arrow" />
			</React.Fragment>
		),
		sortable: field.notSortable ? false : true,
		id: field.name,
		accessor: (row) => {
			let fieldVal = row[field.name];
			if (field.type === "number" && row[field.name]) {
				fieldVal = Number(row[field.name]);
			}
			return fieldVal;
		},
		filterable: field.notFilterable ? false : true,
		minWidth: field.minWidth ? field.minWidth : null,
		Filter: ({ filter, onChange }) =>
			renderFilter(field, filter, onChange, handleDate, resetFilters, getOptions, handleDateRange),
		filterMethod: (filter, row) => renderFilterMethod(filter, row, field),
		Cell: (props) => renderCell(field, props),
		width: "auto",
		className: `col-${field.name}`,
		headerClassName: `col-${field.name}`
	};
};

const renderFilterMethod = (filter, row, field) => {
	switch (field.type) {
		case "date":
			return moment(new Date(row[filter.id]))
				.startOf("day")
				.isSame(filter.value);
		case "daterange":
			let startDate = moment(filter.value[0]).format("DD-MM-YYYY");
			return filter && filter.value.length
				? filter.value.length === 1
					? moment(`${row[filter.id]}.000Z`).format("DD-MM-YYYY") === startDate
					: Moment()
							.range(moment(filter.value[0]).startOf("day"), moment(filter.value[1]).endOf("day"))
							.contains(moment(`${row[filter.id]}.000Z`).startOf("day"))
				: true;
		case "daterangeLocal":
			let startsDate = moment(filter.value[0]).format("DD-MM-YYYY");
			return filter && filter.value.length
				? filter.value.length === 1
					? moment(row[filter.id]).format("DD-MM-YYYY") === startsDate
					: Moment()
							.range(moment(filter.value[0]).startOf("day"), moment(filter.value[1]).endOf("day"))
							.contains(moment(row[filter.id]).startOf("day"))
				: true;
		case "time":
			return moment(row[filter.id]).format("HH:mm") === moment(filter.value).format("HH:mm");
		case "timestring":
			return row[filter.id]
				? moment(`${row[filter.id]}.000Z`).format("HH:mm") === moment(filter.value).format("HH:mm")
				: false;
		case "roles":
			return filter.value ? (row[filter.id] ? Number(row[filter.id].id) === filter.value.value : false) : true;
		case "division":
			return filter.value ? (row[filter.id] ? Number(row[filter.id]) === filter.value.value : false) : true;
		default:
			return row[filter.id]
				? String(row[filter.id])
						.toLowerCase()
						.includes(filter.value.toLowerCase())
				: false;
	}
};

const renderFilter = (field, filter, onChange, handleDate, resetFilters, getOptions, handleDateRange) => {
	switch (field.filter) {
		case "date":
			return dateFilter(field, filter, handleDate, onChange);
		case "daterange":
			return dateRangeFilter(field, filter, handleDateRange, onChange);
		case "timestring":
		case "time":
			return timeFilter(field, filter, handleDate, onChange);
		case "select":
			return selectFilter(field, onChange, getOptions, filter);
		case "reset":
			return resetButton(resetFilters);
		default:
			return defaultFilter(field, filter, onChange);
	}
};

const dateFilter = (field, filter, handleDate, onChange) => (
	<div className={`filter-input p-0`} key={field.name}>
		<DatePicker
			selected={filter ? filter.value : null}
			placeholderText={field.placeHolder}
			popperContainer={calendarContainer}
			isClearable={true}
			className="form-control"
			onChange={(date) => handleDate(date, onChange, field)}
			onChangeRaw={(e) => {
				e.preventDefault();
			}}
		/>
	</div>
);

const dateRangeFilter = (field, filter, handleDateRange, onChange) => {
	let tableRef = null;
	return (
		<label className="date-range-filter-overlay">
			<div className={`filter-input col-${field.name} date-range-picker`} key={field.name}>
				<DatePicker
					placeholderText={field.placeHolder}
					popperContainer={calendarContainer}
					ref={(input) => {
						tableRef = input;
					}}
					className="form-control"
					shouldCloseOnSelect={false}
					selectsStart
					startDate={filter && filter.value ? moment(filter.value[0]) : null}
					endDate={filter && filter.value ? moment(filter.value[1]) : null}
					onChange={(date) => handleDateRange(date, onChange, field, filter, tableRef)}
				/>
			</div>
			{filter && filter.value && filter.value.length > 0 ? (
				<span>
					{filter.value[0] ? moment(filter.value[0]).format("MM/DD/YYYY") : ""}
					{filter.value[1] ? " - " : " "}
					{filter.value[1] ? moment(filter.value[1]).format("MM/DD/YYYY") : ""}
				</span>
			) : (
				<span> {field.placeHolder} </span>
			)}
			{filter && filter.value && filter.value.length ? (
				<button className="clear-date-range" onClick={(event) => clearFilter(onChange, event)}>
					x
				</button>
			) : (
				false
			)}
		</label>
	);
};
const clearFilter = (onChange, e) => {
	onChange([]);
	e.preventDefault();
};

const timeFilter = (field, filter, handleDate, onChange) => (
	<div className={`filter-input p-0`} key={field.name}>
		<DatePicker
			className="form-control"
			selected={filter ? moment(filter.value) : null}
			onChange={(date) => handleDate(date, onChange, field)}
			placeholderText={field.placeHolder}
			popperContainer={calendarContainer}
			isClearable={true}
			showTimeSelect
			showTimeSelectOnly
			timeIntervals={5}
			dateFormat="HH:mm "
			timeFormat="HH:mm"
			timeCaption="Time"
			onChangeRaw={(e) => {
				e.preventDefault();
			}}
		/>
	</div>
);

const selectFilter = (field, onChange, getOptions, filter) => (
	<div className="select-wrap">
		<Select value={filter ? filter.value : null} options={getOptions(field)} isClearable onChange={onChange} />
	</div>
);

const resetButton = (resetFilters) => (
	<div className="filter-input col-filter-reset">
		<button className="btn btn-cell btn-sm btn-reset" onClick={resetFilters}>
			<i className="icon icon-refresh" />
		</button>
	</div>
);

const defaultFilter = (field, filter, onChange) => (
	<input
		type="text"
		className="form-control"
		placeholder={field.placeHolder}
		style={{
			width: "100%"
		}}
		value={filter ? filter.value : ""}
		onChange={(event) => onChange(event.target.value)}
		onKeyPress={(event) => {
			if (event.keyCode === 13 || event.which === 13) {
				event.preventDefault();
			}
		}}
	/>
);

const calendarContainer = ({ children }) => {
	const el = document.getElementById("calendar-portal");
	return <Portal container={el}>{children}</Portal>;
};

export const renderDataCount = (pageNumber, filteredDataLength, pageSize) => {
	if (filteredDataLength === 0) {
		return `0 results`;
	} else {
		let totalPages = Math.floor(filteredDataLength / pageSize);
		if (pageNumber === totalPages) {
			return `${filteredDataLength % pageSize} of ${filteredDataLength} result${filteredDataLength > 1 ? "s" : ""}`;
		} else {
			return `${pageSize} of ${filteredDataLength} result${filteredDataLength > 1 ? "s" : ""}`;
		}
	}
};
