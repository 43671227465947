import React, { Component,useState,useEffect, useRef } from "react";
import moment from "moment";
import { isEqual, pick } from "lodash";
import pickUp from "./../../../../images/pickup.png";
import start from "./../../../../images/start.png";
import delivery from "./../../../../images/delivery.png";
import points from "./../../../../images/points.png";
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Polyline, Marker } from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
const lineSymbol = {
	path: "M 0,-1 0,1",
	strokeOpacity: 1,
	scale: 3
};


export function MapSection({load,markers,setCurrentPosition}) {

	const [origin,setOrigin] = useState(null);
	const [waypoints,setWaypoints] = useState([]);
	const [destinationList,setDestinationList] = useState([]);
	const [prevMarkerValue,setPrevMarkerValue] = useState([]);
	const [coords,setCoords] = useState([]);
	const [currentLocation,setCurrentLocation] = useState([]);
	const prevMarkerRef = useRef();
	const refs = {};

	useEffect(()=>{
		let waypoint = [];
		let destinationListValues = [];
		if (load && load.data && load.data.status === "booked") {
			const bookedData = load.data.bid_pass_data.bids.find((bid) => bid.status === "booked");
			if (bookedData && bookedData.latitude && bookedData.longitude) {
				const point = {location:{
						lat: Number(bookedData.latitude),
						lng: Number(bookedData.longitude)
					}};
					setOrigin(point);
			}
		}
		if(load && load.data && load.data.picks && load.data.picks.length>0){
			load.data.picks.map(pick=>{
				const point = {location:{
					lat:Number(pick.latitude),
					lng:Number(pick.longitude)
				}};
				waypoint.push(point);
			})
		}
		if(load && load.data && load.data.deliveries && load.data.deliveries.length>0){
			load.data.deliveries.map(delivery=>{
				const point = {
					location:{
						lat:Number(delivery.latitude),
						lng:Number(delivery.longitude)
					}
				}
				destinationListValues.push(point);
			});
			setDestinationList(destinationListValues);
			setWaypoints(waypoint);
		}
		setRoute();
		onBoundsChanged();		
	},[])

	useEffect(()=>{
		setRoute();
	},[waypoints,destinationList])

	useEffect(() => {	
		if(prevMarkerValue !== markers){
			if(markers){
				const data = JSON.parse(markers.latest_location);
				const point = [{location:{
					lat:Number(data.lat),
					lng:Number(data.lon)
				}}]
				setCurrentLocation(point);				
			}
			setPrevMarkerValue(markers)
		};
	}, [markers])

	useEffect(() => {
	  onBoundsChanged();
	}, [currentLocation])
	

	const onMapMounted = (ref) => {
		refs.map = ref;
	 }
	const onBoundsChanged = () => { 
		 const bounds = new window.google.maps.LatLngBounds();
		if (load && load.data && load.data.status === "booked") {
			const bookedData = load.data.bid_pass_data.bids.find((bid) => bid.status === "booked");
			if (bookedData && bookedData.latitude && bookedData.longitude) {
				bounds.extend({
					lat: Number(bookedData.latitude),
					lng: Number(bookedData.longitude)
				});
			}
		}
		 if(load && load.data && load.data.picks && load.data.picks.length>0){
			 load.data.picks.map(pick=>{
				bounds.extend({
					lat: Number(pick.latitude),
					lng: Number(pick.longitude)
				})
			 })
		 }
		 if(markers && markers.latest_location){
			 const data = JSON.parse(markers.latest_location)
			 bounds.extend({
				 lat:Number(data.lat),
				 lng:Number(data.lon)
			 })
		 }
		 if(load && load.data && load.data.deliveries && load.data.deliveries.length>0){
			 load.data.deliveries.map(delivery=>{
				 bounds.extend({
					 lat: Number(delivery.latitude),
					 lng: Number(delivery.longitude)
				 })
			 })
		 }
		 refs.map.fitBounds(bounds);
	  }

	const setRoute = () => {
		const directionsService = new window.google.maps.DirectionsService();
		if (waypoints.length > 0 && destinationList.length > 0) {
			directionsService.route({
				origin: origin ? origin : waypoints[0].location,
				destination: destinationList[destinationList.length - 1].location,
				waypoints: [...waypoints,...destinationList],
				travelMode: window.google.maps.TravelMode.DRIVING
			}, (result, status) => {
				if (status === google.maps.DirectionsStatus.OK) {
					const coordinates = result.routes[0].overview_path;
					setCoords(coordinates);
					setCurrentPosition(result.routes[0].legs);
				} else {
					console.error(`Error Fetching Directions ${result}`)
				}
			});
		}
	}

	const renderRoute = () => {
		return (
			coords && <Polyline
				path={coords}
				options={{
					strokeColor: "#535e69",
					strokeOpacity: 0,
					strokeWeight: 5,
					clickable: true,
					icons: [{
						icon: lineSymbol,
						offset: "0",
						repeat: "15px"
					}]
				}}
			></Polyline>
		)
	}

	const renderWayPointMarkers = () => { 
		return (
			waypoints && waypoints.length > 0 &&
			waypoints.map((pick, index) => {
				return (<Marker
					icon={pickUp}
					key={pick.location.lat}
					position={{
						lat: Number(pick.location.lat),
						lng: Number(pick.location.lng)
					}}
				>
					<InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
						<div className="info-custom">
							<div className="info-delivery">Pick Up {waypoints.length > 1 ? index + 1 : ""}</div>
						</div>
					</InfoBox>
				</Marker>)
			})		
		)
	 }
	const renderDestinationMarkers = () => {
		return(
			destinationList && destinationList.length>0 &&
			destinationList.map((destination,index)=>{
				return (<Marker
					icon={delivery}
					key={destination.location.lat}
					position={{
						lat: Number(destination.location.lat),
						lng: Number(destination.location.lng)
					}}
				>
					<InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
						<div className="info-custom">
							<div className="info-delivery">Delivery {destinationList.length > 1 ? index + 1 : ""}</div>
						</div>
					</InfoBox>
				</Marker>)
			})
		)
	}
	const renderCurrentLocation = () => { 
		return(
			currentLocation && currentLocation.length>0 &&
			currentLocation.map(currentPoint=>{
				return(
					<Marker
					icon={points}
					key={currentPoint.location.lat}
					position={{
						lat: Number(currentPoint.location.lat),
						lng: Number(currentPoint.location.lng)
					}}
				>
					<InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
						<div className="info-custom">
							<div className="info-delivery">Current Location {currentPoint.length > 1 ? index + 1 : ""}</div>
						</div>
					</InfoBox>
				</Marker>
				)
			})
		)

	 }


  return (
	<div>
	<GoogleMap 
		ref={onMapMounted}
		defaultZoom={12} 
		defaultCenter={origin} 
		defaultOptions={{
			streetViewControl:false,
			scaleControl:false,
			mapTypeControl:false,
			zoomControl:true,
			fullscreenControl:false,
		}}

	>
			  {coords && renderRoute()}
			  {origin && (
				  <Marker position={origin} icon={start}>
					  <InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
						  <div className="info-custom">
							  <div className="info-origin">Start</div>
						  </div>
					  </InfoBox>
				  </Marker>
			  )}
			  {waypoints && renderWayPointMarkers()}
			  {destinationList && renderDestinationMarkers()}
			  {markers && renderCurrentLocation()}
	</GoogleMap>
	</div>
  )
}

const MapWithADirectionsRenderer = withScriptjs(withGoogleMap(MapSection))

function RouteMap({load,markers,setCurrentPosition}) {
  return (
	 <MapWithADirectionsRenderer 
	 	googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyAOrGDDkQukaz7ROZtIeCDju-YEUcI-u54&v=3.exp&libraries=geometry,drawing,places"}  
		loadingElement= {<div style={{ height: `100%` }} />}
		containerElement= {<div style={{ height: `100%` }} />}
		mapElement= {<div style={{ height: `100%` }} />}
	 	load={load} 
		markers={markers}
		setCurrentPosition={setCurrentPosition}
		/>
  )
}

export default RouteMap

