import React, { Component } from "react";
import { isEqual } from "lodash";
import pickUp from "./../../../images/pickup.png";
import start from "./../../../images/start.png";
import delivery from "./../../../images/delivery.png";
import points from "./../../../images/points.png";
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Polyline, Marker } from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
const lineSymbol = {
	path: "M 0,-1 0,1",
	strokeOpacity: 1,
	scale: 3
};

const MapWithADirectionsRenderer = compose(
	withProps({
		googleMapURL:
			"https://maps.googleapis.com/maps/api/js?key=AIzaSyAOrGDDkQukaz7ROZtIeCDju-YEUcI-u54&v=3.exp&libraries=geometry,drawing,places",
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `100%` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withScriptjs,
	withGoogleMap,
	lifecycle({
		componentWillMount() {
			const refs = {};
			this.setState({
				onMapMounted: (ref) => {
					refs.map = ref;
				},
				onBoundsChanged: () => {
					if (!this.state.boundsChanged)
						this.setState({ boundsChanged: true }, () => {
							const bounds = new window.google.maps.LatLngBounds();
							const { load } = this.props;
							if (load && load.data && load.data.status === "booked") {
								const bookedData = load.data.bid_pass_data.bids.find((bid) => bid.status === "booked");
								if (bookedData && bookedData.latitude && bookedData.longitude) {
									bounds.extend({
										lat: Number(bookedData.latitude),
										lng: Number(bookedData.longitude)
									});
								}
							}
							if (load && load.data && load.data.picks && load.data.picks.length > 0) {
								load.data.picks.forEach((pick) => {
									bounds.extend({
										lat: Number(pick.latitude),
										lng: Number(pick.longitude)
									});
								});
							}
							if (load && load.data && load.data.deliveries && load.data.deliveries.length > 0) {
								load.data.deliveries.forEach((delivery) => {
									bounds.extend({
										lat: Number(delivery.latitude),
										lng: Number(delivery.longitude)
									});
								});
							}
							refs.map.fitBounds(bounds);
						});
				}
			});
		},

		componentDidMount() {
			this.setRoute();
		},

		componentDidUpdate(prevProps) {
			const { load } = this.props;
			if (load && load.data && !isEqual(load.data, prevProps.load.data)) {
				this.setRoute();
			}
		},

		setMarkerLocations(load) {
			let origin = null;
			let waypoints = [];
			let destinationList = [];
			if (load && load.data && load.data.status === "booked") {
				const bookedData = load.data.bid_pass_data.bids.find((bid) => bid.status === "booked");
				if (bookedData && bookedData.latitude && bookedData.longitude) {
					origin = {
						lat: Number(bookedData.latitude),
						lng: Number(bookedData.longitude)
					};
				}
			}
			if (load && load.data && load.data.picks && load.data.picks.length > 0) {
				load.data.picks.forEach((pick) => {
					const point = {
						location: {
							lat: Number(pick.latitude),
							lng: Number(pick.longitude)
						}
					};
					waypoints.push(point);
				});
			}
			if (load && load.data && load.data.deliveries && load.data.deliveries.length > 0) {
				load.data.deliveries.forEach((delivery) => {
					const destinationPoint = {
						location: {
							lat: Number(delivery.latitude),
							lng: Number(delivery.longitude)
						}
					};
					destinationList.push(destinationPoint);
				});
			}
			this.setState({ origin, waypoints, destinationList });
			return { origin, waypoints, destinationList };
		},

		setRoute() {
			const { load } = this.props;
			const { origin, waypoints, destinationList } = this.setMarkerLocations(load);
			const DirectionsService = new window.google.maps.DirectionsService();
			if (destinationList.length > 0 && waypoints.length > 0) {
				DirectionsService.route(
					{
						origin: origin ? origin : waypoints[0].location,
						destination: destinationList[destinationList.length - 1].location,
						waypoints: [...waypoints, ...destinationList],
						travelMode: window.google.maps.TravelMode.DRIVING
					},
					(result, status) => {
						if (status === window.google.maps.DirectionsStatus.OK) {
							const coords = result.routes[0].overview_path;
							this.setState({ coords });
						} else {
							console.error(`error fetching directions ${result}`);
						}
					}
				);
			}
		}
	})
)((props) => {
	const renderDestinationMarkers = () => (
		<React.Fragment>
			{props.destinationList &&
				props.destinationList.length > 0 &&
				props.destinationList.map((destination, index) => (
					<Marker
						position={{
							lat: Number(destination.location.lat),
							lng: Number(destination.location.lng)
						}}
						key={destination.location.lat}
						icon={delivery}>
						<InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
							<div className="info-custom">
								<div className="info-delivery">
									Delivery {props.destinationList.length > 1 ? index + 1 : ""}
								</div>
							</div>
						</InfoBox>
					</Marker>
				))}
		</React.Fragment>
	);

	const renderWayPointMarkers = () => (
		<React.Fragment>
			{props.waypoints &&
				props.waypoints.length > 0 &&
				props.waypoints.map((pick, index) => (
					<Marker
						icon={pickUp}
						key={pick.location.lat}
						position={{
							lat: Number(pick.location.lat),
							lng: Number(pick.location.lng)
						}}>
						<InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
							<div className="info-custom">
								<div className="info-delivery">Pick Up {props.waypoints.length > 1 ? index + 1 : ""}</div>
							</div>
						</InfoBox>
					</Marker>
				))}
		</React.Fragment>
	);

	const renderRoute = () => (
		<React.Fragment>
			{props.coords && (
				<Polyline
					path={props.coords}
					options={{
						strokeColor: "#535e69",
						strokeOpacity: 0,
						strokeWeight: 5,
						clickable: true,
						icons: [
							{
								icon: lineSymbol,
								offset: "0",
								repeat: "15px"
							}
						]
					}}
				/>
			)}
		</React.Fragment>
	);

	return (
		<GoogleMap
			ref={props.onMapMounted}
			onBoundsChanged={props.onBoundsChanged}
			defaultZoom={12}
			defaultOptions={{
				streetViewControl: false,
				scaleControl: false,
				mapTypeControl: false,
				zoomControl: false,
				fullscreenControl: false
			}}
			defaultCenter={props.origin}>
			{renderRoute()}
			{props.origin && (
				<Marker position={props.origin} icon={start}>
					<InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
						<div className="info-custom">
							<div className="info-origin">Start</div>
						</div>
					</InfoBox>
				</Marker>
			)}
			{renderWayPointMarkers()}
			{renderDestinationMarkers()}
		</GoogleMap>
	);
});

export default class RouteMap extends Component {
	render() {
		const { load } = this.props;
		return <MapWithADirectionsRenderer load={load} />;
	}
}
