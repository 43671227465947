import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Auth Module
import Login from "./../PageContainer/Auth/Login";
import SetPassword from "./../PageContainer/Auth/SetPassword";
import ForgotPassword from "./../PageContainer/Auth/ForgotPassword";

// Main Module
import Dashboard from "./../PageContainer/Dashboard/Dashboard";
import Index from "../PageContainer/ViewDetails/Index";
import ViewDetailsTest from "./../PageContainer/ViewDetails/ViewDetailstest";
import CustomDashboard from "./../PageContainer/Dashboard/CustomDashboard";
import OrderDetails from "./../PageContainer/OrderDetails/OrderDetails";
import LoadList from "./../PageContainer/LoadList/LoadList";

// Layout
import Header from "./../Layout/Header";
import NavPanel from "./../Layout/NavPanel";
import UserList from "./../PageContainer/Users/List";
import AddUser from "./../PageContainer/Users/Add";
import CustomUserList from "./../PageContainer/CustomUser/List";
import AddCarrierUser from "./../PageContainer/Carrierusers/Add";
import CarrierForms from "../PageContainer/Carrierusers/Forms";
import CarrierUserList from "./../PageContainer/Carrierusers/List";
import ListwithPagination from "../PageContainer/Carrierusers/ListwithPagination";

// Booking Status
import BookingStatus from "./../PageContainer/BookingStatus/BookingStatus";

export const BasicRouter = () => (
	<React.Fragment>
		<div className="wrapper">
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/forgot/password" component={ForgotPassword} />
				<Route exact path="/set/password" component={SetPassword} />
				<Route exact path="/carrier/:loadType/:id/:token" component={OrderDetails} />
				<Route exact path="/status/:orderno/:carrierId" component={BookingStatus}/>
				<Redirect to="/login" />
			</Switch>
		</div>
	</React.Fragment>
);

export class AuthRouter extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="wrapper">
					<div id="calendar-portal" />
					<Header />
					<div className="main-container">
						<NavPanel />
						<div className="body-wrapper">
							<Switch>
								<Route exact path="/" component={CustomDashboard} />
								<Route exact path="/test" component={Dashboard} />
								<Route exact path="/viewdetails/:loadType/:orderid" component={Index} />{/* "/viewdetails/:mcno/:orderno" */}
								<Route exact path="/viewdetailstest" component={ViewDetailsTest} />
								<Route exact path="/users" component={UserList} />
								<Route exact path="/users/new" component={AddUser} />
								<Route exact path="/user/:id" component={AddUser} />
								<Route exact path="/carrierusers" component={ListwithPagination} />
								<Route exact path="/carrieruser/:id" component={CarrierForms} />
								<Route exact path="/testuser" component={CustomUserList} />
								<Route exact path="/sniper" component={LoadList} />
								<Route exact path="/load/:loadType/:id" component={OrderDetails} />
								<Route exact path="/carrier/:loadType/:id/:token" component={OrderDetails} />
								<Route exact path="/status/:orderno/:carrierId" component={BookingStatus}/>
								<Redirect to="/" />
							</Switch>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
