import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { fieldFilters } from "./Helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { renderColumn, renderDataCount } from "./../../Utils/Filter";
import TargetRateModal from "./../../Utils/TargetRateModal";
import { tooltip } from "./../../Utils/TooltipBuilder";
import { NotificationManager } from "react-notifications";
import { setTargetRate, updateField } from "../../../Redux/Action/LoadDetails";
import { fetchLoadList } from "../../../Redux/Action/Loads";

class LoadListTable extends Component {
	state = {
		nonFilterColumns: [
			{
				name: "stops",
				placeHolder: "Stops",
				notSortable: true,
				filter: "reset"
			},
			{
				name: "bids",
				placeHolder: "Bids",
				notSortable: true,
				notFilterable: true
			},
			{
				name: "passes",
				placeHolder: "Passes",
				notSortable: true,
				notFilterable: true
			}
		],
		pageSize: 100,
		pageNumber: 0,
		filteredDataLength: 0,
		showPagination: true,
		endDate: null,
		targetRate: "",
		submitting: false,
		id: 0,
		load_type: "",
		target_rate: ""
	};

	componentDidMount() {
		if (this.props.loading) {
			this.setState({ showPagination: false });
		}
	}

	componentDidUpdate() {
		const { pageSize, showPagination, filteredDataLength } = this.state;

		if (this.loadTable) {
			let currentDataLength = this.loadTable.getResolvedState().sortedData.length;
			if (currentDataLength !== filteredDataLength) {
				this.setState({ filteredDataLength: currentDataLength });
			}
			let show = currentDataLength > pageSize;
			if (show !== showPagination) {
				this.setState({ showPagination: show });
			}
		}
	}

	resetFilters = (e) => {
		e.preventDefault();
		this.props.updateField("filtered", []);
	};

	renderRateModal = (e) => {
		e.preventDefault();
		this.props.updateField("showRateModal", true);
	};

	renderTargetRateModal = () => {
		const { target_rate, submitting } = this.state;
		const { showRateModal } = this.props;
		return (
			<TargetRateModal
				showRateModal={showRateModal}
				targetRate={target_rate}
				submitting={submitting}
				hideModal={this.hideModal}
				setTargetRate={this.setTargetRate}
				updateTargetValue={this.updateTargetValue}
			/>
		);
	};

	hideModal = () => {
		this.props.updateField("showRateModal", false);
		this.setState({ target_rate: "", submitting: false });
	};

	updateTargetValue = (e) => {
		this.setState({ target_rate: e.target.value });
	};

	setTargetRate = () => {
		this.setState({ submitting: true });
		let payload = {
			load_id: this.state.id,
			load_type: this.state.load_type,
			target_rate: this.state.target_rate
		};
		const successHandler = (res) => {
			this.setState({ target_rate: "", submitting: false });
			this.props.updateField("showRateModal", false);
			if (200 <= res.status && res.status < 300) {
				this.props.fetchLoadList();
				NotificationManager.success(`Target rate updated`, "", 5000);
			} else {
				NotificationManager.error(res.statusText, "", 5000);
			}
		};
		this.props.setTargetRate(payload, successHandler);
	};

	handleDate = (date, updateFilter, field) => {
		const { filtered } = this.props;
		if (date) {
			updateFilter(date);
		} else {
			let newFilter = filtered.filter((val) => val.id !== field.name);
			this.props.updateField("filtered", newFilter);
		}
	};

	handleDateRange = (date, updateFilter, field, filter, tableRef) => {
		if (tableRef && filter && filter.value && filter.value.length) {
			tableRef.cancelFocusInput();
			tableRef.setOpen(false);
		}
		const { filtered } = this.props;
		if (date) {
			let dateRangeArray = [];

			if (!filter) {
				dateRangeArray.push(date);
			} else {
				dateRangeArray = filter.value;

				filter.value.length < 2
					? dateRangeArray[0] > date
						? dateRangeArray.unshift(date)
						: dateRangeArray.push(date)
					: date <= dateRangeArray[1]
						? (dateRangeArray[0] = date)
						: (dateRangeArray[1] = date);
			}

			updateFilter(dateRangeArray);
		} else {
			let newFilter = filtered.filter((val) => val.id !== field.name);
			this.props.updateField("filtered", newFilter);
		}
	};

	showNamesTooltip = (data) => {
		let maxCount = 10;
		let list = data.names;
		let count = list.length;
		let newList = list.filter((item, index) => {
			return index < maxCount - 1;
		});
		return (
			<Tooltip>
				<React.Fragment>
					<ul className="basic-list">
						{newList.map((item, index) => {
							return <li key={index}>{item.carrier_name}</li>;
						})}
					</ul>
					{count > maxCount ? (
						<div className="pt-2 pb-2">
							<span
								className="text-link"
								onClick={() => {
									console.log(list);
								}}>
								{`${count - maxCount} more...`}
							</span>
						</div>
					) : null}
				</React.Fragment>
			</Tooltip>
		);
	};

	renderCell = (field, props) => {
		switch (field.name) {
			case "bids":
			case "passes":
				return this.renderBidDetails(props);
			case "target_rate":
				return this.renderTargetRate(props);
			case "pickDate":
			case "deliveryDate":
				return props.value ? <div>{moment(props.value).format("MM/DD/YYYY")}</div> : "-----------";
			case "pickTime":
			case "deliveryTime":
				return props.value ? <div>{moment(props.value).format("HH:mm")}</div> : "-----------";
			default:
				return props.value || props.value === 0 ? <div>{props.value}</div> : "-----------";
		}
	};

	renderBidDetails = (props) => {
		return (
			<React.Fragment>
				<div className="bid-cell">
					{props.value.count ? (
						<OverlayTrigger placement="left" trigger="focus" overlay={this.showNamesTooltip(props.value)}>
							<button className="btn btn-cell btn-xs btn-secondary" type="button">
								{props.value.count}
							</button>
						</OverlayTrigger>
					) : (
						props.value.count
					)}
				</div>
			</React.Fragment>
		);
	};

	renderTargetRate = (props) => {
		return (
			<React.Fragment>
				<div className="bid-cell">
					{props.original.status === "Available" ? (
						<OverlayTrigger placement="left" trigger={["hover"]} overlay={tooltip("Update Target Rate")}>
							{props.value || props.value === 0 ? <div>{props.value}</div> : "-----------"}
						</OverlayTrigger>
					) : props.value || props.value === 0 ? (
						<div>{props.value}</div>
					) : (
						"-----------"
					)}
				</div>
			</React.Fragment>
		);
	};

	columns = [
		...fieldFilters.map((field) =>
			renderColumn(field, this.renderCell, this.resetFilters, this.handleDate, this.handleDateRange)
		),
		...this.state.nonFilterColumns.map((field) => renderColumn(field, this.renderCell, this.resetFilters, this.handleDate))
	];
	render() {
		const { data, loading, pageNumber, filtered } = this.props;
		const { pageSize, showPagination, filteredDataLength } = this.state;
		return (
			<div>
				<ReactTable
					className="table-primary"
					ref={(r) => (this.loadTable = r)}
					data={data}
					columns={this.columns}
					minRows={0}
					showPagination={showPagination}
					showPageSizeOptions={false}
					pageSize={pageSize}
					page={pageNumber}
					filtered={filtered}
					onPageSizeChange={(pageSize) => {
						this.setState({ pageSize });
					}}
					onPageChange={(pageNumber) => {
						this.props.setValue("pageNumber", pageNumber);
					}}
					onFilteredChange={(filtered) => {
						this.props.setValue("pageNumber", 0);
						this.props.updateField("filtered", filtered);
					}}
					noDataText={loading ? "" : "No results found!"}
					getTrProps={(state, rowInfo) => {
						return {
							className: `tr-${
								rowInfo.original.status === "Booked" ? rowInfo.original.status : rowInfo.original.load_status
							} pointer`
						};
					}}
					getTdProps={(state, rowInfo, column) => {
						return {
							onClick: (event) => {
								if (
									column.id === "target_rate" &&
									rowInfo &&
									(rowInfo.original.status === "Available" || rowInfo.original.status === "Offered")
								) {
									this.setState({
										id: rowInfo.original.p_key,
										load_type: rowInfo.original.load_type
									});
									this.renderRateModal(event);
								} else if (
									rowInfo &&
									!(
										(column.id === "bids" && rowInfo.original.bids.count > 0) ||
										(column.id === "passes" && rowInfo.original.passes.count > 0)
									)
								) {
									this.props.history.push(`load/${rowInfo.original.load_type}/${rowInfo.original.p_key}`);
								}
							}
						};
					}}
					previousText=""
					nextText=""
				/>
				{this.renderTargetRateModal()}
				{filteredDataLength > 0 && (
					<span className={showPagination ? "results-count" : "results-length"}>
						{renderDataCount(pageNumber, filteredDataLength, pageSize)}
					</span>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	showRateModal: state.LoadDetails.showRateModal
});

const mapDispatchToProps = {
	updateField,
	setTargetRate,
	fetchLoadList
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoadListTable);
