import React, { useState, useEffect } from "react";
import data from "./../StaticValues.json";
import BasicTable from "./../../../Utils/BasicTable";
import PerfectScrollbar from 'react-perfect-scrollbar';
import "react-perfect-scrollbar/dist/css/styles.css";
import { fetchLoadOfferHistory, unbookLoad, rejectLoadBooking, confirmLoadBooking } from "./../../../../Redux/Action/Dashboard";
import PreloaderInner from "./../../../Utils/PreloaderInner";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const headers = [
    { accessor: "type", Header: "Type", sortField: true },
    { accessor: "reason", Header: "Reason", sortField: true },
    { accessor: "create_datatime", Header: "Create Date Time", sortField: true },
    { accessor: "broker", Header: "Broker Name", sortField: true },
    { accessor: "carrier_name", Header: "Carrier Name", sortField: true },
    // { accessor: "carrierPhone", Header: "Carrier Phone",sortField:true },
    { accessor: "dispatcher_name", Header: "Dispatcher Name", sortField: true },
    { accessor: "dispatcher_phone", Header: "Dispatcher Phone", sortField: true },
    {accessor: "", Header: "", sortField: false },
    {accessor: "", Header: "", sortField: false },
    {accessor: "", Header: "", sortField: false }
];

export default function LoadOfferHistory({ orderNo, isReload, setReload, brokers, sniperId, loadType, setNextLoadChanged, isNextLoadChanged, updateBidPass, setLoadAvailable, updateStatus }) {
    const [isLoader, SetLoader] = useState(false);
    const [loadOfferHistory, SetLoadOfferHistory] = useState([]);
    const [loadOfferHistoryNumber, setLoadOfferHistoryNumber] = useState(0);
    const [loadOfferHistoryArray, setLoadOfferHistoryArray] = useState([])
    const [countPassBidValues, setCountPassBidValues] = useState({
        bidCount: '',
        passCount: ''
    })
    const [sortData, setSortData] = useState(false);
    const [apiFieldName, setApiFieldName] = useState('');
    const [descending, setDescending] = useState(false);

    useEffect(() => {
        return () => {
            return () => {
                setNextLoadChanged(false)
            }
        }
    }, [])

    useEffect(() => {
        if (orderNo) {
            SetLoader((prevState) => prevState = true);
            fetchLoadOfferHistory(orderNo, sniperId, loadType).then(result => {
                if (result.message && result.message === "success") {
                    const combinedPassBidArray = result.data.load_offer_history;
                    const sortedPassBidArray = combinedPassBidArray.sort((a, b) => new Date(b.create_datatime) - new Date(a.create_datatime))
                    SetLoadOfferHistory((prevState) => prevState = result && sortedPassBidArray);
                    setLoadOfferHistoryNumber((prevState) => prevState = result.data && sortedPassBidArray.length)
                    SetLoader((prevState) => prevState = false);
                    setReload(false);
                } else {
                    SetLoader((prevState) => prevState = false);
                    setReload(false);

                }
            }).catch(error => {
                // console.log(error)
            });
        } else {
            SetLoader((prevState) => prevState = false);
            setReload(false);

            return;
        }
    }, [orderNo])

    useEffect(() => {
        SetLoader((prevState) => prevState = true);
        if (isReload && orderNo) {
            fetchLoadOfferHistory(orderNo, sniperId, loadType).then(result => {
                if (result.message === "success") {
                    const combinedPassBidArray = result.data.load_offer_history;
                    const sortedPassBidArray = combinedPassBidArray.sort((a, b) => new Date(b.create_datatime) - new Date(a.create_datatime))
                    SetLoadOfferHistory((prevState) => prevState = result && sortedPassBidArray);
                    setLoadOfferHistoryNumber((prevState) => prevState = result.data && sortedPassBidArray.length)
                    SetLoader((prevState) => prevState = false);
                    setReload(false);
                    countPassBid(combinedPassBidArray)
                } else {
                    SetLoader((prevState) => prevState = false);
                    setReload(false);
                }
            }).catch(error => {
                // console.log(error)
            });
        } else {
            SetLoader((prevState) => prevState = false);
            setReload(false);
            return;
        }
    }, [isReload])

    useEffect(() => {
        var bidsArray = [];
        var passArray = []
        loadOfferHistory.map((load) => {
            load.type === 'Bid' ? bidsArray.push(load) : passArray.push(load)
        })
        isReload && updateBidPass(orderNo, bidsArray.length, passArray.length)
    }, [loadOfferHistory])

    const sortingData = (field) => {
        if (field === apiFieldName) {
            setDescending((prevState) => !prevState)
        } else {
            setDescending(false)
        }
        setSortData(true);
        setApiFieldName(field);
    }

    useEffect(() => {
        const sortTextData = ['type', 'reason', 'create_datatime', 'broker', 'carrier_name', 'dispatcher_phone','dispatcher_name']
        if (sortData) {
            if (!descending) {
                sortTextData.map((field) => {
                    if (field === apiFieldName) {
                        var sortedData = loadOfferHistory.sort((a, b) => (a[field] !== null ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
                        setSortData(false);
                        setLoadOfferHistoryArray(sortedData);
                    }
                })
            } else {
                sortTextData.map((field) => {
                    if (field === apiFieldName) {
                        var sortedData = loadOfferHistory.sort((a, b) => (b[field] !== null ? b[field] : 'zzz').localeCompare(a[field] !== null ? a[field] : 'zzz'));
                        setSortData(false);
                        setLoadOfferHistoryArray(sortedData);
                    }
                })
            }
        }
    }, [sortData])


    const unbookBookedLoad = () => {
        unbookLoad(orderNo,sniperId).then((res)=>{
            if(res.message==='success'){
                setReload(true);
                NotificationManager.success(res.description, 'Success', 5000);
                setLoadAvailable((prevState)=>({
                    ...prevState,
                    isAvailable:true,
                    offerNo:orderNo
                  }));
            }else{
                NotificationManager.error(res.description,'Error',3500);
            }
           
        })
    }

/* --------------------------------- TT Booked Load Confirm & Reject ------------------------------ */
    const confirmLoad = (data) => {
        // console.log(data.mc_number.split('MC').pop())
        const payload = {
            'mc_number':data.mc_number,
            'carrier_name':data.carrier_name,
            'carrier_phone':data.carrier_phone,
            'carrier_email': data.carrier_email,
            'driver_name':data.dispatcher_name,
            'driver_phone':data.dispatcher_phone,
            'load_id':data.order_id,
            'reason':data.reason
        }

        confirmLoadBooking(payload).then((res)=>{
            if(res.message === 'success'){
                NotificationManager.success(`Load Booking Successful for ${data.offer_nbr}`,`Success`,2500);
                setReload(true);
            }
        }).catch(err=>console.log(err));
    }

    const rejectLoad = (data) => {
        const payload = {
            "order_id": data.order_id,
            "load_number": data.offer_nbr
        }

        rejectLoadBooking(payload).then((res)=>{
            if(res.message === "success"){
                NotificationManager.success(`Booking for ${data.offer_nbr} is Rejected`,'Rejected',2500)
                setReload(true);
                updateStatus(data.offer_nbr);
            }else{
                NotificationManager.error(`Error rejecting Booking for ${data.offer_nbr}`,`Error`,2500)
            }
        })
        .catch((err)=>{
            console.log(err)
        });
    }
    
    



/* ------------------------------- TT Booked Load Confirm & Reject End ------------------------------ */
    return <div className={`outLineBox rounded p-2 h-100 ${isNextLoadChanged ? 'selected' : ''}`}>
        <div className="row">
            <div className="col">
                <h2>Load Offer History ({loadOfferHistoryNumber})</h2>
                <div className="table-responsive auto-scroll">
                    <PreloaderInner hidden={!isLoader} />
                    <PerfectScrollbar>
                        <table className="table truck-table mb-0">
                            <tbody>
                                <tr>{headers.map((headerData) => {
                                    return <th>{headerData.Header} <div className={`d-inline-block ml-1`}><img className={`${headerData.sortField ? '' : 'd-none'}`} onClick={() => sortingData(headerData.accessor)} src={`${headerData.accessor === apiFieldName ? descending ? '/images/ascending-arw.png' : '/images/descending-arw.png' : '/images/updown-arrow.png'}`} /></div></th>
                                })}
                                </tr>
                                {loadOfferHistory && loadOfferHistory.length > 0
                                    ? loadOfferHistory.map((rowData) => {
                                        return <tr>
                                            <td>{rowData.type}</td>
                                            <td>{rowData.reason}</td>
                                            <td>{moment(rowData.create_datatime).format('MM/DD/YYYY HH:mm')}</td>
                                            <td>{rowData.broker}</td>
                                            <td>{rowData.carrier_name}</td>
                                            <td>{rowData.dispatcher_name}</td>
                                            <td>{rowData.dispatcher_phone}</td>
                                            <td><button className={`${rowData.type==='Book'?'btn btn-danger btn-pill':'d-none'}`} onClick={()=>{unbookBookedLoad()}}>UNBOOK</button></td>
                                            <td><button className={`btn btn-pill btn-success ${rowData.type==="booked"?'':'d-none'}`} onClick={()=>confirmLoad(rowData)}>CONFIRM</button></td>
                                            <td><button className={`btn btn-pill btn-danger  ${rowData.type==="booked"?'':'d-none'}`} onClick={()=>rejectLoad(rowData)}>REJECT</button></td>
                                        </tr>
                                    })
                                    : <tr className="text-center text-danger"><td colSpan={headers.length}>No Record Found</td></tr>}
                            </tbody>
                        </table>

                        {/* <BasicTable columns={headers} data={loadOfferHistory} /> */}
                    </PerfectScrollbar>
                </div>
            </div>
        </div>
    </div>

}