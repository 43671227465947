export const statusFilters = [
	{
		label: "Spot",
		field: "spot",
		class: "spot"
	},
	{
		label: "Go",
		field: "go",
		class: "ml-3 go"
	},
	{
		label: "Hot",
		field: "hot",
		class: "ml-3 hot"
	}
];

export const fieldFilters = [
	{
		name: "id",
		type: "number",
		placeHolder: "Order No",
		default: ""
	},
	{
		name: "status",
		type: "text",
		placeHolder: "Status",
		default: ""
	},
	{
		name: "division",
		type: "text",
		placeHolder: "Division",
		default: ""
	},
	{
		name: "target_rate",
		type: "number",
		placeHolder: "Target Rate",
		default: ""
	},
	{
		name: "type_of_load",
		type: "text",
		placeHolder: "Load Type",
		default: ""
	},
	{
		name: "customer",
		type: "text",
		placeHolder: "Customer",
		default: ""
	},
	{
		name: "pickDate",
		type: "daterangeLocal",
		placeHolder: "Pick Date",
		filter: "daterange",
		default: null
	},
	{
		name: "pickTime",
		type: "time",
		placeHolder: "Pick Time",
		filter: "timestring",
		default: ""
	},
	{
		name: "pickLocation",
		type: "text",
		placeHolder: "Pick Location",
		default: ""
	},
	{
		name: "destination",
		type: "text",
		placeHolder: "Destination",
		default: ""
	},
	{
		name: "deliveryDate",
		type: "daterangeLocal",
		placeHolder: "Delivery Date",
		filter: "daterange",
		default: null
	},
	{
		name: "deliveryTime",
		type: "time",
		placeHolder: "Delivery Time",
		filter: "timestring",
		default: ""
	}
];
