import { is } from "date-fns/locale";
import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { fetchRates, fetchTruckInformation } from './../../../../Redux/Action/Dashboard'
import PreloaderInner from "./../../../Utils/PreloaderInner";

export default function AllRate({ save, sendRateValues, setAllInRateValues, error, setError, orderNo, loadType, sniperId, isNextLoadChanged,ratesReload,setRatesReload,loadStatus,viewBookedLoads }) {

    const [AllInRate, setAllInRate] = useState();
    const [isLoader, SetLoader] = useState(false);
    const [isRateChange, setRateChange] = useState(false);
    const [revenue, setRevenue] = useState();
    const [FixedAllInRate,setFixedAllInRate] = useState(0);
    const [Rates, setRates] = useState({
        'target_rate': '',
        'chariot_rate': '',
        'max_by': '',
        'carrier_rate': '',
        'load_id': sniperId,
        'load_type': loadType
    });
    const [rateChangeStatus, setRateChangeStatus] = useState(false)

    const restrictedAccess = JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.restricted_access;

    useEffect(() => {
        SetLoader((prevState) => prevState = true)
        if (sniperId) {
            fetchTruckInformation(loadType, sniperId).then((result) => {
                if (result.message === 'success') {
                    setAllInRate(result.data.charges);
                    setFixedAllInRate(result.data.charges);
                }
            }).catch((error) => {
                // console.log(error) 
            })
        }

    }, [loadType, sniperId])

    useEffect(() => {
        const offer_no = orderNo;//orderNo/10001
        fetchRates(offer_no, loadType).then((result) => {
            if (result.message === 'success') {
                SetLoader((prevState) => prevState = true)
                setRates({
                    target_rate: result.data.rates_get[0].target_rate && result.data.rates_get[0].target_rate !== '0' ? result.data.rates_get[0].target_rate : '',
                    chariot_rate: result.data.rates_get[0].chariot_rate && result.data.rates_get[0].chariot_rate !== '0' ? result.data.rates_get[0].chariot_rate : '',
                    max_by: result.data.rates_get[0].max_by && result.data.rates_get[0].max_by !== '0' ? result.data.rates_get[0].max_by : '',
                    carrier_rate: result.data.rates_get[0].carrier_rate && result.data.rates_get[0].carrier_rate !== '0' ? result.data.rates_get[0].carrier_rate : '',
                    load_id: sniperId,
                    load_type: loadType
                });
                if (revenue > 0) { SetLoader((prevState) => prevState = false) }
                setRatesReload((prevState)=>({
                    ...prevState,
                    DashboardRatesReload : false
                }));
            }
        }).catch((error) => {
            // console.log('No Rates found for this Order')
            setRates((prevValues) => ({
                ...prevValues,
                target_rate: '',
                chariot_rate: '',
                max_by: '',
                carrier_rate: ''
            }));
            setRatesReload((prevState)=>({
                ...prevState,
                DashboardRatesReload : false
            }));
        })
    }, [orderNo, loadType ,ratesReload.DashboardRatesReload ])

    useEffect(() => {
        setAllInRateValues(Rates)
    }, [Rates])

    // useEffect(() => {
    //     if (rateChangeStatus.chariot_rate && rateChangeStatus.carrier_rate && rateChangeStatus.target_rate && rateChangeStatus.max_by) {
    //         setRateChange(true)
    //     }
    // }, [rateChangeStatus])

    const setRateValuesStatus = (event) => {
        setRateChangeStatus(prevValue => prevValue = true)
    }

    useEffect(() => {
        if (rateChangeStatus && sniperId) {
            sendRateValues(Rates)
            setRateChangeStatus(prevValue => prevValue = false)
        }
    }, [rateChangeStatus])


    useEffect(() => {
        const chariotrate = Rates && Rates.chariot_rate ? Rates.chariot_rate : 0
        setRevenue(AllInRate - Number(chariotrate))
    }, [Rates.chariot_rate, AllInRate])

    const setRateValues = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        setRates(prev => ({
            ...prev,
            [name]: value
        }));
        if (name === 'chariot_rate' && value) {
            setError('');
        }
    }

    useEffect(() => {
        SetLoader((prevState) => prevState = false)
    }, [revenue])

    return <div className={`allRateBox outLineBox rounded bg-white px-2 pt-2 mb-3 ${isNextLoadChanged ? 'selected' : ''}`}>
        <PreloaderInner hidden={!isLoader} />
        <form>
            {restrictedAccess ? <h3 className="text-dark">All In Rate : $ {FixedAllInRate? Number(FixedAllInRate).toFixed(2) : '0'}</h3>:<h3></h3>}
            <div className="form-group row mb-0">
                <label htmlFor="target-rate" className={`col-sm-2 col-form-label`}>{restrictedAccess?"Target Rate":"Book It Now Rate"}</label>
                <div className="input-group col-sm-3 mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text hgt-25" id="target-rate">$</span>
                    </div>
                    <input value={Rates.target_rate} disabled={!viewBookedLoads} type="text" className="form-control px-1 hgt-25" name='target_rate' aria-label="target-rate" aria-describedby="target-rate"
                        onChange={(e) => setRateValues(e)} onBlur={(e) => setRateValuesStatus(e)}
                        disabled={restrictedAccess?loadStatus.includes("Booked")?true:false:!restrictedAccess}
                    />
                </div>
                <div className={"col-sm-2 px-0 pt-2"}></div>
                {restrictedAccess && <label htmlFor="max-buy" className="col-sm-2 col-form-label">Max Buy</label>}
                {restrictedAccess && <div className="input-group col-sm-3 mb-1">
                    <div className="input-group-prepend">
                        <span className="input-group-text hgt-25" id="max-buy">$</span>
                    </div>
                    <input value={Rates.max_by} disabled={!viewBookedLoads} type="text" className="form-control px-1 hgt-25" name='max_by' aria-label="max-buy" aria-describedby="max-buy"
                        onChange={(e) => setRateValues(e)} onBlur={(e) => setRateValuesStatus(e)}
                        disabled={loadStatus.includes("Booked")?true:false}
                    />
                </div>}
            </div>
            <div className="form-group row mb-0">
                <label htmlFor="chariot-rate" className={"col-sm-2 col-form-label" + (error ? " text-danger" : "")}>{restrictedAccess?"Chariot Rate":"Offer"}</label>
                <div className="input-group col-sm-3 mb-1">
                    <div className="input-group-prepend">
                        <span className="input-group-text hgt-25" id="chariot-rate">$</span>
                    </div>
                    <input value={Rates.chariot_rate} disabled={!viewBookedLoads}  type="text" className="form-control hgt-25" name='chariot_rate' aria-label="chariot-rate" aria-describedby="chariot-rate"
                        onChange={(e) => setRateValues(e)} onBlur={(e) => setRateValuesStatus(e)}
                        disabled={restrictedAccess?loadStatus.includes("Booked")?true:false:!restrictedAccess}
                    />
                </div>
                <div className={"col-sm-2 px-0 pt-2"}><h3 className={`${revenue>0?'text-success':'text-danger'}`}>$ {revenue ? revenue.toFixed(2) : '0'}</h3></div>
                {restrictedAccess && <label htmlFor="carrier-rate" className={"col-sm-2 col-form-label"}>Carrier Rate</label>}
                {restrictedAccess && <div className="input-group col-sm-3 mb-1">
                    <div className="input-group-prepend">
                        <span className="input-group-text hgt-25" id="carrier-rate">$</span>
                    </div>
                    <input value={Rates.carrier_rate} disabled={!viewBookedLoads} type="text" className="form-control hgt-25" name='carrier_rate' aria-label="carrier-rate" aria-describedby="carrier-rate"
                        onChange={(e) => setRateValues(e)} onBlur={(e) => setRateValuesStatus(e)}
                        disabled={loadStatus.includes("Booked")?true:false}
                    />
                </div>}
            </div>
        </form>
    </div>
}