import React, { useState, useEffect } from "react";
import { fetchTruckInformation } from "./../../../../Redux/Action/Dashboard";
import PreloaderInner from "./../../../Utils/PreloaderInner";

export default function TruckInformation({ loadType, sniperId, setCharges, carrierName, isNextLoadChanged, carrierInfo }) {

  const [isLoader, SetLoader] = useState(false);
  const [truckInfo, SetTruckInfo] = useState({});

  useEffect(() => {
    if (loadType && sniperId) {
      SetLoader((prevState) => prevState = true);
      fetchTruckInformation(loadType, sniperId).then(result => {
        if (result.message === "success") {
          SetTruckInfo((prevState) => prevState = result.data);
          setCharges(Number(result.data.charges))
          SetLoader((prevState) => prevState = false);
        } else {
          SetLoader((prevState) => prevState = false);
          SetTruckInfo({});
          setCharges(0)
        }
      }).catch(error => {
        // console.log(error)
      });
    } else {
      SetLoader((prevState) => prevState = false);
      SetTruckInfo({});
      setCharges(0)
      return;
    }
  }, [loadType, sniperId])


  return (

    <div className={`truckInfoWrapper p-0 ${isNextLoadChanged ? 'selected' : ''}`}>

      <PreloaderInner hidden={!isLoader} />

      <div className="truckInfoItem">
        {/* <span className="">
          <i className="ch ch-truck-icon"></i>
        </span> */}
        <div className="label">Carrier Name</div>
        <p className="text-primary">
          {carrierInfo.carrier_name
            ? carrierInfo.carrier_name
            : "NA"}
        </p>
      </div>
      <div className="truckInfoItem">
        {/* <span className="">
          <i className="ch ch-truck-location"></i>
        </span> */}
        <div className="label">Truck Empty Location</div>
        <p className="text-primary">
          {carrierInfo.truck_empty_location
            ? carrierInfo.truck_empty_location
            : "NA"}{" "}
          <span></span>
        </p>
      </div>

      <div className="truckInfoItem">
        {/* <span className="">
          <i className="ch ch-truck-icon"></i>
        </span> */}
        <div className="label">Vehicle Type</div>
        <p className="text-primary">
          {carrierInfo.truck_type
            ? carrierInfo.truck_type
            : "NA"}
        </p>
      </div>

      <div className="truckInfoItem">
        {/* <span className="">
          <i className="ch ch-chart-icon"></i>
        </span> */}
        <div className="label">Commodity</div>
        <p className="text-primary">
          {truckInfo.commodity
            ? truckInfo.commodity
            : "NA"}
        </p>
      </div>

      <div className="truckInfoItem">
        {/* <span className="">
          <i className="ch ch-weight-icon"></i>
        </span> */}
        <div className="label">Weight</div>
        <p className="text-primary">
          {truckInfo.weight ? truckInfo.weight : "NA"}
        </p>
      </div>

      <div className="truckInfoItem">
        {/* <span className="">
          <i className="ch ch-mile-icon"></i>
        </span> */}
        <div className="label">Miles</div>
        <p className="text-primary">
          {truckInfo.miles ? truckInfo.miles : "NA"}
        </p>
      </div>
    </div>
  )
}