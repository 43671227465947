import React, { useState, useEffect } from "react";
import { fetchAverageCostLane, fetchAverageCostLaneDetails } from './../../../../Redux/Action/Dashboard'
import NotificationManager from "react-notifications/lib/NotificationManager";
import PreloaderInner from "./../../../Utils/PreloaderInner";
import { Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";

const headerData = [
  { header: 'Order', field: 'order_nbr', sortField: true },
  { header: 'Broker', field: 'broker', sortField: true },
  { header: 'Pickup Date', field: 'pickDate', sortField: true },
  { header: 'Pickup Location', field: 'pickLocation', sortField: true },
  { header: 'Destination', field: 'destination', sortField: true },
  { header: 'Revenue', field: 'revenue', sortField: true },
  { header: 'Truck Cost', field: 'truck_cost', sortField: true },
]

export default function AverageCostLane({ data, isNextLoadChanged }) {
  const [isModalShow, SetModal] = useState(false);
  const [averageCost, setAverageCost] = useState({
    'Seven': '', 'Fifteen': '', 'Thirty': '', 'Year': ''
  });
  const [noOfDays, setNoOfDays] = useState('');
  const [ModalTitle, setModalTitle] = useState('')
  const [isLoader, SetLoader] = useState(false);
  const [isDaysLoader, SetDaysLoader] = useState(false);
  const [AverageCostForDays, setAverageCostForDays] = useState([]);
  const [sortData, setSortData] = useState(false);
  const [apiFieldName, setApiFieldName] = useState('');
  const [descending, setDescending] = useState(false);

  useEffect(() => {
    const payLoad = {
      latitude: data.latitude,
      longitude: data.longitude,
      load_type: data.load_type,
      longitude_destination:data.longitude_destination,
      latitude_destination:data.latitude_destination,
      order_id:data.p_key
      }

    SetLoader((prevState) => prevState = true)
    fetchAverageCostLane(payLoad).then((result) => {
      if (result.message === 'success') {
        if (result.data.get_average_cost.length) {
          result.data.get_average_cost.map((quote) => {
            setAverageCost((prevValue) => ({
              ...prevValue,
              [quote.average_days]: Number(quote.quoted_rate_avg).toFixed(2)
            }))
          })
          SetLoader((prevState) => prevState = false)
        } else {
          setAverageCost({
            Seven: '', Fifteen: '', Thirty: '', Year: ''
          })
          SetLoader((prevState) => prevState = false)
        }
      }
    }).catch((error) => {
      //  console.log(error)
    })
  }, [data.id])


  useEffect(() => {
    const payLoad = {
      latitude: data.latitude,
      longitude: data.longitude,
      load_type: data.load_type,
      days: noOfDays?noOfDays:'seven',
      longitude_destination:data.longitude_destination,
      latitude_destination:data.latitude_destination,
      order_id:data.p_key

      // current_city: data.current_city,
      // current_state: data.current_state,
      // final_city: data.final_city,
      // final_state: data.final_state,
      // load_type: data.load_type,
      // days: noOfDays,
    }
    // setApiFieldName('');
    SetDaysLoader(prevValue => prevValue=true)
    fetchAverageCostLaneDetails(payLoad).then((result) => {
      if (result.message === 'success') {
        setAverageCostForDays(result.data)
        SetDaysLoader(prevValue => prevValue=false)
      }
    }).catch((error) => {
      // console.log(error) 
      SetDaysLoader(prevValue => prevValue=false)
    })
  }, [ModalTitle])

  const setModalValues = (value) => {
    setModalTitle(value)
    SetModal(!isModalShow)
  }

  const closeModal = () => {
    SetModal(!isModalShow)
  }

  const sortingData = (field) => {
    if (field === apiFieldName) {
      setDescending((prevState) => !prevState)
    } else {
      setDescending(false)
    }
    setApiFieldName(field)
    setSortData(true)
  }

  useEffect(() => {
    const sortFields = ['broker', 'pickDate', 'pickLocation', 'destination'];
    if (sortData) {
      if (!descending) {
        sortFields.map((field) => {
          if (field === apiFieldName) {
            setSortData(false);
            var sortedData = AverageCostForDays.sort((a, b) => (a[field] !== null ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
            setAverageCostForDays(sortedData);
          } else {
            setSortData(false);
            var sortedData = AverageCostForDays.sort((a, b) => (a[apiFieldName] !== null ? a[apiFieldName] : 'zzz') - (b[apiFieldName] !== null ? b[apiFieldName] : 'zzz'));
            setAverageCostForDays(sortedData);
          }
        })
      } else {
        sortFields.map((field) => {
          if (field === apiFieldName) {
            setSortData(false);
            var sortedData = AverageCostForDays.sort((a, b) => (b[field] !== null ? b[field] : 'zzz').localeCompare(a[field] !== null ? a[field] : 'zzz'));
            setAverageCostForDays(sortedData);
          } else {
            setSortData(false);
            var sortedData = AverageCostForDays.sort((a, b) => (b[apiFieldName] !== null ? b[apiFieldName] : 'zzz') - (a[apiFieldName] !== null ? a[apiFieldName] : 'zzz'));
            setAverageCostForDays(sortedData);
          }
        })
      }
    }
  }, [sortData])

  return (
    <div
      className={`avgCostBox outLineBox rounded px-2 pt-2 mb-0 ${isNextLoadChanged ? "selected" : ""}`}>
      <PreloaderInner hidden={!isLoader} />
      <h3 class="mb-1">Average Cost of Lane</h3>
      <div className="row">
        <div className="col-md-6">
          <div className="avgCostBoxItem mb-1" onClick={() => { setModalValues('7 Days'), setNoOfDays('seven') }}> 7 Days{" "} <span className="ml-auto">
            $ {averageCost.Seven ? averageCost.Seven : "0.00"}
          </span>
          </div>

          {/* <div className="avgCostBoxItem mb-3">
            <div className="custom-control custom-radio" >
              <input
                type="radio"
                id="aclRadio1"
                name="aclRadio"
                className="custom-control-input"
              />
              <label
                onClick={() => setModalValues('7 Days')}
                className="custom-control-label"
                htmlFor="aclRadio1"
              >
                7 Days{" "}
                <span className="ml-auto">
                  $ {averageCost.Seven ? averageCost.Seven : "0"}
                </span>
              </label>
            </div>
          </div> */}
        </div>

        <div className="col-md-6">

          <div className="avgCostBoxItem mb-1" onClick={() => { setModalValues('15 Days'), setNoOfDays('fifteen') }}> 15 Days{" "} <span className="ml-auto">
            $ {averageCost.Fifteen ? averageCost.Fifteen : "0.00"}
          </span>
          </div>

          {/* <div className="avgCostBoxItem mb-3">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="aclRadio2"
                name="aclRadio"
                className="custom-control-input"
              />
              <label onClick={() => setModalValues('15 Days')} className="custom-control-label" htmlFor="aclRadio2">
                15 Days{" "}
                <span className="ml-auto">
                  $ {averageCost.Fifteen ? averageCost.Fifteen : "0"}
                </span>
              </label>
            </div>
          </div> */}
        </div>
        <div className="col-md-6">

          <div className="avgCostBoxItem mb-1" onClick={() => { setModalValues('30 Days'), setNoOfDays('thirty') }}> 30 Days{" "} <span className="ml-auto">
            $ {averageCost.Thirty ? averageCost.Thirty : "0.00"}
          </span>
          </div>
          {/* <div className="avgCostBoxItem mb-3">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="aclRadio3"
                name="aclRadio"
                className="custom-control-input"
              />
              <label onClick={() => setModalValues('30 Days')} className="custom-control-label" htmlFor="aclRadio3">
                30 Days{" "}
                <span className="ml-auto">
                  $ {averageCost.Thirty ? averageCost.Thirty : "0"}
                </span>
              </label>
            </div>
          </div> */}
        </div>
        <div className="col-md-6">

          <div className="avgCostBoxItem mb-1" onClick={() => { setModalValues('1 Year'), setNoOfDays('year') }}>  1 Year{" "} <span className="ml-auto">
            $ {averageCost.Year ? averageCost.Year : "0.00"}
          </span>
          </div>

          {/* <div className="custom-control custom-radio">
              <input
                type="radio"
                id="aclRadio4"
                name="aclRadio"
                className="custom-control-input"
              />
              <label onClick={() => setModalValues('1 Year')} className="custom-control-label" htmlFor="aclRadio4">
                1 Year{" "}
                <span className="ml-auto">
                  $ {averageCost.Year ? averageCost.Year : "0"}
                </span>
              </label>
            </div> */}
        </div>
      </div>

      <Modal className="viewAverageCostModal" show={isModalShow} onHide={() => closeModal()} >
        <Modal.Header closeButton>
          <Modal.Title>{'Average Cost of Lane for - ' + ModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table truck-table" >
            <tbody>
              {/* <tr>
                <th>Order</th>
                <th>Broker</th>
                <th>Pickup Date/Time</th>
                <th>Pickup Location</th>
                <th>Destination</th>
                <th>Revenue</th>
                <th>Truck Cost</th>
              </tr> */}
              <tr>{headerData.map((headerData) => {
                return <th>{headerData.header} <div className={`d-inline-block ml-1`}><img className={`${headerData.sortField ? '' : 'd-none'}`} onClick={() => sortingData(headerData.field)} src={`${headerData.field === apiFieldName ? descending ? '/images/ascending-arw.png' : '/images/descending-arw.png' : '/images/updown-arrow.png'}`} /></div></th>
              })}
              </tr>
              <PreloaderInner hidden={!isDaysLoader} />
              {AverageCostForDays && AverageCostForDays.length > 0 ?
                AverageCostForDays.map((data) => {
                  return <tr>
                    <td>{data.order_nbr?data.order_nbr:''}</td>
                    <td>{data.broker?data.broker:''}</td>
                    <td>{data.pickDate?moment(data.pickDate).format('MM/DD/YYYY'):''}</td>
                    <td>{data.pickLocation?data.pickLocation:''}</td>
                    <td>{data.destination?data.destination:''}</td>
                    <td>{data.revenue?data.revenue:''}</td>
                    <td>{data.truck_cost?data.truck_cost:''}</td>
                  </tr>
                }) : <tr className="text-danger text-center" ><td colSpan='18'>No Data Available</td></tr>
              }
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
}
