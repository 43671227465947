import { AUTH } from "../ActionTypes";

const initialState = {
	activePage: "dashboard",
	showChangePasswordModal: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case AUTH.POST_LOGIN_CREDENTIALS:
			return {
				...state,
				user: action.payload
			};
		case AUTH.UPDATE_LOGIN_CREDENTIALS:
			return {
				...state,
				user: action.payload
			};
		case AUTH.UPDATE_FIELD:
			return {
				...state,
				[action.field]: action.val
			};
		default:
			return state;
	}
}
