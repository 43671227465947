import { rules } from "../../Utils/Validator";

const { required, minLength6, passwordsMatch } = rules;

export const fields = [
	{
		name: "currentpassword",
		label: "Password",
		validate: [required]
	},
	{
		name: "password",
		label: "New Password",
		validate: [required, minLength6]
	},
	{
		name: "confirmPassword",
		label: "Confirm Password",
		validate: [required, minLength6, passwordsMatch]
	}
];
