import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { AuthRouter, BasicRouter } from "./Components/Main/Main";
import { withRouter } from "react-router-dom";
import jwtDecode from "jwt-decode"; 

class App extends Component {
	checkTokenExpiry = (data) => {
		if (JSON.parse(data) && JSON.parse(data).token) {
			let tokenExpiry = jwtDecode(JSON.parse(data).token).exp;
			if (Date.now() / 1000 > tokenExpiry) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	render() {
		let userData = localStorage.getItem("CHARIOT_USER_DATA");
		if (!userData || (userData && this.checkTokenExpiry(userData)) || window.location.search.includes("?token")) {
			return (
				<React.Fragment>
					<BasicRouter />
					<NotificationContainer />
				</React.Fragment>
			);
		}
		return (
			<div className="main-wrap">
				<AuthRouter onScroll={this.onScroll} />
				<NotificationContainer />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(App));
