import { CASE_FILE } from "../ActionTypes";

const initialState = {
	wizardIndex: 1,
	pdfScale: 1,
	previousWizardIndex: 1,
	viewMode: true
};

export default function(state = initialState, action) {
	switch (action.type) {
		case CASE_FILE.FETCH:
			return {
				...state,
				caseDetails: action.payload
			};
		case CASE_FILE.LIST:
			return {
				...state,
				caseList: action.payload
			};
		case CASE_FILE.FETCH_DOCUMENTS:
			return {
				...state,
				documentList: action.payload
			};
		case CASE_FILE.UPDATE_FIELD:
			return {
				...state,
				[action.field]: action.val
			};
		case CASE_FILE.FETCH_CASE_STATUS:
			return {
				...state,
				caseStatus: action.payload
			};
		case CASE_FILE.FETCH_FILES:
			return {
				...state,
				previousDocuments: action.payload
			};
		case CASE_FILE.FETCH_DOCUMENT_TYPES:
			return {
				...state,
				documentTypes: action.payload
			};
		case CASE_FILE.FETCH_DATAPOINTS:
			return {
				...state,
				datapointsList: action.payload
			};
		case CASE_FILE.FETCH_EXTRACTION_PROGRESS:
			return {
				...state,
				extractionProgress: action.payload
			};
		case CASE_FILE.FETCH_CHECKLIST:
			return {
				...state,
				checklistAnalysisList: action.payload
			};
		case CASE_FILE.FETCH_FINAL_REPORT:
			return {
				...state,
				checklistFinalReport: action.payload
			};
		default:
			return state;
	}
}
