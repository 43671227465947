import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ReactTable from "react-table";
import { loadLaneFields } from "./Helper";
import { renderColumn } from "./../../Utils/Filter";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";

export default class LoadLaneDetails extends Component {
	state = {
		showPagination: false,
		pageSize: 1000,
		filtered: [],
		nonFilterColumns: [
			{
				name: "load-reset",
				placeHolder: "",
				notSortable: true,
				filter: "reset"
			}
		]
	};

	componentDidUpdate() {
		const { pageSize, showPagination } = this.state;

		if (this.loadLaneTable) {
			let currentDataLength = this.loadLaneTable.getResolvedState().sortedData.length;
			let show = currentDataLength > pageSize;
			if (show !== showPagination) {
				this.setState({ showPagination: show });
			}
		}
	}

	resetFilters = (e) => {
		e.preventDefault();
		this.setState({ filtered: [] });
	};

	renderCell = (field, props) => {
		const { token } = this.props;
		switch (field.name) {
			case "count":
				return token ? (
					<div className="pt-1 pb-1">{props.value ? props.value : "-----------"}</div>
				) : (
					this.renderCountDetails(props)
				);
			case "load-reset":
				return null;
			default:
				return <div className="pt-1 pb-1">{props.value ? props.value : "-----------"}</div>;
		}
	};

	renderCountDetails = (props) => {
		return (
			<React.Fragment>
				<div>
					{props.value ? (
						<OverlayTrigger placement="left" trigger="focus" overlay={this.showNamesTooltip(props.original)}>
							<button className="btn btn-cell btn-xs btn-secondary" type="button">
								{props.value}
							</button>
						</OverlayTrigger>
					) : (
						props.value
					)}
				</div>
			</React.Fragment>
		);
	};

	showNamesTooltip = (data) => {
		return (
			<Tooltip>
				<React.Fragment>
					<ul className="basic-list">
						{data.orders.length > 0 &&
							data.orders.map((order, index) => {
								return (
									<li onClick={() => this.gotoOrder(order)} className="pointer" key={index}>
										{order.order_nbr}
									</li>
								);
							})}
					</ul>
				</React.Fragment>
			</Tooltip>
		);
	};

	gotoOrder = (order) => {
		this.props.history.push(`/load/order/${order.id}`);
		this.props.updateField("load", false);
		this.props.updateField("currentOrderID", order.id);
		this.props.fetchDetails(`loads/order/${order.id}`);
	};

	columns = [
		...loadLaneFields.map((field) => renderColumn(field, this.renderCell, this.resetFilters)),
		...this.state.nonFilterColumns.map((field) => renderColumn(field, this.renderCell, this.resetFilters))
	];

	renderTable = (data) => {
		const { pageSize, showPagination, filtered } = this.state;
		const { loading } = this.props;
		return (
			<div>
				<Scrollbars autoHeight autoHeightMin={100} autoHeightMax={380}>
					<ReactTable
						className="table-primary"
						data={data}
						ref={(r) => (this.loadLaneTable = r)}
						pageSize={pageSize}
						columns={this.columns}
						minRows={0}
						showPagination={showPagination}
						showPageSizeOptions={false}
						filterable={true}
						filtered={filtered}
						onFilteredChange={(filtered) => {
							this.setState({ filtered });
						}}
						loading={loading}
						noDataText={loading ? "" : "No results found!"}
						previousText=""
						nextText=""
					/>
				</Scrollbars>
			</div>
		);
	};

	render() {
		const { loadList, laneList } = this.props;
		return (
			<div className="load-lane-container">
				<Tabs defaultActiveKey="loads">
					<Tab eventKey="loads" title="Loads">
						{this.renderTable(loadList)}
					</Tab>
					<Tab eventKey="lanes" title="Lanes">
						{this.renderTable(laneList)}
					</Tab>
				</Tabs>
			</div>
		);
	}
}
