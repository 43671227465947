import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change as changeFieldValue, formValueSelector } from "redux-form";
import { isEqual } from "lodash";
import { NotificationManager } from "react-notifications";
import { tooltip } from "./../../Utils/TooltipBuilder";
import { rules } from "./../../Utils/Validator";
import { OverlayTrigger } from "react-bootstrap";
import { updateField, createBid, createPass, fetchCarriersList, fetchDetails } from "./../../../Redux/Action/LoadDetails";
import { carrierInfoFields, additionalInfoFields, targetRateFields, formButtons, requiredFields, fieldValidations } from "./Helper";
import { renderField } from "./../../Utils/Fields";
import PassReasonModal from "./PassReasonModal";

class BidForm extends Component {
	constructor() {
		super();
		this.state = {
			selectedCarrier: null,
			carriersList: [],
			carriersOptions: [],
			equipmentTypesList: [],
			equipmentTypesOptions: [],
			carrierName: "",
			carrierId: "",
			showCarrierList: false,
			carrierLoading: false,
			showReasonModal: false,
			passData: null,
			submitting: false,
			orderRate: 0,
			orderType: "",
			isShowingAdditionalInfo: false
		};
		this.fetchApi = null;
	}

	componentDidMount() {
		const { load, token } = this.props;
		if (load && load.data && load.data.carrier && token) {
			this.props.changeFieldValue("bidForm", "mcNo", load.data.carrier.icc_mc_nbr);
			this.props.changeFieldValue("bidForm", "carrierName", load.data.carrier.name);
			this.setState({
				carrierId: load.data.carrier.id
			});
		}
	}

	componentDidUpdate = () => {
		const { equipmentTypesList, load } = this.props;
		if (load && load.data && load.data.charges && !isEqual(Number(load.data.charges), this.state.orderRate)) {
			this.setState({ orderRate: Number(load.data.charges) });
		}
		if (
			!isEqual(equipmentTypesList, this.state.equipmentTypesList) &&
			equipmentTypesList &&
			equipmentTypesList.data &&
			equipmentTypesList.data.length > 0
		) {
			let equipmentTypesOptions = equipmentTypesList.data.map((equipment) => {
				return { value: equipment.id, label: equipment.description };
			});
			this.setState({ equipmentTypesList, equipmentTypesOptions });
		}
	};

	successHandler = (res) => {
		const { loadType, token, orderId } = this.props;
		if (200 <= res.status && res.status < 300) {
			NotificationManager.success(`${this.state.orderType} created`, "", 5000);
			this.hideForm();
			if (token) {
				this.props.fetchDetails(`carrier/loads/${loadType}/${orderId}/${token}`);
			} else {
				this.props.fetchDetails(`loads/${loadType}/${orderId}`);
			}
		} else {
			NotificationManager.error(res.statusText, "", 5000);
		}
		this.setState({ submitting: false });
	};

	handlePass = (reason) => {
		let payLoad = this.state.passData;
		payLoad.reason = reason.value;
		this.setState({ showReasonModal: false });
		this.props.createPass(payLoad, this.successHandler);
	};

	closePassModal = () => {
		this.setState({ showReasonModal: false, submitting: false });
	};

	handleSubmit = (values) => {
		const { loadType, locationLatLng, token, orderId } = this.props;
		this.setState({ submitting: true });
		let payLoad = {
			mc_number: values.mcNo,
			user_id: (localStorage.getItem("CHARIOT_USER_DATA")
				? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.id
				: 1),
			carrier_name: values.carrierName,
			load_id: orderId,
			load_type: loadType,
			driver_name: values.driverName,
			driver_phone: values.driverPhoneNumber,
			driver_phone_ext: values.driverPhoneExtension,
			dispatcher_name: values.dispatcherName,
			dispatcher_phone: values.dispatcherPhoneNumber,
			dispatcher_phone_ext: values.dispatcherPhoneExternsion,
			dispatcher_email: values.dispatcherEmail,
			carrier_insurance: values.carrierInsurance
		};

		if (this.state.orderType === "pass") {
			this.setState({ showReasonModal: true, passData: payLoad });
		} else {
			let bidURL = "bids/store";
			let bidData = {
				equipment_type: values.equipmentType ? values.equipmentType.value : "",
				length_in_feet: values.trailerLength,
				location: values.truckLocation,
				latitude: locationLatLng ? locationLatLng.lat : "",
				longitude: locationLatLng ? locationLatLng.lng : "",
				eta_for_pickup: values.etaForPickup,
				driver_empty: values.driverEmpty ? values.driverEmpty.value : "",
				empty_time: values.emptyTime,
				comment: values.comments,
				quoted_rate: values.quotedRate,
				status: this.state.orderType === "book" ? "booked" : this.state.orderType
			};
			payLoad = { ...payLoad, ...bidData };
			if (token) {
				bidURL = "carrier/bids/store";
				payLoad.token = token;
			}
			this.props.createBid(bidURL, payLoad, this.successHandler);
		}
	};

	hideForm = (e) => {
		this.props.updateField("formEnabled", false);
	};

	fetchCarriers = (e) => {
		let carrierName = e.target.value;
		this.setState({ carrierName, carrierId: "" });
		this.props.changeFieldValue("bidForm", "mcNo", "");
		this.props.changeFieldValue("bidForm", "carrierName", "");
		const successHandler = (res) => {
			res.json().then((data) => {
				this.setState({ carrierLoading: false, carriersList: data });
			});
		};
		let carrierFetchURL = "carriers";
		if (this.props.token) {
			carrierFetchURL = "carriers/search";
		}
		clearTimeout(this.fetchApi);
		if (carrierName && carrierName.length >= 3) {
			this.setState({ carrierLoading: true });
			this.fetchApi = setTimeout(() => {
				this.props.fetchCarriersList(carrierFetchURL, carrierName, successHandler);
			}, 1000);
			if (!this.state.showCarrierList) {
				this.setState({ showCarrierList: true });
			}
		} else {
			this.setState({ carriersOptions: [], carriersList: [], showCarrierList: false });
		}
	};

	setCarrier = (carrier) => {
		this.props.changeFieldValue("bidForm", "mcNo", carrier.icc_mc_nbr);
		this.props.changeFieldValue("bidForm", "carrierName", carrier.name);
		this.setState({
			carrierName: `${carrier.icc_mc_nbr} - ${carrier.name} ${carrier.carrier_abrv ? `(${carrier.carrier_abrv})` : ""}`,
			carrierId: carrier.id,
			showCarrierList: false
		});
	};

	removeList = () => {
		setTimeout(() => {
			if (!this.state.carrierId) {
				this.setState({ carrierName: "", showCarrierList: false });
			}
		}, 200);
	};

	validateFields = (e, orderType, handleSubmit) => {
		e.preventDefault();
		this.setState({ orderType }, () => {
			// eslint-disable-next-line no-lone-blocks
			{
				orderType === "book"
					? this.setState({
						isShowingAdditionalInfo: true
					})
					: this.setState({
						isShowingAdditionalInfo: false
					});
			}

			setTimeout(() => {
				handleSubmit();
			});
		});
	};

	calculateProfit = () => {
		const { quotedRate } = this.props;
		const { orderRate } = this.state;
		let loss = false;
		let profit = 0;
		if (orderRate && quotedRate) {
			profit = ((quotedRate / orderRate) * 100).toFixed(2);
			if (profit > 100) {
				loss = true;
				profit = profit - 100;
			} else {
				profit = 100 - profit;
			}
		}
		return `${loss ? "-" : ""}${parseFloat(profit).toFixed(2)}`;
	};

	getProfitAmount = () => {
		const { quotedRate } = this.props;
		const { orderRate } = this.state;
		if (orderRate && quotedRate) {
			return (orderRate - quotedRate).toFixed(2);
		}
		return orderRate.toFixed(2);
	};

	getValidation = (field) => {
		return (this.state.orderType ? requiredFields[this.state.orderType] : []).includes(field.name)
			? fieldValidations[this.state.orderType][field.name]
				? fieldValidations[this.state.orderType][field.name]
				: rules.required
			: field.validation
				? field.validation
				: null;
	};

	renderCarrierInfoForm = () => (
		<React.Fragment>
			<div className="title-info collapsing-title">Carrier Info</div>
			<div className="row carrier-form-body mt-4">
				{carrierInfoFields.map((field) => (
					<Field
						name={field.name}
						fieldToUpdate={field.latLng}
						latLng={this.props[field.latLng]}
						component={renderField}
						key={field.label}
						type={field.type}
						label={field.label}
						readOnly={field.readOnly}
						placeholder={field.placeholder}
						updateField={this.props.updateField}
						validate={this.getValidation(field)}
					/>
				))}
			</div>
		</React.Fragment>
	);

	renderAdditionalInfoForm = (equipmentTypesOptions, isShowingAdditionalInfo) => (
		<React.Fragment>
			<div className="title-info pt-2 relative collapsing-title">
				Additional Info
				<label
					className="expand-info pointer"
					onClick={() => this.setState({ isShowingAdditionalInfo: !isShowingAdditionalInfo })}>
					{isShowingAdditionalInfo ? (
						<i className="icon icon-arrow-up relative" aria-hidden="true" />
					) : (
						<i className="icon icon-arrow-down" aria-hidden="true" />
					)}
				</label>
			</div>
			{isShowingAdditionalInfo ? (
				<div className="row additional-form-body mt-4">
					{additionalInfoFields.map((field) => (
						<Field
							name={field.name}
							key={field.label}
							component={renderField}
							type={field.type}
							label={field.label}
							placeholder={field.placeholder}
							validate={this.getValidation(field)}
							options={field.label === "Equipment Type" ? equipmentTypesOptions : field.options}
						/>
					))}
				</div>
			) : null}
			<div className="row mt-20">
				<Field
					name="comments"
					component={renderField}
					fileldSize="col-md-12"
					type="text"
					label="Comments"
					placeholder="Enter Comment"
				/>
			</div>
		</React.Fragment>
	);

	renderRateModal = (e) => {
		e.preventDefault();
		this.props.updateField("showRateModal", true);
	};

	renderRateTable = (load) => (
		<div className="rate-table  p-0">
			<div className="text-center tgt-rate">All in Rate ${this.state.orderRate.toFixed(2)}</div>

			<table className="text-center">
				<thead>
					<tr className="rate-table-header">
						<th />
						<th>Cost</th>
						<th>Profit</th>
					</tr>
				</thead>
				<tbody>
					{targetRateFields.map((field) => (
						<tr key={field.percentage}>
							<td className={field.class}>{field.percentage}%</td>
							<td>$ {(this.state.orderRate - (this.state.orderRate * (field.percentage / 100))).toFixed(2)}</td>
							<td>$ {(this.state.orderRate * (field.percentage / 100)).toFixed(2)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);

	renderRateSection = (load, token) => (
		<div>
			<div className="title-info collapsing-title pt-2">Rates</div>
			<div className="rate-section d-flex">
				<div className="rate-input  flex-fill">
					{!token && (
						<div className="d-flex justify-content-center align-items-center mt-2">
							<div className="text-center tgt-rate">
								TARGET RATE ${load && load.data && load.data.target_rate ? load.data.target_rate : 0.0}
							</div>
							<div className="rate-change">
								<button
									disabled={!this.props.orderAvailable}
									className="btn btn-sm btn-primary"
									onClick={this.renderRateModal}>
									Change Rate
								</button>
							</div>
						</div>
					)}
					<div className="d-flex">
						<Field
							name="quotedRate"
							component={renderField}
							fileldSize="col-md-10"
							type="number"
							label="Quoted Rate"
							placeholder="Enter Rate"
							validate={this.state.orderType !== "pass" ? rules.required : null}
						/>
						{!token && (
							<div className="col-md-2 flex-fill">
								<label />
								<div className="rate-percent">{this.calculateProfit()}%</div>
							</div>
						)}
					</div>
					{!token && (
						<React.Fragment>
							<div className="rate-val d-flex justify-content-center">
								<div className={`rate-number ${this.getProfitAmount < 1 ? 'is-loss' : 'is-profit'}`}>
									${this.getProfitAmount()}
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
				{!token && this.renderRateTable(load)}
			</div>
		</div>
	);

	renderForm = () => {
		const { equipmentTypesOptions, submitting, isShowingAdditionalInfo } = this.state;
		const { load, orderBooked, invalidToken, orderAvailable, token } = this.props;
		return (
			<form>
				<div className="form-body">
					{this.renderCarrierInfoForm()}
					{this.renderAdditionalInfoForm(equipmentTypesOptions, isShowingAdditionalInfo)}
					{this.renderRateSection(load, token)}

					<div className=" text-right bid-submit mb-4">
						<button className="d-none" onClick={(e) => e.preventDefault()}>
							SUBMIT
						</button>
						{formButtons.map(
							(button, index) =>
								!(token && index === 2) && (
									<button
										key={button.label}
										disabled={submitting || orderBooked || invalidToken || !orderAvailable}
										onClick={(e) =>
											this.validateFields(
												e,
												button.value,
												this.props.handleSubmit((data) => this.handleSubmit(data))
											)
										}
										className={`btn bid-btn ml-2 ${button.class}`}>
										{button.label}
									</button>
								)
						)}
					</div>
				</div>
			</form>
		);
	};

	renderCarrierSelect = () => {
		const { carrierName, showCarrierList, carrierLoading } = this.state;
		const { carriersList } = this.state;
		return (
			<div className="carrier-select">
				<input
					type="text"
					className="form-control"
					value={carrierName}
					placeholder="Carrier"
					onChange={this.fetchCarriers}
					onBlur={this.removeList}
				/>
				{showCarrierList && (
					<ul>
						{carrierLoading ? (
							<li>Loading ...</li>
						) : carriersList && carriersList.data && carriersList.data.length > 0 ? (
							carriersList.data.map((carrier, index) => (
								<li key={`${carrier.name}${index}`} onClick={() => this.setCarrier(carrier)}>
									{`${carrier.icc_mc_nbr} - ${carrier.name} ${carrier.carrier_abrv ? `(${carrier.carrier_abrv})` : ""
										}`}
								</li>
							))
						) : (
							<li>No results found</li>
						)}
					</ul>
				)}
			</div>
		);
	};

	render() {
		const { load, token } = this.props;
		const { showReasonModal } = this.state;
		return (
			<div className="bid-form">
				<PassReasonModal show={showReasonModal} save={this.handlePass} close={this.closePassModal} />
				<div className="form-hdr d-flex align-items-center">
					<div className="title flex-fill">
						New Bid For Order{" "}
						<span className="ml-3 order-number">
							#{load && load.data ? (load.data.offerNumber ? load.data.offerNumber : load.data.orderNumber) : ""}
						</span>
					</div>
					{!token && this.renderCarrierSelect()}
					<OverlayTrigger placement="top" trigger={["hover"]} overlay={tooltip("Close")}>
						<div className="bid-form-close" onClick={this.hideForm}>
							<button className="btn btn-sm btn-dark btn-cell">
								<i className="icon icon-close" />
							</button>
						</div>
					</OverlayTrigger>
				</div>
				{this.renderForm()}
			</div>
		);
	}
}

const selector = formValueSelector("bidForm");

const mapStateToProps = (state) => ({
	load: state.LoadDetails.load,
	equipmentTypesList: state.LoadDetails.equipmentTypesList,
	quotedRate: selector(state, "quotedRate"),
	locationLatLng: state.LoadDetails.locationLatLng
});

const mapDispatchToProps = {
	updateField,
	createBid,
	createPass,
	fetchDetails,
	fetchCarriersList,
	changeFieldValue
};

export default reduxForm({
	form: "bidForm",
	onSubmitFail: (errors) => {
		if (errors) {
			NotificationManager.error("Please fill all the required fields properly", "", 5000);
		}
	}
})(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(BidForm)
);
