import { LOADS } from "./../ActionTypes";

const initialState = {
	filtered: [],
	loadFilterList: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOADS.FETCH_LIST:
			return {
				...state,
				loadList: action.payload
			};
		case LOADS.FETCH_FILTER:
			return {
				...state,
				loadFilterList: action.payload
			};
		case LOADS.UPDATE_FIELD:
			return {
				...state,
				[action.field]: action.val
			};
		default:
			return state;
	}
}
