import React, { useState, useEffect } from "react";
import BasicTable from "./../../../Utils/BasicTable";
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import { fetchNextLoadDestination } from "./../../../../Redux/Action/Dashboard";
import PreloaderInner from "./../../../Utils/PreloaderInner";
import moment from "moment";

export default function NextLoadDestination({ data, orderNo, city, state, deliveryDate, changeNextLoadOrder, isNextLoadChanged, setNextLoadChanged, customData }) {
    const [isLoader, SetLoader] = useState(false);
    const [nextLoadDestination, SetNextLoadDestination] = useState([]);
    const [sortData, setSortData] = useState(false);
    const [apiFieldName, setApiFieldName] = useState('');
    const [descending, setDescending] = useState(false);

    const headerData = [
        { header: 'Order', field: 'id', sortField: true },
        { header: 'Customer', field: 'customer', sortField: true },
        { header: 'Pick Up Date/Time', field: 'pickDate', sortField: true },
        { header: 'Pick Up Location', field: 'pickLocation', sortField: true },
        { header: 'Destination', field: 'destination', sortField: true },
        { header: 'Delivery Date/Time', field: 'deliveryDate', sortField: true },
        { header: 'TruckType', field: 'truck_type', sortField: true },
        { header: 'Weight', field: 'weight', sortField: true },
        { header: '', field: '', sortField: false }
    ]



    useEffect(() => {
        if (data && data.equiptype !== 'Container') {
            var payLoad = {
                offer_no: data.id,
                delivery_date: data.deliveryDate,
                latitude: data.latitude_destination,
                longitude: data.longitude_destination
            }
            SetLoader((prevState) => prevState = true);
            fetchNextLoadDestination(payLoad).then(result => {
                if (result.message === "success") {
                    SetNextLoadDestination((prevState) => prevState = result && result.data);
                    SetLoader((prevState) => prevState = false);
                } else {
                    SetLoader((prevState) => prevState = false);
                }
            }).catch(error => {
                //  console.log(error)
            });
        } else {
            SetNextLoadDestination([]);
            return;
        }
    }, [data])

    const setnextLoadValue = (data) => {
        changeNextLoadOrder(true, data)
    }

    const sortingData = (field) => {
        if (field === apiFieldName) {
            setDescending((prevState) => !prevState)
        } else {
            setDescending(false)
        }
        setApiFieldName(field)
        setSortData(true)
    }

    useEffect(() => {
        const sortFields = ['customer', 'pickDate', 'pickLocation', 'destination', 'deliveryDate', 'truck_type'];
        if (sortData) {
            if (!descending) {
                sortFields.map((field) => {
                    if (field === apiFieldName) {
                        setSortData(false);
                        var sortedData = nextLoadDestination.sort((a, b) => (a[field] !== null ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
                        SetNextLoadDestination(sortedData);
                    } else {
                        setSortData(false);
                        var sortedData = nextLoadDestination.sort((a, b) => (a[apiFieldName] !== null ? a[apiFieldName] : 'zzz') - (b[apiFieldName] !== null ? b[apiFieldName] : 'zzz'));
                        SetNextLoadDestination(sortedData);
                    }
                })
            } else {
                sortFields.map((field) => {
                    if (field === apiFieldName) {
                        setSortData(false);
                        var sortedData = nextLoadDestination.sort((a, b) => (b[field] !== null ? b[field] : 'zzz').localeCompare(a[field] !== null ? a[field] : 'zzz'));
                        SetNextLoadDestination(sortedData);
                    } else {
                        setSortData(false);
                        var sortedData = nextLoadDestination.sort((a, b) => (b[apiFieldName] !== null ? b[apiFieldName] : 'zzz') - (a[apiFieldName] !== null ? a[apiFieldName] : 'zzz'));
                        SetNextLoadDestination(sortedData);
                    }
                })
            }
        }
    }, [sortData])


    return <div className="col-md-6 col-lg-6 col-xl-6 mb-3">
        <div className="outLineBox rounded p-2 h-100">
            <div className="row">
                <div className="col">
                    <h2>Next Load at Destination ({nextLoadDestination.length})</h2>
                    <div className="table-responsive auto-scroll">
                        <PreloaderInner hidden={!isLoader} />
                        <PerfectScrollbar>
                            <table className="table truck-table mb-0">
                                <tbody>
                                    <tr>{headerData.map((headerData) => {
                                        return <th>{headerData.header} <div className={`d-inline-block ml-1`}><img className={`${headerData.sortField ? '' : 'd-none'}`} onClick={() => sortingData(headerData.field)} src={`${nextLoadDestination && headerData.field === apiFieldName ? descending ? '/images/ascending-arw.png' : '/images/descending-arw.png' : '/images/updown-arrow.png'}`} /></div></th>
                                    })}
                                    </tr>
                                    {nextLoadDestination && nextLoadDestination.length > 0
                                        ? nextLoadDestination.map((rowData) => {
                                            return <tr>
                                                <td onClick={() => setnextLoadValue(rowData)}>{rowData.id?rowData.id:''}</td>
                                                <td onClick={() => setnextLoadValue(rowData)}>{rowData.customer?rowData.customer:''}</td>
                                                <td onClick={() => setnextLoadValue(rowData)}>{rowData.pickDate?moment(rowData.pickDate).format('MM/DD/YYYY HH:mm'):''}</td>
                                                <td onClick={() => setnextLoadValue(rowData)}>{rowData.pickLocation?rowData.pickLocation:''}</td>
                                                <td onClick={() => setnextLoadValue(rowData)}>{rowData.destination?rowData.destination:''}</td>
                                                <td onClick={() => setnextLoadValue(rowData)}>{rowData.deliveryDate?moment(rowData.deliveryDate).format('MM/DD/YYYY HH:mm'):''}</td>
                                                <td onClick={() => setnextLoadValue(rowData)}>{rowData.truck_type?rowData.truck_type:''}</td>
                                                <td onClick={() => setnextLoadValue(rowData)}>{rowData.weight?rowData.weight:''}</td>
                                                <td>
                                                    <button class={`btn btn-danger btn-circle btn-24 ${isNextLoadChanged && customData.id === rowData.id ? '' : 'd-none'}`} onClick={() => setNextLoadChanged(false)}>
                                                        <i class="ch ch-close"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        })
                                        : <tr className="text-center text-danger"><td colSpan={headerData.length}>No Record Found</td></tr>}
                                </tbody>
                            </table>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
