import React, { Component } from 'react';

export default class BasicTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //selected: {},
            //selectAll: 0,
            tableData: this.props.data,
            tableHeader: this.props.columns
        }
    }

    // toggleRow =  (title) => {
    //     const newSelected = Object.assign({}, this.state.selected);
    //     newSelected[title] = !this.state.selected[title];
    //     this.setState({
    //         selected: newSelected,
    //         selectAll: 2
    //     });
    // }

    // toggleSelectAll = () => {
    //     let newSelected = {};
    //     if (this.state.selectAll === 0) {
    //         this.state.tableData.forEach(x => {
    //             newSelected[x[this.props.field]] = true;
    //         });
    //     }
    //     this.setState({
    //         selected: newSelected,
    //         selectAll: this.state.selectAll === 0 ? 1 : 0
    //     });
    // }

    static getDerivedStateFromProps(props, state) {
        if (props.data && props.data !== state.tableData) {
            return {
                tableData: props.data
            }
        }
        return null;
    }

    render() {
        return (<>
            <table className="table truck-table mb-0">
                <tbody>
                    <tr>

                        {/* {this.props.hasCheckBox && <td className="checkboxCol"> <input checked={this.state.selectAll === 1} onChange={this.toggleSelectAll} type="checkbox" /></td>} */}
                        {this.state.tableHeader && this.state.tableHeader.map((data, index) => {
                            return data.width ? <th key={index} width={data.width}>{data.Header}</th> : <th key={index}>{data.Header}<div className={`d-inline-block ml-1`}><img src='images/updown-arrow.png' /></div></th>//className={`${data.sortField?'':'d-none'}`} onClick={()=>sortingData(data.apiField)}
                        })}

                    </tr>

                    {this.state.tableData && this.state.tableData.map((data, index) => {
                        return (<tr key={index}>
                            {/* {this.props.hasCheckBox &&
                                <td className="checkboxCol">
                                    <input checked={this.state.selected[data[this.props.field]] === true} onChange={() => this.toggleRow(data[this.props.field])} type="checkbox" />
                                </td>
                            } */}
                            {this.state.tableHeader && this.state.tableHeader.map((hdata, hindex) => {
                                return <td key={hindex}>{data[hdata.accessor]}</td>
                            })}
                        </tr>)
                    })}
                    {this.state.tableData && this.state.tableData.length === 0 &&
                        <tr key={0} className="text-center text-danger"><td colSpan={this.state.tableHeader.length}>No Record Found</td></tr>}
                </tbody>
            </table>
        </>);
    }
}