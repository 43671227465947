import React, { Component } from "react";
import loginBg from "./../../../images/login-bg.jpg";
import loginLogo from "./../../../images/logo2.png";
import horse from "./../../../images/horse.png";

export default class AuthWrap extends Component {
	render() {
		return (
			<div className="login-wrap ">
				<div className="login-left">
					<div className="login-bg-horse">
						<img src={horse} alt="" />
					</div>
					<p className="login-cmnt">Carrying people to a better place.</p>
				</div>
				<div className="login-bg ">
					<img src={loginBg} alt="backgroundd" />
				</div>
				<div className="login-right">
					<div className="login-container">
						<div className="login-logo text-center">
							<img src={loginLogo} alt="" />
						</div>
						{this.props.children}
						{/* <div className="login-footer">
							<p className="mt-3">
								Don't have an account. <a>Signup</a>
							</p>
						</div> */}
					</div>
				</div>
			</div>
		);
	}
}
