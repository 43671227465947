import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class TargetRateModal extends Component {
	render() {
		const { showRateModal, targetRate, submitting } = this.props;
		return (
			<Modal size="sm" show={showRateModal} onHide={this.props.hideModal} aria-labelledby="example-modal-sizes-title-sm">
				<Modal.Header closeButton>
					<Modal.Title id="example-modal-sizes-title-sm">Enter Target Rate</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<input
						placeholder="target rate"
						type="number"
						className="form-control"
						value={targetRate}
						onChange={this.props.updateTargetValue}
					/>
				</Modal.Body>
				<Modal.Footer>
					<button disabled={!targetRate || submitting} className="btn btn-success" onClick={this.props.setTargetRate}>
						Update
					</button>
				</Modal.Footer>
			</Modal>
		);
	}
}
