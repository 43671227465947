import React, { useState, useEffect } from 'react';
import {waterfallCarrierLoadBookStatus} from './../../../Redux/Action/Dashboard';

function BookingStatus(props) {
    const [OrderDetails,setOrderDetails] = useState();
    const [status,setStatus] = useState();
    const [ref,setRef] = useState();

    const bookingSuccessStyle = {
      'width':'400px',
      'height':'400px',
      'margin-left': '30%'
    }

    const bookingFailureStyle = {
      'width':'300px',
      'height':'300px',
      'margin-left': '30%'
    }

    const bookingDetails = {
      'margin-left' : '33%'
    }


    useEffect(()=>{
        setOrderDetails(props.match.params)
    },[])

    useEffect(()=>{
      if(OrderDetails){
        const payload = {
          "order_nbr" : OrderDetails.orderno,
          "carrier_id": OrderDetails.carrierId
        }
        waterfallCarrierLoadBookStatus(payload).then(result=>{
          if(result.message==='success'){
            setStatus(true)
            setRef(result.data)
          }else{
            setStatus(false)
            // setRef(result.data)
          }
        }).catch(err=>{console.log(err)})
      }
    },[OrderDetails])

return <div>
    {OrderDetails && status===true &&
    <div className="container p-2">
    <img src="/images/check.png" style={bookingSuccessStyle} className=""></img>
    <div style={bookingDetails}>
    <h1 className='text-success'>Booking Successful</h1> 
    <>Ref No: BSTN {ref}</>
    <h2>OrderNo: {OrderDetails.orderno}</h2> 
    <h2>Carrier Id: {OrderDetails.carrierId}</h2>
    </div>
    </div>}
    {OrderDetails && status===false && 
    <div className="container p-2">
    <img src="/images/red-cross-icon.jpg" style={bookingFailureStyle} className=""></img>
    <div>
    <div className='booking-details' style={bookingDetails}>
    <h1 className='text-danger'>Booking Failed</h1> 
    
    <p>Ref No: BSTNGDSE120012C</p>
    <h2>OrderNo: {OrderDetails.orderno}</h2> 
    <h2>Carrier Id: {OrderDetails.carrierId}</h2>
    </div>
    </div>
    </div>}     
  </div>;
}

export default BookingStatus;
