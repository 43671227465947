import React, { useState, useEffect, } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchNewCarrierUserData } from '../../../Redux/Action/Dashboard';
import { useHistory } from "react-router-dom";
import { TextField } from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';

const columns = [
    // { field: 'id', headerName: 'User Id', width: 100, filterable: false },
    { field: 'username', headerName: 'MC Number', width: 200,sortable:false, filterable: false },
    { field: 'firstname', headerName: 'Carrier Name', width: 350,sortable:false, filterable: false },
    { field: 'carrier_abrv', headerName: 'Carrier Abbrv', width: 250 ,sortable:false, filterable: false},
    { field: 'dispacher_name', headerName: 'Dispatcher Name', width: 300,sortable:false, filterable: false },
    { field: 'email', headerName: 'Dispatcher Email', width: 350,sortable:false, filterable: false },
    { field: 'phone_number', headerName: 'Phone Number', width: 200 ,sortable:false, filterable: false},
    // { field: 'carrier_safety_rating', headerName: 'Carrier Safety Rating', width: 200 },
    // { field: 'carrier_insurance', headerName: 'Carrier insurance', width: 200 },
    {renderCell: (params) => {
            return (
                <>
                    <button className='btn btn-cell btn-sm btn-success pointer border-0' onClick={()=>{window.open(`/carrieruser/${params.row.id}`,"_self")}}><i className="icon icon-user " /></button>
                </>
            )
        }, sortable:false, filterable: false
    }
]

function ListwithPagination() {
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 100
    });
    const [searchValue,setSearchValue] = useState('');


    useEffect(() => {
        if(searchValue){}else{
            getPageData(pageState.page,pageState.pageSize,'');
        }
    }, [pageState.page, pageState.pageSize])

    useEffect(()=>{
        if(searchValue===''){
            getPageData(pageState.page,pageState.pageSize,'');
        }
    },[searchValue])

    const getPageData = (page,pageSize,key) => { 
        fetchNewCarrierUserData(page, pageSize, key).then((response) => {
            setPageState((old) => ({
                ...old,
                isLoading: false,
                data: response.data,
                total: response.total
            }))
        }).catch(error => { console.log(error) });
     }

    const onkeypress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            getPageData(1,pageState.pageSize,searchValue);
        }
     }
    return (
        <div>
            <div className="page-header mb-2">
                <h4 className="section-title flex-fill text-decoration-underline">Carrier Users List</h4>
                <br/>
            </div>
            <div className='row my-1'>
            <div className='col-md-3 mb-2 d-flex'>
            <TextField
              variant='outlined'
              label='MC Number / Carrier Name'
              size='small'
              value={searchValue} 
              type="search" 
              placeholder="Search a Carrier" 
              className="" 
              fullWidth
              name='mc_number'
              onChange={(e) => {setSearchValue(e.target.value)}} 
              onKeyPress={(e)=>onkeypress(e)}
              
            />
            </div>
            <div className='col-6 mt-1'>
            <button className='btn btn-primary btn-large' onClick={(e)=>{getPageData(1,pageState.pageSize,searchValue)}}>Search</button>
            </div>
            </div>
            <DataGrid
                autoHeight
                rows={pageState.data}
                rowCount={pageState.total}
                loading={pageState.isLoading}
                rowsPerPageOptions={[10, 30, 50, 70, 100]}
                pagination
                page={pageState.page}
                pageSize={pageState.pageSize}
                paginationMode="server"
                onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage }))}
                onPageSizeChange={(newPageSize) => setPageState((old) => ({ ...old, pageSize: newPageSize }))}
                columns={columns}
            />
        </div>
    )
}

export default ListwithPagination