import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { NotificationManager } from "react-notifications";
import { fetchDetails, updateField, fetchEquipmenttypesList, setTargetRate } from "./../../../Redux/Action/LoadDetails";
import { validateToken } from "./../../../Redux/Action/Auth";
import { orderDetails } from "./Helper";
import BidListTable from "./BidListTable";
import BidForm from "./BidForm";
import PassListTable from "./PassListTable";
import RouteMap from "./RouteMap";
import RouteDetails from "./RouteDetails";
import LoadLaneDetails from "./LoadLaneDetails";
import PreloaderInner from "./../../Utils/PreloaderInner";
import { Scrollbars } from "react-custom-scrollbars";
import Logo from "./../../../images/logo.png";
import TargetRateModal from "./../../Utils/TargetRateModal";

class OrderDetails extends Component {
	state = {
		activityList: [],
		bidList: [],
		passList: [],
		loadList: [],
		laneList: [],
		targetRate: "",
		submitting: false,
		orderBooked: false,
		orderAvailable: false,
		invalidToken: false,
		loading: true,
		expandMap: false
	};

	componentDidMount = () => {
		const { match } = this.props;
		let fetchEquipmentURL = "equipmenttypes";
		if (match.params.token) {
			if (localStorage.getItem("CHARIOT_USER_DATA")) {
				document.body.classList.toggle("hide-menu");
				localStorage.removeItem("CHARIOT_USER_DATA");
				window.location.reload();
			}
			const successHandler = (res) => {
				res.json().then((values) => {
					if (values.description === "failure") {
						this.setState({ invalidToken: true });
						NotificationManager.error("Token is invalid", "", 5000);
					}
				});
			};
			fetchEquipmentURL = "carrier/equipmenttypes";
			this.props.validateToken({ type: 2, token: match.params.token }, successHandler);
			this.props.fetchDetails(`carrier/loads/${match.params.loadType}/${match.params.id}/${match.params.token}`);
		} else {
			this.props.fetchDetails(`loads/${match.params.loadType}/${match.params.id}`);
		}
		this.props.fetchEquipmenttypesList(fetchEquipmentURL);
		this.props.updateField("currentOrderID", match.params.id);
	};

	componentDidUpdate = (prevProps) => {
		const { load, match, currentOrderID } = this.props;
		if (Number(match.params.id) !== Number(currentOrderID)) {
			this.props.updateField("load", false);
			this.props.updateField("currentOrderID", match.params.id);
			this.props.fetchDetails(`loads/${match.params.loadType}/${match.params.id}`);
		}
		if (
			load &&
			load.data &&
			(!isEqual(load.data.load_history, this.state.loadList) || !isEqual(load.data.lane_history, this.state.laneList))
		) {
			this.setState({ loadList: load.data.load_history, laneList: load.data.lane_history });
		}
		if (load && !isEqual(load, prevProps.load) && match.params.token) {
			const successHandler = (res) => {
				res.json().then((values) => {
					if (values.description === "failure") {
						this.setState({ invalidToken: true });
					}
				});
			};
			this.props.validateToken({ type: 2, token: match.params.token }, successHandler);
		}

		this.setBidPassData(load, match);
		this.checkOrderAvailability(load);
		if (load && load.data && load.data.status !== "booked" && this.state.orderBooked) {
			this.setState({ orderBooked: false });
		} else if (load && load.data && load.data.status === "booked" && !this.state.orderBooked) {
			this.setState({ orderBooked: true });
		}
	};

	componentWillUnmount = () => {
		this.props.updateField("formEnabled", false);
		this.props.updateField("load", false);
	};

	checkOrderAvailability = (load) => {
		if (
			load &&
			load.data &&
			(load.data.status !== "Available" && load.data.status !== "Offered") &&
			this.state.orderAvailable
		) {
			this.setState({ orderAvailable: false });
		} else if (
			load &&
			load.data &&
			(load.data.status === "Available" || load.data.status === "Offered") &&
			!this.state.orderAvailable
		) {
			this.setState({ orderAvailable: true });
		}
	};

	setBidPassData = (load, match) => {
		if (load && load.data && load.data.bid_pass_data) {
			if (this.state.loading) {
				this.setState({ loading: false });
			}
			let loadData = load.data.bid_pass_data;
			if (loadData.bids && !isEqual(loadData.bids, this.state.bidList)) {
				if (match.params.token) {
					let filteredBidList = loadData.bids.filter((bid) => bid.mc_number === load.data.carrier.icc_mc_nbr);
					if (!isEqual(filteredBidList, this.state.bidList)) {
						this.setState({ bidList: filteredBidList });
					}
				} else {
					this.setState({
						bidList: loadData.bids
					});
				}
			} else if (loadData.load_pass && !isEqual(loadData.load_pass, this.state.passList)) {
				this.setState({
					passList: loadData.load_pass
				});
			}
		}
	};

	showForm = () => {
		this.props.updateField("formEnabled", true);
	};

	setTargetRate = () => {
		this.setState({ submitting: true });
		let payload = {
			load_id: this.props.match.params.id,
			load_type: this.props.match.params.loadType,
			target_rate: this.state.targetRate
		};
		const successHandler = (res) => {
			this.setState({ targetRate: "", submitting: false });
			this.props.updateField("showRateModal", false);
			if (200 <= res.status && res.status < 300) {
				this.props.fetchDetails(`loads/${this.props.match.params.loadType}/${this.props.match.params.id}`);
				NotificationManager.success(`Target rate updated`, "", 5000);
			} else {
				NotificationManager.error(res.statusText, "", 5000);
			}
		};
		this.props.setTargetRate(payload, successHandler);
	};

	hideModal = () => {
		this.props.updateField("showRateModal", false);
		this.setState({ targetRate: "" });
	};

	updateTargetValue = (e) => {
		this.setState({ targetRate: e.target.value });
	};

	toggleMapView = () => {
		this.setState({ expandMap: !this.state.expandMap });
	};

	renderOrderDetails = (load) => (
		<div className="order-details row">
			<div className="col-md-12">
				<div className="d-flex row details-title align-items-center">
					Order details{" "}
					<span className="ml-3 order-number">
						#{load && load.data ? (load.data.offerNumber ? load.data.offerNumber : load.data.orderNumber) : ""}
					</span>
					{this.state.orderBooked && <button className="btn btn-default ml-2 bkd-btn">Booked</button>}
				</div>
				<div className="row details">
					{orderDetails.map(
						(order) =>
							!(order.name === "Division" && this.props.match.params.token) && (
								<div className="col-md-4 mb-3 d-flex" key={order.name}>
									<div>
										<div>
											<label className="mb-0 order-name">{order.name}</label>
										</div>
										<div className="order-val-section">
											<b className="order-val">
												{load && load.data && (load.data[order.label] || load.data[order.label] === 0)
													? load.data[order.label]
													: ""}
											</b>
										</div>
									</div>
								</div>
							)
					)}
				</div>
			</div>
		</div>
	);

	renderTrackHorizontal = (props) => <div className="track track-horizontal" {...props} />;

	renderBidList = () => {
		const { orderBooked, orderAvailable, bidList, loading } = this.state;
		const { history, match } = this.props;
		return (
			<div className="activities-list mb-4">
				<div className="">
					<div className=" details-title">Bid List</div>
				</div>
				<div className="bid-list-table-container">
					<Scrollbars
						autoHeight
						autoHeightMin={100}
						autoHeightMax={600}
						renderTrackHorizontal={this.renderTrackHorizontal}>
						<BidListTable
							orderBooked={orderBooked}
							orderAvailable={orderAvailable}
							data={bidList}
							history={history}
							loadType={match.params.loadType}
							token={match.params.token}
							orderId={match.params.id}
							loading={loading}
						/>
					</Scrollbars>
				</div>
			</div>
		);
	};

	renderPassList = () => (
		<div className="activities-list mb-4">
			<div className=" ">
				<div className=" details-title">Pass List</div>
			</div>
			<PassListTable data={this.state.passList} history={this.props.history} loading={this.state.loading} />
		</div>
	);

	renderTargetRateModal = () => {
		const { targetRate, submitting } = this.state;
		const { showRateModal } = this.props;
		return (
			<TargetRateModal
				showRateModal={showRateModal}
				targetRate={targetRate}
				submitting={submitting}
				hideModal={this.hideModal}
				setTargetRate={this.setTargetRate}
				updateTargetValue={this.updateTargetValue}
			/>
		);
	};

	renderLoadLaneDetails = () => {
		const { loadList, laneList, loading } = this.state;
		const { match, history, fetchDetails, updateField } = this.props;
		return (
			<LoadLaneDetails
				loadList={loadList}
				laneList={laneList}
				loading={loading}
				history={history}
				fetchDetails={fetchDetails}
				updateField={updateField}
				token={match.params.token}
			/>
		);
	};

	renderBidForm = () => {
		const { orderBooked, orderAvailable, invalidToken } = this.state;
		const { match } = this.props;
		return (
			<BidForm
				orderBooked={orderBooked}
				orderAvailable={orderAvailable}
				invalidToken={invalidToken}
				token={match.params.token}
				orderId={match.params.id}
				loadType={match.params.loadType}
			/>
		);
	};

	renderMap = () => {
		const { expandMap } = this.state;
		const { load } = this.props;
		return (
			<div className={`route-map ${expandMap ? "expanded" : ""}`}>
				{load &&
					load.data && (
						<React.Fragment>
							<RouteMap load={load} />
							<RouteDetails load={load} />
							<div className="size-control pointer" onClick={this.toggleMapView}>
								{expandMap ? <i className="icon icon-shrink" /> : <i className="icon icon-expand" />}
							</div>
						</React.Fragment>
					)}
			</div>
		);
	};

	renderLoadDetails = () => {
		const { orderBooked, orderAvailable, invalidToken, bidList, passList } = this.state;
		const { match, load } = this.props;
		return (
			<div className="load-details">
				<PreloaderInner hidden={load} />
				{this.renderMap()}
				{this.renderTargetRateModal()}
				{this.renderOrderDetails(this.props.load)}
				{!match.params.token && this.renderLoadLaneDetails()}
				{this.props.formEnabled && this.renderBidForm()}
				{!this.props.formEnabled && (
					<div className="text-right mr-3 mb-2">
						<button
							className="btn btn-primary add-bid"
							onClick={this.showForm}
							disabled={orderBooked || invalidToken || !orderAvailable}>
							{`BID / BOOK ${match.params.token ? "" : "/ PASS"}`}
						</button>
					</div>
				)}
				{bidList.length > 0 && this.renderBidList()}
				{!match.params.token && passList.length > 0 && this.renderPassList()}
			</div>
		);
	};

	render() {
		return this.props.match.params.token ? (
			<React.Fragment>
				<header>
					<div className="header-container d-flex justify-content-between">
						<div className="logo-container">
							<a>
								<img src={Logo} alt="Chariot" />
							</a>
						</div>
					</div>
				</header>
				<div className="main-container">
					<div className="body-wrapper">{this.renderLoadDetails()}</div>
				</div>
			</React.Fragment>
		) : (
			<div>{this.renderLoadDetails()}</div>
		);
	}
}

const mapStateToProps = (state) => ({
	formEnabled: state.LoadDetails.formEnabled,
	load: state.LoadDetails.load,
	showRateModal: state.LoadDetails.showRateModal,
	currentOrderID: state.LoadDetails.currentOrderID
});

const mapDispatchToProps = {
	updateField,
	fetchDetails,
	fetchEquipmenttypesList,
	setTargetRate,
	validateToken
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OrderDetails);
