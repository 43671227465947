import React from "react";

function ViewDetailsTest() {
  return (
    <div className="order-details-wrapper">
      <nav>
        <div class="nav od-tabs nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-link active" id="nav-orderDetails-tab" data-toggle="tab" href="#nav-orderDetails" role="tab" aria-controls="nav-orderDetails" aria-selected="true">Order Details</a>
          <a class="nav-link" id="nav-progress-tab" data-toggle="tab" href="#nav-progress" role="tab" aria-controls="nav-progress" aria-selected="false">Progress</a>
          <a class="nav-link" id="nav-document-tab" data-toggle="tab" href="#nav-document" role="tab" aria-controls="nav-document" aria-selected="false">Documents
          </a>
        </div>
      </nav>

      <div className="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-orderDetails"
          role="tabpanel"
          aria-labelledby="nav-orderDetails-tab">
          <div className="orderDetails-Section1 py-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="orderQuickInfo mb-3">
                    <div className="oQ1">
                      <span className="orderNumber mr-3">44550</span>
                      <span className="sourceNdestination mr-3">
                        Montery, TN{" "}
                        <i className="mx-2">
                          <img src="images/arrow.svg" alt="" />
                        </i>{" "}
                        San Antanio,TX
                      </span>
                    </div>
                    <div className="oQ2 badge status-badge badge-pill badge-danger mr-3">
                      Accessorial
                    </div>
                    <div className="oQ3 text-primary mr-3">
                      On route to delivery ETA 4hrs/33 mi
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-4 mb-lg-0 mb-3 ">
                  <div className="card orderWhiteBox p-3 h-100">
                    <dl className="row">
                      <dt className="col-4">Customer:</dt>
                      <dd className="col-8">Graham Bell</dd>
                      <dt className="col-4">AE:</dt>
                      <dd className="col-8">Willa Gardner</dd>
                      <dt className="col-4">POD:</dt>
                      <dd className="col-8">Willa</dd>
                      <dt className="col-4">Broker:</dt>
                      <dd className="col-8">Will Smith</dd>
                    </dl>
                  </div>
                </div>
                <div className="col-12 col-lg-4 mb-lg-0 mb-3">
                  <div className="card orderWhiteBox p-3 h-100">
                    <dl className="row">
                      <dt className="col-4">Carrier Name:</dt>
                      <dd className="col-8">MIB Trucking</dd>
                      <dt className="col-4">Truck Location:</dt>
                      <dd className="col-8">Smyrna, TN</dd>
                      <dt className="col-4">Last Event/Activity:</dt>
                      <dd className="col-8">NA</dd>
                      <dt className="col-4">Last Event/Activity Time:</dt>
                      <dd className="col-8">04/04/2021 12:00</dd>
                    </dl>
                  </div>
                </div>
                <div className="col-12 col-lg-4 mb-lg-0 mb-3">
                  <div className="card orderWhiteBox p-3 h-100">
                    <dl className="row">
                      <dt className="col-4">Dispatcher Name:</dt>
                      <dd className="col-8">Bod</dd>
                      <dt className="col-4">Dispatcher Phone:</dt>
                      <dd className="col-8">615-258-5888</dd>
                      <dt className="col-4">Driver Name:</dt>
                      <dd className="col-8">Bill</dd>
                      <dt className="col-4">Driver Phone:</dt>
                      <dd className="col-8">615-252-5556</dd>
                      <dt className="col-4">Truck:</dt>
                      <dd className="col-8">NA</dd>
                      <dt className="col-4">Trailer:</dt>
                      <dd className="col-8">NA</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="orderDetails-Section2">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-md-12 col-xl-6 mb-3 mb-lg-0">
                  <div className="card bg-lightgray border-0 h-100">
                    <h2 className="px-2 pt-2">Waterfall</h2>
                    <table class="table truck-table mb-0">
                      <tbody>
                        <tr>
                          <th></th>
                          <th>Ranking</th>
                          <th>Broker Name</th>
                          <th>Carrier Name</th>
                          <th>Carrier Email</th>
                          <th>Truck Cost</th>
                          <th>Expiration</th>
                          <th>Capacity</th>
                          <th>&nbsp;</th>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/move.svg" />
                          </td>
                          <td>01</td>
                          <td><select class="form-control broker_sel"><option value="" disabled="" hidden=""></option><option>Brandon Joyce</option><option>Brad Perling</option><option>Alex Schnitzer</option><option>Aubrey Ingham</option><option>John Miller</option><option>William Ruderer</option><option>Kibrom Negash</option><option>Tucker Munns</option><option>Shannon Cappiello</option><option>Caleb Brown</option><option>Tyler House</option><option>Jim Wilson</option><option>Christian Baraks</option><option>Bailey Bashore</option><option>mohit s</option></select></td>
                          <td>Whiteman Trucking</td>
                          <td>whitemantrucking@smaple.com</td>
                          <td>$684.00</td>
                          <td>06/24/2021</td>
                          <td>Capacity</td>
                          <td>
                            <button class="btn btn-danger btn-circle btn-24">
                              <i class="ch ch-close"></i>
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/move.svg" />
                          </td>
                          <td>01</td>
                          <td><select class="form-control broker_sel"><option value="" disabled="" hidden=""></option><option>Brandon Joyce</option><option>Brad Perling</option><option>Alex Schnitzer</option><option>Aubrey Ingham</option><option>John Miller</option><option>William Ruderer</option><option>Kibrom Negash</option><option>Tucker Munns</option><option>Shannon Cappiello</option><option>Caleb Brown</option><option>Tyler House</option><option>Jim Wilson</option><option>Christian Baraks</option><option>Bailey Bashore</option><option>mohit s</option></select></td>
                          <td>Whiteman Trucking</td>
                          <td>whitemantrucking@smaple.com</td>
                          <td>$684.00</td>
                          <td>06/24/2021</td>
                          <td>Capacity</td>
                          <td>
                            <button class="btn btn-danger btn-circle btn-24">
                              <i class="ch ch-close"></i>
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/move.svg" />
                          </td>
                          <td>01</td>
                          <td><select class="form-control broker_sel"><option value="" disabled="" hidden=""></option><option>Brandon Joyce</option><option>Brad Perling</option><option>Alex Schnitzer</option><option>Aubrey Ingham</option><option>John Miller</option><option>William Ruderer</option><option>Kibrom Negash</option><option>Tucker Munns</option><option>Shannon Cappiello</option><option>Caleb Brown</option><option>Tyler House</option><option>Jim Wilson</option><option>Christian Baraks</option><option>Bailey Bashore</option><option>mohit s</option></select></td>
                          <td>Whiteman Trucking</td>
                          <td>whitemantrucking@smaple.com</td>
                          <td>$684.00</td>
                          <td>06/24/2021</td>
                          <td>Capacity</td>
                          <td>
                            <button class="btn btn-danger btn-circle btn-24">
                              <i class="ch ch-close"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button className="btn btn-pill btn-success m-3 w-25">
                      Add Routing Option
                    </button>
                  </div>
                </div>
                <div className="col-md-12 col-xl-6 mb-3 mb-lg-0">
                  <div className="card bg-lightgray border-0 h-100">
                    <h2 className="px-2 pt-2">Next Load Destination</h2>
                    <table class="table truck-table mb-0">
                      <tbody>
                        <tr>
                          <th>Order</th>
                          <th>Customer</th>
                          <th>PickUpLocation</th>
                          <th>PickUpDateTime</th>
                          <th>Destination</th>
                          <th>DeliveryDateTime</th>
                          <th>TruckType</th>
                          <th>Weight</th>
                          <th>Load</th>
                        </tr>
                        <tr>
                          <td>64988</td>
                          <td></td>
                          <td></td>
                          <td>2018-11-13 00:00:00</td>
                          <td>TWIN FALLS ID</td>
                          <td></td>
                          <td>Van</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>64988</td>
                          <td></td>
                          <td></td>
                          <td>2018-11-13 00:00:00</td>
                          <td>TWIN FALLS ID</td>
                          <td></td>
                          <td>Van</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="orderDetails-Section3">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col">
                  <ul className="detaieldStatusBar">
                    <li>Available</li>
                    <li>Planned</li>
                    <li>Dispatch</li>
                    <li>Arrived @ Shipper</li>
                    <li>Picked Up</li>
                    <li>Arrived @ Delivery</li>
                    <li>Delivered</li>
                    <li className="active">Accessorial</li>
                    <li>Finished</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="orderDetails-Section4">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col">
                  <table class="table truck-table table-bordered mb-0">
                    <tbody>
                      <tr>
                        <th width="25%">Load Details</th>
                        <th width="25%">Origin</th>
                        <th width="25%">Destination</th>
                        <th width="25%">Line Itesms</th>
                      </tr>
                      <tr>
                        <td className="border-right">
                          <ul className="list-unstyled load-details-ul">
                            <li>
                              <div className="title">Bill To:</div>
                              <div className="value">xyz</div>
                            </li>
                            <li>
                              <div className="title">Customer:</div>
                              <div className="value">xxx</div>
                            </li>
                            <li>
                              <div className="title">Reference No:</div>
                              <div className="value">xyz</div>
                            </li>
                            <li>
                              <div className="title">Pick Up:</div>
                              <div className="value">xyz</div>
                            </li>
                            <li>
                              <div className="title">Delivery No:</div>
                              <div className="value">xyz</div>
                            </li>
                            <li><div className="title">Carrier Name:</div>
                              <div className="value">xyz</div>
                            </li>
                            <li> <div className="title">Truck Empty Location:</div>
                              <div className="value">xyz</div></li>
                            <li>
                              <div className="title">Vehicle Type:</div>
                              <div className="value">xyz</div>
                            </li>
                            <li>
                              <div className="title">Commodity:</div>
                              <div className="value">xyz</div>
                            </li>
                            <li>
                              <div className="title">Weight:</div>
                              <div className="value">xyz</div>
                            </li>
                            <li>
                              <div className="title">Miles:</div>
                              <div className="value">xyz</div>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <h6>Alex Supplier,</h6>
                          <dl>
                            <dt>Montery, TN</dt>
                            <dd>on 02 April 2021 @ 00:09</dd>
                          </dl>
                        </td>
                        <td>
                          <h6>Miracle Labs LLC,</h6>
                          <dl>
                            <dt>San Antanio, TX</dt>
                            <dd>on 04 April 2021 @ 04:00</dd>
                          </dl>
                        </td>
                        <td>
                          <dl className="line-items-dl">
                            <dt className="w-50">Total Revenue:</dt>
                            <dd className="w-50">$500</dd>
                            <dt className="w-50">Total Truck Cost:</dt>
                            <dd className="w-50">$400</dd>
                            <dt className="w-50">Margin:</dt>
                            <dd className="w-50">$200</dd>
                            <dt className="w-50">Margin %:</dt>
                            <dd className="w-50 text-success">15%</dd>
                          </dl>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="orderDetails-Section5 ">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col">
                  <div className="card bg-lightgray border-0 border-radius-0">
                    <div className="card-body">
                      <form className="row">
                        <div className="col-12 col-md-6">
                          <div class="form-group mb-1">
                            <label for="SpecialNote">Special Note</label>
                            <textarea
                              class="form-control"
                              id="SpecialNote"
                              rows="4"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div class="form-group mb-1">
                            <label for="SpecialNote1">Special Note 1</label>
                            <textarea
                              class="form-control"
                              id="SpecialNote1"
                              rows="2"
                            ></textarea>
                          </div>
                          <div class="form-group mb-1">
                            <label for="SpecialNote2">Special Note 2</label>
                            <textarea
                              class="form-control"
                              id="SpecialNote2"
                              rows="2"
                            ></textarea>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="orderDetails-Section6">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-12 col-xl-6">
                  {/* <div class="truckInfoWrapper h-100">
                    <div class="truckInfoItem">
                      <span class="">
                        <i class="ch ch-truck-icon"></i>
                      </span>
                      <div class="label">Carrier Name</div>
                      <p class="text-primary">NA</p>
                    </div>
                    <div class="truckInfoItem">
                      <span class="">
                        <i class="ch ch-truck-location"></i>
                      </span>
                      <div class="label">Truck Empty Location</div>
                      <p class="text-primary">
                        POWDERLY TX <span></span>
                      </p>
                    </div>
                    <div class="truckInfoItem">
                      <span class="">
                        <i class="ch ch-truck-icon"></i>
                      </span>
                      <div class="label">Vehicle Type</div>
                      <p class="text-primary">Van or Flatbed</p>
                    </div>
                    <div class="truckInfoItem">
                      <span class="">
                        <i class="ch ch-chart-icon"></i>
                      </span>
                      <div class="label">Commodity</div>
                      <p class="text-primary">NA</p>
                    </div>
                    <div class="truckInfoItem">
                      <span class="">
                        <i class="ch ch-weight-icon"></i>
                      </span>
                      <div class="label">Weight</div>
                      <p class="text-primary">44000</p>
                    </div>
                    <div class="truckInfoItem">
                      <span class="">
                        <i class="ch ch-mile-icon"></i>
                      </span>
                      <div class="label">Miles</div>
                      <p class="text-primary">587</p>
                    </div>
                  </div> */}

                  <div className="avgCostBox outLineBox rounded px-3 pt-3 mb-3">
                    <h3>Average Cost of Lane</h3>
                    <div className="row">
                      <div class="col-md-6">
                        <div class="avgCostBoxItem mb-3">
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              id="aclRadio1"
                              name="aclRadio1"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="aclRadio1">
                              7 Days <span class="ml-auto">$50.00</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="avgCostBoxItem mb-3">
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              id="aclRadio2"
                              name="aclRadio2"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="aclRadio2">
                              15 Days <span class="ml-auto">$80.00</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="avgCostBoxItem mb-3">
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              id="aclRadio3"
                              name="aclRadio3"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="aclRadio3">
                              30 Days <span class="ml-auto">$100.00</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="avgCostBoxItem mb-3">
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              id="aclRadio4"
                              name="aclRadio4"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="aclRadio4">
                              1 Year <span class="ml-auto">$150.00</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-12 col-xl-6">


                  <div class="allRateBox outLineBox rounded bg-white px-3 pt-3">
                    <form>
                      <h3 class="text-dark">All In Rate : $ 265.00</h3>
                      <div class="form-row">
                        <label
                          for="target-rate"
                          class="col-sm-2 col-form-label"
                        >
                          Target Rate
                        </label>
                        <div class="input-group col-sm-4 mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="target-rate">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            name="target_rate"
                            aria-label="target-rate"
                            aria-describedby="target-rate"
                            value="200"
                          />
                        </div>
                        <label for="max-buy" class="col-sm-2 col-form-label">
                          Max Buy
                        </label>
                        <div class="input-group col-sm-4 mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="max-buy">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            name="max_by"
                            aria-label="max-buy"
                            aria-describedby="max-buy"
                            value=""
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <label
                          for="chariot-rate"
                          class="col-sm-2 col-form-label"
                        >
                          Chariot Rate
                        </label>
                        <div class="input-group col-sm-4 mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="chariot-rate">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            name="chariot_rate"
                            aria-label="chariot-rate"
                            aria-describedby="chariot-rate"
                            value=""
                          />
                        </div>
                        <label
                          for="carrier-rate"
                          class="col-sm-2 col-form-label"
                        >
                          Carrier Rate
                        </label>
                        <div class="input-group col-sm-4 mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="carrier-rate">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            name="carrier_rate"
                            aria-label="carrier-rate"
                            aria-describedby="carrier-rate"
                            value=""
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="orderDetails-Section7 mt-3">
            <div className="container-fluid ">
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      class="btn btn-info btn-pill m-2 mw-104"
                    >
                      Add Bid
                    </button>
                    <button
                      type="button"
                      class="btn btn-success btn-pill m-2 mw-104"
                    >
                      Book
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-pill m-2 mw-104"
                    >
                      Pass
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="orderDetails-Section8 mb-3">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col">
                  <div class="outLineBox rounded p-3">
                    <h2>Load Offer History (1)</h2>

                    <table class="table truck-table mb-0">
                      <tbody>
                        <tr>
                          <th>Type</th>
                          <th>Reason</th>
                          <th>Create Date Time</th>
                          <th>Broker Name</th>
                          <th>Carrier Name</th>
                          <th>Carrier Phone</th>
                          <th>Dispatcher Name</th>
                          <th>Dispatcher Phone</th>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td> </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-progress"
          role="tabpanel"
          aria-labelledby="nav-progress-tab"
        >
          <div className="progress-Section">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-12 col-xl-6">
                  <div className="soudest-info d-flex flex-row align-items-center">
                    <div className="sourceBox px-3">
                      <h5 className="mb-0">
                        Minniepolis{" "}
                        <span className="h2 d-block">Minnesota, USA</span>
                      </h5>
                    </div>
                    <div className="px-3">
                      <img src="images/arrow.svg" alt="" />
                    </div>
                    <div className="destinationBox px-3">
                      <h5 className="mb-0">
                        Orlando <span className="h2 d-block">Florida, USA</span>
                      </h5>
                    </div>
                    <div className="px-3 border-left">
                      <h5 className="mb-0 text-dark">
                        Estimated Time{" "}
                        <span className="h2 d-block">
                          On 05/05/2021, 10:00 AM
                        </span>
                      </h5>
                    </div>
                  </div>
                  <ul className="status-timeline">
                    <li>
                      <div className="">
                        <h5>
                          Dispatched
                          <span className="">On 04/27/2021 @ 2.30PM </span>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="">
                        <h5>
                          Arrived @ Shipper
                          <span className="">On 04/29/2021 @ 10:00 AM </span>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="">
                        <h5>
                          Pickup
                          <span className="">On 04/29/2021 @ 05.00 PM </span>
                        </h5>
                      </div>
                    </li>
                    <li className="current">
                      <div className="">
                        <h5>
                          Arrived @ Delivery
                          <span className="">On 04/27/2021 @ 2.30PM </span>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="">
                        <h5>
                          Delivery
                          <span className="">On 04/27/2021 @ 2.30PM </span>
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-xl-6">Map here</div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade " id="nav-document" role="tabpanel" aria-labelledby="nav-document-tab">
          <div className="document-Section">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-12 col-xl-6">
                  <div className="drag-document-box p-3 mb-3">
                    <div className="mt-auto text-primary"><p className="mb-0">Drag your files here. <a href="#" className="text-primary font-weight-bold">Or select a file from your computer.</a></p></div>
                  </div>
                  <div className="document-progress-wrapper">
                    <div className="document-progess-item d-flex flex-sm-row flex-column justify-content-between align-items-center mb-3">
                      <div className="document-thumb d-flex m-1"></div>
                      <div className="document-progress m-1 flex-fill">
                        <div className="document-name">Contract Document.word</div>
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aluenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div className="document-size">375.65 KB</div>
                      </div>
                      <div className="document-buttons m-1">
                        <button class="btn btn-info btn-pill mw-104 m-1">Upload</button>
                        <button class="btn btn-danger btn-pill mw-104 m-1">Cancle</button>
                      </div>
                    </div>
                    <div className="document-progess-item d-flex flex-sm-row flex-column justify-content-between align-items-center mb-3">
                      <div className="document-thumb d-flex m-1"></div>
                      <div className="document-progress m-1 flex-fill">
                        <div className="document-name">Contract Document.word</div>
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aluenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div className="document-size">375.65 KB</div>
                      </div>
                      <div className="document-buttons m-1">
                        <button class="btn btn-info btn-pill mw-104 m-1">Upload</button>
                        <button class="btn btn-danger btn-pill mw-104 m-1">Cancle</button>
                      </div>
                    </div>
                    <div className="document-progess-item d-flex flex-sm-row flex-column justify-content-between align-items-center mb-3">
                      <div className="document-thumb d-flex m-1"></div>
                      <div className="document-progress m-1 flex-fill">
                        <div className="document-name">Contract Document.word</div>
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aluenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div className="document-size">375.65 KB</div>
                      </div>
                      <div className="document-buttons m-1">
                        <button class="btn btn-info btn-pill mw-104 m-1">Upload</button>
                        <button class="btn btn-danger btn-pill mw-104 m-1">Cancle</button>
                      </div>
                    </div>
                  </div>
                  <h3>Uploaded Documents</h3>
                  <div className="uploaded-docuemnt-item-wrapper">
                    <div className="uploaded-docuemnt-item d-flex flex-sm-row flex-column justify-content-between align-items-center mb-1">
                      <div className="document-name flex-fill m-1">Chuk-Brothers-Transport.pdf</div>
                      <div className="document-info d-flex justify-content-end align-items-center">
                        <div className="document-size m-1">323 KB</div>
                        <a className="btn btn-light2 btn-circle btn-32 m-1">
                          <i class="ch ch-eye-icon"></i>
                        </a>
                        <a className="btn btn-light2 btn-circle btn-32 m-1">
                          <i class="ch ch-direct-download"></i>
                        </a>
                      </div>
                    </div>

                    <div className="uploaded-docuemnt-item d-flex flex-sm-row flex-column justify-content-between align-items-center mb-1">
                      <div className="document-name flex-fill m-1">Chuk-Brothers-Transport.pdf</div>
                      <div className="document-info d-flex justify-content-end align-items-center">
                        <div className="document-size m-1">323 KB</div>
                        <a className="btn btn-light2 btn-circle btn-32 m-1">
                          <i class="ch ch-eye-icon"></i>
                        </a>
                        <a className="btn btn-light2 btn-circle btn-32 m-1">
                          <i class="ch ch-direct-download"></i>
                        </a>
                      </div>
                    </div>

                    <div className="uploaded-docuemnt-item d-flex flex-sm-row flex-column justify-content-between align-items-center mb-1">
                      <div className="document-name flex-fill m-1">Chuk-Brothers-Transport.pdf</div>
                      <div className="document-info d-flex justify-content-end align-items-center">
                        <div className="document-size m-1">323 KB</div>
                        <a className="btn btn-light2 btn-circle btn-32 m-1">
                          <i class="ch ch-eye-icon"></i>
                        </a>
                        <a className="btn btn-light2 btn-circle btn-32 m-1">
                          <i class="ch ch-direct-download"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
                <div className="col-12 col-xl-6">
                  <div className="no-document d-flex justify-content-center align-items-center h-100">
                    <div className="no-docuemnt-box text-center">
                      <img src="../images/no-document.png" alt="" />
                      <p className="h5 text-primary mt-2">Currently No Preview</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewDetailsTest;
