import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { passReasons } from "./Helper";

export default function PassReasonModal({ show, save, close }) {
	const [reason, SetReason] = useState(passReasons[passReasons.length - 1]);

	const savePassBid = () => {
		save(reason.value);
		SetReason(passReasons[passReasons.length - 1]);
	}

	return (
		<div className="confirm-modal info-select">
			<Modal size="sm" show={show} onHide={close}>
				<Modal.Header closeButton>
					<Modal.Title>Enter Pass Reason</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="info-select">
						<Select
							value={reason}
							options={passReasons}
							onChange={(value) => SetReason(value)}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-danger" onClick={close}>
						Cancel
					</button>
					<button disabled={!reason} className="btn btn-success" onClick={() => savePassBid()}>
						Save
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}