import React, { Component } from "react";
import ReactTable from "react-table";
import { fieldFilters } from "./Helper";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { fetchCarrierUsersList } from "../../../Redux/Action/Carrieruser";
import { updateActivePage } from "../../../Redux/Action/Auth";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import ConfirmationModal from "../../Utils/ConfirmationModal";
import { renderColumn, renderDataCount } from "../../Utils/Filter";
import PreloaderInner from "../../Utils/PreloaderInner";

class CarrierUserList extends Component {
	state = {
		nonFilterColumns: [
			{
				name: "register",
				notSortable: true,
				filter: "reset"
			}
		],
		filtered: [],
		pageSize: 20,
		pageNumber: 0,
		filteredDataLength: 0,
		showPagination: true,
		userId: "",
		showConfirmationModal: false,
		modalContent: "Do you really want to delete this user",
		submitting: false
	};

	componentDidMount() {
		this.props.fetchCarrierUsersList();
		
		this.props.updateActivePage("activePage", "carrieruser");
	}

	componentDidUpdate() {
		const { pageSize, showPagination, filteredDataLength } = this.state;

		if (this.loadTable) {
			let currentDataLength = this.loadTable.getResolvedState().sortedData.length;
			if (currentDataLength !== filteredDataLength) {
				this.setState({ filteredDataLength: currentDataLength });
			}
			let show = currentDataLength > pageSize;
			if (show !== showPagination) {
				this.setState({ showPagination: show });
			}
		}
	}

	componentWillUnmount = () => {
		// this.props.updateField("roles", {});
		// this.props.updateField("divisions", {});
	};

	handleClose = () => {
		this.setState({ showConfirmationModal: false });
	};

	handleSave = () => {
		this.setState({ submitting: true });
		const successHandler = (res) => {
			this.setState({ submitting: false, showConfirmationModal: false });
			if (200 <= res.status && res.status < 300) {
				this.props.fetchCarrierUsersList();
				NotificationManager.success(`Carrieruser Deleted`, "", 5000);
			} else {
				NotificationManager.error(res.statusText, "", 5000);
			}
		};
		this.props.deleteUser(this.state.userId, successHandler);
	};

	renderCell = (field, props) => {
		switch (field.name) {
			case "register":
				return this.renderRegisterIcon(props)
			case "roles":
				return props.value ? <div>{props.value.display_name}</div> : "-----------";
			case "pickDate":
				return props.value ? <div>{moment(new Date(props.value)).format("MM/DD/YYYY")}</div> : "-----------";
			case "deliveryDate":
				return props.value ? <div>{moment(new Date(props.value)).format("MM/DD/YYYY")}</div> : "-----------";
			case "division":
				return props.original.division_name ? <div>{props.original.division_name}</div> : "-----------";
			// case "MC Number":
			// 	return props.original.username ? <div>{props.original.username}</div> : "-----------";
			default:
				return props.value ? <div>{props.value}</div> : "-----------";
		}
	};

	renderRegisterIcon = (props) => {
		return (
			<div className="text-left ">
				<button className="btn btn-cell btn-sm btn-success pointer">
					<i className="icon icon-user " />
				</button>
			</div>
		);
	};


	showConfirmationModal = (e, props) => {
		e.preventDefault();
		this.setState({ userId: props.original.id, showConfirmationModal: true });
	};

	resetFilters = (e) => {
		e.preventDefault();
		this.setState({ filtered: [] });
	};

	handleDate = (date, updateFilter, field) => {
		const { filtered } = this.state;
		if (date) {
			updateFilter(date);
		} else {
			let newFilter = filtered.filter((val) => val.id !== field.name);
			this.setState({ filtered: newFilter });
		}
	};

	getOptions = (field) => {
		const { userRoles, divisionsList } = this.props;
		let options = [];
		if (field.name === "division" && divisionsList && divisionsList.data && divisionsList.data.length > 0) {
			options = divisionsList.data.map((division) => {
				return { value: division.id, label: division.division };
			});
		} else if (field.name === "roles" && userRoles && userRoles.data && userRoles.data.length > 0) {
			options = userRoles.data.map((role) => {
				return { value: role.id, label: role.display_name };
			});
		}
		return options;
	};

	columns = [
		...fieldFilters.map((field) =>
			renderColumn(field, this.renderCell, this.resetFilters, this.handleDate, null, this.getOptions)
		),
		...this.state.nonFilterColumns.map((field) => renderColumn(field, this.renderCell, this.resetFilters, this.handleDate))
	];

	renderTable = () => {
		const { usersList } = this.props;
		const { pageSize, filtered, showPagination, filteredDataLength, pageNumber } = this.state;
		return (
			<form className="mt-3 p-r">
				<PreloaderInner hidden={usersList} />
				<ReactTable
					className="table-primary"
					ref={(r) => (this.loadTable = r)}
					data={usersList ? usersList.data : []}
					columns={this.columns}
					minRows={0}
					showPagination={showPagination}
					showPageSizeOptions={false}
					pageSize={pageSize}
					page={pageNumber}
					align-items='center'
					onPageChange={(pageNumber) => {
						this.setState({ pageNumber });
					}}
					filterable={true}
					filtered={filtered}
					onFilteredChange={(filtered) => {
						this.setState({ filtered, pageNumber: 0 });
					}}
					noDataText={(usersList && filteredDataLength) === 0 ? "No results found!" : ""}
					getTdProps={(state, rowInfo, column) => {
						return {
							onClick: () => {
								if (column.id === "register") {
									this.props.history.push(`/carrieruser/${rowInfo.original.id}`);
								}
							}
						};
					}}
					previousText=""
					nextText=""
				/>
				{filteredDataLength > 0 && (
					<span className={showPagination ? "results-count" : "results-length"}>
						{renderDataCount(pageNumber, filteredDataLength, pageSize)}
					</span>
				)}
			</form>
		);
	};

	render() {
		const { showConfirmationModal, modalContent, submitting } = this.state;
		return (
			<React.Fragment>
				<ConfirmationModal
					show={showConfirmationModal}
					content={modalContent}
					submitting={submitting}
					close={this.handleClose}
					save={this.handleSave}
				/>
				<section className="user-list-header ">
					<div className="page-header">
						<h4 className="section-title flex-fill">Carrier Users List</h4>
						
					</div>
				</section>
				<section className="user-list-table-container">
					<Scrollbars>
						<div className="load-list-table-block justify-content-space-between">{this.renderTable()}</div>
					</Scrollbars>
				</section>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	usersList: state.Carrieruser.list,
});

const mapDispatchToProps = {
	fetchCarrierUsersList,
	
	// updateField,
	updateActivePage,
	// deleteUser
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CarrierUserList);
