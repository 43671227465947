import React, { useState, useEffect } from 'react';
import { fetchRates, saveTargetMaxRateValues, saveBidRates } from '../../../../Redux/Action/Dashboard';
import BidModule from '../SniperSection/BIDModule/Index';

function CollapsibleSection({ carrierData, carrierInfo, setReload, setError, truckerToolData, setRatesReload, ratesReload, setLoadBooked,loadStatus }) {
    const [isVisible, setVisible] = useState(false);
    const [notes, setNotes] = useState('')
    const [rates, setRates] = useState({
        chariot_rate: '',
        carrier_rate: ''
    });
    const [truckerToolMCNo, setTruckerToolMCNo] = useState('')

    useEffect(() => {
        var TruckerMcNo = truckerToolData.mc.split('MC').pop()
        setTruckerToolMCNo(TruckerMcNo)
    }, [truckerToolData])

    useEffect(() => {
        if (carrierData.id) {
            const offer_no = carrierData.id;
            const loadType = carrierData.load_type;
            fetchRates(offer_no, loadType).then((result) => {
                if (result.message === 'success') {
                    setRates({
                        chariot_rate: result.data.rates_get[0].chariot_rate && result.data.rates_get[0].chariot_rate !== '0' ? result.data.rates_get[0].chariot_rate : '',
                        carrier_rate: result.data.rates_get[0].carrier_rate && result.data.rates_get[0].carrier_rate !== '0' ? result.data.rates_get[0].carrier_rate : '',
                    });
                }
                setRatesReload((prevState) => ({
                    ...prevState,
                    SplitScreenRatesReload: false
                }));
            }).catch((error) => {
                // console.log('No Rates found for this Order')
                setRates((prevValues) => ({
                    ...prevValues,
                    chariot_rate: '',
                    carrier_rate: ''
                }));
                setRatesReload((prevState) => ({
                    ...prevState,
                    SplitScreenRatesReload: false
                }));
            })
        }
    }, [carrierData, ratesReload.SplitScreenRatesReload])

    const handleVisibleClick = (e) => {
        e.preventDefault()
        setVisible(!isVisible)
    }

    const handleOnChangeNotes = (e) => {
        const { name, value } = e.target
        setNotes(value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // saveSplitScreenNotes(notes);
    }

    const saveRateValues = (e) => {
        const { name, value } = e.target
        setRates((prevValue) => ({
            ...prevValue,
            [name]: value
        }))
    }

    const saveRates = () => {
        const payLoad = {
            load_id: carrierData.p_key,
            load_type: carrierData.load_type,
            chariot_rate: rates.chariot_rate,
            carrier_rate: rates.carrier_rate
        }
        saveBidRates(payLoad).then((result) => {
            if (result.message === 'success') {
                setRatesReload((prevState) => ({
                    ...prevState,
                    DashboardRatesReload: true
                }));
            } else {
            }
        }).catch(err => {
            //  console.log(err)
        })
    }


    return (
        <div id="dataRow-1" className="dataRow p-3">
            <div className="row split-row-data align-items-center">
                <div className="col-12 col-md-6 col-lg-6">
                    <label class="my-1 mr-2 " htmlFor="preferred-destination">
                        <b>Preferred Destinations:</b>
                    </label>
                    <span className="text-break white-space" id="preferred-destination">{truckerToolData.preferredDestinations}</span>
                    <div className="d-flex align-items-center">
                        <a href="#" className="addtruck d-block">
                            <img
                                src='images/icon-plus-square.png'
                                alt="add"
                                onClick={(e) => handleVisibleClick(e)}
                            />
                        </a>
                        <span className={`badge split-badge ${isVisible ? '' : 'd-none'}`}>DD</span>
                        <span className={`badge split-badge ${isVisible ? '' : 'd-none'}`}>Called</span>
                        <form class="form-inline mx-3 w-100" onSubmit={(e) => { handleSubmit(e) }}>
                            <label class="my-1 mr-2" htmlFor="splitDetailNote">
                                Notes
                            </label>
                            <textarea class="form-control my-1 mr-sm-2" id="splitDetailNote" rows="2" onChange={(e) => { handleOnChangeNotes(e) }}></textarea>
                            <button type="submit" class="btn btn-primary my-1">
                                Submit
                            </button>
                        </form>
                    </div>

                </div>
                <div className="col-12 col-md-6 col-lg-2">
                    <dl class="line-items-dl">
                        <dt class="w-50">Chariot Rate:</dt>
                        {/* <dd class="w-50">${rates.chariot_rate}</dd> */}
                        <dd class="w-50">
                            <div class="input-group w-75">
                                <div class="input-group-prepend"><span class="input-group-text" id="target-rate">$</span></div>
                                <input type="text" class="form-control px-1" placeholder="" name='chariot_rate' value={rates.chariot_rate}
                                    onChange={saveRateValues} onBlur={saveRates} />
                            </div>
                        </dd>
                        <dt class="w-50">Carrier Rate :</dt>
                        {/* <dd class="w-50">${rates.carrier_rate}</dd> */}
                        <dd class="w-50">
                            <div class="input-group w-75">
                                <div class="input-group-prepend"><span class="input-group-text" id="target-rate">$</span></div>
                                <input type="text" class="form-control px-1" placeholder="" name='carrier_rate' value={rates.carrier_rate}
                                    onChange={saveRateValues} onBlur={saveRates} />
                            </div>
                        </dd>
                    </dl>
                </div>
                <div class="col-12 col-lg-4 text-center text-lg-right">
                    {/* <button class="btn btn-info btn-pill mw-104 m-1">
                        Add Bid
                    </button>
                    <button class="btn btn-success btn-pill mw-104 m-1">
                        Book
                    </button>
                    <button class="btn btn-danger btn-pill mw-104 m-1">
                        Pass
                    </button> */}

                    <BidModule MCNo={truckerToolMCNo} CarrierName={truckerToolData.companyName} Rates={rates} carrierInfo={carrierInfo} carrierData={carrierData} setError={setError} setReload={setReload} sourceType={truckerToolData.sourcetype}
                        contactPhone={truckerToolData.contactPhone} contactEmail={truckerToolData.contactEmail}
                        setLoadBooked={setLoadBooked} loadStatus={loadStatus}
                    />
                </div>
            </div>
        </div>
    )
}

export default CollapsibleSection
