import React, { Component } from "react";
import "./../../Styles/Loader.css";

export default class PreloaderInner extends Component {
	render() {
		return (
			<div className={`preloader-wrap inner ${this.props.hidden ? "hidden" : ""}`}>
				{/* <div className="preloader-inner"></div> */}
				<div className="loader">Loading...</div>
			</div>
		);
	}
}
