const required = (value) => {
	return (value && typeof value !== "object") ||
		(value && typeof value === "object" && value.length === undefined) ||
		(value && typeof value === "object" && value.length !== undefined && value.length > 0) ||
		typeof value === "number"
		? undefined
		: "Required";
};
const maxLength = (max) => (value) => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
const number = (value) => (value && isNaN(Number(value)) ? "Must be a number" : undefined);
const email = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim()) ? "Invalid email address" : undefined;
const minLength = (min) => (value) => (value && value.length < min ? `Must be ${min} characters or more` : undefined);
const minLength6 = minLength(6);
const maxLength6 = maxLength(6);
const minLength5 = minLength(5);
const maxLength9 = maxLength(9);
const phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const PhoneNumber = (elementValue) =>
	elementValue && !phoneNumberPattern.test(elementValue) ? `Invalid phone number` : undefined;
const passwordsMatch = (value, allValues) => (value !== allValues.password ? "Passwords don't match" : undefined);
const greaterThanETA = (value, allValues) => {
	return !allValues.etaForPickup ||
		!value ||
		(value &&
			allValues.etaForPickup &&
			value
				.seconds(0)
				.milliseconds(0)
				.isAfter(allValues.etaForPickup.seconds(0).milliseconds(0)))
		? undefined
		: "Should be greater than ETA";
};

export const rules = {
	required,
	maxLength,
	minLength,
	number,
	email,
	PhoneNumber,
	minLength6,
	maxLength6,
	minLength5,
	maxLength9,
	passwordsMatch,
	greaterThanETA
};