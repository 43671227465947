import { rules } from "../../Utils/Validator";
import { createTextMask } from "redux-form-input-masks";

const phoneMask = createTextMask({
    pattern: '( 999 ) 999-9999',
	guide:false,
	allowEmpty:true
  });

export const fieldFilters = [
	{
		name: "id",
		type: "number",
		placeHolder: "User ID",
		default: "",
	},
	{
		name: "username",
		type: "number",
		placeHolder: "Mc Number",
		default: "",
	},

	{
		name: "firstname",
		type: "text",
		placeHolder: "Carrier Name",
		default: null
	},
	{
		name: "carrier_abrv",
		type: "text",
		placeHolder: "Carrier Abbrv",
		default: null
	},
	// {
	// 	name: "lastname",
	// 	type: "text",
	// 	placeHolder: "Last Name",
	// 	default: ""
	// },
	// {
	// 	name: "roles",
	// 	filter: "select",
	// 	type: "roles",
	// 	placeHolder: "User Role",
	// 	default: ""
	// },
	// {
	// 	name: "division",
	// 	type: "division",
	// 	filter: "select",
	// 	placeHolder: "Division",
	// 	default: ""
	// },
	{
		name: "name",
		type: "text",
		placeHolder: "Name",
		default: ""
	},
	{
		name: "email",
		type: "text",
		placeHolder: "Email",
		default: ""
	},

	// {
	// 	name: "state",
	// 	type: "text",
	// 	placeHolder: "State",
	// 	default: ""
	// },
	// {
	// 	name: "city",
	// 	type: "text",
	// 	placeHolder: "City",
	// 	default: ""
	// },
	// {
	// 	name: "zipcode",
	// 	type: "text",
	// 	placeHolder: "Zip Code",
	// 	default: ""
	// },
	{
		name: "phone_number",
		type: "text",
		placeHolder: "Phone Number",
		default: "",
		mask:phoneMask,
	},

	{
		name: "carrier_safety_rating",
		type: "text",
		placeHolder: "Carrier Safety Rating",
		default: ""
	},
	{
		name: "carrier_insurance",
		type: "text",
		placeHolder: "Carrier Insurance",
		default: ""
	}
	// {
	// 	name: "extension",
	// 	type: "text",
	// 	placeHolder: "Extension",
	// 	default: ""
	// }
];

export const basicInfoFields = [
	// {
	// 	name: "firstname",
	// 	type: "text",
	// 	placeholder: "Enter First Name",
	// 	label: "First Name"
	// },
	// {
	// 	name: "lastname",
	// 	type: "text",
	// 	placeholder: "Enter Last Name",
	// 	label: "Last Name"
	// },
	{
		name: "roles",
		type: "select",
		label: "User Role",
		placeholder: "Enter User Role"
	},
	{
		name: "division",
		type: "select",
		label: "Division",
		placeholder: "Enter Division"
	},
	{
		name: "state",
		type: "select",
		placeholder: "Enter State",
		label: "State"
	},
	{
		name: "city",
		type: "text",
		placeholder: "Enter City",
		label: "City"
	},
	{
		name: "zipcode",
		type: "text",
		placeholder: "Enter Zip Code",
		label: "Zip Code",
		validation: [rules.required,rules.minLength5,rules.maxLength9]
	}
];

export const contactInfoFields = [
	// {
	// 	name: "phone_number",
	// 	type: "text",
	// 	placeholder: "Enter Phone Number",
	// 	label: "Phone Number",
	// 	validation: [rules.required, rules.PhoneNumber]
	// },
	// {
	// 	name: "extension",
	// 	type: "number",
	// 	placeholder: "Enter Extension",
	// 	label: "Extension"
	// },
	// {
	// 	name: "email",
	// 	type: "text",
	// 	label: "Email",
	// 	placeholder: "Enter Email",
	// 	validation: [rules.required, rules.email]
	// }
];

export const carrierInfoFields = [
	{
		name:"mcNo",
		type:"text",
		label:"MC Number",
		placeholder:"Enter Valid MC Number",
		readOnly: true,
		validation:[rules.required],
		// mask:"",
		// filter:"",
	},
	{
		name:"CarrierName",
		type:"text",
		label:"Carrier Name",
		placeholder:"Carrier Name",
		validation:[rules.required],
		// mask:"",
		// filter:"",
	},
	{
		name:"DispatcherName",
		type:"text",
		label:"Dispatcher Name",
		placeholder:"Dispatcher Name",
		validation:[rules.required],
		// mask:"",
		// filter:"",
	},
	{
		name:"DispatcherPhoneNumber",
		type:"text",
		label:"Dispatcher Phone Number",
		placeholder:"Dispatcher Phone number",
		validation:[rules.required,rules.PhoneNumber],
		mask:phoneMask,
		// filter:"",
	},
	{
		name: "extension",
		type: "number",
		placeholder: "Enter Extension",
		label: "Extension"
	},
	{
		name:"DispatcherEmail",
		type:"email",
		label:"Dispatcher Email",
		placeholder:"Dispatcher Email",
		validation:[rules.required],
		// mask:"",
		// filter:"",
	},
	{
		name:"CarrierSafetyRating",
		type:"text",
		label:"Carrier Safety Rating",
		placeholder:"Carrier Safety Rating",
		// validation:[rules.required],
		// mask:"",
		// filter:"",
	},
	{
		name:"CarrierInsurance",
		type:"text",
		label:"Carrier Insurance",
		placeholder:"Carrier Insurance",
		// validation:[rules.required],
		// mask:"",
		// filter:"",
	}
];