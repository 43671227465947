import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import LoadOfferHistory from "./LoadOfferHistory";
import NextLoadDestination from "./NextLoadDestination";
import TruckInformation from "./TruckInformation";
import AverageCostLane from "./AverageCostLane";
import AllRate from "./AllRate";
import { Dropdown } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Collapsible from "../../../Utils/Collapse/Collapsible";
import PerfectScrollbar from "react-perfect-scrollbar";
import DatePicker from "react-datepicker";
import moment from "moment";
import { fetchSniperList, saveDifficultyValue, sendMail, setBrokers, deleteBroker, saveChariotRate, saveTags, saveBidRates, fetchTags, fetchTruckInformation, saveTargetMaxRateValues, fetchSnipperDashboardCount, fetchSnipperBookedLoads } from "../../../../Redux/Action/Dashboard";
import BidModule from "./BIDModule/Index";
import PreloaderInner from "../../../Utils/PreloaderInner";
import { NotificationManager } from "react-notifications";
import sniperListData from './../StaticSniperData.json';
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { setTruckEmptyLocation, resetCloned } from "./../../../../Redux/Action/TruckDetails";
import _, { set } from 'lodash';
// import Pagination from "./Pagination";

const restrictedAccess = localStorage.getItem("CHARIOT_USER_DATA")?JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.restricted_access:'';
const staticFilterObject = [
  { value: "", type: "text", fieldName: "order", isFilterOption: true, placeholder: "Order No", isFilterEnabled: true, apiField: "id", sortField: true },
  { value: "", type: "text", fieldName: "status", isFilterOption: true, placeholder: "Status", isFilterEnabled: true, apiField: "status", sortField: true },
  { value: "", type: "text", fieldName: "broker", isFilterOption: true, placeholder: "Broker", isFilterEnabled: true, apiField: "broker_id", sortField: true },
  { value: "", type: "text", fieldName: "division", isFilterOption: true, placeholder: "Division", isFilterEnabled: true, apiField: "division", sortField: true },
  { value: "", type: "text", fieldName: "difficulty", isFilterOption: true, placeholder: "Difficulty", isFilterEnabled: true, apiField: "difficulty", sortField: true },
  { value: "", type: "text", fieldName: "tags", isFilterOption: true, placeholder: "Tags", isFilterEnabled: true, apiField: "tags", sortField: false },
  { value: "", type: "text", fieldName: "loadtype", isFilterOption: true, placeholder: "Account Executive", isFilterEnabled: false, apiField: "load_type", sortField: true },
  { value: "", type: "text", fieldName: "customer", isFilterOption: true, placeholder: "Customer", isFilterEnabled: false, apiField: "customer", sortField: true },
  { value: null, type: "datetime", fieldName: "pickdatetime", isFilterOption: true, placeholder: "Pick Up Date/Time", isFilterEnabled: true, apiField: "pickDate", sortField: true },
  { value: "", type: "text", fieldName: "pickuplocation", isFilterOption: true, placeholder: "Pick Up Location", isFilterEnabled: true, apiField: "pickLocation", sortField: true },
  { value: "", type: "text", fieldName: "destination", isFilterOption: true, placeholder: "Destination", isFilterEnabled: true, apiField: "destination", sortField: true },
  { value: null, type: "datetime", fieldName: "deliverydatetime", isFilterOption: true, placeholder: "Delivery Date/Time", isFilterEnabled: true, apiField: "deliveryDate", sortField: true },

];


const ara = ['status', 'division', 'load_type', 'pickLocation', 'destination', 'pickDate', 'deliveryDate']

const ENDPOINT = process.env.REACT_APP_BACKEND_API_SOCKET_URL
const KEY = process.env.REACT_APP_MIX_PUSHER_APP_KEY

function SniperList({ MCNo, CarrierName, spot, go, hot, brokerList, carrierInfo, filterDates, setSplitScreenOrderNo, setTruckEmptyLocation, isCloned, truckId, resetCloned, isReload, setReload, error, setError, isDateSelected, LatLong, ratesReload, setRatesReload,loadBooked, setLoadBooked, loadAvailable, setLoadAvailable, setLoadStatus, setReset, setBookData }) {//, setCarrierInfoData 
  const [filterObject, setFilterObject] = useState(staticFilterObject);
  const [sniperList, setSniperList] = useState([]);
  const [sniperCollection, setStaticSniperList] = useState([]);
  const [sniperCountData,setSniperCountData] = useState([]);
  const [dummySniperCollection, setDummyStaticSniperList] = useState([]);
  const [isLoader, SetLoader] = useState(false);
  const [rowId, selectRow] = useState();
  const [selectAll, setSelectAll] = useState(0);
  const [selected, SetSelected] = useState({});
  const [difficulty, SetDifficulty] = useState();
  const [broker, SetBroker] = useState();
  const [tagsFilter, setTagsFilter] = useState(false);
  const [AllInRates, setAllInRates] = useState({})
  const [charges, setCharges] = useState();
  // const [error, setError] = useState();
  // const [isReload, setReload] = useState(false);
  const [options, setOptions] = useState([])
  const [tagsList, setTagsList] = useState()
  const [brokers, setBrokerList] = useState(brokerList);
  const [NextLoadData, setNextLoadData] = useState({});
  const [isNextLoadChanged, setNextLoadChanged] = useState(false);
  const [isModalShow, SetModal] = useState(false);
  const [TargetMaxValue, setTargetMaxValue] = useState({
    'targetRate': '',
    'maxBuy': ''
  });
  const [brokerDropdownValue, setBrokerDropdownValue] = useState('');
  const [brokersList, setBrokersList] = useState([]);
  const [sortData, setSortData] = useState(false);
  const [apiFieldName, setApiFieldName] = useState('');
  const [descending, setDescending] = useState(false);
  const [showBadgeSortFields, setShowBadgeSortFields] = useState(false);
  const [currentPage,setCurrentPage] = useState(1);
  const [postsPerPage,setPostsPerPage] = useState(100);
  // const [orderList,setOrderList] = useState([]);  
  const [viewBookedLoads,setViewBookedLoads] = useState(false);
  const [sniperListBackUp,setSniperListBackUp]= useState([]);
  const restrictedAccess = localStorage.getItem("CHARIOT_USER_DATA")?JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.restricted_access:'';
  const badgeFields = [
    { value: "", type: "text", fieldName: "stops", isFilterOption: false, placeholder: "Stops", isFilterEnabled: true, apiField: "stops", sortField: true },
    { value: "", type: "text", fieldName: "loads", isFilterOption: false, placeholder: "No Of Loads", isFilterEnabled: true, apiField: "loads", sortField: true },
    { value: "", type: "text", fieldName: "bids", isFilterOption: false, placeholder: "Bids", isFilterEnabled: true, apiField: "bids", sortField: true },
    { value: "", type: "text", fieldName: "passes", isFilterOption: false, placeholder: "Passes", isFilterEnabled: true, apiField: "passes", sortField: restrictedAccess },
  ]
  
  useEffect(() => {
    if (carrierInfo && carrierInfo.truck_empty_location && MCNo) {
      applyFilter(filterObject);
      if (isCloned) {
        resetCloned(truckId);
      }
    }
  }, [sniperCollection]);


  useEffect(() => {
    fetchTags().then(result => {
      if (result.message === 'success') {
        setTagsList(result.data)
        var tagsOptions = []
        result.data.map((tag) => {
          tagsOptions.push(tag.tag_name)
        })
        setOptions(tagsOptions)
      }
    })
  }, [])

  useEffect(() => {
    const filteredbroker = brokerList.filter(b => b.firstname !== null)
    setBrokerList(filteredbroker)
    var customBrokers = [];
    filteredbroker.map(brokerData => {
      var customData = {
        role: brokerData.role_id,
        value: brokerData.id,
        label: brokerData.firstname + ' ' + brokerData.lastname
      }
      customBrokers.push(customData)
    });
    if (customBrokers.length === filteredbroker.length) {
      setBrokersList(customBrokers)
    }
  }, [brokerList])

  useEffect(() => {
    applyFilter(filterObject);
  }, [spot, go, hot, carrierInfo, MCNo])


  useEffect(() => {

    /*----------------STATIC DATA FOR SNIPERLIST------------------------------------------*/

    // SetLoader((prevState) => prevState = true);
    // var customObject = sniperListData.data.map((data) => ({  //var customObject = filteredData.map((data) => ({
    //   ...data,
    //   tags: [...data.tags.map((tag) => { return tag.name })],
    // }));
    // setSniperList(customObject.filter(m => m.pickDate && moment(m.pickDate).format("MM/DD/YYYY") === moment(new Date()).format("MM/DD/YYYY")));
    // setStaticSniperList(customObject);
    // setCountData(customObject)
    // SetLoader((prevState) => prevState = false);
    // var emptyTruckLocations = customObject.map(m => { return {"pickLocation" : m.pickLocation, "latitude" : m.latitude, "longitude" : m.longitude}});
    // setTruckEmptyLocation(_.uniqBy(emptyTruckLocations, "pickLocation"));

    /*----------------STATIC DATA FOR SNIPERLIST ENDS------------------------------------------*/

    /*-----------------DYNAMIC DATA USING API FOR SNIPERLIST-----------------------------------*/

    // const start_date = moment(filterDates.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    // const end_date = moment(filterDates.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    // SetLoader((prevState) => (prevState = true));
    // if (end_date === 'Invalid date') {
    //   SetLoader((prevState) => prevState = false);
    // } else {
    //   /*PUT THE CODE FOR SNIPPERLIST CALL WITH DATE FILTER HERE*/
    // }


    // ------------------------
    var payLoad = {
      latitude: LatLong.latitude,
      longitude: LatLong.longitude,
      truck_empty_location: carrierInfo.truck_empty_location
    }

    SetLoader((prevState) => prevState = true);
    fetchSniperList(payLoad) //, start_date, end_date
      .then((result) => {
        if (result.message === "success") {
          // const filteredData = result.data.filter((sniperunit) => { return sniperunit.pickDate && Date(sniperunit.pickDate) > Date.now() });
          var customObject = result.data.map((data) => ({  //var customObject = filteredData.map((data) => ({
            ...data,
            tags: [...data.tags.map((tag) => { return tag.name })],
            status: data.booking_initiated_by === "1"?"Booked by TT":data.status==='Booked'?data.sniper_ltrack_status?data.sniper_ltrack_status!=='Available'?data.sniper_ltrack_status:data.status:data.status:data.status
          }));
          setSniperList(customObject.filter(m => m.pickDate && moment(m.pickDate).format("MM/DD/YYYY") === moment(new Date()).format("MM/DD/YYYY")));
          // setSniperList(customObject);
          setStaticSniperList(customObject);
          setSniperListBackUp(customObject);
          SetLoader((prevState) => prevState = false);
          var emptyTruckLocations = customObject.map(m => { return { "pickLocation": m.pickLocation, "latitude": m.latitude, "longitude": m.longitude } });
          setTruckEmptyLocation(_.uniqBy(emptyTruckLocations, "pickLocation"));
          setCountData(customObject);
        } else {
          setSniperList([]);
          setStaticSniperList([])
          SetLoader((prevState) => (prevState = false));
        }
      })
      .catch((error) => {
        // console.log(error)
      });


    /*-----------------DYNAMIC DATA USING API FOR SNIPERLIST ENDS-----------------------------------*/

  }, []);//MCNo

  /*------------------------ SETTING the COUNT DATA----------------------------------------------------------*/

  const setCountData = async (DataList) => {
    const token = localStorage.getItem("CHARIOT_USER_DATA")
      ? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).token
      : null;

    let headersData = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    const API_URL = process.env.REACT_APP_BACKEND_API_URL;

    var payLoad = {
      latitude: LatLong.latitude,
      longitude: LatLong.longitude,
      truck_empty_location: carrierInfo.truck_empty_location
    }


    await fetch(`${API_URL}snipperlist_with_count`,
      {
        method: "POST",
        headers: {
          ...headersData
        },
        body: JSON.stringify(payLoad)
      }).then((result) => { return result.json() }).then(result => {
        const countData = result.data;
        var updatedSniperList = DataList.map((data) => {
          const countValues = countData.filter(m => m.id === data.id)
          var updatedRecord = {
            ...data,
            bids: { count: countValues[0].id === data.id ? countValues[0].bids.count : 0 },
            passes: { count: countValues[0].id === data.id ? countValues[0].passes.count : 0 },
            loads: { count: countValues[0].id === data.id ? countValues[0].loads.count : 0 }
          }
          return updatedRecord
        });
        setSniperListBackUp(updatedSniperList);
        // setStaticSniperList(updatedSniperList);
        // setSniperCountData(updatedSniperList.filter(m => m.pickDate && moment(m.pickDate).format("MM/DD/YYYY") >= moment(filterDates.startDate).format("MM/DD/YYYY") && moment(m.pickDate).format("MM/DD/YYYY") <= moment(filterDates.endDate).format("MM/DD/YYYY")));
        setShowBadgeSortFields(true);
      }).catch(error => {
        // console.log(error)
      })
  }

  useEffect(() => {
    applyFilter(filterObject);
  }, [sniperCountData])

  useEffect(() => {
    if (apiFieldName) {
      applySorting(dummySniperCollection)
    } else {
      setSniperList(dummySniperCollection);
    }
  }, [dummySniperCollection])

  /*------------------------ SETTING the COUNT DATA ENDS-------------------------------------------------------*/

  const UpdateStatus = (offerNumber) => {
    const newSniperList = sniperCollection.map((data)=>{
      var updateRecordValue = {
        ...data,
        status:data.id===offerNumber?"Available":data.status,
        booking_initiated_by:data.id===offerNumber?"0":data.booking_initiated_by
      }
      return updateRecordValue;
    });
    setSniperList(newSniperList);
    setStaticSniperList(newSniperList);
  }
  



  // const ws = new WebSocket('ws://localhost:3000');
  // console.log(ws)


  /*------------------------ PAGINATION FUNCTIONALITY ---------------------------------------------------------*/
  var indexOfLastPost = currentPage * postsPerPage;
  var indexOfFirstPost = indexOfLastPost - postsPerPage;
  var currentPosts = sniperList.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    indexOfLastPost = currentPage * postsPerPage;
    indexOfFirstPost = indexOfLastPost - postsPerPage;
    currentPosts = sniperList.slice(indexOfFirstPost, indexOfLastPost);

  }, [sniperList])

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const toggleSwitch = () => {
    return(
      <div>
        <label className="mt-2 switch ml-3">
          <input type="checkbox" checked={viewBookedLoads} onChange={()=>setViewBookedLoads(!viewBookedLoads)}/>
          <span className="slider"/>
        </label>
      </div>
    )
  }

  useEffect(() => {
    if(viewBookedLoads){      
      if(filterDates.startDate && filterDates.endDate !== 'Invalid date'){
        // SetLoader(true)
      }
      var payload = {
        start_date:moment(filterDates.startDate).format("YYYY/MM/DD"),
        end_date:moment(filterDates.endDate).format("YYYY/MM/DD")
      }
      filterDates.endDate!=='Invalid date' && fetchSnipperBookedLoads(payload).then(result=>{
        if(result.message==='success'){
          var customSniperList = result.data.map((data)=>({
            ...data,
            status: data.booking_initiated_by === "1"?"Booked by TT":data.status==="Booked"?data.sniper_ltrack_status!=="Available"?data.sniper_ltrack_status:data.status:data.status
          }));
          setSniperList(customSniperList);
          setStaticSniperList(customSniperList);
          setBookData(viewBookedLoads);
          SetLoader(false);
        }else{
          setSniperList([]);
          setStaticSniperList([]);
          setBookData(viewBookedLoads);
          SetLoader(false);
        }
      }).catch(err=>{console.log(err)})
    }else{
      setSniperList(sniperListBackUp);
      setStaticSniperList(sniperListBackUp);
      setBookData(viewBookedLoads);
      applyFilter(filterObject)
    }
  }, [viewBookedLoads,filterDates,sniperListBackUp])
  

  const pagination = () => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(sniperList.length / postsPerPage); i++) {
      pageNumbers.push(i)
    };
    return (
      <nav className='d-flex align-items-center'>
        <ul className='pagination'>
          <li className='page-item'>
            <a onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)} className={`page-link ${currentPage > 1 ? '' : 'is-disabled'} ${pageNumbers && pageNumbers !== null ? '' : 'd-none'}`}>{"<<  Previous"}</a>
          </li>
          {pageNumbers.map(number => {
            return <li key={number} className={`page-item`} >
              <a onClick={() => paginate(number)} className={`page-link ${currentPage===number?'pagination-active':''}`}>{number}</a>
            </li>
          })}
          <li className='page-item'>
            <a onClick={() => paginate(currentPage < pageNumbers.length ? currentPage + 1 : currentPage)} className={`page-link ${currentPage < pageNumbers.length ? '' : 'is-disabled'}`}>{'Next  >>'}</a>
          </li>
        </ul>
      </nav>
    )
  }
  /*------------------------ PAGINATION FUNCTIONALITY ENDS -----------------------------------------------------*/


  const changeNextLoadOrder = (isClicked, dataObject) => {
    {
      setNextLoadChanged(isClicked)
      setNextLoadData(dataObject)
      setReload(true)
    }
  }

  const toggleSelectAll = () => {
    let newSelected = {};
    sniperList.map((x) => {
      newSelected[x.id] = selectAll === 0;
    });
    SetSelected(newSelected);
    setSelectAll((prev) => (prev === 0 ? 1 : 0));
  };

  const toggleRow = (id) => {
    const newSelected = Object.assign({}, selected);
    newSelected[id] = !selected[id];
    SetSelected(newSelected);
    setSelectAll((prev) => (prev = 0));
  };

  const getSearchHeader = () => {
    return (
      <tr>
        <th className="checkboxCol">&nbsp;</th>
        {filterObject.map((data, index) => {
          if (!data.isFilterEnabled) {
            return (
              <th
                className={
                  "col-" +
                  data.fieldName +
                  (!data.isFilterEnabled ? " d-none" : "")
                }
              >
                &nbsp;
              </th>
            );
          } else {

            if (data.type === "text")
              return (
                <th className={"col-" + data.fieldName}>
                  <div className="filterSearchItem">
                    <input
                      value={data.value}
                      onChange={(e) => setTextBoxValue(e)}
                      name={data.fieldName}
                      type="text"
                      className="form-control"
                      placeholder={data.placeholder}
                    />
                  </div>
                </th>
              );
            else {
              var startDate = null;
              var endDate = null;
              var selectedTime = null;
              var convertedString = null;
              if (data.value) {
                startDate = data.value.startDate;
                endDate = data.value.endDate;
                selectedTime = data.value.time;
                if (startDate && endDate && selectedTime) {
                  convertedString =
                    moment(startDate).format("MM/DD/YYYY") +
                    " - " +
                    moment(endDate).format("MM/DD/YYYY") +
                    " " +
                    moment(selectedTime).format("HH:mm");
                }
              }
              return (
                <th className={"col-" + data.fieldName}>
                  {/* <div className="filterSearchItem" disabled={true}>
                    <DatePicker
                      name={data.fieldName}
                      onChange={(date) => setDatePickerValue(date, data.fieldName)}
                      selected={data.value ? new Date(data.value) : null}
                      dateFormat='MM-dd-yyyy'
                      className="form-control"
                      disabledKeyboardNavigation={true}
                      onKeyDown={(e) => { e.preventDefault() }}
                      autoComplete='off'
                      isClearable
                      shouldCloseOnSelect={true}
                      placeholderText={data.placeholder}
                      disabled
                    />
                  </div> */}
                </th>
              );
            }
          }
        })}
        <th className="col-stops">&nbsp;</th>
        <th className="col-noofloads">&nbsp;</th>
        <th className="col-bids">&nbsp;</th>
        <th className="col-passes">&nbsp;</th>
        <th className="ellipsisCol">&nbsp;</th>
      </tr>
    );
  };

  const sortingData = (field) => {
    selectRow('')
    if (field === apiFieldName) {
      setDescending(prevState => !prevState)
    } else {
      setDescending(false)
    }
    setApiFieldName(field);
    setSortData(true)
  }


  useEffect(() => {
    if (sortData) {
      applySorting(sniperList);
    }
  }, [sortData])



  const applySorting = (DataList) => {
    const sortingTextFields = ['status', 'division', 'load_type', 'pickLocation', 'destination', 'pickDate', 'deliveryDate']
    const sortingNumberFields = ['id', 'broker_id', 'difficulty', 'stops'];
    const sortingbadgeFields = ['bids', 'passes', 'loads']

    if (!descending) {
      sortingTextFields.map((field) => {
        if (field === apiFieldName) {
          setSortData(false);
          var sortedData = DataList.sort((a, b) => (a[field] !== null ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
          setSniperList(sortedData);
          setStaticSniperList(sortedData);
        }
      });
      sortingNumberFields.map(fields => {
        if (fields === apiFieldName) {
          setSortData(false);
          var sortedData = DataList.sort((a, b) => (a[fields] !== null ? a[fields] : 'zzz') - (b[fields] !== null ? b[fields] : 'zzz'));
          setSniperList(sortedData);
          setStaticSniperList(sortedData);
        }
      });
      sortingbadgeFields.map(fields => {
        if (fields === apiFieldName) {
          setSortData(false);
          var sortedData = DataList.sort((a, b) => (a[fields] !== null ? a[fields].count : 'zzz') - (b[fields] !== null ? b[fields].count : 'zzz'));
          setSniperList(sortedData);
          setStaticSniperList(sortedData);
        }
      });
    } else {
      sortingTextFields.map((field) => {
        if (field === apiFieldName) {
          setSortData(false);
          var sortedData = DataList.sort((b, a) => (a[field] !== null ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
          setSniperList(sortedData);
          setStaticSniperList(sortedData);
        }
      });
      sortingNumberFields.map(fields => {
        if (fields === apiFieldName) {
          setSortData(false);
          var sortedData = DataList.sort((b, a) => (a[fields] !== null ? a[fields] : 'zzz') - (b[fields] !== null ? b[fields] : 'zzz'));
          setSniperList(sortedData);
          setStaticSniperList(sortedData);
        }
      });
      sortingbadgeFields.map(fields => {
        if (fields === apiFieldName) {
          setSortData(false);
          var sortedData = DataList.sort((b, a) => (a[fields] !== null ? a[fields].count : 'zzz') - (b[fields] !== null ? b[fields].count : 'zzz'));
          setSniperList(sortedData);
          setStaticSniperList(sortedData);
        }
      });
    }
  }



  const getHeader = () => {
    return (
      <tr>
        <th className="checkboxCol">
          <input
            type="checkbox"
            checked={selectAll === 1}
            onChange={() => toggleSelectAll()}
          />
        </th>
        {filterObject.map((data, index) => {
          if (data.isFilterOption && !data.isFilterEnabled) {
            return (
              <th scope="col" className="d-none" >
                {data.placeholder}
              </th>
            );
          } else {
            return <th scope="col">{data.placeholder}<div className={`d-inline-block ml-1`}><img src={`${data.apiField === apiFieldName ? descending ? 'images/ascending-arw.png' : 'images/descending-arw.png' : 'images/updown-arrow.png'}`} className={`${data.sortField ? '' : 'd-none'}`} onClick={() => sortingData(data.apiField)} /></div></th>;
          }
        })}
        {badgeFields.map((data) => {
          return data.sortField? <th scope="col" className={`col-stops ${data.sortField?'':'d-none'}`}>{data.placeholder}<div className={`d-inline-block ml-1 `}><img src={`${data.apiField === apiFieldName ? descending ? 'images/ascending-arw.png' : 'images/descending-arw.png' : 'images/updown-arrow.png'}`} className={`${data.sortField ? '' : 'd-none'} ${showBadgeSortFields ? '' : 'd-none'}`} onClick={() => sortingData(data.apiField)} /></div></th>:<th className="ellipsisCol">&nbsp;</th>
        })}
        {/* <th scope="col" className="col-stops">Stops</th>
        <th scope="col" className="col-noofloads">No. of Loads</th>
        <th scope="col" className="col-bids">Bids</th>
        <th scope="col" className="col-passes">Passes</th> */}
        <th className="ellipsisCol">&nbsp;</th>
      </tr>
    );
  };

  const updateBidPass = (id, bidCount, passCount) => {
    var sniperListData = sniperList.map((data) => ({
      ...data,
      bids_count: (data.id === id) ? bidCount : data.bids_count,
      load_passes_count: (data.id === id) ? passCount : data.load_passes_count
    }));
    var sniperCollectionData = sniperCollection.map((data) => ({
      ...data,
      bids_count: (data.id === id) ? bidCount : data.bids_count,
      load_passes_count: (data.id === id) ? passCount : data.load_passes_count
    }));
    setSniperList(sniperListData);
    setStaticSniperList(sniperCollectionData);
  }

  const saveTag = (tag, id) => {
    var sniperListData = sniperList.map((data) => ({
      ...data,
      tags: (data.id === id) ? tag : data.tags
    }));
    var sniperCollectionData = sniperCollection.map((data) => ({
      ...data,
      tags: (data.id === id) ? tag : data.tags
    }));
    setSniperList(sniperListData);
    setStaticSniperList(sniperCollectionData);

    const tagsToSave = []
    tagsList.map((tagName) => {
      tag.map((tagNew) => { tagNew === tagName.tag_name ? tagsToSave.push(tagName.id) : '' })
    })
    const tagsToSaveString = tagsToSave.join(',')
    saveTagsValue(id, tagsToSaveString)
  }

  const saveTagsValue = (orderNo, tags) => {
    saveTags(orderNo, tags)
      .then((result) => {
        if (result.message === 'success') {
          NotificationManager.success("", result.data, 5000);
          setTimeout(() => {
          }, 5000);
        } else {
          NotificationManager.error("", "Tags Not Saved", 5000);
          setTimeout(() => {
          }, 5000)
        }
      }).catch((error) => {
        // console.log(error) 
      })
  }

  // useEffect(()=>{
  //   if(rowId){
  //     setCarrierInfoData(carrierInfo)
  //   }
  // },[MCNo,rowId])

  const setRowId = (data) => {
    selectRow((prev) => (prev === data.id ? "" : data.id))
    setSplitScreenOrderNo(rowId === data.id ? '' : data);
    setNextLoadChanged(false)
  }


  const getBody = (data, index) => {
    return (
      <>
        <tr key={index} className={`${rowId === data.id ? 'selected' : ''} ${data.booking_initiated_by==="1" ?'row-red':data.status !== 'Available' ? data.is_tracking_start === "1"  ?'row-tracking':'row-orange' :''}`}>
          <td className={`checkboxCol ${data.status ? 'load-' + data.load_status : ''}`}>
            <input
              name={"row_" + data.id}
              type="checkbox"
              checked={selected[data.id] === true}
              onChange={() => toggleRow(data.id)}
            />
          </td>
          <td
            onClick={() =>
              setRowId(data)
            }
            className={
              "orderCol" + (!filterObject[0].isFilterEnabled && " d-none")
            }
          >
            {data.id}
          </td>
          <td
            onClick={() =>
              setRowId(data)
            }
            className={
              "statusCol" + (!filterObject[1].isFilterEnabled && " d-none")
            }
          >
            {data.status}
          </td>
          <td
            className={
              "brokerCol" + (!filterObject[2].isFilterEnabled && " d-none")
            }
          >
            <Select
              isDisabled = {viewBookedLoads}
              value={brokersList.filter(m => { return (m.value === data.broker_id) })}
              options={brokersList}
              onChange={(value) => setBrokerValue([data.id], value)}
              isClearable={true}
            />
            {/* <select
              value={data.broker_id ? data.broker_id : ''}
              id='brokerSelect' className="form-control broker_sel"
              onChange={(e) => { setBrokerValue([data.id], e.target.value) }}>
              <option value="" disabled hidden>
                {''}
              </option>
              {brokers &&
                brokers.map((data, index) => {
                  return (
                    <option key={index} value={data.id} >
                      {data.firstname} {data.lastname} 
                    </option>
                  );
                })}
            </select> */}
          </td>
          <td
            className={
              "divisionCol" + (!filterObject[3].isFilterEnabled && " d-none")
            }
            onClick={() =>
              setRowId(data)
            }
          >
            {data.division}
          </td>

          <td
            className={
              "difficultyCol" + (!filterObject[4].isFilterEnabled && " d-none")
            }
          >
            <select
              disabled = {viewBookedLoads}
              className="form-control broker_sel" name="difficulty" id="difficulty"
              value={data.difficulty ? data.difficulty : ''}
              onChange={(e) => setDifficultyValue([data.id], e.target.value)}  // onChange={(e) => MCNo ? setDifficultyValue([data.id], e.target.value) : resetDifficultyValue}
            >
              <option value="" disabled hidden>
                {'Difficulty'}
              </option>
              <option value="1">1 - Easy</option>
              <option value="2">2 - Moderate</option>
              <option value="3">3 - Pain</option>
            </select>
          </td>
          <td
            className={
              "tagsCol" + (!filterObject[5].isFilterEnabled && " d-none")
            }
          >
            <Multiselect
              selectedValues={data.tags}
              options={options}
              onSelect={(values) => saveTag(values, data.id)}
              onRemove={(values) => saveTag(values, data.id)}
              showArrow={true}
              isObject={false} />
          </td>
          <td
            className={
              "loadtypeCol" + (!filterObject[6].isFilterEnabled && " d-none")
            }
          >
            {" "}
            {data.type_of_load}
          </td>
          <td
            className={
              "customerCol" + (!filterObject[7].isFilterEnabled && " d-none")
            }
            onClick={() =>
              setRowId(data)
            }
          >
            {data.customer}
          </td>
          <td
            className={
              "pickDateTimeCol" +
              (!filterObject[8].isFilterEnabled && " d-none")
            }
            onClick={() =>
              setRowId(data)
            }
          >
            {data.pickDate &&
              data.pickTime &&
              moment(data.pickDate).format("MM/DD/YYYY") +
              "  " +
              moment(data.pickTime).format("HH:mm")
            }
            {data.pickEndTime === null ? '' : (moment(data.pickTime).format("MM/DD/YYYY") === moment(data.pickEndTime).format("MM/DD/YYYY") && moment(data.pickTime).format("HH:mm") === moment(data.pickEndTime).format("HH:mm") ? '' : "-" + moment(data.pickEndTime).format("HH:mm"))}
          </td>
          <td
            className={
              "pickUpLocationCol" +
              (!filterObject[9].isFilterEnabled && " d-none")
            }
            onClick={() =>
              setRowId(data)
            }
          >
            {data.pickLocation}{data.pickup_zip ? ', ' + data.pickup_zip : ''}
          </td>
          <td
            className={
              "destinationCol" + (!filterObject[10].isFilterEnabled && " d-none")
            }
            onClick={() =>
              setRowId(data)
            }
          >
            {data.destination}{data.destination_zip ? ', ' + data.destination_zip : ''}
          </td>
          <td
            className={
              "deliveryDateTimeCol" +
              (!filterObject[11].isFilterEnabled && " d-none")
            }
            onClick={() =>
              setRowId(data)
            }
          >
            {data.deliveryDate &&
              data.deliveryTime &&
              moment(data.deliveryTime).format("MM/DD/YYYY") +
              "  " +
              moment(data.deliveryTime).format("HH:mm")
            }
            {data.delvEndTime === null ? '' : (moment(data.deliveryTime).format("MM/DD/YYYY") === moment(data.delvEndTime).format("MM/DD/YYYY") && moment(data.deliveryTime).format("HH:mm") === moment(data.delvEndTime).format("HH:mm") ? '' : "-" + moment(data.delvEndTime).format("HH:mm"))}
          </td>
          <td className="stopsCol">
            <OverlayTrigger
              placement="right"
              trigger={'focus'}
              overlay={(
                <Popover className="timeline" >
                  <Popover.Title as="h3" className="d-flex justify-content-between align-items-center">
                    Stops ({data.stops}) <a><span className="ch-icon ch-close-circle btn-gray"></span></a>
                  </Popover.Title>
                  <Popover.Content>

                    <div class="timeline-container">
                      <PerfectScrollbar>
                        {data.picks && data.picks.map((pickup, index) => {
                          return <div className="timeline-block timeline-block-right">
                            <div className="marker pickup"></div>
                            <div className="timeline-content w-auto px-1">
                              <h3>PickUp {data.picks.length > 1 ? index + 1 : ''}</h3>
                              <div><span>{pickup && pickup.name ? pickup.name : ''}</span></div>
                              <div><span>{pickup && pickup.address1 ? pickup.address1 : ''}</span></div>
                              <div><span>{pickup && pickup.city ? pickup.city : ''}, {pickup && pickup.country ? pickup.country : ''}</span></div>
                              <div><span>{pickup && pickup.zip !== null ? pickup.zip : ''}</span></div>
                            </div>
                              <div className="px-1">
                                  <div className="tme">{moment(pickup.pickStartDate).format('HH:mm')}-{moment(pickup.pickEndDate).format('HH:mm')}</div>
                                  <div className="dte">-{moment(pickup.pickStartDate).format('MMM DD, YYYY')}-</div>
                                  {/* Sep 02, 2022 */}
                                </div>
                          </div>
                        })}
                        {data.deliveries && data.deliveries.map((delivery, index) => {
                          return <div className="timeline-block timeline-block-right">
                            <div className="marker delivery"></div>
                            <div className="timeline-content w-auto px-1">
                              <h3>Delivery {data.deliveries.length > 1 ? index + 1 : ''}</h3>
                              <div><span>{delivery && delivery.name ? delivery.name : ''}</span></div>
                              <div><span>{delivery && delivery.address1 ? delivery.address1 : ''}</span></div>
                              <div><span> {delivery && delivery.city ? delivery.city : ''}, {delivery && delivery.country ? delivery.country : ''}</span></div>
                              <div><span>{delivery && delivery.zip !== null ? delivery.zip : ''}</span></div>
                            </div>
                            <div className="px-1">
                                  <div className="tme">{moment(delivery.delvStartDate).format('HH:mm')}-{moment(delivery.delvEndDate).format('HH:mm')}</div>
                                  <div className="dte">-{moment(delivery.delvEndDate).format('MMM DD, YYYY')}-</div>
                                  
                                </div>
                          </div>
                        })}
                      </PerfectScrollbar>
                    </div>
                  </Popover.Content>
                </Popover>
              )}>
              <button className="btn badge badge-stops border-0" >{data.stops}</button>
            </OverlayTrigger>
          </td>
          <td className="loadsCol" onClick={() =>
            setRowId(data)
          }>
            <span className="badge badge-loads">{data.loads && data.loads.count ? data.loads.count : 0}</span>
          </td>
          <td className="bidsCol" onClick={() =>
            setRowId(data)
          }>
            <span className="badge badge-bids">
              {data.bids && data.bids.count ? data.bids.count : 0}
            </span>
          </td>
          <td className="passesCol" onClick={() =>
            setRowId(data)
          }>
            {/* restrictedAccess && */}
             <span className={`badge badge-pass ${restrictedAccess?'':'d-none'}`}>
              {data.passes && data.passes.count ? data.passes.count : 0}
            </span>
          </td>
          <td className="ellipsisCol">
            <Dropdown>
              <Dropdown.Toggle variant="light btn-circle btn-32">
                <i className="ch-more-icon"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => copyDataToClipboard(data)}>Copy</Dropdown.Item>
                <Dropdown.Item href="#/action2">Post Load</Dropdown.Item>
                <Dropdown.Item onClick={() => window.open(`/viewdetails/${data.load_type}/${data.p_key}`, '_blank')}>View Details</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
        <tr key={"hiddenTr" + index} className="hide-table-padding">
          <td colSpan="18">
            <Collapsible open={rowId === data.id}>
              <React.Fragment>
                {rowId === data.id && childElement(data, index)}
              </React.Fragment>
            </Collapsible>
          </td>
        </tr>
      </>
    );
  };

  const setAllInRateValues = (rates) => {
    setAllInRates(rates);
  }

  const saveRateValues = (rates) => {
    saveBidRates(rates).then((result) => {
      if (result.message === 'success') {
        setRatesReload((prevState) => ({
          ...prevState,
          SplitScreenRatesReload: true
        }));
      } else {
      }
    }).catch(err => {
      //  console.log(err)
    })
  }

  useEffect(() => {
    if(loadBooked.isBooked){
      var updatedSniperList = sniperList.map((data) => ({
        ...data,
        status: (data.id === loadBooked.offerNo) ? 'Booked' : data.status
      }));
      var updatedSniperCollection = sniperListBackUp.map((data) => ({
        ...data,
        status: (data.id === loadBooked.offerNo) ? 'Booked' : data.status
      }));

      // setSniperList(updatedSniperList)
      // setStaticSniperList(updatedSniperCollection)
      setSniperListBackUp(updatedSniperCollection)
      setLoadBooked({
        offerNo:'',
        isBooked:false
      })
    }
  }, [loadBooked])
  

  useEffect(() => {
    if(loadAvailable.isAvailable){
      var updatedSniperList = sniperList.map((data) => ({
        ...data,
        status: (data.id === loadAvailable.offerNo) ? 'Available' : data.status
      }));
      var updatedSniperCollection = sniperListBackUp.map((data) => ({
        ...data,
        status: (data.id === loadAvailable.offerNo) ? 'Available' : data.status
      }));

      // setSniperList(updatedSniperList)
      // setStaticSniperList(updatedSniperCollection)
      setSniperListBackUp(updatedSniperCollection)
      setLoadAvailable({
        offerNo:'',
        isAvailable:false
      })
    }
  }, [loadAvailable])

  // const loadBooked = (offerNo) => {

  // }
  
  const listOfsimilarData = (sniperData) => {
    const listOfData=[]; 
    sniperList.map((data)=>{
      if(data.pickLocation === sniperData.pickLocation && data.destination === sniperData.destination 
        && moment(data.pickDate).format('MM-DD-YYYY')===moment(sniperData.pickDate).format('MM-DD-YYYY') && moment(data.deliveryDate).format('MM-DD-YYYY')===moment(sniperData.deliveryDate).format('MM-DD-YYYY')){
          listOfData.push(data.p_key)
        }
    });
    return listOfData;
  }
  

  const childElement = (data, index) => {
    const customData = isNextLoadChanged ? NextLoadData : data;
    const orderList = listOfsimilarData(customData);
    setLoadStatus(customData.status);
    return (
      <div key={index} id={"dataRow-" + index} className={`dataRow p-3 ${customData.booking_initiated_by==="1"?'collapse-bg-red':''}`}>
        <div className="row">
          <div className="col-lg-12 col-xl-4 mb-3 px-1">
            <TruckInformation setCharges={setCharges} loadType={customData.load_type} sniperId={customData.p_key} carrierName={customData.carrier_name} isNextLoadChanged={isNextLoadChanged} carrierInfo={carrierInfo} />
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-2 mb-3 px-1">
           {restrictedAccess?<AverageCostLane data={customData} isNextLoadChanged={isNextLoadChanged} />:<div></div>}
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3 px-1">
            <AllRate error={error} setError={setError} sendRateValues={saveRateValues} setAllInRateValues={setAllInRateValues} 
            orderNo={customData.id} loadType={customData.load_type} sniperId={customData.p_key} isNextLoadChanged={isNextLoadChanged} 
            ratesReload={ratesReload} setRatesReload={setRatesReload} loadStatus={customData.status} viewBookedLoads={viewBookedLoads}/>
          </div>
          <div className="col-12 col-lg-6 col-xl-1 text-left text-md-right px-1">
            {restrictedAccess && <button className="btn btn-bluegray btn-pill mw-104 m-1" name="viewdetails" onClick={() => window.open(`/viewdetails/${data.load_type}/${data.p_key}`, "_blank")} >
                                      View Details
                                  </button>}
            {orderList && <BidModule MCNo={MCNo} CarrierName={CarrierName} Rates={AllInRates} carrierInfo={carrierInfo} 
                       setError={setError} setReload={setReload} carrierData={customData} sourceType={'sniper'} dispatcherPhone={carrierInfo.carrier_phone}
                       setLoadBooked={setLoadBooked} orderList={orderList} loadStatus={customData.status} viewBookedLoads={viewBookedLoads}
                       />}
          </div>
        </div>
        <div className="row">
          {<NextLoadDestination data={data} orderNo={data.id} city={data.final_city} state={data.final_state} deliveryDate={data.deliveryDate} changeNextLoadOrder={changeNextLoadOrder} isNextLoadChanged={isNextLoadChanged} setNextLoadChanged={setNextLoadChanged} customData={customData} />}
          <div className="col-md-6 col-lg-6 col-xl-6 mb-3">
            <LoadOfferHistory isReload={isReload} orderNo={customData.id} loadType={customData.load_type} sniperId={customData.p_key} setReload={setReload} brokers={brokers} isNextLoadChanged={isNextLoadChanged} updateBidPass={updateBidPass} setLoadAvailable={setLoadAvailable} updateStatus={UpdateStatus}/>
          </div>
        </div>
        <div className="row align-items-end">
          <div className="col-12 col-lg-6 col-xl-6 mb-3 mb-lg-0">
            <div className="row ">
              <div className="col-auto d-flex align-items-center">
                <h2>Carrier Progress</h2>
              </div>
              <div className="col-md-10">
                <ul className="carrierProgress">
                  <li>Waterfall</li>
                  <li className="active">SniperList Eco System</li>
                  <li>Load Boards</li>
                </ul>
              </div>
            </div>
          </div>
          </div>
      </div>
    );
  };

  const applyFilter = (currentFilterObject) => {

    var statusArray = [];
    if (spot) {
      statusArray.push("spot");
    }
    if (go) {
      statusArray.push("go");
    }
    if (hot) {
      statusArray.push("hot");
    }
    let searchData = [];
    const filterValues = currentFilterObject.filter(
      (m) => m.value && m.value.length > 0
    );

    var existingSniperCollection = sniperCollection.filter((m) => statusArray && statusArray.indexOf(m.load_status) > -1);
    if (filterDates) {
      if (filterDates.startDate && (filterDates.endDate && filterDates.endDate !== "Invalid date")) {
        existingSniperCollection = existingSniperCollection.filter(m => m.pickDate && moment(m.pickDate).format("YYYY/MM/DD") >= moment(filterDates.startDate).format("YYYY/MM/DD") && moment(m.pickDate).format("YYYY/MM/DD") <= moment(filterDates.endDate).format("YYYY/MM/DD"));
      } else if (filterDates.startDate && (!filterDates.endDate || (filterDates.endDate && filterDates.endDate === "Invalid date"))) {
        existingSniperCollection = existingSniperCollection.filter(m => m.pickDate && moment(m.pickDate).format("YYYY/MM/DD") == moment(filterDates.startDate).format("YYYY/MM/DD"));
      }
    }


    // if (carrierInfo && carrierInfo.truck_empty_location && MCNo) {
    //   existingSniperCollection = existingSniperCollection.filter(m => m.pickLocation.toString().toLowerCase().indexOf(carrierInfo.truck_empty_location.toLowerCase()) > -1)
    // }

    // if(viewBookedLoads){
    //   existingSniperCollection = existingSniperCollection.filter(m=> m.status.toString().toLowerCase()==="booked")
    // }

    if (filterValues && filterValues.length > 0) {
      existingSniperCollection
        .map((data) => {
          var isFound = true;
          for (let index = 0; index < filterValues.length; index++) {
            const sdata = filterValues[index];
            if (sdata.type === "text" && sdata.fieldName === 'broker' && sdata.value) {
              let brokerObject = brokers.find(m => { return m.id == data[sdata.apiField] });
              if (brokerObject && brokerObject.firstname && brokerObject.lastname) {
                const name = brokerObject.firstname.toString().toLowerCase() + " " + brokerObject.lastname.toString().toLowerCase();
                if (name.indexOf(sdata.value.toLowerCase()) === -1) {
                  isFound = false;
                }
              } else {
                isFound = false;
              }
            } else if (sdata.type === "datetime" && data[sdata.apiField]) {
              var existingString = data[sdata.apiField];
              if (existingString && moment(existingString).format("MM/DD/YYYY") !== sdata.value) {
                isFound = false;
              }
            } else {
              var existingString = data[sdata.apiField];
              if (!existingString) {
                isFound = false;
              } else if (existingString && existingString.toString().toLowerCase().indexOf(sdata.value.toLowerCase()) === -1) {
                if (sdata.fieldName === "difficulty") {
                  if (existingString === 1) {
                    if ("1 - easy".indexOf(sdata.value.toLowerCase()) === -1) {
                      isFound = false;
                    }
                  } else if (existingString === 2) {
                    if ("2 - moderate".indexOf(sdata.value.toLowerCase()) === -1) {
                      isFound = false;
                    }
                  } else if (existingString === 3) {
                    if ("3 - pain".indexOf(sdata.value.toLowerCase()) === -1) {
                      isFound = false;
                    }
                  } else {
                    isFound = false;
                  }
                } else {
                  isFound = false;
                }
              }
            }

            if (index === (filterValues.length - 1)) {
              if (isFound) {
                searchData.push(data);
              }
            }
          }
        });
      setSniperList(searchData);
      setCurrentPage(1);
    } else {
      setSniperList(existingSniperCollection);
    }
  };

  const setTextBoxValue = (e) => {
    if (e.target.name === 'tags') {
      const updatedCollection = filterObject.map((data) => ({
        ...data,
        value: data.fieldName === e.target.name ? e.target.value : data.value,
      }));
      setFilterObject(updatedCollection);
      applyFilter(updatedCollection);
    } else {
      const updatedCollection = filterObject.map((data) => ({
        ...data,
        value: data.fieldName === e.target.name ? e.target.value : data.value,
      }));
      setFilterObject(updatedCollection);
      applyFilter(updatedCollection);
    }
  };

  const setDatePickerValue = (dateValue, fieldName) => {
    const updatedCollection = filterObject.map((data) => ({
      ...data,
      value: (data.fieldName === fieldName) ? dateValue ? moment(dateValue).format("MM/DD/YYYY") : null : data.value
    }));
    setFilterObject(updatedCollection);
    applyFilter(updatedCollection);
  }

  const refreshGrid = () => {
    setReset(true);
    setFilterObject(staticFilterObject);
    // const customRecords = sniperCollection.filter(m => m.pickDate && moment(m.pickDate).format("MM/DD/YYYY") >= moment(filterDates.startDate).format("MM/DD/YYYY") && moment(m.pickDate).format("MM/DD/YYYY") <= moment(filterDates.endDate).format("MM/DD/YYYY"))
    setSniperList(sniperCollection.filter(m => m.pickDate && moment(m.pickDate).format("MM/DD/YYYY") >= moment(filterDates.startDate).format("MM/DD/YYYY") && moment(m.pickDate).format("MM/DD/YYYY") <= moment(filterDates.endDate).format("MM/DD/YYYY")));
    setSelectAll(0);
    SetSelected({});
  };


  const getSelectedRowData = () => {
    return sniperList.filter((m) => selected[m.id]);
  };

  const copyDataToClipboard = (singleRowData) => {
    const selectedCollection = singleRowData ? [singleRowData] : getSelectedRowData();
    let formatedString = "";
    selectedCollection.map((m) => {
      formatedString = formatedString.concat(
        `
  Order No :  ${m.id ? m.id : 'NA'}
  Pick Up Time : ${m.pickDate ? moment(m.pickDate).format('MM-DD-YYYY HH:mm') : 'NA'} ${m.pickEndTime === null ? '' : (moment(m.pickTime).format("MM/DD/YYYY") === moment(m.pickEndTime).format("MM/DD/YYYY") && moment(m.pickTime).format("HH:mm") === moment(m.pickEndTime).format("HH:mm") ? '' : "- " + moment(m.pickEndTime).format("HH:mm"))}
  Pick Up Location : ${m.pickLocation ? m.pickLocation + ', ' : 'NA'}${m.pickup_zip ? m.pickup_zip : ''}
  Destination : ${m.destination ? m.destination + ', ' : 'NA'}${m.destination_zip ? m.destination_zip : ''}
  Delivery : ${m.deliveryDate ? moment(m.deliveryDate).format('MM-DD-YYYY HH:mm') : 'NA'} ${m.delvEndTime === null ? '' : (moment(m.deliveryTime).format("MM/DD/YYYY") === moment(m.delvEndTime).format("MM/DD/YYYY") && moment(m.deliveryTime).format("HH:mm") === moment(m.delvEndTime).format("HH:mm") ? '' : "- " + moment(m.delvEndTime).format("HH:mm"))}
  Commodity : ${m.commodity ? m.commodity : 'NA'}
  Weight : ${m.weight ? m.weight : 'NA'}
  Miles : ${m.miles ? m.miles : 'NA'}
  Book It Now Price : ${m.target_rate > 0 ? m.target_rate : 'NA'}
  Equipment Required : ${m.equiptypecode ? m.equiptypecode : 'NA'} - ${m.equiptype ? m.equiptype : 'NA'}
  \n\n       
  `
      );
    });
    if (navigator.clipboard) navigator.clipboard.writeText(formatedString);
    else {
      const dummyElement = document.createElement("span");
      dummyElement.style.whiteSpace = "pre";
      dummyElement.textContent = formatedString;
      document.body.appendChild(dummyElement);
      const selection = window.getSelection();
      selection.removeAllRanges();
      const range = document.createRange();
      range.selectNode(dummyElement);
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();
      document.body.removeChild(dummyElement);
    }
    NotificationManager.success("Copied text to clipboard", "", 5000);
  };

  const showAllFilter = () => {
    const updatedfilterObject = filterObject.map((data) => ({
      ...data,
      isFilterEnabled: data.isFilterOption && true,
    }));
    setFilterObject(updatedfilterObject);
  };

  const toggleFilter = (e) => {
    const updatedfilterObject = filterObject.map((data) => ({
      ...data,
      isFilterEnabled:
        data.fieldName === e.target.name
          ? e.target.checked
          : data.isFilterEnabled,
    }));
    setFilterObject(updatedfilterObject);
  };

  /*--------------------------- Broker --------------------------------------------------------------------*/
  const setBrokerValue = (order_no, data) => {
    var broker_id = typeof (data) === 'object' && data !== null ? data.value : data
    if (data === null) {
      const payLoad = {
        order_nbr: order_no
      }
      deleteBroker(payLoad).then((result) => {
        if (result.message === 'success') {
          var sniperListData = sniperList.map((data) => ({
            ...data,
            broker_id: (order_no.indexOf(data.id) > -1) ? '' : data.broker_id
          }));
          var sniperCollectionData = sniperCollection.map((data) => ({
            ...data,
            broker_id: (order_no.indexOf(data.id) > -1) ? '' : data.broker_id
          }));
          setSniperList(sniperListData);
          setStaticSniperList(sniperCollectionData);
          NotificationManager.success(result.data, 'Success', 5000)
        }
      })
    } else {
      setBrokers(order_no, broker_id).then((result) => {
        if (result.message === "success") {
          if (result.data.broker_assigned.length > 0) {
            var sniperListData = sniperList.map((data) => ({
              ...data,
              broker_id: (order_no.indexOf(data.id) > -1) ? broker_id : data.broker_id
            }));
            var sniperCollectionData = sniperCollection.map((data) => ({
              ...data,
              broker_id: (order_no.indexOf(data.id) > -1) ? broker_id : data.broker_id
            }));
            setSniperList(sniperListData);
            setStaticSniperList(sniperCollectionData);
            NotificationManager.success(result.data.broker_assigned.join(', '), "Broker Assigned Successfully (" + result.data.broker_assigned.length + ')', 5030);
          }
          if (result.data.broker_not_assigned.length > 0) {
            NotificationManager.error(result.data.broker_not_assigned.join(', '), "Broker Not Assigned (" + result.data.broker_not_assigned.length + ')', 5000);
          }
          setTimeout(() => {
            SetBroker('');
          }, 5000);

        } else {
          NotificationManager.error("Broker Not Saved", order_no, 5000);
          setTimeout(() => {
            SetBroker('');
          }, 5000);
        };
      })
    }

  }

  useEffect(() => {
    if (broker) {
      const selectedCollection = getSelectedRowData();
      var offer_no = [];
      selectedCollection.map((data) => {
        offer_no.push(data.id)
      })
      setBrokerValue(offer_no, broker)
    }
  }, [broker]);

  const resetBrokerValue = () => {
    NotificationManager.error('', 'MC Number is required', 2500)
    const brokerValue = document.getElementById('brokerSelect');
    setTimeout(() => { brokerValue.value = '' }, 1000)
  }

  /*----------------------------------------------------------------------------------------------------------*/
  /*-------------------------- Difficulty --------------------------------------------------------------------*/

  const setDifficultyValue = (order_no, difficulty) => {
    saveDifficultyValue(order_no, difficulty).then((result) => {
      if (result.message === "success") {

        if (result.data.difficulty_store.length > 0) {
          var sniperListData = sniperList.map((data) => ({
            ...data,
            difficulty: (order_no.indexOf(data.id) > -1) ? difficulty : data.difficulty
          }));
          var sniperCollectionData = sniperCollection.map((data) => ({
            ...data,
            difficulty: (order_no.indexOf(data.id) > -1) ? difficulty : data.difficulty
          }));
          setSniperList(sniperListData);
          setStaticSniperList(sniperCollectionData);
          NotificationManager.success(result.data.difficulty_store.join(', '), "Difficulty Assigned Successfully (" + result.data.difficulty_store.length + ')', 5030);
        }
        if (result.data.difficulty_not_store.length > 0) {
          NotificationManager.error(result.data.difficulty_not_store.join(', '), "Difficulty Not Assigned (" + result.data.difficulty_not_store.length + ')', 5000);
          setTimeout(() => {
            SetDifficulty('');
          }, 5000);
        }
      } else {
        NotificationManager.error("Difficulty Not Saved", order_no, 5000);
        setTimeout(() => {
          SetDifficulty('');
        }, 5000);
      }
    })
  }
  useEffect(() => {
    if (difficulty) {
      const selectedCollection = getSelectedRowData();
      var offer_no = [];
      selectedCollection.map((data) => {
        offer_no.push(data.id)
      })
      setDifficultyValue(offer_no, difficulty)
      setTimeout(() => {
        SetDifficulty('');
      }, 5000);
    }
  }, [difficulty]);

  const resetDifficultyValue = () => {
    NotificationManager.error('', 'MC Number is required', 2500)
    const difficultyValue = document.getElementById('difficulty');
    setTimeout(() => { difficultyValue.value = '' }, 1000)
  }




  /*----------------------------------------------------------------------------------------------------------*/
  /*-------------------------- Send Mail ---------------------------------------------------------------------*/
  const SendMail = () => {
    const selectedCollection = getSelectedRowData();
    const newCollection = selectedCollection[0];
    if(!MCNo){
      NotificationManager.error('Please Enter a valid MCNo','Error',3500);
    }else{
    const payload = {
      email: carrierInfo.dispatcher_email,
      orders: [{
        "orderNo": newCollection.id,
        "MCNo": MCNo,
        "pickupLocation": newCollection.pickLocation,
        "destination": newCollection.destination,
      }]
    }
    if(carrierInfo.dispatcher_email===''){
      NotificationManager.error('Please Enter a valid email address','Error',3500);
    }else{
      sendMail(payload).then((result) => {
        if (result.code === 200) {
          NotificationManager.success('Mail has been Sent', 'SUCCESS', 3000)
        }
      });
    }
  }
  }

  /*----------------------------------------------------------------------------------------------------------*/

  const ModalPopUp = () => {
    SetModal(!isModalShow)
  }

  const modalClose = () => {
    SetModal(false)
    setTargetMaxValue({
      targetRate: '',
      maxBuy: ''
    })
  }
  const setTargetandMaxRate = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setTargetMaxValue(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const savetargetMaxRate = () => {
    var selectedData = []
    selectedData = getSelectedRowData()
    var offerNotoSend = [];
    var loadIdtoSend = [];
    var loadTypetoSend = []
    selectedData.map((data) => {
      offerNotoSend.push(data.id)
      loadIdtoSend.push(data.p_key)
      loadTypetoSend.push(data.load_type)
    })

    var payLoad = {
      offer_no: offerNotoSend.join(','),
      load_id: loadIdtoSend.join(','),
      load_type: loadTypetoSend.join(','),
      target_rate: TargetMaxValue.targetRate,
      max_by: TargetMaxValue.maxBuy
    }

    saveTargetMaxRateValues(payLoad).then((result) => {
      if (result.message === 'success') {
        NotificationManager.success('', 'Rates Saved', 3500)
        modalClose()
      } else {
        NotificationManager.error('', result.description, 3500)
        selectedData = []
      }
    }).catch(error => {
      // console.log(error)
    })

  }

  return (
    <div className="outLineBox rounded p-3">
      <PreloaderInner hidden={!isLoader} />
      <div className="row">
        <div className="filterItemsBtnWrapper col-xl-3">
          <div className="filterSearchItemWrapper mb-3">
            <div className="filterSearchItem">
              <Dropdown className="columnFilterMenu">
                <Dropdown.Toggle variant="primary mr-2">
                  <i className="ch ch-filter-icon"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-3">
                  {filterObject &&
                    filterObject.map((data, index) => {
                      return (
                        data.isFilterOption && (
                          <div
                            className="custom-control custom-checkbox mb-1"
                            key={index}
                          >
                            <input
                              checked={data.isFilterEnabled}
                              onChange={(e) => toggleFilter(e)}
                              type="checkbox"
                              className="custom-control-input"
                              name={data.fieldName}
                              id={data.fieldName}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={data.fieldName}
                            >
                              {data.placeholder}
                            </label>
                          </div>
                        )
                      );
                    })}
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary mr-1"
                      type="button"
                      onClick={() => showAllFilter()}
                    >
                      Show ALL
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>

              <button
                className="btn btn-primary mr-2"
                type="button"
                onClick={() => refreshGrid()}
              >
                <i className="ch ch-refresh-icon"></i>
              </button>
              <p className="mb-0 h6">Found {sniperList.length} Results</p>
              
          <div>{toggleSwitch()}</div>
            </div>
          </div>
        </div>       

        <div className="whenChekedShowBtnWrapper col-xl-9">
          <div className="d-flex justify-contnet-center flex-wrap justify-content-lg-end">
            <button
              className={
                `btn btn-primary mr-2 mb-3` +
                (JSON.stringify(selected).indexOf(true) === -1 ? " d-none" : "")
              }
              onClick={() => copyDataToClipboard()}
            >
              Copy
            </button>

            <DropdownButton
              title={difficulty ? difficulty : 'Difficulty'}
              id="dropdownbtn-difficulty"
              onSelect={SetDifficulty}
              className={
                `btn-pill mr-2 mb-3 ` +
                (JSON.stringify(selected).indexOf(true) === -1 ? " d-none" : "")
              }
            >
              <Dropdown.Item eventKey="1">1 - Easy</Dropdown.Item>
              <Dropdown.Item eventKey="2">2 - Moderate</Dropdown.Item>
              <Dropdown.Item eventKey="3">3 - Pain</Dropdown.Item>
            </DropdownButton>

            <DropdownButton
              title={broker ? broker : 'Broker Assignment'}
              id="dropdownbtn-BrokerAssignment"
              onSelect={SetBroker}
              className={
                `btn-pill mr-2 mb-3` +
                (JSON.stringify(selected).indexOf(true) === -1 ? " d-none" : "")
              }
            >
              {brokers &&
                brokers.map((data) => (
                  <Dropdown.Item eventKey={data.id}>{" "}{data.id} - {data.firstname} {data.lastname}{" "}</Dropdown.Item>
                )
                )}
            </DropdownButton>

            <button onClick={() => ModalPopUp()}
              className={
                `btn btn-primary mr-2 mb-3` +
                (JSON.stringify(selected).indexOf(true) === -1 ? " d-none" : "")
              }
            >
              Target And Max Assignment
            </button>
            <button
              onClick={() => SendMail()}
              className={
                `btn btn-primary mr-2 mb-3` +
                (JSON.stringify(selected).indexOf(true) === -1 ? " d-none" : "")
              }
            >
              Send Email
            </button>
            <button
              className={
                `btn btn-light btn-circle btn-32` +
                (JSON.stringify(selected).indexOf(true) === -1 ? " d-none" : "")
              }
            >
              <i className="ch ch-close"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <PerfectScrollbar>
          <table className="table truck-table table-hover">
            <thead>{getSearchHeader()} </thead>
            <tbody>
              {getHeader()}
              {sniperList && sniperList.length === 0 ? (
                <tr className="text-center text-danger">
                  <td colSpan="18"><div className='extrapadding'>No Record Found</div></td>
                </tr>
              ) : (
                currentPosts.map((data, index) => getBody(data, index))
              )}
            </tbody>
          </table>
          <div className='container'>{pagination()}</div>
        </PerfectScrollbar>
      </div>
      
      <Modal className="targetAndMaxAssignmentModal" show={isModalShow} onHide={() => modalClose()} >
        <Modal.Header closeButton>
          <Modal.Title>Target and Max assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">$</span>
              </div>
              <input type="text" class="form-control" name='targetRate' value={TargetMaxValue.targetRate} placeholder="Enter Target Rate" aria-label="target" aria-describedby="target" onChange={(e) => setTargetandMaxRate(e)} />
            </div>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">$</span>
              </div>
              <input type="text" class="form-control" name='maxBuy' value={TargetMaxValue.maxBuy} placeholder="Enter Max Buy Value" aria-label="Max" aria-describedby="Max" onChange={(e) => setTargetandMaxRate(e)} />
            </div>
          </form>

        </Modal.Body>
        <Modal.Footer>
          <button type="button" class="btn btn-success" onClick={() => { savetargetMaxRate() }}  >SAVE</button>
          <button type="button" class="btn btn-danger" onClick={() => modalClose()}>Cancel</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setTruckEmptyLocation,
  resetCloned
};

export default connect(null, mapDispatchToProps)(SniperList);
