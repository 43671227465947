import React, { Component } from "react";
import moment from "moment";
import pickUp from "./../../../../images/pickup.png";
import start from "./../../../../images/start.png";
import deliveryIcon from "./../../../../images/delivery.png";
import { Scrollbars } from "react-custom-scrollbars";

export default class RouteDetails extends Component {
	renderPickUpDetatils = (picks) =>
		picks.map((pick, index) => (
			<div key={pick.id} className="waypoints d-flex">
				<div className="point-icon">
					<img src={pickUp} alt="pickup" />
					{/* {index === 0 ? <img src={pickUp} alt="pickup" /> : <div className="waypoint-stop" />} */}
					<div className="side-line2" />
				</div>
				<div className="point-details flex-fill">
					<label className="m-0">Pick Up {picks.length > 1 ? index + 1 : ""}</label>
					{/* <label className="m-0">{index === 0 ? "Pick Up" : `Stop - ${index}`}</label> */}
					<div>{pick.address1}</div>
					<div>
						{pick.city}, {pick.state}, {pick.country}
					</div>
				</div>
				<div className="point-time">
					{(pick.pickStartTime || pick.pickEndTime) && (
						<label>
							{pick.pickStartTime ? moment(pick.pickStartTime).format("HH:mm") : ""}
							{pick.pickStartTime && pick.pickEndTime ? "-" : ""}
							{pick.pickEndTime ? moment(pick.pickEndTime).format("HH:mm") : ""}
						</label>
					)}
					<div className="date">
						{pick.pickStartDate ? `-${moment(pick.pickStartDate).format("MMM DD, YYYY")}-` : ""}
					</div>
				</div>
			</div>
		));

	renderDeliveryDetails = (deliveries) =>
		deliveries.map((delivery, index) => (
			<div key={delivery.id} className="waypoints d-flex">
				<div className="point-icon">
					<img src={deliveryIcon} alt="delivery" />
					<div className="side-line2" />
				</div>
				<div className="point-details flex-fill">
					<label className="m-0">Delivery {deliveries.length > 1 ? index + 1 : ""}</label>

					<React.Fragment>
						<div>{delivery.address1}</div>
						<div>
							{delivery.city}, {delivery.state},{delivery.country}
						</div>
					</React.Fragment>
				</div>
				<div className="point-time">
					{(delivery.delvStartTime || delivery.delvEndTime) && (
						<label>
							{delivery.delvStartTime ? moment(delivery.delvStartTime).format("HH:mm") : ""}
							{delivery.delvStartTime && delivery.delvEndTime ? "-" : ""}
							{delivery.delvEndTime ? moment(delivery.delvEndTime).format("HH:mm") : ""}
						</label>
					)}
					<div className="date">
						{delivery.delvStartDate ? `-${moment(delivery.delvStartDate).format("MMM DD, YYYY")}-` : ""}
					</div>
				</div>
			</div>
		));

	renderDriverDetails = (load) => {
		const bookedData = load.data.bid_pass_data.bids.find((bid) => bid.status === "booked");
		return (
			<div className="driver-empty d-flex">
				<div className="start-icon">
					<img src={start} alt="start" />
					<div className="side-line2" />
				</div>
				<div className="driver-details flex-fill">
					<label className="m-0">Start Driver Empty</label>
					{bookedData && <div>{bookedData.location}</div>}
				</div>
				{bookedData &&
					bookedData.empty_time && (
						<div className="point-time">
							{bookedData && <label>{moment(bookedData.empty_time).format("HH:mm")}</label>}
						</div>
					)}
			</div>
		);
	};

	render() {
		const { load } = this.props;
		return (
			<div className="route-details">
				<div className="title">
					Stops <span className="count"> {load && load.data && load.data.stops}</span>
				</div>
				<div className="stop-details">
					<Scrollbars>
						<div className="stop-scroll">
							<div className="side-line1" />
							{load && load.data && load.data.status === "booked" && this.renderDriverDetails(load)}
							{load &&
								load.data && (
									<React.Fragment>
										{load.data.picks &&
											load.data.picks.length > 0 &&
											this.renderPickUpDetatils(load.data.picks)}
										{load.data.deliveries &&
											load.data.deliveries.length > 0 &&
											this.renderDeliveryDetails(load.data.deliveries)}
									</React.Fragment>
								)}
						</div>
					</Scrollbars>
				</div>
			</div>
		);
	}
}
