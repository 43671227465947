import React, { Component } from "react";
import { updateActivePage } from "./../../../Redux/Action/Auth";
import { connect } from "react-redux";
import "./../../../Styles/Dashboard.scss";
import searchbtn from "./../../../images/search-btn.svg";
import { Tabs, Tab } from "react-bootstrap";

class Dashboard extends Component {
  componentDidMount() {
    this.props.updateActivePage("activePage", "");
  }
  render() {
    return (
      <div className="my-2 dashboard-wrapper">
        <div className="dashboard-trucktabs mb-2">
          <button type="button" className="btn active">
            Truck 1 <span className="ch ch-close-circle ml-1"></span>
          </button>
          <button type="button" className="btn ">
            Truck 2 <span className="ch ch-close-circle ml-1"></span>
          </button>
          <button type="button" className="btn">
            Truck 3 <span className="ch ch-close-circle ml-1"></span>
          </button>
        </div>
        <div className="outLineBox rounded p-3 mb-3">
          <div className="row dbox-header mb-3">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-12 col-xl-4">
                  <div className="row h-100">
                    <div className="col-md-3 col-lg-3">
                      <div className="custom-control custom-radio sphogo spot-radio mb-1">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio1"
                        >
                          Spot
                        </label>
                      </div>
                      <div className="custom-control custom-radio sphogo go-radio mb-1">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio2"
                        >
                          Go
                        </label>
                      </div>
                      <div className="custom-control custom-radio sphogo hot-radio mb-1">
                        <input
                          type="radio"
                          id="customRadio3"
                          name="customRadio"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio3"
                        >
                          Hot
                        </label>
                      </div>
                    </div>
                    <div className="col-md-9 col-lg-9">
                      <form className="form-inline formInlineCarrier my-2 my-lg-0 h-100">
                        <input
                          className="form-control carrier mr-sm-2"
                          type="search"
                          placeholder="Enter MC No/Carrier Name"
                          aria-label="Search"
                        />
                        <button className="btn my-2 my-sm-0 p-0" type="submit">
                          <img src={searchbtn} alt="search" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 col-xl-7">
                  <div className="truck-Slots justify-content-lg-start justify-content-xl-between">
                    <div className="truck-SlotItem">
                      <dl>
                        <dt>Contact Name</dt>
                        <dd>Tim Paul</dd>
                      </dl>
                    </div>
                    <div className="truck-SlotItem">
                      <dl>
                        <dt>Home City, State</dt>
                        <dd>Nashville, TN</dd>
                      </dl>
                    </div>
                    <div className="truck-SlotItem">
                      <dl>
                        <dt>Email</dt>
                        <dd>test@best.com</dd>
                      </dl>
                    </div>
                    <div className="truck-SlotItem">
                      <dl>
                        <dt>Phone Number</dt>
                        <dd>123-123-4567</dd>
                      </dl>
                    </div>
                    <div className="truck-SlotItem">
                      <dl>
                        <dt>Truck Type</dt>
                        <dd>Van</dd>
                      </dl>
                    </div>
                    <div className="truck-SlotItem">
                      <dl>
                        <dt>Available Date</dt>
                        <dd>01-18-2021</dd>
                      </dl>
                    </div>
                    <div className="truck-SlotItem">
                      <dl>
                        <dt>Truck Empty Location</dt>
                        <dd>San Antanio</dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 col-lg-1 d-flex align-items-center justify-content-md-end">
                  <button
                    className="btn btn-circle btn-light btn-32"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSection1"
                  >
                    <i className="ch ch-arrow_drop_down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr></hr>
          <div className="row dbox-content collapsed" id="collapseSection1">
            <div className="col-lg-12 col-xl-5 mb-3 mb-xl-0">
              <div className="outLineBox rounded p-3 h-100">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="chb_CA"
                      />
                      <label className="custom-control-label" htmlFor="chb_CA">
                        Carrier Approved
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="chb_W9"
                      />
                      <label className="custom-control-label" htmlFor="chb_W9">
                        W9
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="cchb_Insurance"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="chb_Insurance"
                      >
                        Insurance
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="chb_Authority"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="chb_Authority"
                      >
                        Authority
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="chb_CC"
                      />
                      <label className="custom-control-label" htmlFor="chb_CC">
                        Carrier Contract
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <form>
                      <div className="form-group">
                        <label htmlFor="cSafRat">Carrier Safety Rating</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cSafRat"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="carInsu">Carrier Insurance</label>
                        <input
                          type="text"
                          className="form-control"
                          id="carInsu"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-4 col-xl-4 h-100">
                    <div className="d-flex flex-column">
                      <button className="btn btn-big btn-primary mb-3">
                        Carrier Freight Guards
                      </button>
                      <button className="btn btn-big btn-info">
                        Start/Stop Monitoring
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-xl-7">
              <div className="outLineBox rounded p-3 h-100" id="trucKTabs">
                <Tabs defaultActiveKey="carLoadHist" id="trucKTabs">
                  <Tab eventKey="carLoadHist" title="Carrier Load History">
                    <div>
                      <table className="table truck-table mb-0">
                        <thead>
                          <tr>
                            <th>Order</th>
                            <th>Broker</th>
                            <th>Pickup Date/Time</th>
                            <th>Pickup Location</th>
                            <th>Destination</th>
                            <th>Revenue</th>
                            <th>Truck Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>22030</td>
                            <td>William M</td>
                            <td>02/04/2021 9:00</td>
                            <td>Nashville, TN</td>
                            <td>Richmond, VA</td>
                            <td>$2500</td>
                            <td>$1500</td>
                          </tr>
                          <tr>
                            <td>23456</td>
                            <td>Lucas P</td>
                            <td>02/06/2021 12:00</td>
                            <td>Jackson, MS</td>
                            <td>Oklahoma, OK</td>
                            <td>$3600</td>
                            <td>$2000</td>
                          </tr>
                          <tr>
                            <td>35678</td>
                            <td>Henry W</td>
                            <td>02/05/2021 10:00</td>
                            <td>Austin, TX</td>
                            <td>Nashville, TN</td>
                            <td>$4100</td>
                            <td>$1000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Tab>
                  <Tab eventKey="truckPosted" title="Truck Posted">
                    dsuyyutyuutyy
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div className="outLineBox rounded p-3">
          <div className="filterItemBtnWrapper d-block ">
            <div className="filterSearchItemWrapper mb-3">
              <div className="filterSearchItem">
                <button className="btn btn-primary mr-2">
                  <i className="ch ch-filter-icon"></i>
                </button>
                <button className="btn btn-primary">
                  <i className="ch ch-refresh-icon"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="whenChekedShow-btnWrapper d-none">
            <div className="d-flex justify-contnet-center flex-wrap justify-content-lg-end">
              <button className="btn btn-primary btn-pill mr-2 mb-3">
                Difficulty
              </button>
              <button className="btn btn-primary btn-pill mr-2 mb-3">
                Broker Assignment
              </button>
              <button className="btn btn-primary btn-pill mr-2 mb-3">
                Target And Max Assignment
              </button>
              <button className="btn btn-primary btn-pill mr-2 mb-3">
                Send Email
              </button>
              <button className="btn btn-light btn-circle btn-32">
                <i className="ch ch-close"></i>
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table truck-table">
              <thead>
                <tr>
                  <th className="checkboxCol">&nbsp;</th>
                  <th className="col-order">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Order No"
                      ></input>
                    </div>
                  </th>
                  <th className="col-broker">&nbsp;</th>
                  <th className="col-status">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Status"
                      />
                    </div>
                  </th>
                  <th className="col-division">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Division"
                      ></input>
                    </div>
                  </th>
                  <th className="col-difficulty">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Difficulty"
                      />
                    </div>
                  </th>
                  <th className="col-tags">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Tags"
                      />
                    </div>
                  </th>
                  <th className="col-loadtype">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Load Type"
                      />
                    </div>
                  </th>
                  <th className="col-customer">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Customer"
                      />
                    </div>
                  </th>
                  <th className="col-pickdatetime">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Pick Up Date/Time"
                      />
                    </div>
                  </th>
                  <th className="col-pickuplocation">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Pick Up Location"
                      />
                    </div>
                  </th>
                  <th className="col-pickupdestination">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Destination"
                      />
                    </div>
                  </th>
                  <th className="col-deliverydatetime">
                    <div className="filterSearchItem">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Delivery Date/Time"
                      />
                    </div>
                  </th>
                  <th className="col-stops">&nbsp;</th>
                  <th className="col-noofloads">&nbsp;</th>
                  <th className="col-bids">&nbsp;</th>
                  <th className="col-passes">&nbsp;</th>
                  <th className="ellipsisCol">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="checkboxCol">
                    <input type="checkbox" id="" />
                  </th>
                  <th scope="col">Order#</th>
                  <th scope="col">Broker</th>
                  <th scope="col">Status</th>
                  <th scope="col">Division</th>
                  <th scope="col">Difficulty</th>
                  <th scope="col">Tags</th>
                  <th scope="col">Load Type</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Pick Date/Time</th>
                  <th scope="col">Pick Up Location</th>
                  <th scope="col">Destination</th>
                  <th scope="col">Delivery Date/Time</th>
                  <th scope="col">Stops</th>
                  <th scope="col">No. of Loads</th>
                  <th scope="col">Bids</th>
                  <th scope="col">Passes</th>
                  <th className="ellipsisCol"></th>
                </tr>
                <tr>
                  <td className="checkboxCol load-blocked">
                    <input type="checkbox" id="exampleCheck1" />
                  </td>
                  <td className="orderCol">44556</td>
                  <td className="brokerCol">
                    <select
                      defaultValue="1"
                      className="form-control broker_sel"
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </td>
                  <td className="statusCol">
                    <span className="text-danger">Blocked</span>
                  </td>
                  <td className="divisionCol">South Team</td>
                  <td className="difficultyCol">3</td>
                  <td className="tagsCol">
                    <div className="tagsWrapper">
                      <span className="chip">
                        Weather <i className="ch ch-close-circle ml-1"></i>
                      </span>
                      <span className="chip">
                        Hot <i className="ch ch-close-circle ml-1"></i>
                      </span>
                    </div>
                  </td>
                  <td className="loadtypeCol">JESS-TL</td>
                  <td className="customerCol">Coca-Cola Bottling Inc</td>
                  <td className="pickDateTimeCol">01/30/2021 - 8:30</td>
                  <td className="pickUpLocationCol">Montery,TN</td>
                  <td className="destinationCol">San Antanio,TX</td>
                  <td className="deliveryDateTimeCol">02/21/2021 5:00</td>
                  <td className="stopsCol">
                    <span className="badge badge-stops">2</span>
                  </td>
                  <td className="loadsCol">
                    <span className="badge badge-loads">2</span>
                  </td>
                  <td className="bidsCol">
                    <span className="badge badge-bids">2</span>
                  </td>
                  <td className="passesCol">
                    <span className="badge badge-pass">2</span>
                  </td>
                  <td className="ellipsisCol">
                    <button className="btn btn-light btn-more">
                      <i className="ch-more-icon"></i>
                    </button>{" "}
                  </td>
                </tr>
                <tr className="hide-table-padding">
                  <td colspan="18">
                    <div id="dataRow-1" className="dataRow collapsed p-3">
                      <div className="row">
                        <div className="col-lg-12 col-xl-6 mb-3">
                          <div className="outLineBox rounded p-2 h-100">
                            <div className="row">
                              <div className="col">
                                <h2>Next Load at Destination (20)</h2>
                                <div className="table-responsive">
                                  <table className="table truck-table">
                                    <thead>
                                      <tr>
                                        <th className="checkboxCol">
                                          <input type="checkbox" id="" />
                                        </th>
                                        <th>Order</th>
                                        <th>Customer</th>
                                        <th>Pick Up Location</th>
                                        <th>Pick Up Date/Time</th>
                                        <th>Destination</th>
                                        <th>Delivery Date/Time</th>
                                        <th>Truck Type</th>
                                        <th>Weight</th>
                                        <th>Load</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="checkboxCol">
                                          <input type="checkbox" id="" />
                                        </td>
                                        <td>50001</td>
                                        <td>Brad Louis</td>
                                        <td>San Antanio, TX</td>
                                        <td>01/05/2021 - 05:20</td>
                                        <td>Dallas, TX</td>
                                        <td>01/12/2021 - 18:00</td>
                                        <td>Semi-Trailer</td>
                                        <td>2000</td>
                                        <td>Willa-TL</td>
                                      </tr>
                                      <tr>
                                        <td className="checkboxCol">
                                          <input type="checkbox" id="" />
                                        </td>
                                        <td>50001</td>
                                        <td>Brad Louis</td>
                                        <td>San Antanio, TX</td>
                                        <td>01/05/2021 - 05:20</td>
                                        <td>Dallas, TX</td>
                                        <td>01/12/2021 - 18:00</td>
                                        <td>Semi-Trailer</td>
                                        <td>2000</td>
                                        <td>Willa-TL</td>
                                      </tr>
                                      <tr>
                                        <td className="checkboxCol">
                                          <input type="checkbox" id="" />
                                        </td>
                                        <td>50001</td>
                                        <td>Brad Louis</td>
                                        <td>San Antanio, TX</td>
                                        <td>01/05/2021 - 05:20</td>
                                        <td>Dallas, TX</td>
                                        <td>01/12/2021 - 18:00</td>
                                        <td>Semi-Trailer</td>
                                        <td>2000</td>
                                        <td>Willa-TL</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-6 mb-3">
                          <div className="outLineBox rounded p-2 h-100">
                            <div className="row">
                              <div className="col">
                                <h2>Load Offer History (10)</h2>
                                <div className="table-responsive">
                                  <table className="table truck-table">
                                    <thead>
                                      <tr>
                                        <th>Type</th>
                                        <th>Reason</th>
                                        <th>Created Date/Time</th>
                                        <th>Broker Name</th>
                                        <th>Carrier Name</th>
                                        <th>Carrier Phone</th>
                                        <th>Dispatcher Name</th>
                                        <th>Dispatcher Phone</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Pass</td>
                                        <td>Weight</td>
                                        <td>01/05/2021 - 05:20</td>
                                        <td>Brad Louis</td>
                                        <td>Saint August</td>
                                        <td>123-456-7890</td>
                                        <td>Jay Mark</td>
                                        <td>123-456-7890</td>
                                      </tr>
                                      <tr>
                                        <td>Pass</td>
                                        <td>Weight</td>
                                        <td>01/05/2021 - 05:20</td>
                                        <td>Brad Louis</td>
                                        <td>Saint August</td>
                                        <td>123-456-7890</td>
                                        <td>Jay Mark</td>
                                        <td>123-456-7890</td>
                                      </tr>
                                      <tr>
                                        <td>Pass</td>
                                        <td>Weight</td>
                                        <td>01/05/2021 - 05:20</td>
                                        <td>Brad Louis</td>
                                        <td>Saint August</td>
                                        <td>123-456-7890</td>
                                        <td>Jay Mark</td>
                                        <td>123-456-7890</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-xl-5 mb-3">
                          <div className="truckInfoWrapper h-100">
                            <div className="truckInfoItem">
                              <span className="">
                                <i className="ch ch-truck-icon"></i>
                              </span>
                              <div className="label">Carrier Name</div>
                              <p className="text-primary">Egal Goods Inc</p>
                            </div>

                            <div className="truckInfoItem">
                              <span className="">
                                <i className="ch ch-truck-location"></i>
                              </span>
                              <div className="label">Truck Empty Location</div>
                              <p className="text-primary">
                                San Antanio <span>(454dh)</span>
                              </p>
                            </div>

                            <div className="truckInfoItem">
                              <span className="">
                                <i className="ch ch-truck-icon"></i>
                              </span>
                              <div className="label">Vehicle Type</div>
                              <p className="text-primary">Cargo Truck</p>
                            </div>

                            <div className="truckInfoItem">
                              <span className="">
                                <i className="ch ch-chart-icon"></i>
                              </span>
                              <div className="label">Comodity</div>
                              <p className="text-primary">NA</p>
                            </div>

                            <div className="truckInfoItem">
                              <span className="">
                                <i className="ch ch-weight-icon"></i>
                              </span>
                              <div className="label">Weight</div>
                              <p className="text-primary">2000</p>
                            </div>

                            <div className="truckInfoItem">
                              <span className="">
                                <i className="ch ch-mile-icon"></i>
                              </span>
                              <div className="label">Miles</div>
                              <p className="text-primary">500</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-3 mb-3">
                          <div className="avgCostBox outLineBox rounded h-100  px-3 pt-3">
                            <h3>Average Cost of Lane</h3>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="avgCostBoxItem mb-3">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="aclRadio1"
                                      name="aclRadio1"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="aclRadio1"
                                    >
                                      7 Days{" "}
                                      <span className="ml-auto">$50.00</span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="avgCostBoxItem mb-3">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="aclRadio2"
                                      name="aclRadio2"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="aclRadio2"
                                    >
                                      20 Days{" "}
                                      <span className="ml-auto">$100.00</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="avgCostBoxItem mb-3">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="aclRadio3"
                                      name="aclRadio3"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="aclRadio3"
                                    >
                                      13 Days{" "}
                                      <span className="ml-auto">$80.00</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="avgCostBoxItem mb-3">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="aclRadio4"
                                      name="aclRadio4"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="aclRadio4"
                                    >
                                      1 Year{" "}
                                      <span className="ml-auto">$150.00</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-4 mb-3">
                          <div className="allRateBox outLineBox rounded bg-white  px-3 pt-3">
                            <form>
                              <h3 className="text-dark">All In Rate $265.00</h3>
                              <div className="form-group row">
                                <label
                                  htmlFor="target-rate"
                                  className="col-sm-2 col-form-label"
                                >
                                  Target Rate
                                </label>
                                <div className="input-group col-sm-4 mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="target-rate"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-label="target-rate"
                                    aria-describedby="target-rate"
                                  />
                                </div>
                                <label
                                  htmlFor="max-buy"
                                  className="col-sm-2 col-form-label"
                                >
                                  Max Buy
                                </label>
                                <div className="input-group col-sm-4 mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="max-buy"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-label="max-buy"
                                    aria-describedby="max-buy"
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="chariot-rate"
                                  className="col-sm-2 col-form-label"
                                >
                                  Chariot Rate
                                </label>
                                <div className="input-group col-sm-4 mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="chariot-rate"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-label="chariot-rate"
                                    aria-describedby="chariot-rate"
                                  />
                                </div>
                                <label
                                  htmlFor="carrier-rate"
                                  className="col-sm-2 col-form-label"
                                >
                                  Max Buy
                                </label>
                                <div className="input-group col-sm-4 mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="carrier-rate"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-label="carrier-rate"
                                    aria-describedby="carrier-rate"
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 col-lg-6">
                          <div className="row">
                            <div className="col-auto d-flex align-items-center">
                              <h2>Carrier Progress</h2>
                            </div>
                            <div className="col-md-10">
                              <ul className="carrierProgress">
                                <li>Waterfall</li>
                                <li className="active">Sniper Eco System</li>
                                <li>Load Boards</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-6 text-center text-lg-right">
                          <button className="btn btn-bluegray btn-pill mw-104 m-1">
                            View Details
                          </button>
                          <button className="btn btn-info btn-pill mw-104 m-1">
                            Add Bid
                          </button>
                          <button className="btn btn-success btn-pill mw-104 m-1">
                            Book
                          </button>
                          <button className="btn btn-danger btn-pill mw-104 m-1">
                            Pass
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="split-screen-wrapper">
          <div class="split-screen-inner p-3">
            <table className="table truck-table table-hover">
              <tbody>
                <tr>
                  <th class="checkboxCol">
                    <input type="checkbox" id="" />
                  </th>
                  <th>Source Type</th>
                  <th>Age</th>
                  <th>Avail</th>
                  <th>Truck</th>
                  <th>F/P</th>
                  <th>Dff-O</th>
                  <th>Origin</th>
                  <th>Trip</th>
                  <th>Destination</th>
                  <th>Dff-D</th>
                  <th>Company</th>
                  <th>Contact</th>
                  <th>Length</th>
                </tr>
                <tr>
                  <td class="checkboxCol">
                    <input type="checkbox" id="" />
                  </td>
                  <td>DAT</td>
                  <td>00.02</td>
                  <td>4/21</td>
                  <td>V</td>
                  <td>F</td>
                  <td>54</td>
                  <td>Russellville, KY</td>
                  <td>--</td>
                  <td>San Antonio, TX</td>
                  <td>--</td>
                  <td>Palm Industries</td>
                  <td>+ (999) 999 9999</td>
                  <td>53ft</td>
                </tr>
                <tr className="hide-table-padding">
                  <td colSpan="14">
                    <div id="dataRow-1" className="dataRow p-3">
                      <div className="row split-row-data align-items-center">
                        <div className="col-12 col-lg-6">
                          <div className="d-flex align-items-center">
                            <a href="#" className="addtruck d-block">
                              <img
                                src="../images/icon-plus-square.png"
                                alt="add"
                              />
                            </a>
                            <span className="badge split-badge">DD</span>
                            <span className="badge split-badge">Called</span>
                            <form class="form-inline mx-3 w-100">
                              <label class="my-1 mr-2" for="splitDetailNote">
                                Notes
                              </label>
                              <textarea class="form-control my-1 mr-sm-2" id="splitDetailNote" rows="2"></textarea>
                              <button type="submit" class="btn btn-primary my-1">
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                        <div className="col-12 col-lg-3">
                          <dl class="line-items-dl">
                            <dt class="w-50">Chariot Rate:</dt>
                            <dd class="w-50">$500</dd>
                            <dt class="w-50">Carrier Rate :</dt>
                            <dd class="w-50">$400</dd>                          
                          </dl>
                        </div>
                        <div class="col-md-12 col-lg-3 text-center text-lg-right">
                          <button class="btn btn-info btn-pill mw-104 m-1">
                            Add Bid
                          </button>
                          <button class="btn btn-success btn-pill mw-104 m-1">
                            Book
                          </button>
                          <button class="btn btn-danger btn-pill mw-104 m-1">
                            Pass
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="checkboxCol">
                    <input type="checkbox" id="" />
                  </td>
                  <td>DAT</td>
                  <td>00.02</td>
                  <td>4/21</td>
                  <td>V</td>
                  <td>F</td>
                  <td>54</td>
                  <td>Russellville, KY</td>
                  <td>--</td>
                  <td>San Antonio, TX</td>
                  <td>--</td>
                  <td>Palm Industries</td>
                  <td>+ (999) 999 9999</td>
                  <td>53ft</td>
                </tr>
                <tr>
                  <td class="checkboxCol">
                    <input type="checkbox" id="" />
                  </td>
                  <td>DAT</td>
                  <td>00.02</td>
                  <td>4/21</td>
                  <td>V</td>
                  <td>F</td>
                  <td>54</td>
                  <td>Russellville, KY</td>
                  <td>--</td>
                  <td>San Antonio, TX</td>
                  <td>--</td>
                  <td>Palm Industries</td>
                  <td>+ (999) 999 9999</td>
                  <td>53ft</td>
                </tr>
                <tr>
                  <td class="checkboxCol">
                    <input type="checkbox" id="" />
                  </td>
                  <td>DAT</td>
                  <td>00.02</td>
                  <td>4/21</td>
                  <td>V</td>
                  <td>F</td>
                  <td>54</td>
                  <td>Russellville, KY</td>
                  <td>--</td>
                  <td>San Antonio, TX</td>
                  <td>--</td>
                  <td>Palm Industries</td>
                  <td>+ (999) 999 9999</td>
                  <td>53ft</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateActivePage,
};

export default connect(null, mapDispatchToProps)(Dashboard);
