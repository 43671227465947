import { USERS } from "../ActionTypes";

const initialState = {
	brokers : []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case USERS.FETCH_USERS_LIST:
			return {
				...state,
				list: action.payload
			};
		case USERS.FETCH_USER_DETAILS:
			return {
				...state,
				details: action.payload
			};
		case USERS.FETCH_ROLES:
			return {
				...state,
				roles: action.payload
			};
		case USERS.FETCH_DIVISIONS:
			return {
				...state,
				divisions: action.payload
			};
		case USERS.UPDATE_FIELD:
			return {
				...state,
				[action.field]: action.val
			};
		case USERS.FETCH_BROKERS_LIST:
			return {
				...state,
				brokers: (action.payload.message === "success") ? action.payload.data : []
			};
		default:
			return state;
	}
}
