import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { renderField } from "./../../Utils/Fields";
import { carrierInfoFields } from './Helper';
import { updateCarrierUserData } from './../../../Redux/Action/Dashboard';
import { fetchCarrierUserDetails } from '../../../Redux/Action/Carrieruser';
import { NotificationManager } from 'react-notifications';
import { rules } from './../../Utils/Validator';
import PreloaderInner from '../../Utils/PreloaderInner';


function UpdateCarrierValuesForm({user,loading,handleSubmit,submitting}) { 
    
    // const [userData,setUserData] = useState(user)
    console.log(user)
    const onSubmit = (values) => {
        const phoneFormat = values.DispatcherPhoneNumber;
        let start = phoneFormat.slice(0,3);
        let middle = phoneFormat.slice(3,6);
        let end = phoneFormat.slice(6,10);
        var payload = {
            icc_mc_nbr: values.mcNo,
            carrierName: values.CarrierName,
            dispatcherPhoneNumber: `(${start})${middle}-${end}`,
            dispatcherName: values.DispatcherName,
            dispatcherEmail: values.DispatcherEmail,
            carrierSafetyRating:values.CarrierSafetyRating,
            carrierInsurance:values.CarrierInsurance,
            extension:values.extension
        }
        updateCarrierUserData(payload).then(res=>{
            if(res.message==='success'){
                NotificationManager.success('Success','Values Updation Success',3500);
            }else{
                NotificationManager.error('Error','Values Updation Failed',3500)
            }        
        })
    }

    return (
        <div className='user-new-container m-2'>
        <PreloaderInner hidden={!loading}/>
        <div className="user-new-header">
			<h4 className="m-0">{`Update Carrier Data`}</h4>
		</div>
        <div className='create-user-form'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="title-info ">
                Carrier Info
                </div>
                <div className="row additional-form-body mt-4">
                    {carrierInfoFields.map((field) =>
                        <Field
                            name={field.name}
                            component={renderField}
                            type={field.type}
                            validate={field.validation}
                            label={field.label}
                            placeholder={field.placeholder}
                            readOnly={field.readOnly}
                            {...field.mask}
                        />
                    )}
                </div>
                <div className="form-footer text-right mr-2">
                    <button type='submit' disabled={submitting} onSubmit={handleSubmit(onSubmit)} className='btn btn-success'> Update </button>
                    <button type='button' disabled={submitting} onClick={(e)=>{e.preventDefault();window.open('/carrierusers','_self')}} className='btn btn-danger ml-2'> Cancel </button>
                </div>
            </form>
            </div>
        </div>
    )
}

UpdateCarrierValuesForm = reduxForm({
    form: 'carrierInfoUpdateForm'
})(UpdateCarrierValuesForm);



export default connect((state,props)=>({
    initialValues:{
        mcNo:props.user?.icc_mc_nbr,
        CarrierName:props.user?.firstname?props.user?.firstname:'',
        DispatcherName:props.user?.contact,
        DispatcherPhoneNumber:props.user?.phone_number.replace(/\D/g, ''),
        DispatcherEmail:props.user?.email,
        CarrierSafetyRating:'',
        CarrierInsurance:'',
        extension:''
    }
}))(UpdateCarrierValuesForm);