import React, { useEffect, useState } from "react";
import { fetchInsuranceDetails, saveInsuranceDetails } from "./../../../../Redux/Action/Dashboard";
import { Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

export default function MonitoringSection({ MCNo }) {
    const [isModalShow, SetModal] = useState(false);
    const [insuranceDetails, setInsuranceDetails] = useState({
        mc_number: MCNo,
        carrier_safety_rating: "",
        carrier_insurance: ""
    });

    useEffect(() => {
        fetchInsuranceDetails(MCNo).then((result) => {
            if (result.message === 'success') {
                setInsuranceDetails((prevState) => ({
                    ...prevState,
                    mc_number: MCNo,
                    carrier_safety_rating: result.data && (result.data.carrier_safety_rating !== null ? result.data.carrier_safety_rating : ''),
                    carrier_insurance: result.data && (result.data.carrier_insurance !== null ? result.data.carrier_insurance : '')
                }))
            }
        }).catch(error => {
            // console.log(error) 
        })
        return () => {
            setInsuranceDetails({
                "mc_number": "",
                "carrier_safety_rating": "",
                "carrier_insurance": ""
            });
        }
    }, [MCNo])


    const changeInsuranceValues = (e) => {
        const { name, value } = e.target
        setInsuranceDetails((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const saveDetails = (e) => {
        saveInsuranceDetails(insuranceDetails).then((result) => {
            if (result.message === 'error') {
                NotificationManager.error('Data Not Saved', result.description, 5000);
            }
        })
    }

    const monitor = (e) => {
        e.preventDefault()
        window.open('http://www.carrier411.com', '_newtab');
    }

    return <div className="col-lg-5">
        <div className="outLineBox rounded p-2 h-100">
            <div className="row">
                <div className="col-lg-6 col-xl-3 mb-3 mb-xl-0">
                    <div className="custom-control custom-checkbox mb-2">
                        <input type="checkbox" className="custom-control-input" id="chb_CA" />
                        <label className="custom-control-label" htmlFor="chb_CA">Carrier Approved</label>
                    </div>
                    <div className="custom-control custom-checkbox mb-2">
                        <input type="checkbox" className="custom-control-input" id="chb_W9" />
                        <label className="custom-control-label" htmlFor="chb_W9">W9</label>
                    </div>
                    <div className="custom-control custom-checkbox mb-2">
                        <input type="checkbox" className="custom-control-input" id="cchb_Insurance" />
                        <label className="custom-control-label" htmlFor="chb_Insurance">Insurance</label>
                    </div>
                    <div className="custom-control custom-checkbox mb-2">
                        <input type="checkbox" className="custom-control-input" id="chb_Authority" />
                        <label className="custom-control-label" htmlFor="chb_Authority">Authority</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="chb_CC" />
                        <label className="custom-control-label" htmlFor="chb_CC">Carrier Contract</label>
                    </div>
                </div>
                <div className="col-lg-6 col-xl-5 mb-3 mb-xl-0">
                    <form>
                        <div className="form-group mb-2">
                            <label htmlFor="cSafRat">Carrier Safety Rating</label>
                            <input type="text" className="form-control" name="carrier_safety_rating" value={MCNo && insuranceDetails.carrier_safety_rating ? insuranceDetails.carrier_safety_rating : ''} id="cSafRat" onChange={(e) => changeInsuranceValues(e)} onBlur={saveDetails}/>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="carInsu">Carrier Insurance</label>
                            <input type="text" className="form-control" name="carrier_insurance" value={MCNo && insuranceDetails.carrier_insurance ? insuranceDetails.carrier_insurance : ''} id="carInsu" onChange={(e) => changeInsuranceValues(e)} onBlur={saveDetails} />
                        </div>
                    </form>
                </div>
                <div className="col-lg-12 col-xl h-100">
                    <div className="d-flex flex-column">
                        <button className="btn btn-big btn-primary mb-3" onClick={() => SetModal(!isModalShow)}>Carrier Freight Guards</button>
                        <button className="btn btn-big btn-info" onClick={monitor}>Start/Stop Monitoring</button>
                    </div>
                </div>
            </div>
        </div>

        <Modal className="viewReportModal" show={isModalShow} onHide={() => SetModal(!isModalShow)}>
            <Modal.Header closeButton>
                <Modal.Title>Freight Guards Reports</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                <table className="table viewReportModalTable">
                    <thead>
                        <tr>
                            <td>Submitted</td>
                            <td>Report Age</td>
                            <td>&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>02/04/2020 9:00</td>
                            <td>48 Days</td>
                            <td className="text-right"><a className="viewReportLink" href="#">View Report <i className="ch ch-eye-icon"></i></a></td>
                        </tr>
                        <tr>
                            <td>06/04/2019 5:00</td>
                            <td>90 Days</td>
                            <td className="text-right"><a className="viewReportLink" href="#">View Report <i className="ch ch-eye-icon"></i></a></td>
                        </tr>
                        <tr>
                            <td>02/04/2020 9:00</td>
                            <td>90 Days</td>
                            <td className="text-right"><a className="viewReportLink" href="#">View Report <i className="ch ch-eye-icon"></i></a></td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    </div>
}