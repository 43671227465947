import React, { useEffect, useState } from "react";
import BasicTable from "./../../../Utils/BasicTable";
import PerfectScrollbar from 'react-perfect-scrollbar'
import data from "./../StaticValues.json";
import { fetchCarrierLoadHistory } from "./../../../../Redux/Action/Dashboard";
import PreloaderInner from "./../../../Utils/PreloaderInner";
import moment from "moment";

const headers = [
    { accessor: "order_nbr", Header: "Order", sortField: true },
    { accessor: "broker", Header: "Broker", sortField: true },
    { accessor: "pickup_date_time", Header: "Pickup Date/Time", sortField: true },
    { accessor: "pick_up_location", Header: "Pickup Location", sortField: true },
    { accessor: "destination", Header: "Destination", sortField: true },
    { accessor: "revenue", Header: "Revenue", sortField: true },
    { accessor: "cost", Header: "Truck Cost", sortField: true }
];

export default function CarrierLoadHistory({ MCNo, filterDates }) {
    const [isLoader, SetLoader] = useState(false);
    const [carrierLoadHistory, SetCarrierLoadHistory] = useState([]);
    const [sortData, setSortData] = useState(false);
    const [apiFieldName, setApiFieldName] = useState('');
    const [descending, setDescending] = useState(false);

    useEffect(() => {
        if (MCNo) {
            SetLoader((prevState) => prevState = true);
            fetchCarrierLoadHistory(MCNo).then(result => {
                if (result.message === "success") {
                    var customData = result && result.data.orders.sort((b, a) => (a.pickup_date_time ? a.pickup_date_time : 'zzz').localeCompare(b.pickup_date_time !== null ? b.pickup_date_time : 'zzz'))
                    SetCarrierLoadHistory((prevState) => prevState = customData);
                    SetLoader(false);//(prevState) => prevState = false
                } else {
                    SetLoader((prevState) => prevState = false);
                }
            }).catch(error => {
                // console.log(error)
                SetLoader((prevState) => prevState = false);
            });
        } else {
            SetCarrierLoadHistory([]);
            SetLoader((prevState) => prevState = false);
            return;
        }
    }, [MCNo,filterDates])



    const sortingData = (field) => {
        if (field === apiFieldName) {
            setDescending((prevState) => !prevState)
        } else {
            setDescending(false)
        }
        setApiFieldName(field);
        setSortData(true);
    }

    useEffect(() => {
        var sortTextData = ['broker', 'pickup_date_time', 'pick_up_location', 'destination'];
        var sortNumberData = ['order_nbr', 'revenue', 'cost']
        if (sortData) {
            if (!descending) {
                sortTextData.map((field) => {
                    if (field === apiFieldName) {
                        var sortedData = carrierLoadHistory && carrierLoadHistory.sort((a, b) => (a[field] ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
                        setSortData(false);
                        SetCarrierLoadHistory(sortedData);
                    }
                });
                sortNumberData.map((field) => {
                    if (field === apiFieldName) {
                        setSortData(false);
                        var sortedData = carrierLoadHistory && carrierLoadHistory.sort((a, b) => (a[field] !== null ? a[field] : 'zzz') - (b[field] !== null ? b[field] : 'zzz'));
                        SetCarrierLoadHistory(sortedData);
                    }
                });
            } else {
                sortTextData.map((field) => {
                    if (field === apiFieldName) {
                        var sortedData = carrierLoadHistory && carrierLoadHistory.sort((b, a) => (a[field] ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
                        setSortData(false);
                        SetCarrierLoadHistory(sortedData);
                    }
                });
                sortNumberData.map((field) => {
                    if (field === apiFieldName) {
                        setSortData(false);
                        var sortedData = carrierLoadHistory && carrierLoadHistory.sort((b, a) => (a[field] !== null ? a[field] : 'zzz') - (b[field] !== null ? b[field] : 'zzz'));
                        SetCarrierLoadHistory(sortedData);
                    }
                });
            }
        }
    }, [sortData])

    return <div className="table-responsive auto-scroll">
        <PreloaderInner hidden={!isLoader} />
        <PerfectScrollbar>
            <table className="table truck-table mb-0">
                <tbody>
                    <tr>{headers.map((headerData) => {
                        return <th>{headerData.Header} <div className={`d-inline-block ml-1`}><img className={`${headerData.sortField ? '' : 'd-none'}`} onClick={() => sortingData(headerData.accessor)} src={`${headerData.accessor === apiFieldName ? descending ? 'images/ascending-arw.png' : 'images/descending-arw.png' : 'images/updown-arrow.png'}`} /></div></th>
                    })}
                    </tr>
                    {carrierLoadHistory && carrierLoadHistory.length > 0
                        ? carrierLoadHistory.map((rowData) => {
                            return <tr>
                                <td>{rowData.order_nbr}</td>
                                <td>{rowData.broker}</td>
                                <td>{moment(rowData.pickup_date_time).format('MM/DD/YYYY HH:mm')}</td>
                                <td>{rowData.pick_up_location}</td>
                                <td>{rowData.destination}</td>
                                <td>{rowData.revenue}</td>
                                <td>{rowData.cost}</td>
                            </tr>
                        })
                        : <tr className="text-center text-danger"><td colSpan={headers.length}>No Record Found</td></tr>}
                </tbody>
            </table>
            {/* <BasicTable columns={headers} data={carrierLoadHistory} /> */}
        </PerfectScrollbar>
    </div>
}

