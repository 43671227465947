import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { fetchEquipmenttypesList } from './../../../../Redux/Action/Dashboard';
import { renderField } from "./../../../Utils/Fields";
import { carrierInfoFields, additionalInfoFields } from "./BIDModule/Helper";
import { createNumberMask, createTextMask } from 'redux-form-input-masks';

function AllCarrierInfoForm({ handleSubmit, handleClose, updateField,  carrierInfo, insuranceDetails }) {

  const [equipmentTypesList, setEquipmentTypesList] = useState([]);
  const [equipmentTypesOptions, setEquipmentTypesOptions] = useState([]);
  const [isShowingAdditionalInfo, setisShowingAdditionalInfo] = useState(false);
  useEffect(() => {
    fetchEquipmenttypesList().then((result) => {
      setEquipmentTypesList(result.data)
    })
  }, [])

  useEffect(() => {
    const equipmentOptions = equipmentTypesList.map((equipment) => {
      return { value: equipment.id, label: equipment.description };
    })
    setEquipmentTypesOptions(equipmentOptions)
  }, [equipmentTypesList])

  

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <React.Fragment>
        <div className="title-info collapsing-title">Carrier Info</div>
        <div className="row carrier-form-body mt-4">
          {carrierInfoFields.map((field) => (
            <Field
              name={field.name}
              component={renderField}
              fieldToUpdate={field.latLng}
              latLng={[field.latLng]}
              key={field.label}
              type={field.type}
              label={field.label}
              readOnly={field.readOnly}
              placeholder={field.placeholder}
              updateField={updateField}
              validate={field.validation}
              {...field.mask}
            />
          ))}
        </div>
        <div className="title-info collapsing-title">
          Additional Carrier Info
          <span className="expand-info pointer mr-3"
            onClick={() => setisShowingAdditionalInfo(!isShowingAdditionalInfo)}>
            {isShowingAdditionalInfo ? (
              <i className="icon icon-arrow-up" aria-hidden="true" />
            ) : (
              <i className="icon icon-arrow-down" aria-hidden="true" />
            )}
          </span>
        </div>
        <div className={`row additional-form-body mt-4 ${isShowingAdditionalInfo ? '' : 'd-none'}`}>
          {additionalInfoFields.map((field) => (
            <Field
              name={field.name}
              key={field.label}
              component={renderField}
              type={field.type}
              label={field.label}
              placeholder={field.placeholder}
              options={field.label === "Equipment Type" ? equipmentTypesOptions : field.options}
              {...field.mask}
            />
          ))}
        </div>
        <div className="row text-center float-right mt-2">
          <div className="col-md-12 mb-2">
            <button
              disabled={false}
              type="submit"
              className="btn btn-success btn-pill mw-104 m-1"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-danger btn-pill mw-104 m-1"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    </form>
  );
};

AllCarrierInfoForm = reduxForm({
  form: "all-carrier-info-form",
})(AllCarrierInfoForm);

export default connect((state, props) => ({
  initialValues: {
    mcNo: props.MCNo ? props.MCNo : "",
    carrierName: props.CarrierName ? props.CarrierName : "",
    truckLocation: "",
    dispatcherName: props.carrierInfo && props.carrierInfo.contact_name ? props.carrierInfo.contact_name : "",
    dispatcherPhoneNumber: props.contactPhone?props.contactPhone:props.carrierInfo && props.carrierInfo.carrier_phone ? props.carrierInfo.carrier_phone.replace(/\D/g, '') : "",
    dispatcherPhoneExternsion: "",
    dispatcherEmail: props.contactEmail?props.contactEmail:props.carrierInfo && props.carrierInfo.dispatcher_email ? props.carrierInfo.dispatcher_email : "",
    carrierSafetyRating: props.insuranceDetails.carrier_safety_rating,
    carrierInsurance:props.insuranceDetails.carrier_insurance,
    truckNumber: "",
    trailerNumber: "",
    driverName: "",
    driverPhoneNumber: "",
    driverPhoneExtension: "",
    equipmentType: props.carrierInfo && props.carrierInfo.truck_type ? props.carrierInfo.truck_type : "",
    trailerLength: "",
    driverEmpty: "",
    etaForPickup: "",
    emptyTime: ""
  }
}))(AllCarrierInfoForm);
