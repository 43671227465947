import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchLoadList, updateField, storeSearchFilter, fetchSearchFilter } from "../../../Redux/Action/Loads";
import { updateActivePage } from "./../../../Redux/Action/Auth";
import { isEqual } from "lodash";
import LoadListTable from "./../LoadList/LoadListTable";
import PreloaderInner from "./../../Utils/PreloaderInner";
import { statusFilters } from "./Helper";
import { Scrollbars } from "react-custom-scrollbars";

class LoadList extends Component {
	state = {
		pageCount: 0,
		pageSize: 20,
		pageNumber: 0,
		loadList: [],
		completLoadList: [],
		searchKey: "",
		spot: true,
		go: true,
		hot: true,
		id: "",
		division: "",
		customer: "",
		pickDate: null,
		pickLocation: "",
		destination: "",
		deliveryDate: null,
		filter: "",
		docListLoaded: true,
		loading: true,
		query: "pagenumber=1&pagesize=20"
	};

	componentDidMount = () => {
		this.props.updateActivePage("activePage", "sniper");
		this.props.fetchLoadList();
		if (localStorage.getItem("CHARIOT_USER_DATA")) {
			this.setState({ userDetails: JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")) });
			this.props.fetchSearchFilter(JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.id);
		}

		window.onbeforeunload = () => {
			this.storeFilter();
		};

		// To reload the data from the store when its already loaded, but it delays mounting

		// const { loadList } = this.props;
		// if (loadList && loadList.data && !isEqual(loadList.data, this.state.loadList)) {
		// 	this.setState({
		// 		loadList: loadList.data
		// 	});
		// }
	};

	componentDidUpdate = (prevProps) => {
		const { loadList, loadFilterList } = this.props;
		if (loadList && loadList.data && this.state.loading !== false) {
			this.setState({ loading: false });
		}
		if (loadFilterList && loadFilterList.data && !isEqual(loadFilterList, prevProps.loadFilterList)) {
			if (loadFilterList.data && loadFilterList.data.search_filter) {
				let searchFilter = JSON.parse(loadFilterList.data.search_filter);
				if (searchFilter.statusArray && searchFilter.statusArray.length > 0) {
					searchFilter.statusArray.forEach((status) => {
						this.setState({ [status]: false }, () => {
							this.setValue("pageNumber", 0);
						});
					});
				}
				this.props.updateField("filtered", searchFilter.filtered);
				this.setState({ searchKey: searchFilter.searchKey });
			}
		}
		if (loadList && loadList.data && !isEqual(loadList.data, this.state.completLoadList)) {
			this.setState({
				completLoadList: loadList.data,
				loadList: loadList.data
			});
			this.setValue("pageNumber", 0);
		}
	};

	componentWillUnmount = () => {
		this.storeFilter();
		this.props.updateField("loadFilterList", {});
	};

	storeFilter = () => {
		const { filtered } = this.props;
		const { go, hot, spot, searchKey, userDetails } = this.state;
		let statusArray = [];
		if (!go) statusArray.push("go");
		if (!hot) statusArray.push("hot");
		if (!spot) statusArray.push("spot");
		let payload = {
			user_id: userDetails.user.id,
			search_filter: {
				filtered,
				searchKey,
				statusArray
			}
		};
		this.props.storeSearchFilter(payload, userDetails.token);
	};

	setValue = (field, val) => {
		this.setState({ [field]: val, pageNumber: field === "pageNumber" ? val : 0 }, () => {
			const { go, hot, spot, searchKey } = this.state;
			let statusArray = [];
			let filteredArray = this.props.loadList && this.props.loadList.data ? this.props.loadList.data : [];
			if (go) statusArray.push("go");
			if (hot) statusArray.push("hot");
			if (spot) statusArray.push("spot");
			if (statusArray.length > 0) {
				filteredArray = filteredArray.filter((order) => statusArray.includes(order.load_status));
			} else {
				filteredArray = [];
			}
			if (searchKey)
				filteredArray = filteredArray.filter(
					(order) =>
						order.customer.toLowerCase().includes(searchKey.toLowerCase()) ||
						order.pickLocation.toLowerCase().includes(searchKey.toLowerCase()) ||
						order.destination.toLowerCase().includes(searchKey.toLowerCase())
				);
			this.setState({
				loadList: filteredArray
			});
		});
	};

	renderTable = () => (
		<form className="mt-3 p-r">
			<PreloaderInner hidden={!this.state.loading} />
			<LoadListTable
				loading={this.state.loading}
				data={this.state.loadList}
				history={this.props.history}
				filtered={this.props.filtered}
				pageNumber={this.state.pageNumber}
				updateField={this.props.updateField}
				setValue={this.setValue}
			/>
		</form>
	);

	renderStatusFilters = () => {
		return (
			<div className="filter-container form-group field-icon">
				{statusFilters.map((status, index) => (
					<label key={index} className={`custom-checkbox mb-0 pointer ${status.class}`}>
						<input
							type="checkbox"
							checked={this.state[status.field]}
							name={status.field}
							className="custom-checkbox-input"
							onChange={(e) => this.setValue(status.field, e.target.checked)}
						/>
						<span className="custom-checkbox-label">{status.label}</span>
					</label>
				))}
			</div>
		);
	};

	renderSearchInput = () => (
		<div className="search-container form-group field-icon">
			<input
				type="text"
				autoComplete="off"
				value={this.state.searchKey}
				onChange={(e) => this.setValue("searchKey", e.target.value)}
				className="form-control search-input"
				placeholder="Search for Customer, Pick Location, Destination......"
			/>
			<i className="icon icon-search" />
		</div>
	);

	render() {
		return (
			<React.Fragment>
				<section className="load-list-header">
					<div className="page-header">
						<h4 className="section-title">Sniper load board</h4>
						{this.renderSearchInput()}
						{this.renderStatusFilters()}
					</div>
				</section>
				<section className="load-list-table-container">
					<Scrollbars>
						<div className="load-list-table-block">{this.renderTable()}</div>
					</Scrollbars>
				</section>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	loadList: state.Loads.loadList,
	filtered: state.Loads.filtered,
	loadFilterList: state.Loads.loadFilterList
});

const mapDispatchToProps = {
	fetchLoadList,
	updateField,
	updateActivePage,
	storeSearchFilter,
	fetchSearchFilter
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoadList);
