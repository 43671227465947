import React, { useState, useEffect, useRef } from 'react'
import searchbtn from "./../../../../images/search-btn.svg";
import { changeCarrierInfo, addNewEmptyLocation, setTruckEmptyLocation } from "../../../../Redux/Action/TruckDetails";
import { savePostedTruck, getNearbyLocations, getSelectedTruckEmptyLocationLatLng } from "../../../../Redux/Action/Dashboard"
import { connect } from "react-redux";
import moment from 'moment';

function TruckEmptyLocationSearch({ MCNo, TruckEmptyLocation, saveCarrierInfoValues, carrierInfo, truckId, changeCarrierInfo, addNewEmptyLocation, startDate, endDate, truckTypeList, setTruckPostedReload, SearchCarrierName, setLatLong, setReloadTruckPosted  }) {

    const [hasType, SetType] = useState(false);
    const [showTruckEmptyLocation, SetShowTruckEmptyLocation] = useState(false);
    const wrapperRef = useRef(null);
    const [locations, SetLocationList] = useState([]);
    const [alllocations, SetAllLocation] = useState([]);
    const [truckLocation, setTruckLocation] = useState(TruckEmptyLocation);
    const [currentTruckEmpty, setCurrentTruckEmpty] = useState(TruckEmptyLocation);
    const [currentTruckEmptyData,setCurrentTruckEmptyData] = useState('');

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    });

    useEffect(() => {
        if (carrierInfo && carrierInfo.truck_empty_location) {
            setCurrentTruckEmpty(carrierInfo.truck_empty_location);
        }
    }, []);


    useEffect(() => {
        if (truckLocation) {
            // SetLocationList(TruckEmptyLocation);
            SetAllLocation(TruckEmptyLocation);
        }
    }, [truckLocation]);

    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
            SetShowTruckEmptyLocation(false);
        }
    };

    const resetTruckEmptyLocation = () => {
        var objCollection = {
            ...carrierInfo,
            truck_empty_location: "",
            longitude: '',
            latitude: ''
        };
        setLatLong(() => ({
            latitude: '',
            longitude: ''
        }))
        setCurrentTruckEmpty("");
        changeCarrierInfo(truckId, objCollection);
    }

    const SetSearchValue = (value) => {
        SetType(true);
        if (!value) {
            SetShowTruckEmptyLocation(false);
            saveCarrierInfoValues();
            resetTruckEmptyLocation();
        } else {
            var objCollection = {
                ...carrierInfo,
                truck_empty_location: value
            };
            changeCarrierInfo(truckId, objCollection);
            if (hasType) {
                SetShowTruckEmptyLocation(true);
            
                const payLoad={
                    home_city_state: carrierInfo.home_city_state?carrierInfo.home_city_state:'Nashville, TN',
                    destination: value 
                }
                getNearbyLocations(payLoad).then(result=>{
                    if(result.message==='success'){
                        SetLocationList(result.data);
                    }
                }).catch(error=>{
                    // console.log(error)
                })


                // var filteredData = alllocations.filter(m => m.pickLocation.toLowerCase().indexOf(value.toLowerCase()) > -1);
                // if (filteredData)
                //     SetLocationList(filteredData);
            }

            setCurrentTruckEmpty(value);
        }
    }
    

    // const SetSearchValue = (value) => {
    //     SetType(true);
    //     if (!value) {
    //         SetShowTruckEmptyLocation(false);
    //         saveCarrierInfoValues();
    //         resetTruckEmptyLocation();
    //     } else {
    //         var objCollection = {
    //             ...carrierInfo,
    //             truck_empty_location: value
    //         };
    //         changeCarrierInfo(truckId, objCollection);
    //         if (hasType) {
    //             SetShowTruckEmptyLocation(true);
    //             var filteredData = alllocations.filter(m => m.pickLocation.toLowerCase().indexOf(value.toLowerCase()) > -1);
    //             if (filteredData)
    //                 SetLocationList(filteredData);
    //         }

    //         setCurrentTruckEmpty(value);
    //     }
    // }

    const SelectLocation = (pickLocation, latitude, longitude) => {
        SetType(false);
        SetShowTruckEmptyLocation(false);
        setCurrentTruckEmpty(pickLocation);
        var objCollection = {
            ...carrierInfo,
            truck_empty_location: pickLocation,
            longitude: longitude,
            latitude: latitude
        };
        changeCarrierInfo(truckId, objCollection);
        saveCarrierInfoValues();
    }

    const OnEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!currentTruckEmpty || currentTruckEmpty.length === 0) {
                return;
            }
            if (locations && locations.length > 0 && currentTruckEmpty && currentTruckEmpty.length > 0) {
                var filteredData = locations //alllocations.filter(m => m.pickLocation.toLowerCase().indexOf(currentTruckEmpty.toLowerCase()) > -1);
                if (filteredData && filteredData.length > 0) {
                    if(currentTruckEmpty.includes(', USA')){
                        filteredData.map(data=>{
                            if(data.location===currentTruckEmpty){
                                setCurrentTruckEmptyData(data)
                            } 
                        })
                    }else{
                        setCurrentTruckEmptyData(filteredData[0]);
                    }
                }
            }
            // if (locations) {
            //     var existingData = locations.filter(m => m.pickLocation.toLowerCase().trim() === carrierInfo.truck_empty_location.toLowerCase().trim());
            //     var selectedLocation = locations[0];
            //     if (existingData && existingData.length > 0) {
            //         selectedLocation = existingData[0];
            //     }
            //     var objCollection = {
            //         ...carrierInfo,
            //         truck_empty_location: selectedLocation.pickLocation,
            //         longitude: selectedLocation.longitude,
            //         latitude: selectedLocation.latitude
            //     };
            //     changeCarrierInfo(truckId, objCollection);
            //     SetShowTruckEmptyLocation(false);
            //     SetLocationList(alllocations);
            //     saveCarrierInfoValues();
            //     addNewEmptyLocation(truckId);
            //     saveTruckPosted();
            // }
            setReloadTruckPosted(true)
        }
    }

    useEffect(() => {
        if(currentTruckEmptyData){
        var selectedLocation = currentTruckEmptyData;
        var objCollection = {
            ...carrierInfo,
            truck_empty_location: selectedLocation.location,
            longitude: selectedLocation.longitude,
            latitude: selectedLocation.latitude
        };
        setCurrentTruckEmpty(selectedLocation.location);
        changeCarrierInfo(truckId, objCollection);
        setLatLong(() => ({
            latitude: selectedLocation.latitude,
            longitude: selectedLocation.longitude
        }))
        SetShowTruckEmptyLocation(false);
        SetLocationList(locations);
        saveCarrierInfoValues();
        addNewEmptyLocation(truckId);
        saveTruckPosted();
    }
    }, [currentTruckEmptyData])
    

    const saveTruckPosted = () => {
        var customSearchName = SearchCarrierName.split('- ').pop();
        var customtrucktype = 'V'
        truckTypeList.map((truckType) => {
            if (truckType.description === carrierInfo.truck_type) {
                customtrucktype = truckType.equip_type
            }
        })
        const payLoad = {
            mc_number: MCNo,
            latitude: currentTruckEmpty?currentTruckEmptyData.latitude:locations[0].latitude,
            longitude: currentTruckEmpty?currentTruckEmptyData.longitude:locations[0].longitude,
            truck_type: customtrucktype,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            truck_empty_location: currentTruckEmpty?currentTruckEmptyData.location:locations[0].location,
            contact: carrierInfo.carrier_phone,
            home_city_state: carrierInfo.home_city_state,
            company: customSearchName,
            user_id: localStorage.getItem("CHARIOT_USER_DATA") ? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.id : 1
        }
        savePostedTruck(payLoad).then((result) => {
            setTruckPostedReload(true)
        })
            .catch(error => {
                // console.log(error)
            })
    }

    return (
        <div className="formInlineCarrier h-100 d-flex align-items-center">
            <form className="d-flex position-relative w-100">
                <input
                    autoComplete="off"
                    value={carrierInfo && carrierInfo.truck_empty_location}
                    onKeyDown={(e) => OnEnterKeyPress(e)}
                    onChange={(e) => SetSearchValue(e.target.value)}
                    name="TruckEmptyLocation"
                    className="form-control carrier mr-sm-2"
                    type="search"
                    placeholder="Enter Location"
                    aria-label="Search"
                />
                <button className="btn my-2 my-sm-0 p-0" type="button">
                    <img src={searchbtn} alt="search" />
                </button>
                {showTruckEmptyLocation && (
                    <ul ref={wrapperRef}>
                        {locations ? (
                            locations.map((data, index) => (
                                <li
                                    key={`${data.location}${index}`}
                                    onClick={() => SelectLocation(data.location, data.latitude, data.longitude)}
                                >
                                    {data && data.location ? data.location : ""}
                                </li>
                            ))
                        ) : (
                            <li>No results found</li>
                        )}
                    </ul>
                )}
            </form>
        </div>
    );
}

const mapStateToProps = (state) => ({
    TruckEmptyLocation: state.TruckDetails.TruckEmptyLocation
});

const mapDispatchToProps = {
    changeCarrierInfo,
    addNewEmptyLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(TruckEmptyLocationSearch);
