import React, { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { saveAvailableDate, saveCarrierInfoData } from './../../../../Redux/Action/Dashboard';
import { changeCarrierInfo } from "./../../../../Redux/Action/TruckDetails";
import { MapController } from "react-map-gl";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import TruckEmptyLocationSearch from "./../MCSection/TruckEmptyLocationSearch";

function CarrierInformation({ MCNo, truckId, carrierInfo, availableDateFilter, truckTypeList, changeCarrierInfo, cloneEmptyLocation, setTruckPostedReload, SearchCarrierName, setDateSelected, setLatLong, setReloadTruckPosted, isReset, setReset, viewBookData  }) {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isEndDateSelected, setEndDateSelected] = useState(false);
  const [formattedPhoneNumber,setFormattedPhoneNumber] = useState('');

  const ExampleCustomInput = React.forwardRef(
    ({ value, onClick }, ref) => (
      <label onClick={onClick} ref={ref} >{`${moment(startDate).format('MM/DD/YYYY')} ${endDate === null ? '' : ' - ' + moment(endDate).format('MM/DD/YYYY')}`}</label>
    ),
  );

  useEffect(()=>{
    if(carrierInfo && MCNo){
      const phoneFormat = carrierInfo.carrier_phone?carrierInfo.carrier_phone.replace(/\D/g, ''):'';
      let start = phoneFormat.slice(0,3);
      let middle = phoneFormat.slice(3,6);
      let end = phoneFormat.slice(6,10);

      setFormattedPhoneNumber(phoneFormat !==''?`(${start})${middle}-${end}`:'') ;
      // console.log(phoneFormat?true:false)
    }
    
  },[carrierInfo])

  const handleChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end !== null) {
      setEndDateSelected(false)
    } else {
      setEndDateSelected(true)
    }
    setDateSelected(true)
  };

  useEffect(()=>{
    if(isReset){
      setStartDate(new Date());
      setEndDate(new Date());
      setReset(false)
    }
  },[isReset])

  useEffect(() => {
		if(!viewBookData){
			setStartDate(new Date());
      setEndDate(new Date());
		}		
	}, [viewBookData])

  useEffect(() => {
    const formattedStartDate = moment(startDate).format('DD/MM/YYYY')
    const formattedEndDate = endDate ? moment(endDate).format('DD/MM/YYYY') : moment(startDate).format('DD/MM/YYYY')
    if (startDate && endDate) {
      saveAvailableDate('url', formattedStartDate, formattedEndDate)
    }
    saveCarrierInfoValues();
    availableDateFilter(startDate, endDate)
    var objCollection = { ...carrierInfo, available_date_start: moment(startDate).format('MM/DD/YYYY'),
        available_date_end: endDate ? moment(endDate).format('MM/DD/YYYY') : moment(startDate).format('MM/DD/YYYY') };
    changeCarrierInfo(truckId, objCollection);
  }, [startDate, endDate]);

  const setCarrierInfoValues = (event) => {
    var { name, value } = event.target;

    if (name === "truck_type") {
      saveCarrierInfoValues();
    }
    var objCollection = { ...carrierInfo, [name]: value };
    changeCarrierInfo(truckId, objCollection);
  }

  const saveCarrierInfoValues = () => {    
    if (MCNo) {
      
      const payLoad = {
        icc_mc_nbr: MCNo,
        carrier_name: carrierInfo.carrier_name,
        name: carrierInfo.contact_name,
        address: carrierInfo.home_city_state,
        email: carrierInfo.dispatcher_email,
        phone: carrierInfo.carrier_phone,
        truck_type: carrierInfo.truck_type ? carrierInfo.truck_type : 'Van',
        available_date_start: moment(startDate).format('MM/DD/YYYY'),
        available_date_end: endDate ? moment(endDate).format('MM/DD/YYYY') : moment(startDate).format('MM/DD/YYYY'),
        truck_empty_location: carrierInfo.truck_empty_location,
        zip: carrierInfo.zip
      };
      saveCarrierInfoData(payLoad).then((result) => {
        if(result.code===200 && result.message==='error'){
          result.data.map(errorMessage=>{
            // NotificationManager.error(errorMessage,'Values not Updated',3500);
          })

        }
      }).catch((error) => {
         console.log(error) 
        // NotificationManager.error('', error, 5000);
      });
    }
  }

  const newEmptyLocation = (event) => {
    if (event.key === 'Enter') {
      cloneEmptyLocation(truckId);
    }
  }

  return (
    <div className="col-md-11 col-lg-11 col-xl-8">
      <div className="truck-Slots justify-content-lg-start align-items-stretch">
        <div className="truck-SlotItem">
          <dl>
            <dt>Contact Name</dt>
            <dd><input type="text" disabled={true} name='contact_name' value={carrierInfo.contact_name ? carrierInfo.contact_name : ''} onChange={setCarrierInfoValues} /></dd>
          </dl>
        </div>
        <div className="truck-SlotItem">
          <dl>
            <dt className="zipcodeToggle">Home City, State<span className={`${carrierInfo.zip ? '' : 'd-none'}`}>{carrierInfo.zip}</span></dt>
            <dd><input type="text" disabled={true} name='home_city_state' value={`${carrierInfo.home_city_state ? carrierInfo.home_city_state : ''} ${carrierInfo.zip ? ', ' + carrierInfo.zip : ''}`} onChange={setCarrierInfoValues}
            /></dd>
          </dl>
        </div>
        <div className="truck-SlotItem">
          <dl>
            <dt>Email</dt>
            <dd><input className={`email-type ${MCNo ? 'editable' : ''}`} type="email" disabled={MCNo ? false : true} name='dispatcher_email' value={carrierInfo.dispatcher_email} onChange={setCarrierInfoValues} onBlur={saveCarrierInfoValues} /></dd>
          </dl>
        </div>
        <div className="truck-SlotItem">
          <dl>
            <dt>Phone Number</dt>
            <dd><input type="text" className={MCNo ? 'editable' : ''} disabled={MCNo ? false : true} name='carrier_phone' value={carrierInfo.carrier_phone && formattedPhoneNumber ? formattedPhoneNumber : ''} onChange={setCarrierInfoValues} onBlur={saveCarrierInfoValues} /></dd>
          </dl>
        </div>
        <div className="truck-SlotItem">
          <dl>
            <dt>Truck Type</dt>
            <dd>
              <select className="form-control broker_sel" name='truck_type' value={carrierInfo.truck_type ? carrierInfo.truck_type : 'Van'} onChange={(e) => setCarrierInfoValues(e)}>
                {truckTypeList && truckTypeList.map((truckType, index) => {
                  return <option key={index} value={truckType.description}>{truckType.equip_type} - {truckType.description}</option>
                })
                }
              </select>
            </dd>
          </dl>
        </div>
        <div className="truck-SlotItem">
          <dl>
            <dt>Available Date</dt>
            <dd>
              <DatePicker
                minDate={viewBookData?'':Date.now()}
                className={`truck-SlotItem `}
                selected={startDate}
                onChange={handleChange}
                shouldCloseOnSelect={isEndDateSelected}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                customInput={<ExampleCustomInput />}
              />
            </dd>
          </dl>
        </div>
        <div className="truck-SlotItem">
          <dl>
            <dt>Truck Empty Location</dt>
            <dd>{MCNo && <TruckEmptyLocationSearch MCNo={MCNo} saveCarrierInfoValues={saveCarrierInfoValues} carrierInfo={carrierInfo} truckId={truckId} startDate={startDate} endDate={endDate} truckTypeList={truckTypeList} setTruckPostedReload={setTruckPostedReload} SearchCarrierName={SearchCarrierName} setLatLong={setLatLong} setReloadTruckPosted={setReloadTruckPosted} />}</dd>
            {/* <dd><input onKeyPress={newEmptyLocation} type="text" className={MCNo ? 'editable' : ''} disabled={MCNo ? false : true} name='truck_empty_location' onBlur={saveCarrierInfoValues} value={carrierInfo.truck_empty_location} onChange={setCarrierInfoValues} /></dd>*/}
          </dl>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  changeCarrierInfo
};

export default connect(null, mapDispatchToProps)(CarrierInformation);