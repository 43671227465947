import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { passReasons } from "./Helper";

export default class PassReasonModal extends Component {
	state = {
		reason: null
	};

	handleSave = () => {
		this.props.save(this.state.reason);
		this.setState({ reason: null });
	};

	handleClose = () => {
		this.props.close();
		this.setState({ reason: null });
	};

	render() {
		const { show } = this.props;
		const { reason } = this.state;
		return (
			<div className="confirm-modal info-select">
				<Modal size="sm" show={show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Enter Pass Reason</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="info-select">
							<Select
								value={reason}
								options={passReasons}
								onChange={(value) => this.setState({ reason: value })}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-danger" onClick={this.handleClose}>
							Cancel
						</button>
						<button disabled={!reason} className="btn btn-success" onClick={() => this.handleSave(reason)}>
							Save
						</button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}
