import { fetchHandler } from "../../Providers/Api";
import { USERS } from  "../ActionTypes";

export function fetchUsersList() {
	const fetchOptions = {
		url: `users`,
		method: "get",
		secure: true,
		actionType: USERS.FETCH_USERS_LIST
	};

	return fetchHandler(fetchOptions);
}

export function fetchUserDetails(id) {
	const fetchOptions = {
		url: `user/edit/${id}`,
		method: "get",
		secure: true,
		actionType: USERS.FETCH_USER_DETAILS
	};

	return fetchHandler(fetchOptions);
}

export function fetchUserRoles() {
	const fetchOptions = {
		url: `roles`,
		method: "get",
		secure: true,
		actionType: USERS.FETCH_ROLES
	};

	return fetchHandler(fetchOptions);
}

export function fetchUserDivisions() {
	const fetchOptions = {
		url: `divisions`,
		method: "get",
		secure: true,
		actionType: USERS.FETCH_DIVISIONS
	};

	return fetchHandler(fetchOptions);
}

export function createUser(data, successHandler) {
	const fetchOptions = {
		url: `user/store`,
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
		actionType: USERS.CREATE_NEW
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function updateUser(data, successHandler) {
	const fetchOptions = {
		url: `user/update`,
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
		actionType: USERS.UPDATE
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function deleteUser(id, successHandler) {
	const fetchOptions = {
		url: `user/delete/${id}`,
		method: "DELETE",
		secure: true,
		actionType: USERS.UPDATE
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function updateField(field, val) {
	return {
		type: USERS.UPDATE_FIELD,
		field,
		val
	};
}
