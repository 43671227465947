import React, { useEffect, useState } from "react";
import moment from "moment";
import { fetchCurrentMapLocation } from "../../../../Redux/Action/Dashboard";

function RouteDetails({ carrierData, deliveryStatus, orderNo }) {

  const [ETA,setETA] = useState('');
  const [destination,setDestination] = useState({});
  const [markers,setMarkers] = useState({});
  const [origin,setOrigin] = useState({});
  const [currentPosition,setCurrentPosition] = useState([]);
  const [displayRouteTime,setDisplayRouteTime] = useState(true);

  useEffect(() => {
    if(deliveryStatus === 'Delivered' || deliveryStatus === 'Accesorial'|| deliveryStatus === 'Finished' ){
      setDisplayRouteTime(false);
    }
  }, [deliveryStatus])
  

  useEffect(()=>{
    var pickUpTime = moment(carrierData.pickTime);
    var deliveryTime = moment(carrierData.deliveryTime);
    var time = pickUpTime.to(deliveryTime)

    setETA(time)
    
  },[carrierData])

  useEffect(()=>{
    let waypoint = [];
    let destinationListValues = [];
    if(carrierData &&  carrierData.picks && carrierData.picks.length>0){
		  carrierData.picks.map(pick=>{
				const point = {location:{
					lat:Number(pick.latitude),
					lng:Number(pick.longitude)
				}};
				waypoint.push(point);
			});
      setOrigin(waypoint[0])
		}
    if(carrierData &&  carrierData.deliveries && carrierData.deliveries.length>0){
			carrierData.deliveries.map(delivery=>{
				const point = {
					location:{
						lat:Number(delivery.latitude),
						lng:Number(delivery.longitude)
					}
				}
				destinationListValues.push(point);
      })
      setDestination(destinationListValues[destinationListValues.length-1])
    }

    if(carrierData.load_nbr){
      var payload = {
        load_nbr:carrierData.load_nbr,
        order_nbr:carrierData.id
      }
      fetchCurrentMapLocation(payload).then(result=>{
        if(result.message==='success'){
          const data = JSON.parse(result.data.latest_location)
          setMarkers({
            location:{
              lat:Number(data.lat),
              lng:Number(data.lon)
            }
          })
        }
      })
      .catch(err=>{console.log(err)})
    }    
  },[carrierData])

  useEffect(() => {
    if(markers.location && destination){
      const directions = new window.google.maps.DirectionsService();
      directions.route({
        origin: markers?markers.location:origin.location,
        destination: destination.location,
        travelMode: window.google.maps.TravelMode.DRIVING
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setCurrentPosition(result.routes[0].legs);
        } else {
          console.error(`Error Fetching Directions ${result}`)
        }
      });
    }
  }, [markers])
  
    return (
        <div className="orderQuickInfo">
          <div className="oQ1">
            <span className="orderNumber mr-3">{orderNo}</span>
            <span className="sourceNdestination mr-3">
              {carrierData.pickLocation}{" "}
              <i className="mx-2">
                <img src="/images/arrow.svg" alt="" />
              </i>{" "}
              {carrierData.destination}
            </span>
          </div>
          <div className={`oQ2 badge status-badge badge-pill badge-success mr-3`}>
            {deliveryStatus}
          </div>
          <div className={`oQ3 text-success mr-3 ${displayRouteTime?'':'d-none'}`}>
            {currentPosition.length>0?`On route to delivery ETA ${currentPosition[0].duration.text}/ ${currentPosition[0].distance.text}`:''}
          </div>
        </div>
  )
}

export default RouteDetails;
