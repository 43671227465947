import React, { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import { fetchSpecialNotes, saveSpecialNotes } from './../../../../Redux/Action/Dashboard'

function SpecialNotesSection({ offerNo }) {
  const [specialNotes, setSpecialNotes] = useState({
    'special_note': '',
    'special_note_1': '',
    'special_note_2': ''
  })
  const [isValid, setValid] = useState({
    'special_note': false,
    'special_note_1': false,
    'special_note_2': false
  })

  useEffect(() => {
    fetchSpecialNotes(offerNo).then((result) => {
      if (result.message == 'success') {
        setSpecialNotes((prevValue) => ({
          ...prevValue,
          special_note: result.data.special_note,
          special_note_1: result.data.special_note_1,
          special_note_2: result.data.special_note_2
        }))
      }
    }).catch((error) => {
      // console.log(error)
    })

  }, [offerNo])

  useEffect(() => {
    if (isValid.special_note && isValid.special_note_1 && isValid.special_note_2) {
      saveValues()
    }
  }, [isValid])

  const setSpecialNotesValue = (e) => {
    const { name, value } = e.target;
    setSpecialNotes((prevValues) => ({
      ...prevValues,
      [name]: value
    }))
  }

  // const validation = (e) => {
  //   const { name, value } = e.target
  //   setValid((prevValue) => ({
  //     ...prevValue,
  //     [name]: true
  //   }))
  // }

  const saveValues = () => {
    var payLoad = {
      ...specialNotes,
      load_type: 'order',
      offer_no: offerNo
    }
    saveSpecialNotes(payLoad).then((result) => {
      if (result.message === 'error') {
        NotificationManager.error(result.description, 'Error', 5000)
      }
      if (result.message === 'success') {
        NotificationManager.success(result.description, 'Success', 5000)
        // setValid({
        //   'special_note': false,
        //   'special_note_1': false,
        //   'special_note_2': false
        // })
      }
    }).catch(error => {
      // console.log(error)
    })
  }

  return (
    <div className="row">
      <div className="col">
        <div className="card bg-lightgray border-0 border-radius-0">
          <div className="card-body">
            <form className="row">
              <div className="col-12 col-lg-4">
                <div class="form-group">
                  <label htmlFor="SpecialNote">Special Note</label>
                  <textarea
                    class="form-control"
                    id="SpecialNote"
                    rows="7"
                    name='special_note' value={specialNotes.special_note}
                    onChange={(e) => { setSpecialNotesValue(e) }} onBlur={((e) => { saveValues(e) })}
                  ></textarea>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div class="form-group">
                  <label htmlFor="SpecialNote1">Special Note 1</label>
                  <textarea
                    class="form-control"
                    id="SpecialNote1"
                    rows="2"
                    name='special_note_1' value={specialNotes.special_note_1}
                    onChange={(e) => { setSpecialNotesValue(e) }} onBlur={((e) => { saveValues(e) })}
                  ></textarea>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div class="form-group">
                  <label htmlFor="SpecialNote2">Special Note 2</label>
                  <textarea
                    class="form-control"
                    id="SpecialNote2"
                    rows="2"
                    name='special_note_2' value={specialNotes.special_note_2}
                    onChange={(e) => { setSpecialNotesValue(e) }} onBlur={((e) => { saveValues(e) })}
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecialNotesSection
