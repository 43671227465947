import React, { Component, useState, userEffect, useEffect } from "react";
import { connect } from "react-redux";
import './../../../Styles/Dashboard.scss';
import { fetchBrokers } from "../../../Redux/Action/Dashboard";
import { addNewTruck, removeTruck, changeActiveTab, addNewEmptyLocation, setCollapsible } from "../../../Redux/Action/TruckDetails";
import { Tabs, Tab, Modal, Button } from "react-bootstrap";
import moment from "moment";
import SplitPane, { Pane } from 'react-split-pane';
import PerfectScrollbar from "react-perfect-scrollbar";
import PreloaderInner from "./../../Utils/PreloaderInner";

//MC section components
import MCSection from "./MCSection/MCSection";
import TruckPosted from "./MCSection/TruckPosted";
import CarrierLoadHistory from "./MCSection/CarrierLoadHistory";
import MonitoringSection from "./MCSection/MonitoringSection";

//Sniper section components
import SniperList from "./SniperSection/SniperList";
import Collapsible from "../../Utils/Collapse/Collapsible";

//SplitScreen Components
import SplitScreen from './SplitScreenSection/SplitScreen'

function Dashboard({ truckCollection, fetchBrokers, brokers, activeTruckTab, addNewTruck, removeTruck, changeActiveTab, addNewEmptyLocation, activeTruckTabDetail, setCollapsible }) {

	// const [isCollapse, SetCollapsible] = useState(true);
	const [isLoader, SetLoader] = useState(false);
	const [isDateSelected, setDateSelected] = useState(false);
	const [filterDates, setFilterDates] = useState({
		startDate: '',
		endDate: ''
	});
	const [splitScreenData, setSplitScreenData] = useState({});
	const [carrierInfoData, setCarrierInfoData] = useState({});
	const [isReload, setReload] = useState(false);
	const [error, setError] = useState();
	const [truckPostedReload, setTruckPostedReload] = useState(false)
	// const [addedNewTab, setAddedNewTab] = useState(false);
	const [LatLong, setLatLong] = useState({
		latitude: '',
		longitude: ''
	});
	const [SplitScreenInitialSize, setSplitScreenInitialSize] = useState('10');
	const [ratesReload, setRatesReload] = useState({
		DashboardRatesReload: false,
		SplitScreenRatesReload: false
	});
	const [reloadTruckPosted, setReloadTruckPosted] = useState(false);
	const [loadBooked, setLoadBooked] = useState({
		offerNo: '',
		isBooked: false
	});
	const [loadAvailable, setLoadAvailable] = useState({
		offerNo: '',
		isAvailable: false
	});
	const [loadStatus,setLoadStatus] = useState('');
	const [isReset,setReset] = useState(false);
	const [viewBookData,setBookData] = useState(false);



	useEffect(() => {
		fetchBrokers();
	}, []);


	const newTruck = (e) => {
		addNewTruck();
		e.stopPropagation();
	}

	const removeExistingTruck = (e, truckId) => {
		removeTruck(truckId);
		e.stopPropagation();
	}

	const setFilterDateValues = (start, end) => {
		setFilterDates((prevState) => ({
			...prevState,
			startDate: moment(start).format('MM/DD/YYYY'),
			endDate: moment(end).format('MM/DD/YYYY')
		}))
	}

	const setSplitScreenOrderNo = (data) => {
		if (data) {
			setSplitScreenData(data)
		} else {
			setSplitScreenData('')
		}
	}

	const cloneEmptyLocation = (truckId) => {
		SetLoader(true);
		setTimeout(() => {
			SetLoader(false);
		}, 3000);
		addNewEmptyLocation(truckId);
	}

	const setCollapsibleStatus = (truckId) => {
		setCollapsible(truckId);
	}


	return <SplitPane split="horizontal" primary="second">
		<div className="my-2 dashboard-wrapper">
			<PerfectScrollbar>
				<div className="dashboard-trucktabs mb-2">
					{truckCollection && truckCollection.map((data, index) => {
						if (truckCollection.length === (index + 1))
							return <button key={index} onClick={() => changeActiveTab(data.truckId)} type="button" className={"btn" + (data.truckId === activeTruckTab ? " active" : "")}>{data.truck_empty_location ? (data.truck_empty_location) : `Truck ${index + 1}`} <span className="ch ch-plusmark-circle-outline ml-1 " onClick={(e) => newTruck(e)}></span>{/*<span className="ch ch-close-circle ml-1" onClick={(e) => removeExistingTruck(e, data.truckId)}></span>*/}</button>
						else
							return <button key={index} onClick={() => changeActiveTab(data.truckId)} type="button" className={"btn" + (data.truckId === activeTruckTab ? " active" : "")}>{data.truck_empty_location ? (data.truck_empty_location) : `Truck ${index + 1}`} <span className="ch ch-close-circle ml-1" onClick={(e) => removeExistingTruck(e, data.truckId)}></span></button>
					})}
					{/* <span className="ch ch-plusmark-circle-outline ml-1 " onClick={(e) => newTruck(e)}></span> */}
				</div>
				<div className="tab-content">
					{truckCollection && truckCollection.map((data, index) => {
						return <div key={index} className={"tab-pane" + (data.truckId === activeTruckTab ? " active" : "")}>
							<div className="outLineBox rounded pt-2 px-2 mb-2">
								<div className="row dbox-header mb-2">
									<div className="col-12">
										<MCSection
											cloneEmptyLocation={cloneEmptyLocation}
											carrierInfo={{
												carrier_name: data.carrier_name,
												dispatcher_email: data.dispatcher_email,
												contact_name: data.contact_name,
												carrier_phone: data.carrier_phone,
												home_city_state: data.home_city_state,
												available_date_start: data.available_date_start,
												available_date_end: data.available_date_end,
												truck_empty_location: data.truck_empty_location,
												truck_type: data.truck_type
											}}
											isCloned={data.isCloned}
											truckId={data.truckId}
											MCNo={data.MCNo}
											spot={data.spot}
											go={data.go}
											hot={data.hot}
											toggleSection={() => setCollapsibleStatus(data.truckId)}
											availableDateFilter={setFilterDateValues}
											SearchCarrierName={data.SearchCarrierName}
											setTruckPostedReload={setTruckPostedReload}
											setDateSelected={setDateSelected}
											setLatLong={setLatLong}
											setReloadTruckPosted={setReloadTruckPosted}
											isReset={isReset}
											setReset={setReset}
											viewBookData = {viewBookData}
										/>
									</div>
								</div>
								<Collapsible open={data.isCollapse}>
									<React.Fragment>
										<div className="row dbox-content mb-2">
											<MonitoringSection MCNo={data.MCNo} />
											<div className="col-lg-7">
												<div className="outLineBox rounded p-2 h-100" id="trucKTabs">
													<Tabs defaultActiveKey="carLoadHist" id="trucKTabs">
														<Tab eventKey="carLoadHist" title="Carrier Load History">
															<CarrierLoadHistory MCNo={data.MCNo} filterDates={filterDates} />
														</Tab>
														<Tab eventKey="truckPosted" title="Truck Posted">
															<TruckPosted
																MCNo={data.MCNo}
																latitude={data.latitude}
																longitude={data.longitude}
																truckPostedReload={truckPostedReload}
																setTruckPostedReload={setTruckPostedReload}
																truckId={data.truckId}
																filterDates={filterDates}
																activeTabId={activeTruckTab}
																reloadTruckPosted={reloadTruckPosted}
																setReloadTruckPosted={setReloadTruckPosted}
																carrierInfo={data}
															/>
														</Tab>
													</Tabs>
												</div>
											</div>
										</div>
									</React.Fragment>
								</Collapsible>
							</div>
							{brokers && data &&
								<SniperList
									MCNo={data.MCNo}
									CarrierName={data.CarrierName}
									carrierInfo={{
										carrier_name: data.carrier_name,
										dispatcher_email: data.dispatcher_email,
										contact_name: data.contact_name,
										carrier_phone: data.carrier_phone,
										home_city_state: data.home_city_state,
										available_date_start: data.available_date_start,
										available_date_end: data.available_date_end,
										truck_empty_location: data.truck_empty_location,
										truck_type: data.truck_type,
										latitude: data.latitude,
										longitude: data.longitude
									}}
									filterDates={filterDates}
									spot={data.spot}
									go={data.go}
									hot={data.hot}
									brokerList={brokers}
									isCloned={data.isCloned}
									truckId={data.truckId}
									setSplitScreenOrderNo={setSplitScreenOrderNo}
									// setCarrierInfoData={setCarrierInfoData}
									isReload={isReload}
									setReload={setReload}
									error={error}
									setError={setError}
									isDateSelected={isDateSelected}
									LatLong={LatLong}
									ratesReload={ratesReload}
									setRatesReload={setRatesReload}
									loadBooked={loadBooked}
									setLoadBooked={setLoadBooked}
									loadAvailable={loadAvailable}
									setLoadAvailable={setLoadAvailable}
									setLoadStatus={setLoadStatus}
									setReset={setReset}
									setBookData={setBookData}
								/>}
						</div>
					})}
				</div>
			</PerfectScrollbar>
		</div>
		<SplitScreen className='mt-2 pt-2' defaultSize='1%' minSize='2%' maxSize='100%' initialSize={SplitScreenInitialSize + '%'}
			splitScreenData={splitScreenData}
			carrierInfo={activeTruckTabDetail}
			setSplitScreenInitialSize={setSplitScreenInitialSize}
			setReload={setReload}
			setError={setError}
			ratesReload={ratesReload}
			setRatesReload={setRatesReload}
			setLoadBooked={setLoadBooked}
			loadStatus={loadStatus}
			viewBookData={viewBookData}
		/>
	</SplitPane>
}

const mapStateToProps = (state) => ({
	brokers: state.User.brokers,
	truckCollection: state.TruckDetails.truckCollection,
	activeTruckTab: state.TruckDetails.activeTruckTab,
	activeTruckTabDetail: state.TruckDetails.activeTruckTabDetail
});

const mapDispatchToProps = {
	addNewTruck,
	removeTruck,
	changeActiveTab,
	fetchBrokers,
	addNewEmptyLocation,
	setCollapsible
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);