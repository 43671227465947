import { fetchHandler, dynamicFetchHandler } from "../../Providers/CustomApi";
import { USERS, LOAD_DETAILS } from "../ActionTypes";

export function fetchSniperList(payLoad) {
	const fetchOptions = {
		url: 'snipperlist', ///${MCNo}
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad),//MCNo
	};

	return fetchHandler(fetchOptions);
}

export function fetchLoadHistory() {
	const fetchOptions = {
		url: `users`,
		method: "get",
		secure: true,
		actionType: USERS.FETCH_USERS_LIST,
	};

	return fetchHandler(fetchOptions);
}

export function fetchCarrierLoadHistory(data) {
	const fetchOptions = {
		url: 'carrerloadhistory',
		method: "POST",
		secure: true,
		body: JSON.stringify({ mc_number: data }),
	};

	return fetchHandler(fetchOptions);
}

export function fetchTruckPosted(data) {
	const fetchOptions = {
		url: 'loads/truckposted',
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
	};

	return fetchHandler(fetchOptions);
}

export function fetchNextLoadDestination(payLoad) {
	const fetchOptions = {
		url: 'loads/nextloaddestination',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad),
	};

	return fetchHandler(fetchOptions);
}

export function fetchLoadOfferHistory(data, sniperId, loadType) {
	const fetchOptions = {
		url: 'loads/loadofferhistory',
		method: "POST",
		secure: true,
		body: JSON.stringify({ offer_no: data, p_key: sniperId, load_type: loadType }),
	};

	return fetchHandler(fetchOptions);
}

export function unbookLoad(orderNo, sniperId) {
	const fetchOptions = {
		url: 'load_unbook',
		method: "POST",
		secure: true,
		body: JSON.stringify({ order_id: sniperId, load_number: orderNo }),
	};

	return fetchHandler(fetchOptions);
}

export function fetchCarrierLoad() {
	const fetchOptions = {
		url: `users`,
		method: "get",
		secure: true,
		actionType: USERS.FETCH_USERS_LIST,
	};

	return fetchHandler(fetchOptions);
}

export function fetchTruckDetails(id) {
	const fetchOptions = {
		url: `loads/offer/${id}`, ///${MCNo}
		method: "GET",
		secure: true,
	};

	return fetchHandler(fetchOptions);
}

export function fetchDetails(url) {
	const fetchOptions = {
		url: url,
		method: "GET",
		secure: true,
		actionType: LOAD_DETAILS.FETCH,
	};

	return fetchHandler(fetchOptions);
}

export function createPass(data) {
	const fetchOptions = {
		url: 'orderpass/store',
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
	};
	return fetchHandler(fetchOptions);
}

export function fetchCarriersList(key, signal) {
	const fetchOptions = {
		url: `carriers?searchkey=${key ? key : ''}`,//
		method: "GET",
		secure: true,
		signal: signal
		// body: JSON.stringify({searchkey:key})
	};
	return fetchHandler(fetchOptions);
}

export function createBid(data) {
	const fetchOptions = {
		url: "bids/store",
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
	};
	return fetchHandler(fetchOptions);
}

export function bookCarrier(payLoad) {
	const fetchOptions = {
		url: "bids/book_save",
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad),
	};
	return fetchHandler(fetchOptions);
}

export function saveBidRates(data) {
	const fetchOptions = {
		url: 'loads/token_store',
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
	};
	return fetchHandler(fetchOptions);
}

export function rejectLoadBooking(data) {
	const fetchOptions = {
		url: 'load_reject_TT',
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
	};
	return fetchHandler(fetchOptions);
}

export function confirmLoadBooking(data) {
	const fetchOptions = {
		url: 'load_booked_TT',
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
	};
	return fetchHandler(fetchOptions);
}


export function fetchRates(data, loadType) {
	const fetchOptions = {
		url: 'loads/allrates',
		method: "POST",
		secure: true,
		body: JSON.stringify({ offer_no: data, load_type: loadType }),
	};
	return fetchHandler(fetchOptions);
}

export function fetchCarrierDetails(data) {
	const fetchOptions = {
		url: "carriers/carrierinfo",
		method: "POST",
		secure: true,
		body: JSON.stringify({ MC_no: data }),
	};
	return fetchHandler(fetchOptions);
}

export function fetchCarrierData(loadType,data) {
	const fetchOptions = {
		url: `loads/${loadType}/${data}`,
		method: "GET",
		secure: true
	};
	return fetchHandler(fetchOptions);
}

export function fetchBrokers() {
	const fetchOptions = {
		url: `user/fetchbroker`,
		method: "GET",
		secure: true,
		actionType: USERS.FETCH_BROKERS_LIST,
	};
	return dynamicFetchHandler(fetchOptions);
}

export function updateField(field, val) {
	return {
		type: LOAD_DETAILS.UPDATE_FIELD,
		field,
		val,
	};
}

export function fetchTruckInformation(order_type, p_key) {
	const fetchOptions = {
		url: `loads/${order_type}/${p_key}`,
		method: "GET",
		secure: true,
		// body: JSON.stringify({ offer_no: data }),
	};
	return fetchHandler(fetchOptions);
}

export function fetchEquipmenttypesList() {
	const fetchOptions = {
		url: 'carrier/equipmenttypes',
		method: "GET",
		secure: true,
	};

	return fetchHandler(fetchOptions);
}

export function setBrokers(order_no, broker_id) {
	const fetchOptions = {
		url: 'loads/save_broker',
		method: "POST",
		secure: true,
		body: JSON.stringify({ offer_no: order_no, broker_id: broker_id }),
	};
	return fetchHandler(fetchOptions);
	// Need to update below code with API
}

export function deleteBroker(payLoad) {
	const fetchOptions = {
		url: 'delete_broker',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad),
	};
	return fetchHandler(fetchOptions);
	// Need to update below code with API
}

export function saveDifficultyValue(order_no, difficulty) {
	const fetchOptions = {
		url: 'loads/difficulty_assign',
		method: "POST",
		secure: true,
		body: JSON.stringify({ offer_no: order_no, difficulty: difficulty }),
	};
	return fetchHandler(fetchOptions);
}

export function sendMail(payload) {
	const fetchOptions = {
		url: 'sendmail',
		method: "POST",
		secure: true,
		body: JSON.stringify(payload)
	};
	return fetchHandler(fetchOptions);
	// console.log(payload);
	// return {
	// 	code: 200,
	// 	message: "success",
	// 	description: "In Progress - Mail has been sent.",
	// };
}

export function saveChariotRate(url, data) {
	// Need to update below code with API
	return {
		code: 200,
		message: "success",
		description: "Chariot Rate has been Set",
	};
}

export function saveAvailableDate(url, startDate, endDate) {
	// Need to update below code with API
	// console.log(startDate, endDate);
	return {
		code: 200,
		message: "success",
		description: "Dates has been Set",
	};
}

export function fetchTags() {
	const fetchOptions = {
		url: `tags`,
		method: "get",
		secure: true,
	};

	return fetchHandler(fetchOptions);
}

export function saveTags(orderNo, tagsId) {
	// console.log(JSON.stringify({order_offer_id: orderNo, tags_id: tagsId}))
	const fetchOptions = {
		url: 'save-order-tags',
		method: "POST",
		secure: true,
		body: JSON.stringify({ order_offer_id: orderNo, tags_id: tagsId })
	};

	return fetchHandler(fetchOptions);

	// return {
	// 	code: 200,
	// 	message: "success",
	// 	description: "Tags has been Set",
	// };
}


export function fetchAverageCostLane(payLoad) {
	const fetchOptions = {
		url: 'loads/averagecost',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);

	// return {
	// 	code: 200,
	// 	message: "success",
	// 	description: "Average Cost has been Set",
	// };
}

export function fetchAverageCostLaneDetails(payLoad) {
	const fetchOptions = {
		url: 'loads/average_cost_for_days',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);

	// return {
	// 	code: 200,
	// 	message: "success",
	// 	description: "Average Cost has been Set",
	// };
}

export function saveCarrierInfoData(payLoad) {
	const fetchOptions = {
		url: 'carrier/carrierInfo_store',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);

	// return {
	// 	code: 200,
	// 	message: "success",
	// 	description: "Average Cost has been Set",
	// };
}

export function fetchInsuranceDetails(MCNo) {
	const fetchOptions = {
		url: 'loads/get_insurance',
		method: "POST",
		secure: true,
		body: JSON.stringify({ mc_number: MCNo })
	};

	return fetchHandler(fetchOptions);
}

export function saveInsuranceDetails(payLoad) {
	const fetchOptions = {
		url: 'loads/store_insurance',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);
}

export function saveTargetMaxRateValues(payLoad) {
	const fetchOptions = {
		url: 'loads/store_multiple_rates',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);
}

export function savePostedTruck(payLoad) {
	const fetchOptions = {
		url: 'loads/savetruckposted',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);
}

export function fetchBidCount(payLoad) {
	const fetchOptions = {
		url: 'get_bid_count',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);
}

export function fetchPassCount(payLoad) {
	const fetchOptions = {
		url: 'get_pass_count',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);
}

export function fetchSnipperDashboardCount(payLoad) {
	const fetchOptions = {
		url: 'get_count_snipper',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);
}

export function fetchSnipperBookedLoads(payLoad) {
	const fetchOptions = {
		url: 'snipperlist_booked',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);
}

/*------------------------ Details Page APIs ------------------------------------------------------*/

export function fetchDeliveryStatus(offerNo, loadType) {
	const fetchOptions = {
		url: 'loads/delivery_status',
		method: "POST",
		secure: true,
		body: JSON.stringify({ offer_no: offerNo, load_type: loadType })
	};

	return fetchHandler(fetchOptions);
}

export function fetchLoadDeliveryStatus(payload) {
	const fetchOptions = {
		url: 'get_track_statusdetails',
		method: "POST",
		secure: true,
		body: JSON.stringify(payload)
	};

	return fetchHandler(fetchOptions);
}

export function saveLoadDeliveryStatus(payload) {
	const fetchOptions = {
		url: 'save_loadtrack_status',
		method: "POST",
		secure: true,
		body: JSON.stringify(payload)
	};

	return fetchHandler(fetchOptions);
}

export function fetchOrderStatus(payload) {
	const fetchOptions = {
		url: 'get_loadtrack_status',
		method: "POST",
		secure: true,
		body: JSON.stringify(payload)
	};

	return fetchHandler(fetchOptions);
}

export function fetchCurrentMapLocation(payload) {
	const fetchOptions = {
		url: 'get_track_mapdetails',
		method: "POST",
		secure: true,
		body: JSON.stringify(payload)
	};

	return fetchHandler(fetchOptions);
}

export function fetchCustomerActivityDetails(payLoad) {
	const fetchOptions = {
		url: 'loads/customer_activity',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);
}



export function fetchBillingDetails(offerNo) {
	const fetchOptions = {
		url: 'loads/billing_details',
		method: "POST",
		secure: true,
		body: JSON.stringify({ offer_no: offerNo })
	};

	return fetchHandler(fetchOptions);
}

export function fetchSpecialNotes(offerNo) {
	const fetchOptions = {
		url: 'loads/get_special_notes',
		method: "POST",
		secure: true,
		body: JSON.stringify({ offer_no: offerNo, load_type: 'order' })
	};

	return fetchHandler(fetchOptions);
}

export function saveSpecialNotes(payLoad) {
	const fetchOptions = {
		url: 'loads/store_special_notes',
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);
}


export function fetchMapData(sniperId, loadType) {
	const fetchOptions = {
		url: `viewdetails/${loadType}/${sniperId}`,
		method: "GET",
		secure: true,
		actionType: LOAD_DETAILS.FETCH
	};

	return fetchHandler(fetchOptions);
}



export function fetchWaterfallDetails(offerNo) {
	const fetchOptions = {
		url: 'waterfall_carriers/view',
		method: "POST",
		secure: true,
		body: JSON.stringify({ order_offer_id: offerNo })
	};

	return fetchHandler(fetchOptions);
}

export function saveWaterFallCarrierData(payLoad) {
	const fetchOptions = {
		url: `waterfall_carriers/update`,
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};

	return fetchHandler(fetchOptions);

	// return {
	// 	code: 200,
	// 	message: "success",
	// 	description: "Routes has been Added"
	// };
}


export function fetchWaterFallCarriers(key) {
	const fetchOptions = {
		url: `waterfall_carriers/search?searchkey=${key ? key : ''}`,//
		method: "GET",
		secure: true,
		// body: JSON.stringify({searchkey:key})
	};
	return fetchHandler(fetchOptions);
}

export function removeWaterFallCarriers(carrierId, offerNo) {
	const fetchOptions = {
		url: `waterfall_carriers/delete`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify({ carrier_id: carrierId, order_offer_id: offerNo })
	};
	return fetchHandler(fetchOptions);
}


export function getSelectedTruckEmptyLocationLatLng(payLoad) {
	const fetchOptions = {
		url: `get_locations_with_latlong`,
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};
	return fetchHandler(fetchOptions);
}

export function getNearbyLocations(payLoad) {
	const fetchOptions = {
		url: `get_locations`,
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};
	return fetchHandler(fetchOptions);
}

export function saveDriverData(payLoad) {
	const fetchOptions = {
		url: `savedriverdata`,
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};
	return fetchHandler(fetchOptions);
}

export function waterfallCarrierLoadBookStatus(payLoad) {
	const fetchOptions = {
		url: `waterfall_carriers`,
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};
	return fetchHandler(fetchOptions);
}


/*----------------------------------------------------------------------------------------------------*/
/*------------------------------UPLOAD DOCUMENTS------------------------------------------------------*/
export function uploadFilesonServer(payLoad) {
	const fetchOptions = {
		url: `viewdetail/document_add`,
		// mode: 'no-cors',
		method: "POST",
		headers: {
			"Content-Type": "multipart/form-data"
		},
		secure: true,
		body: payLoad
	};
	return fetchHandler(fetchOptions);
	// 	return {
	// 	code: 200,
	// 	message: "success",
	// 	description: "Documents Uploaded Successfully"
	// };
}

/*----------------------------------------------------------------------------------------------------*/
/*-------------------------------SPLIT SCREEN API-----------------------------------------------------*/

export function fetchSplitScreenData(payLoad) {
	const fetchOptions = {
		url: `get_load_matching_carrierlists`,
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};
	return fetchHandler(fetchOptions);
}



/*----------------------------------------------------------------------------------------------------*/
/*------------------------- CARRIER USERS PAGE API ---------------------------------------------------*/
export function updateCarrierUserData(payLoad) {
	const fetchOptions = {
		url: `updateCarrierData`,
		method: "POST",
		secure: true,
		body: JSON.stringify(payLoad)
	};
	return fetchHandler(fetchOptions);
}

export function fetchCarrierUserData(id) {
	const fetchOptions = {
		url: `carrieruser/edit/${id}`,
		method: "GET",
		secure: true,
	};
	return fetchHandler(fetchOptions);
}

export function fetchNewCarrierUserData(page,limit,key) {
	const fetchOptions = {
		url: `/carrierusers?page=${page}&limit=${limit}&order_by=icc_mc_nbr&order=desc&searchkey=${key}`,
		method: "GET",
		secure: true,
	};
	return fetchHandler(fetchOptions);
}

/*----------------------------------------------------------------------------------------------------*/