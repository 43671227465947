import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, destroy } from "redux-form";
import { NotificationManager } from "react-notifications";
import { resetPassword, updateActivePage } from "../../../Redux/Action/Auth";
import { renderField } from "../../Utils/Helper";
import { Modal } from "react-bootstrap";
import { fields } from "./Helper";

class ChangePassword extends Component {
	state = {
		submitting: false
	};

	handleSubmit = (values) => {
		this.setState({ submitting: true });
		const successHandler = (res) => {
			this.setState({ submitting: false });
			if (200 <= res.status && res.status < 300) {
				this.props.updateActivePage("showChangePasswordModal", false);
				this.props.destroy("resetPassword");
				NotificationManager.success("Password updated", "", 5000);
			} else {
				res.json().then((values) => {
					NotificationManager.error("Invalid password", "", 5000);
				});
			}
		};
		let payload = {
			password: values.currentpassword,
			newpassword: values.password
		};
		this.props.resetPassword(payload, successHandler);
	};

	handleClose = () => {
		this.props.updateActivePage("showChangePasswordModal", false);
		this.props.destroy("resetPassword");
	};

	renderModalBody = () => (
		<Modal.Body>
			{fields.map((field) => (
				<Field
					name={field.name}
					key={field.name}
					autoComplete="off"
					component={renderField}
					type="password"
					label={field.label}
					validate={field.validate}
				/>
			))}
		</Modal.Body>
	);

	renderModalFooter = () => (
		<Modal.Footer className="p-0 mr-3">
			<div className=" align-items-center mb-3">
				<div className=" text-center">
					<button
						disabled={this.state.submitting}
						type="submit"
						onClick={this.props.handleSubmit(this.handleSubmit)}
						className="btn btn-primary text-uppercase login-btn w-100 mb-2">
						Submit
					</button>
				</div>
			</div>
		</Modal.Footer>
	);

	render() {
		return (
			<form>
				<Modal size="sm" show={this.props.showModal} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Change Password</Modal.Title>
					</Modal.Header>
					{this.renderModalBody()}
					{this.renderModalFooter()}
				</Modal>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	showModal: state.Auth.showChangePasswordModal
});

const mapDispatchToProps = {
	resetPassword,
	updateActivePage,
	destroy
};

export default reduxForm({
	form: "resetPassword"
})(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ChangePassword)
);
