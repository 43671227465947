import { CARRIERUSERS } from "../ActionTypes";

const initialState = {
	
};

export default function(state = initialState,action) {
	switch (action.type) {
		case CARRIERUSERS.FETCH_CARRIERUSERS_LIST:
			return {
				...state,
				list: action.payload
			};
		case CARRIERUSERS.FETCH_CARRIERUSER_DETAILS:
			return {
				...state,
				details: action.payload
			};
		case CARRIERUSERS.FETCH_CARRIERUSER_ROLES:
			return {
				...state,
				roles: action.payload
			};
		case CARRIERUSERS.FETCH_DIVISIONS:
				return {
					...state,
					divisions: action.payload
				};
		case CARRIERUSERS.UPDATE_FIELD:
			return {
				...state,
				[action.field]: action.val
			};
		default:
			return state;
	}
}
