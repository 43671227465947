export const CASE_FILE = {
	CREATE: "CREATE",
	UPDATE: "UPDATE",
	DELETE: "DELETE",
	FETCH: "FETCH",
	LIST: "LIST",
	UPLOAD: "UPLOAD",
	IDENTIFY: "IDENTIFY",
	IDENTIFY_DATAPOINTS: "IDENTIFY_DATAPOINTS",
	SAVE_DRAFT: "SAVE_DRAFT",
	UPDATE_FIELD: "UPDATE_FIELD",
	UPDATE_STATUS: "UPDATE_STATUS",
	FETCH_FILES: "FETCH_FILES",
	FETCH_DOCUMENTS: "FETCH_DOCUMENTS",
	UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
	DELETE_FILES: "DELETE_FILES",
	CANCECL_UPLOAD: "CANCECL_UPLOAD",
	FETCH_DOCUMENT_TYPES: "FETCH_DOCUMENT_TYPES",
	FETCH_CASE_STATUS: "FETCH_CASE_STATUS",
	FETCH_DATAPOINTS: "FETCH_DATAPOINTS",
	UPDATE_DATAPOINT: "UPDATE_DATAPOINT",
	FETCH_EXTRACTION_PROGRESS: "FETCH_EXTRACTION_PROGRESS",
	FETCH_CHECKLIST: "FETCH_CHECKLIST",
	FETCH_FINAL_REPORT: "FETCH_FINAL_REPORT"
};

export const AUTH = {
	POST_LOGIN_CREDENTIALS: "POST_LOGIN_CREDENTIALS",
	UPDATE_LOGIN_CREDENTIALS: "UPDATE_LOGIN_CREDENTIALS",
	UPDATE_FIELD: "UPDATE_FIELD",
	SET_PASSWORD: "SET_PASSWORD",
	RESET_PASSWORD: "RESET_PASSWORD",
	FORGOT_PASSWORD: "FORGOT_PASSWORD",
	VALIDATE_TOKEN: "VALIDATE_TOKEN"
};

export const LOADS = {
	FETCH_LIST: "FETCH_LIST",
	UPDATE_FIELD: "UPDATE_FIELD",
	STORE_FILTER: "STORE_FILTER",
	FETCH_FILTER: "FETCH_FILTER"
};

export const USERS = {
	FETCH_USERS_LIST: "FETCH_USERS_LIST",
	UPDATE_FIELD: "UPDATE_FIELD",
	FETCH_ROLES: "FETCH_ROLES",
	FETCH_DIVISIONS: "FETCH_DIVISIONS",
	CREATE_NEW: "CREATE_NEW",
	UPDATE: "UPDATE",
	FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
	FETCH_BROKERS_LIST: "FETCH_BROKERS_LIST"
};

export const CARRIERUSERS = {
	FETCH_CARRIERUSERS_LIST: "FETCH_CARRIERUSERS_LIST",
	UPDATE_FIELD: "UPDATE_FIELD",
	FETCH_CARRIERUSER_ROLES: "FETCH_CARRIERUSER_ROLES",
	FETCH_DIVISIONS: "FETCH_DIVISIONS",
	// UPDATE: "UPDATE",
	FETCH_CARRIERUSER_DETAILS: "FETCH_CARRIERUSER_DETAILS",
};

export const LOAD_DETAILS = {
	FETCH: "FETCH",
	CREATE_BID: "CREATE_BID",
	CREATE_PASS: "CREATE_PASS",
	FETCH_CARRIER_LIST: "FETCH_CARRIER_LIST",
	FETCH_EQUIPMENT_TYPES_LIST: "FETCH_EQUIPMENT_TYPES_LIST",
	UPDATE_FIELD: "UPDATE_FIELD",
	SET_TARGET_RATE: "SET_TARGET_RATE",
	CHANGE_BOOKING_STATUS: "CHANGE_BOOKING_STATUS"
};

export const TRUCK_DETAILS = {
	FETCH: "FETCH",
	CREATE_NEW_TRUCK: "CREATE_NEW_TRUCK",
	ADD_TRUCKEMPTYLOCATION: "ADD_TRUCKEMPTYLOCATION",
	RESET_CLONE: "RESET_CLONE",
	SET_TRUCKEMPTYLOCATIONS: "SET_TRUCKEMPTYLOCATIONS",
	REMOVE_TRUCK: "REMOVE_TRUCK",
	CHANGE_ACTIVE_TAB: "CHANGE_ACTIVE_TAB",
	ADD_MCNO: "ADD_MCNO",
	CHANGE_STATUS: "CHANGE_STATUS",
	SET_CARRIERINFO: "SET_CARRIERINFO",
	SET_COLLAPSIBLE: "SET_COLLAPSIBLE"
};
