import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class ConfirmationModal extends Component {
	render() {
		const { submitting, show, content, close, save } = this.props;
		return (
			<div className="confirm-modal">
				<Modal size="sm" show={show} onHide={close}>
					<Modal.Header closeButton>
						<Modal.Title>Are you sure?</Modal.Title>
					</Modal.Header>
					<Modal.Body>{content}</Modal.Body>
					<Modal.Footer>
						<button disabled={submitting} className="btn btn-danger" onClick={close}>
							NO
						</button>
						<button disabled={submitting} className="btn btn-success" onClick={save}>
							YES
						</button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}
