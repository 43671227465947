import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { rules } from "./../../Utils/Validator";
import { forgotPassword } from "./../../../Redux/Action/Auth";
import AuthWrap from "././AuthWrapper";
import { renderField } from "./Helper";

class ForgotPassword extends Component {
	state = {
		submitting: false
	};

	handleSubmit = (values) => {
		this.setState({ submitting: true });
		const successHandler = (res) => {
			res.json().then((values) => {
				this.setState({ submitting: false });
				if (values.description === "failure") {
					NotificationManager.error(values.errors, "", 5000);
				} else {
					NotificationManager.success(values.description, "", 5000);
					this.props.history.push("/");
				}
			});
		};
		let payload = {
			email: values.email.trim(),
			base_url: `${window.location.origin}/set/password`
		};
		this.props.forgotPassword(payload, successHandler);
	};

	renderLoginForm = () => {
		const { email, required } = rules;
		return (
			<form>
				<Field name="email" component={renderField} type="text" label="Email" validate={[required, email]} />
				<div className=" align-items-center mb-3 mt-3">
					<div className=" text-center">
						<button
							disabled={this.state.submitting}
							type="submit"
							onClick={this.props.handleSubmit(this.handleSubmit)}
							className="btn btn-primary text-uppercase login-btn w-100 mb-2">
							Submit
						</button>
						<p className="pointer" onClick={() => this.props.history.push("/")}>
							Login
						</p>
					</div>
				</div>
			</form>
		);
	};

	render() {
		return <AuthWrap>{this.renderLoginForm()}</AuthWrap>;
	}
}

const mapDispatchToProps = {
	forgotPassword
};

export default reduxForm({
	form: "forgotPassword"
})(
	connect(
		null,
		mapDispatchToProps
	)(ForgotPassword)
);
