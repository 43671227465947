import React, { useState, useEffect } from "react";
import BookModal from "./BookModal";
import PassReasonModal from "./PassReasonModal";
import { createPass, createBid, fetchInsuranceDetails, bookCarrier } from "../../../../../Redux/Action/Dashboard";
import { NotificationManager } from "react-notifications";
import moment from "moment";

export default function BidModule({ CarrierName, MCNo, carrierInfo, Rates, setError, setReload, carrierData, sourceType, dispatcherPhone, contactPhone, contactEmail,setLoadBooked, orderList, loadStatus, viewBookedLoads }) {
  const [showBidModal, setBidModal] = useState(false);
  const [showPassModal, setPassModal] = useState(false);
  const [insuranceDetails, setInsuranceDetails] = useState({});
  const [bookPayload,setBookPayload] = useState({});
  const [bookLoadApiCall,setBookApiCall] = useState(false);

  useEffect(() => {
    if (MCNo) {
      fetchInsuranceDetails(MCNo).then((result) => {
        if (result.message === 'success') {
          setInsuranceDetails((prevState) => ({
            ...prevState,
            mc_number: MCNo,
            carrier_safety_rating: result.data && (result.data.carrier_safety_rating !== null ? result.data.carrier_safety_rating : ''),
            carrier_insurance: result.data && (result.data.carrier_insurance !== null ? result.data.carrier_insurance : '')
          }))
        }
      }).catch(error => {console.log(error)})
    }
  }, [MCNo])

  useEffect(() => {
    let controller =new AbortController();
    if(bookLoadApiCall){
      bookCarrier(bookPayload,controller.signal).then((res) => {//
        if (res.message === "success"){
          setReload(true);
          setLoadBooked((prevState)=>({
            ...prevState,
            isBooked:true,
            offerNo:carrierData.id
          }))
          NotificationManager.success("Booked", "Booking Successful", 5000);
          // loadBooked(carrierData.id);
         
          setBidModal(false)
          setBookApiCall(false);
        } else {
          setBidModal(false)
          if(res.status && res.status === 400 ){
            res.res.json().then((value)=>{
              if(value.description==='failure'){
                NotificationManager.error(value.errors, 'Error', 5000);
              }
            })
            setBookApiCall(false);
          }else{
            if(res.code === 200 && res.message === 'error'){
              const err = Object.keys(res.data);
              err.map((errorField)=>{
                NotificationManager.error(res.data[errorField],'Validation Error',3500)
              })
            }else{
              res.res.json().then((value)=>{
                if(value.description === 'failure'){
                  NotificationManager.error(value.errors, 'Error', 5000);
                }
              }); 
            }           
            setBookApiCall(false);
          }
        }
      }).catch(error => {
        console.log(error);
        setBookApiCall(false);
        });
    }
  
    return () => {
      controller.abort();
    }
  }, [bookLoadApiCall])
  
  const saveAddBidDetails = () => {
    if (!MCNo) {
      NotificationManager.error("No MC No selected.", "Validation Error", 5000);
    } else if (!Rates.chariot_rate) {
      NotificationManager.error("Please add carrier Rate & chariot Rate", "Validation Error", 5000);
      setError("Required");
    } else {
      var payload = {
        type: 'Bid',
        reason: `CHA-${Rates.chariot_rate?Rates.chariot_rate:0}/CAR-${Rates.carrier_rate?Rates.carrier_rate:0}`,
        createdDateTime: moment(Date.now()).format('MM/DD/YYYY HH:mm'),
        carrier_name: CarrierName,
        dispatcher_phone: contactPhone?contactPhone:dispatcherPhone?dispatcherPhone:'NA',
        dispatcher_name: carrierInfo && carrierInfo.contact_name?carrierInfo.contact_name:'NA',
        load_id: orderList,
        load_type: carrierData.load_type,
        mc_number: MCNo,
        broker: (localStorage.getItem("CHARIOT_USER_DATA")
          ? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.id
          : 1),
        quoted_rate: Rates.chariot_rate,
        status: "bid",
        user_id: localStorage.getItem("CHARIOT_USER_DATA")
          ? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.id
          : 1,
        token: JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).token,
      };
      createBid(payload)
        .then((res) => {
          if(res.message==="success"){
            const failed = res.data.failed;
            const success = res.data.sucess;
            if(failed.length>0){
              setReload(true);
              NotificationManager.error(`Loads ${failed.join(', ')} is Already Booked`, "Failed", 3500);
            }
            if(success.length>0){
              setReload(true);
              NotificationManager.success(`Bid Created - ${success.join(',')}`, "Success", 3500);
            }
          }else{
            NotificationManager.error(res.description,`Error`,3500)
          }
        })
        .catch((error) => {console.log(error)});
    }
  };
  const checkPassValidator = () => {
    if (!MCNo) {
      NotificationManager.error("No MC No selected.", "Validation Error", 5000);
    } else {
      setPassModal(true)
    }
  }

  const savePassBidDetails = (reason) => {
    setPassModal(false);
    let payLoad = {
      type: 'Pass',
      reason: reason,
      createdDateTime: moment(Date.now()).format('MM/DD/YYYY HH:mm'),
      broker: (localStorage.getItem("CHARIOT_USER_DATA")
        ? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.id
        : 1),
      carrier_name: CarrierName,
      broker: carrierData.broker,
      dispatcher_phone: contactPhone?contactPhone:dispatcherPhone?dispatcherPhone:'NA',
      dispatcher_name: carrierInfo && carrierInfo.contact_name?carrierInfo.contact_name:'NA',
      mc_number: MCNo,
      user_id: (localStorage.getItem("CHARIOT_USER_DATA")
        ? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.id
        : 1),
      load_id: orderList,
      load_type: carrierData.load_type,
    };

    createPass(payLoad).then((res) => {
      if(res.message==="success"){
        const failed = res.data.failed;
        const success = res.data.sucess;
        if(failed.length>0){
          setReload(true);
          NotificationManager.error(`Pass Failed for - ${failed.join(', ')}`, "Failed", 3500);
        }
        if(success.length>0){
          setReload(true);
          NotificationManager.success(`Pass Created - ${success.join(',')}`, "Success", 3500);
        }
      }else{
        NotificationManager.error(res.description,`Error`,3500);
      }
      // if (result.message === 'success') {
      //   setReload(true);
      //   console.log(res.data)
      //   NotificationManager.success('Pass Created', 'Success', 5000)
      // }
    }).catch(error => {console.log(error)});
    ;
  };

  const saveBookDetails = (payLoad) => {
    setBookPayload(payLoad);
    setBookApiCall(true)
  };
  const showBookModal = () => {
    if (!MCNo) {
      NotificationManager.error("No MC No selected.","Validation Error",5000);
    } else if (!Rates.chariot_rate) {
      NotificationManager.error("Please add Carrier Rate & Chariot Rate", "Validation Error", 5000);
      setError("Required");
    }else {
      setBidModal(true)
    }
  }

  return <>
    <button
      className={`btn btn-info btn-pill mw-104 m-1 ${viewBookedLoads?'d-none':''}`}
      disabled={loadStatus.toLowerCase().includes("booked")?true:false}
      name="bid"
      onClick={() => saveAddBidDetails()} >
      Add Bid
    </button>
    <br></br>
    <button
      className={`btn btn-success btn-pill mw-104 m-1 ${viewBookedLoads?'d-none':''}`}
      disabled={loadStatus.toLowerCase().includes("booked")?true:false}
      name="book"
      onClick={() => showBookModal()}
      // save={saveBookDetails}
    >
      Book
    </button>
    <button
      className={`btn btn-danger btn-pill mw-104 m-1 ${viewBookedLoads?'d-none':''}`}
      disabled={loadStatus.toLowerCase().includes("booked")?true:false}
      name="pass"
      onClick={() => checkPassValidator()}>
      Pass
    </button>

    <BookModal
      CarrierName={CarrierName}
      MCNo={MCNo}
      carrierInfo={carrierInfo}
      carrierData={carrierData}
      contactEmail={contactEmail}
      insuranceDetails={insuranceDetails}
      sourceType={sourceType?sourceType:'sniper'}
      contactPhone={contactPhone}
      showBookModal={showBidModal}
      Rates={Rates}
      setReload={setReload}
      save={saveBookDetails}
      close={()=>{setBidModal(false);setBookApiCall(false)}}
    />
    <PassReasonModal
      show={showPassModal}
      save={savePassBidDetails}
      close={() => setPassModal(false)}
    />
  </>
};