import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { bidListFields } from "./Helper";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { changeBookingStatus, fetchDetails } from "./../../../Redux/Action/LoadDetails";
import ConfirmationModal from "./../../Utils/ConfirmationModal";
import { renderColumn } from "./../../Utils/Filter";

class BidListTable extends Component {
	state = {
		showPagination: false,
		showConfirmationModal: false,
		modalContent: "",
		bidId: "",
		pageSize: 10,
		nameList: [],
		filtered: [],
		nonFilterColumns: [
			{
				name: "comment",
				placeHolder: "Comment",
				notSortable: true,
				minWidth: 60,
				filter: "reset"
			},
			{
				name: "status",
				placeHolder: "",
				notSortable: true,
				notFilterable: true,
				filter: false,
				minWidth: 60
			}
		]
	};

	componentDidMount() {
		this.setPagination();
	}

	componentDidUpdate() {
		this.setPagination();
	}

	setPagination = () => {
		const { pageSize, showPagination } = this.state;
		if (this.bidTable) {
			let currentDataLength = this.bidTable.getResolvedState().sortedData.length;
			let show = currentDataLength > pageSize;
			if (show !== showPagination) {
				this.setState({ showPagination: show });
			}
		}
	};

	resetFilters = (e) => {
		e.preventDefault();
		this.setState({ filtered: [] });
	};

	handleDate = (date, updateFilter, field) => {
		const { filtered } = this.state;
		if (date) {
			updateFilter(date);
		} else {
			let newFilter = filtered.filter((val) => val.id !== field.name);
			this.setState({ filtered: newFilter });
		}
	};

	handleDateRange = (date, updateFilter, field, filter, tableRef) => {
		if (tableRef && filter && filter.value && filter.value.length) {
			tableRef.cancelFocusInput();
			tableRef.setOpen(false);
		}
		const { filtered } = this.state;
		if (date) {
			let dateRangeArray = [];

			if (!filter) {
				dateRangeArray.push(date);
			} else {
				dateRangeArray = filter.value;

				filter.value.length < 2
					? dateRangeArray[0] > date
						? dateRangeArray.unshift(date)
						: dateRangeArray.push(date)
					: date <= dateRangeArray[1]
						? (dateRangeArray[0] = date)
						: (dateRangeArray[1] = date);
			}

			updateFilter(dateRangeArray);
		} else {
			let newFilter = filtered.filter((val) => val.id !== field.name);
			this.setState({ filtered: newFilter });
		}
	};

	renderCell = (field, props) => {
		switch (field.name) {
			case "comment":
				return props.value ? this.renderCommentDetails(props) : "-----------";
			case "edit":
				return this.renderEditIcon(props);
			case "status":
				return this.renderStatusIcon(props);
			case "eta_for_pickup":
			case "empty_time":
				return props.value && !isNaN(new Date(props.value)) ? (
					<div>{moment(new Date(props.value)).format("HH:mm")}</div>
				) : (
					"-----------"
				);
			case "created_at":
				return props.value ? (
					<div>
						{moment
							.utc(props.value)
							.local()
							.format("MM/DD/YYYY HH:mm")}
					</div>
				) : (
					"-----------"
				);
			case "driver_empty":
				return <div className="pt-1 pb-1">{props.value ? "true" : "false"}</div>;
			default:
				return <div className="pt-1 pb-1">{props.value ? props.value : "-----------"}</div>;
		}
	};

	renderCommentDetails = (props) => {
		return (
			<OverlayTrigger placement="left" overlay={this.renderCommentTooltip(props.value)}>
				<a className="view-cmnt">View</a>
			</OverlayTrigger>
		);
	};

	renderCommentTooltip = (comment) => {
		return (
			<Tooltip>
				<span className="basic-list ">{comment}</span>
			</Tooltip>
		);
	};

	changeBookingStatus = (status, id) => {
		this.setState({
			newStatus: status,
			bidId: id,
			modalContent: `Do you really want to ${status ? "book" : "unbook"} this order`,
			showConfirmationModal: true
		});
	};

	handleClose = () => {
		this.setState({ showConfirmationModal: false });
	};

	handleSave = () => {
		this.setState({ statusSubmitting: true });
		let payload = {
			booking_status: this.state.newStatus,
			bid_id: this.state.bidId,
			load_id: this.props.orderId,
			load_type: this.props.loadType
		};
		const successHandler = (res) => {
			this.setState({ statusSubmitting: false, showConfirmationModal: false });
			if (200 <= res.status && res.status < 300) {
				this.props.fetchDetails(`loads/${this.props.loadType}/${this.props.orderId}`);
				NotificationManager.success(`Status updated`, "", 5000);
			} else {
				NotificationManager.error(res.statusText, "", 5000);
			}
		};
		this.props.changeBookingStatus(payload, successHandler);
	};

	renderStatusIcon = (props) => {
		const { orderBooked, token, orderAvailable } = this.props;
		let booked = props.value && props.value === "booked";
		return (
			<div className="pt-1 pb-1 text-center ">
				<button
					disabled={(!booked && orderBooked) || token || (!orderAvailable && !orderBooked)}
					onClick={() => {
						this.changeBookingStatus(booked ? 0 : 1, props.original.id);
					}}
					className={`btn  btn-sm status-btn ${booked ? "btn-danger" : "btn-success"}`}>
					{booked ? "UNBOOK" : "BOOK"}
				</button>
			</div>
		);
	};

	renderEditIcon = (props) => {
		return (
			<div className="text-center ">
				<i className="icon icon-pencil pointer" onClick={() => this.populateValues(props)} />
			</div>
		);
	};

	populateValues = (props) => {
		// To be implemented
		console.log("populateValues ", props);
	};

	columns = [
		...bidListFields.map((field) =>
			renderColumn(field, this.renderCell, this.resetFilters, this.handleDate, this.handleDateRange)
		),
		...this.state.nonFilterColumns.map((field) => renderColumn(field, this.renderCell, this.resetFilters, this.handleDate))
	];

	render() {
		const { pageSize, showPagination, filtered, showConfirmationModal, modalContent, statusSubmitting } = this.state;
		const { loading, data } = this.props;
		console.log("data ", data);
		return (
			<div className="bidlist-container">
				<ConfirmationModal
					show={showConfirmationModal}
					content={modalContent}
					submitting={statusSubmitting}
					close={this.handleClose}
					save={this.handleSave}
				/>
				<ReactTable
					className="table-primary"
					data={data}
					ref={(r) => (this.bidTable = r)}
					pageSize={pageSize}
					columns={this.columns}
					minRows={0}
					showPagination={showPagination}
					showPageSizeOptions={false}
					filterable={true}
					filtered={filtered}
					onFilteredChange={(filtered) => {
						this.setState({ filtered });
					}}
					loading={loading}
					noDataText={loading ? "" : "No results found!"}
					previousText=""
					nextText=""
					getTdProps={(state, rowInfo, column) => {
						return {
							className: column.id === "status" ? `td-${rowInfo.original.status}` : ""
						};
					}}
				/>
			</div>
		);
	}
}

const mapDispatchToProps = {
	changeBookingStatus,
	fetchDetails
};

export default connect(
	null,
	mapDispatchToProps
)(BidListTable);
