import React,{useState,useEffect} from 'react';
import { fetchCarrierUserData } from '../../../Redux/Action/Dashboard';
import Add from './Add';
import UpdateCarrierValuesForm from './UpdateCarrierValuesForm';

function Forms(props) {
  const [userData,setUserData] = useState();
  const [loading,setLoading] = useState(true);
  useEffect(() => {
    if(props.match.params.id){
      fetchCarrierUserData(props.match.params.id).then(res=>{
        // console.log(res);
        setUserData(res.data);
        setLoading(false);
      })
    }
  }, [])
  
  return (
    <div>
        {/* <Add id={props.match.params.id}/> */}
        {!loading && <UpdateCarrierValuesForm user={userData} loading={loading}/>}
    </div>
  )
}

export default Forms