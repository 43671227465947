import { LOAD_DETAILS } from "./../ActionTypes";

const initialState = {
	formEnabled: false,
	showRateModal: false,
	locationLatLng: null,
	currentOrderID: null
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_DETAILS.FETCH:
			return {
				...state,
				load: action.payload
			};
		case LOAD_DETAILS.FETCH_EQUIPMENT_TYPES_LIST:
			return {
				...state,
				equipmentTypesList: action.payload
			};
		case LOAD_DETAILS.UPDATE_FIELD:
			return {
				...state,
				[action.field]: action.val
			};
		default:
			return state;
	}
}
