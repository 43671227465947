import React, { useState, useEffect } from 'react';
import { fetchMapData, fetchLoadDeliveryStatus, fetchTruckInformation, fetchCurrentMapLocation } from '../../../../Redux/Action/Dashboard';
import RouteDetails from './RouteDetails';
import MapRoute from './RouteMap'
import moment from 'moment';
import PreloaderInner from "../../../Utils/PreloaderInner";

const Loadstatus = ['Dispatched', 'Arrived @ Shipper', 'Pickup', 'Arrived @ Delivery', 'Delivery']
function ProgressStatus({ sniperId, loadType, carrierData }) {
  const [expandMap, setExpandMap] = useState(false);
  const [isLoader, SetLoader] = useState(false);
  const [sniperDetails, setSniperDetails] = useState(
    {
      sniperId: sniperId,
      loadType: loadType
    }
  )
  const [markers,setMarkers] = useState();
  const [loadNumber,setLoadNumber] = useState('');
  const [orderNumber,setOrderNumber] = useState('');
  const [load, setLoad] = useState({});
  const [statusArray,setStatusArray] = useState([])
  const [planned,setPlanned] = useState('');
  const [dispatched,setDispatched] = useState('');
  const [ArrivedAtShipper,setArrivedAtShipper] = useState('');
  const [PickUp,setPickUp] = useState('');
  const [Delivered, setDelivered] = useState('');
  const [ArrivedAtDelivery,setArrivedAtDelivery] = useState('');
  const [Cancelled,setCancelled] = useState('');
  const [SystemStopped,setSystemStopped] = useState('');
  const [DriverStopped,setDriverStopped] = useState('');
  const [currentPosition,setCurrentPosition] = useState([]);
  const [location,setLocation] = useState();

  var timings = [carrierData.pickTime, carrierData.pickEndTime, carrierData.pickEndTime, carrierData.deliveryTime, carrierData.delvEndTime]

  useEffect(() => {
    if(loadType && sniperId){
      fetchTruckInformation(loadType,sniperId).then((result)=>{
      if(result.message==="success"){
        setLoadNumber(result.data.load_nbr)
        setOrderNumber(result.data.id)
      }
    }).catch(err=>{console.log(err)})}
  }, [sniperDetails])
  
  useEffect(() => {
    if(carrierData.load_nbr){
      var payload = {
        load_nbr:carrierData.load_nbr,
        order_nbr:carrierData.id
      }
      fetchLoadDeliveryStatus(payload).then(result=>{
        if(result.message==='success'){
          result.data.map(m=>{
            const statusUpdate = JSON.parse(m.latest_status);
            statusUpdate.code === "CL"?setPlanned(statusUpdate.timestamp):'';
            statusUpdate.code === "ST"?setDispatched(statusUpdate.timestamp):'';
            statusUpdate.code === "PE"?setArrivedAtShipper(statusUpdate.timestamp):'';
            statusUpdate.code === "PX"?setPickUp(statusUpdate.timestamp):'';
            statusUpdate.code === "DE"?setArrivedAtDelivery(statusUpdate.timestamp):'';
            statusUpdate.code === "DX"?setDelivered(statusUpdate.timestamp):'';
            statusUpdate.code === "CA"?setCancelled(statusUpdate.timestamp):'';
            statusUpdate.code === "SD"?setDriverStopped(statusUpdate.timestamp):'';
            statusUpdate.code === "SS"?setSystemStopped(statusUpdate.timestamp):'';
          })
        }
      }).catch(err=>console.log(err));

      fetchCurrentMapLocation(payload).then(result=>{
        if(result.message==='success'){
          setMarkers(result.data)
        }
      }).catch(err=>console.log(err));
    }
  }, [loadNumber,orderNumber,carrierData])

  
  useEffect(()=>{
    if(loadNumber){
      var inter = setInterval(()=>{
        var payload = {
          load_nbr:loadNumber,
          order_nbr:orderNumber
        }
        fetchCurrentMapLocation(payload).then(result=>{
          if(result.message==='success'){
            setMarkers(result.data)
          }
        }).catch(err=>console.log(err));
      },60000)
    }
    return()=>{
      clearInterval(inter)
    }
  },[loadNumber,orderNumber])

  useEffect(() => {
    if(markers){
      let data = JSON.parse(markers.latest_location)
      setLocation(data)
    }
  }, [markers])
  


  useEffect(() => {
    SetLoader(true)
    if (sniperId && loadType) {
      fetchMapData(sniperId, loadType).then((result) => {
        if (result.message === 'success') {
          setLoad(result)
          SetLoader((prevState) => prevState = false)
        }
      }).catch((error) => {
        // console.log(error)
      })
    }
  }, [sniperId, loadType]);
  

  const toggleMapView = () => {
    setExpandMap((prevValue) => prevValue = !prevValue)
  };
  return (
    <div className="progress-Section">
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-4">
            <div className="soudest-info d-flex flex-row align-items-center">
              <div className="sourceBox px-3">
                <h5 className="mb-0">
                  {location?location.city.toUpperCase():carrierData && carrierData.current_city && carrierData.current_city}
                  <span className="h2 d-block">{location?`${location.state}, USA`:carrierData && carrierData.current_state && carrierData.current_state + ', USA'}</span>
                </h5>
              </div>
              <div className="px-3">
                <img src="images/arrow.svg" alt="" />
              </div>
              <div className="destinationBox px-3">
                <h5 className="mb-0">
                  {carrierData && carrierData.final_city?`${carrierData.final_city}`:''} <span className="h2 d-block">{carrierData && carrierData.final_state?`${carrierData.final_state}, USA`:''}</span>
                </h5>
              </div>
              <div className="px-3 border-left">
                <h5 className="mb-0 text-dark">
                  Estimated Time{" "}
                  <span className="h2 d-block">
                    {carrierData && carrierData.delvEndTime?`On ${moment(carrierData.delvEndTime).format('MM/DD/YYYY HH:mm')}`:''}
                  </span>
                </h5>
              </div>
            </div>
            <ul className="status-timeline">
              
            <li className={`${planned? 'current' : ''}`}>
                  <div className="">
                    <h5 className={`${Cancelled?'text-strike':''}`}>{"Planned"}
                    {/* <del>{"Planned"}</del> */}
                      {planned && <span className="">On {planned.split(' ')[0]} @ {planned.split(' ')[1]} {planned.split(' ')[2]} </span>}
                    </h5>
                  </div>
                </li>
                <li className={`${dispatched? 'current' : ''}`}>
                  <div className="">
                    <h5 className={`${Cancelled?'text-strike':''}`}>
                      {"Dispatched"}
                      {dispatched && <span className="">On {dispatched.split(' ')[0]} @ {dispatched.split(' ')[1]} {dispatched.split(' ')[2]}</span>}
                    </h5>
                  </div>
                </li>
                <li className={`${ArrivedAtShipper ? 'current' : ''}`}>
                  <div className="">
                    <h5 className={`${Cancelled?'text-strike':''}`}>
                      {"Arrived@Shipper"}
                      {ArrivedAtShipper && <span className="">On {ArrivedAtShipper.split(' ')[0]} @ {ArrivedAtShipper.split(' ')[1]} {ArrivedAtShipper.split(' ')[2]}</span>}
                    </h5>
                  </div>
                </li>
                <li className={`${PickUp ? 'current' : ''}`}>
                  <div className="">
                    <h5 className={`${Cancelled?'text-strike':''}`}>
                      {"PickUp"}
                      {PickUp && <span className="">On {PickUp.split(' ')[0]} @ {PickUp.split(' ')[1]} {PickUp.split(' ')[2]}</span>}
                    </h5>
                  </div>
                </li>
                <li className={`${ArrivedAtDelivery ? 'current' : ''}`}>
                  <div className="">
                    <h5 className={`${Cancelled?'text-strike':''}`}>
                      {"Arrived@Delivery"}
                      {ArrivedAtDelivery && <span className="">On {ArrivedAtDelivery.split(' ')[0]} @ {ArrivedAtDelivery.split(' ')[1]} {ArrivedAtDelivery.split(' ')[2]}</span>}
                    </h5>
                  </div>
                </li>
                <li className={`${Delivered ? 'current' : ''}`}>
                  <div className="">
                    <h5 className={`${Cancelled?'text-strike':''}`}>
                      {"Delivered"}
                      {Delivered && <span className="">On {Delivered.split(' ')[0]} @ {Delivered.split(' ')[1]} {Delivered.split(' ')[2]}</span>}
                    </h5>
                  </div>
                </li>
              
            </ul>
            <div className="">
              <h5 className={`${Cancelled ? 'text-danger' : 'd-none'}`}>
                {"This Load has been Cancelled"}
              </h5>
            </div>
  
          </div>
          {/* ----------------------------------MAP SECTION------------------------------------------- */}
          <div className="col-12 col-md-6 col-xl-8">
            <div className={`route-map ${expandMap ? "expanded" : ""}`}>
              <PreloaderInner hidden={!isLoader} />
              {load && load.data &&
                <React.Fragment>
                {SystemStopped?<h4 className='text-danger font-weight-bold text-center'>Tracking stopped by system</h4>:''}
                {DriverStopped?<h4 className='text-danger font-weight-bold text-center'>Tracking stopped by Driver</h4>:''}
                {markers?'':<h4 className='text-danger font-weight-bold text-center'>Tracking for this load has not yet started</h4>}
                  <MapRoute load={load} markers={markers} setCurrentPosition={setCurrentPosition}/>
                  <div className="size-control pointer" onClick={() => { toggleMapView() }}>
                    {expandMap ? <i className="icon icon-shrink" /> : <i className="icon icon-expand" />}
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
          {/* ----------------------------------MAP SECTION ENDS------------------------------------------- */}
        </div>
      </div>
    </div>
  )
}
export default ProgressStatus
