import React, { Component } from "react";
import ReactTable from "react-table";
import { passListFields } from "./Helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { renderColumn } from "./../../Utils/Filter";
import moment from "moment";

export default class PassListTable extends Component {
	state = {
		showPagination: false,
		pageSize: 10,
		nameList: [],
		filtered: [],
		nonFilterColumns: [
			{
				name: "reason",
				placeHolder: "Pass Reason",
				notSortable: true,
				minWidth: 60,
				filter: "reset"
			}
		]
	};

	componentDidUpdate() {
		const { pageSize, showPagination } = this.state;

		if (this.passTable) {
			let currentDataLength = this.passTable.getResolvedState().sortedData.length;
			let show = currentDataLength > pageSize;
			if (show !== showPagination) {
				this.setState({ showPagination: show });
			}
		}
	}

	handleDateRange = (date, updateFilter, field, filter, tableRef) => {
		if (tableRef && filter && filter.value && filter.value.length) {
			tableRef.cancelFocusInput();
			tableRef.setOpen(false);
		}
		const { filtered } = this.state;
		if (date) {
			let dateRangeArray = [];

			if (!filter) {
				dateRangeArray.push(date);
			} else {
				dateRangeArray = filter.value;

				filter.value.length < 2
					? dateRangeArray[0] > date
						? dateRangeArray.unshift(date)
						: dateRangeArray.push(date)
					: date <= dateRangeArray[1]
						? (dateRangeArray[0] = date)
						: (dateRangeArray[1] = date);
			}

			updateFilter(dateRangeArray);
		} else {
			let newFilter = filtered.filter((val) => val.id !== field.name);
			this.setState({ filtered: newFilter });
		}
	};

	resetFilters = (e) => {
		e.preventDefault();
		this.setState({ filtered: [] });
	};

	renderCell = (field, props) => {
		switch (field.name) {
			case "reason":
				return this.renderReasonDetails(props);
			case "created_at":
				return props.value ? (
					<div>
						{moment
							.utc(props.value)
							.local()
							.format("MM/DD/YYYY  HH:mm")}
					</div>
				) : (
					"-----------"
				);
			default:
				return <div className="pt-1 pb-1">{props.value ? props.value : "-----------"}</div>;
		}
	};

	renderReasonDetails = (props) => {
		return (
			<OverlayTrigger placement="left" overlay={this.renderReasonTooltip(props.value)}>
				<a className="view-cmnt">View</a>
			</OverlayTrigger>
		);
	};

	renderReasonTooltip = (comment) => {
		return (
			<Tooltip>
				<span className="basic-list ">{comment}</span>
			</Tooltip>
		);
	};

	columns = [
		...passListFields.map((field) => renderColumn(field, this.renderCell, this.resetFilters, null, this.handleDateRange)),
		...this.state.nonFilterColumns.map((field) => renderColumn(field, this.renderCell, this.resetFilters))
	];

	render() {
		const { pageSize, showPagination, filtered } = this.state;
		const { loading } = this.props;
		return (
			<div>
				<ReactTable
					className="table-primary"
					data={this.props.data}
					ref={(r) => (this.passTable = r)}
					pageSize={pageSize}
					columns={this.columns}
					minRows={0}
					showPagination={showPagination}
					showPageSizeOptions={false}
					filterable={true}
					filtered={filtered}
					onFilteredChange={(filtered) => {
						this.setState({ filtered });
					}}
					loading={loading}
					noDataText={loading ? "" : "No results found!"}
					previousText=""
					nextText=""
					getTdProps={(state, rowInfo, column) => {
						return {
							className: column.id === "status" ? `td-${rowInfo.original.status}` : ""
						};
					}}
				/>
			</div>
		);
	}
}
