import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { rules } from "./../../Utils/Validator";
import { setPassword, validateToken } from "./../../../Redux/Action/Auth";
import { renderField } from "./Helper";
import AuthWrap from "././AuthWrapper";

class SetPassword extends Component {
	state = {
		submitting: true
	};

	componentDidMount = () => {
		localStorage.removeItem("CHARIOT_USER_DATA");
		const successHandler = (res) => {
			res.json().then((values) => {
				if (values.description === "failure") {
					NotificationManager.error(values.errors, "", 5000);
				} else {
					this.setState({ submitting: false });
				}
			});
		};
		this.props.validateToken({ type: 1, token: window.location.search.slice(7) }, successHandler);
	};

	handleSubmit = (values) => {
		this.setState({ submitting: true });
		const successHandler = (res) => {
			res.json().then((values) => {
				this.setState({ submitting: false });
				if (values.description === "failure") {
					NotificationManager.error(values.errors, "", 5000);
				} else {
					NotificationManager.success("Password updated", "", 5000);
					this.props.history.push("/");
				}
			});
		};
		let payload = {
			token: window.location.search.slice(7),
			password: values.password,
			password_confirmation: values.confirmpassword
		};
		this.props.setPassword(payload, successHandler);
	};

	renderSetPasswordForm = () => {
		const { required, minLength6, passwordsMatch } = rules;
		return (
			<form>
				<Field
					name="password"
					component={renderField}
					type="password"
					label="Password"
					validate={[required, minLength6, passwordsMatch]}
				/>
				<Field
					name="confirmpassword"
					component={renderField}
					type="password"
					label="Confirm Password"
					validate={[required, minLength6, passwordsMatch]}
				/>
				<div className=" align-items-center mb-3 mt-3">
					<div className=" text-center">
						<button
							disabled={this.state.submitting}
							type="submit"
							onClick={this.props.handleSubmit(this.handleSubmit)}
							className="btn btn-primary text-uppercase login-btn w-100 mb-2">
							Submit
						</button>
					</div>
				</div>
			</form>
		);
	};

	render() {
		return <AuthWrap>{this.renderSetPasswordForm()}</AuthWrap>;
	}
}

const mapDispatchToProps = {
	setPassword,
	validateToken
};

export default reduxForm({
	form: "setPassword"
})(
	connect(
		null,
		mapDispatchToProps
	)(SetPassword)
);
