import { TRUCK_DETAILS } from "../ActionTypes";

const randomInitialNo = Math.random().toString(36).substr(2, 9);
const initialState = {
	truckCollection: [
		{
			truckId: randomInitialNo,
			MCNo: "",
			CarrierName: "",
			spot: true,
			go: true,
			hot: true,
			carrier_name: "",
			dispatcher_email: "",
			contact_name: "",
			carrier_phone: "",
			home_city_state: "",
			available_date_start: "",
			available_date_end: "",
			truck_empty_location: "",
			truck_type: 'Van',
			SearchCarrierName: "",
			isCloned: false,
			longitude: "",
			latitude: "",
			isCollapse: false
		}],
	activeTruckTab: randomInitialNo,
	activeTruckTabDetail: { truckId: randomInitialNo, MCNo: "", CarrierName: "", spot: true, go: true, hot: true, carrier_name: "", dispatcher_email: "", contact_name: "", carrier_phone: "", home_city_state: "", available_date_start: "", available_date_end: "", truck_empty_location: "", truck_type: 'Van', SearchCarrierName: "", isCloned: false, longitude: "", latitude: "", isCollapse: false }
};

export default function (state = initialState, action) {
	switch (action.type) {
		case TRUCK_DETAILS.FETCH:
			return {
				...state,
				load: action.payload
			};
		case TRUCK_DETAILS.CREATE_NEW_TRUCK:
			return {
				...state,
				truckCollection: state.truckCollection.concat({ "truckId": Math.random().toString(36).substr(2, 9), "MCNo": "", "CarrierName": "", "SearchCarrierName": "", spot: true, go: true, hot: true, dispatcher_email: "", contact_name: "", carrier_phone: "", home_city_state: "", available_date_start: "", available_date_end: "", truck_empty_location: "", truck_type: "Van", isCloned: false, longitude: "", longitude: "", isCollapse: false })
			};
		case TRUCK_DETAILS.ADD_TRUCKEMPTYLOCATION:
			const existingData = state.truckCollection.filter(m => m.truckId === action.payload)[0];
			const newTabId = Math.random().toString(36).substr(2, 9);
			var clonedTab = { ...existingData, truckId: newTabId, isCloned: true, isCollapse: false };
			return {
				...state,
				truckCollection: state.truckCollection.concat(clonedTab),
				activeTruckTabDetail: clonedTab
			};
		case TRUCK_DETAILS.REMOVE_TRUCK:
			var objIndex = state.truckCollection.findIndex(x => x.truckId === action.payload);
			var currentObj = state.truckCollection.filter((data) => data.truckId !== action.payload)[0];

			var newTruckCollection = state.truckCollection.length > 0 ? state.truckCollection.filter((data) => data.truckId !== action.payload) : state.truckCollection;
			var lastObj = newTruckCollection.slice(-1)[0];
			var truckTabId = (state.activeTruckTab === action.payload ? newTruckCollection[objIndex].truckId : (currentObj.truckId === lastObj.truckId ? lastObj.truckId : state.activeTruckTab));
			return {
				...state,
				truckCollection: newTruckCollection,
				activeTruckTab: truckTabId,
				activeTruckTabDetail: newTruckCollection.filter(m => m.truckId === truckTabId)[0]
			};


		case TRUCK_DETAILS.CHANGE_ACTIVE_TAB:
			return {
				...state,
				activeTruckTab: action.payload,
				activeTruckTabDetail: state.truckCollection.filter(m => m.truckId === action.payload)[0]
			};
		case TRUCK_DETAILS.ADD_MCNO:
			return {
				...state,
				truckCollection: state.truckCollection.map(el =>
					el.truckId === action.truckId ? { ...el, MCNo: action.MCNo, CarrierName: action.CarrierName, SearchCarrierName: action.SearchCarrierName } : el
				)
			};
		case TRUCK_DETAILS.RESET_CLONE:
			return {
				...state,
				truckCollection: state.truckCollection.map(el =>
					el.truckId === action.payload ? {
						...el,
						isCloned: false
					} : el
				)
			};
		case TRUCK_DETAILS.SET_CARRIERINFO:
			var updatedCollection = state.truckCollection.map(el =>
				el.truckId === action.truckId ? {
					...el,
					carrier_name: action.carrierInfo.carrier_name,
					dispatcher_email: action.carrierInfo.dispatcher_email,
					contact_name: action.carrierInfo.contact_name,
					carrier_phone: action.carrierInfo.carrier_phone,
					home_city_state: action.carrierInfo.home_city_state,
					available_date_start: action.carrierInfo.available_date_start,
					available_date_end: action.carrierInfo.available_date_end,
					truck_empty_location: action.carrierInfo.truck_empty_location,
					truck_type: action.carrierInfo.truck_type,
					latitude: action.carrierInfo.latitude,
					longitude: action.carrierInfo.longitude
				} : el
			);
			return {
				...state,
				truckCollection: updatedCollection,
				activeTruckTabDetail: updatedCollection.filter(m => m.truckId === action.truckId)[0]
			};
		case TRUCK_DETAILS.CHANGE_STATUS:
			return {
				...state,
				truckCollection: state.truckCollection.map(el =>
					el.truckId === state.activeTruckTab ? {
						...el,
						[action.status]: !el[action.status]
					} : el
				)
			};
		case TRUCK_DETAILS.SET_TRUCKEMPTYLOCATIONS:
			return {
				...state,
				TruckEmptyLocation: action.payload
			};
		case TRUCK_DETAILS.SET_COLLAPSIBLE:
			return {
				...state,
				truckCollection: state.truckCollection.map(el =>
					el.truckId === action.payload ? {
						...el,
						isCollapse: !el.isCollapse
					} : el
				)
			};

		default:
			return state;
	}
}
