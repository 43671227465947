import { fetchHandler } from "../../Providers/Api";
import { LOAD_DETAILS } from "../ActionTypes";

export function fetchDetails(url) {
	const fetchOptions = {
		url: url,
		method: "GET",
		secure: true,
		actionType: LOAD_DETAILS.FETCH
	};

	return fetchHandler(fetchOptions);
}

export function fetchCarriersList(url, key, successHandler) {
	const fetchOptions = {
		url: `${url}?searchkey=${key ? key : ""}`,
		method: "GET",
		secure: true,
		actionType: LOAD_DETAILS.FETCH_CARRIER_LIST
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function fetchEquipmenttypesList(url) {
	const fetchOptions = {
		url: url,
		method: "GET",
		secure: true,
		actionType: LOAD_DETAILS.FETCH_EQUIPMENT_TYPES_LIST
	};

	return fetchHandler(fetchOptions);
}

export function createBid(url, data, successHandler) {
	const fetchOptions = {
		url: url,
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
		actionType: LOAD_DETAILS.CREATE_BID
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function createPass(data, successHandler) {
	const fetchOptions = {
		url: "orderpass/store",
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
		actionType: LOAD_DETAILS.CREATE_PASS
	};
	return fetchHandler(fetchOptions, successHandler);
}

export function setTargetRate(data, successHandler) {
	const fetchOptions = {
		url: "settargetrate",
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
		actionType: LOAD_DETAILS.SET_TARGET_RATE
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function changeBookingStatus(data, successHandler) {
	const fetchOptions = {
		url: "changebookingstatus",
		method: "POST",
		secure: true,
		body: JSON.stringify(data),
		actionType: LOAD_DETAILS.CHANGE_BOOKING_STATUS
	};

	return fetchHandler(fetchOptions, successHandler);
}

export function updateField(field, val) {
	return {
		type: LOAD_DETAILS.UPDATE_FIELD,
		field,
		val
	};
}
