import React, { useState, useEffect, Component } from "react";
import { Modal } from "react-bootstrap";
import { updateField } from "../../../../../Redux/Action/Dashboard";
import AllCarrierInfoForm from "../AllCarrierInfoForm";

export default function BookModal({ CarrierName, MCNo, showBookModal, save, close, carrierInfo, setReload, insuranceDetails, carrierData, sourceType, contactPhone, contactEmail, Rates }) {

  const handleSubmit = (values) => {
    setReload(true)
    let payLoad = {
      mc_number: MCNo,
      carrier_name: CarrierName,
      carrier_phone:contactPhone?contactPhone:values && (values.dispatcherPhoneNumber ? values.dispatcherPhoneNumber : "NA"),
      carrier_email: contactEmail?contactEmail:values && (values.dispatcherEmail ? values.dispatcherEmail : "NA"),
      location:values && (values.truckLocation?values.truckLocation:'NA'),
      dispatcher_name: values && (values.dispatcherName ? values.dispatcherName : "NA"),
      dispatcher_phone: values && (values.dispatcherPhoneNumber ? values.dispatcherPhoneNumber : "NA"),
      dispatcher_email: values && (values.dispatcherEmail ? values.dispatcherEmail : "NA"),
      dispatcher_phone_ext: values && (values.dispatcherPhoneExternsion ? values.dispatcherPhoneExternsion : "NA"),
      carrier_safety_rating: values && (values.carrierSafetyRating?values.carrierSafetyRating:'NA'),
      carrier_insurance: values && (values.carrierInsurance ? values.carrierInsurance : "NA"),
      driver_name: values && (values.driverName ? values.driverName : "NA"),
      driver_phone: values && (values.driverPhoneNumber ? values.driverPhoneNumber : "NA"),
      driver_phone_ext: values && (values.driverPhoneExtension ? values.driverPhoneExtension : "NA"),
      trailer_number:values &&(values.trailerNumber?values.trailerNumber:'NA'),
      trailer_length:values &&(values.trailerLength?values.trailerLength:'NA'),
      driver_empty:values &&(values.driverEmpty.value?values.driverEmpty.value:'NA'),
      equipment_type:values &&(values.equipmentType.value?values.equipmentType.value:'NA'),
      load_id: carrierData.p_key,
      load_type: carrierData.load_type,
      user_id: localStorage.getItem("CHARIOT_USER_DATA") ? JSON.parse(localStorage.getItem("CHARIOT_USER_DATA")).user.id : 1,
      type:'Book',
      quoted_rate:Rates.chariot_rate,
      targetRate:Rates.target_rate,
      reason:'$ '+ Rates.chariot_rate,
      eta_for_pickup : values &&(values.etaForPickup?values.etaForPickup:'NA'),
      sourcetype:sourceType?sourceType:'sniper',
      empty_time:values.emptyTime,
      truck_location:values.truckLocation,
      truck_number: values.truckNumber
    };
    save(payLoad);
  };

  return (
    <div className="confirm-modal info-select">
      <Modal size="xl" show={showBookModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Carrier Booking Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AllCarrierInfoForm
            MCNo={MCNo}
            CarrierName={CarrierName}
            carrierInfo={carrierInfo}
            contactPhone={contactPhone}
            contactEmail={contactEmail}
            insuranceDetails = {insuranceDetails}
            onSubmit={handleSubmit}
            handleClose={close}
            updateField={updateField}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}