import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { fetchCustomerActivityDetails, saveDriverData } from './../../../../Redux/Action/Dashboard';
import { autofill } from 'redux-form';
import { NotificationManager } from 'react-notifications';
import { contactInfoFields } from '../../Users/Helper';
import InputMask from "react-input-mask";
// import {reduxForm} from 'redux-form';
import TextField from '@material-ui/core/TextField';

function CustomerActivityDetails({ carrierData, CarrierName, carrierInfo, offerNo }) {
  const [customerActivityDetails, setCustomerActivityDetails] = useState();
  const [driverDetails,setDriverDetails] = useState({
    driver_name:'',
    driver_phone:''
  });


  useEffect(() => {
    if (carrierData) {
      const payLoad = {
        offer_no: offerNo,
        load_type: carrierData.load_type
      }

      fetchCustomerActivityDetails(payLoad).then((result) => {
        if (result.message === 'success') {
          setCustomerActivityDetails(result.data.customer_activity[0])
        }
      })
        .catch((error) => {
          // console.log(error)
        })
    }
  }, [carrierData])

  useEffect(()=>{
    if(Object.keys(carrierData).length>0){
      carrierData.bid_pass_data.bids.length>0 && 
      carrierData.bid_pass_data.bids.map(bidData=>{
        if(bidData.status==='booked'){
          setDriverDetails(prev=>({
            ...prev,
            driver_name:bidData.driver_name==='NA'||null||undefined?'':bidData.driver_name,
            driver_phone:bidData.driver_phone==='NA'||null||undefined?'':bidData.driver_phone
          }))
        }       
      })
    }
  },[carrierData])  

  const setDetails = (e) => { 
    var {name,value} = e.target;
    setDriverDetails(prev=>({
      ...prev,
      [name]:value
    }));
   }
   
   const saveDriverDetails = () => { 
     if( carrierData.p_key && driverDetails.driver_name && driverDetails.driver_phone){
      const payload = {
        load_id:carrierData.p_key,
        driver_name:driverDetails.driver_name,
        driver_phone:driverDetails.driver_phone
      }
      saveDriverData(payload).then(result=>{
       if(result.message==='success'){
         NotificationManager.success('Driver Details Saved Successfully','Success',3500)
       }else{
         if(result.status && result.status===400){
           result.res.json().then(value=>{
             if(value.description==='failure'){
              NotificationManager.error(value.errors.error,'Driver Details not saved',3500)
             }else{
              console.log(value);
             }
           })
         }else{
          NotificationManager.error('Driver Details not saved','Error',3500);
          console.log(value)
         }
       }
      }).catch(err=>console.log(err))
     }     
    }

  return (
    <div className="row">
      <div className="col-12 col-lg-4 mb-lg-0 mb-3 ">
        <div className="card orderWhiteBox p-3 h-100">
          <dl className="row">
            <dt className="col-4">Customer:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.customer !== null ? customerActivityDetails.customer : 'NA'}</dd>
            <dt className="col-4">AE:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.ae !== null ? customerActivityDetails.ae : 'NA'}</dd>
            <dt className="col-4">POD:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.POD !== null ? customerActivityDetails.POD : 'NA'}</dd>
            <dt className="col-4">Broker:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.broker !== null ? customerActivityDetails.broker : 'NA'}</dd>
          </dl>
        </div>
      </div>
      <div className="col-12 col-lg-4 mb-lg-0 mb-3">
        <div className="card orderWhiteBox p-3 h-100">
          <dl className="row">
            <dt className="col-4">Carrier Name:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.carrier_name !== null ? customerActivityDetails.carrier_name : 'NA'}</dd>
            <dt className="col-4">Truck Location:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.truck_location !== null ? customerActivityDetails.truck_location : 'NA'}</dd>
            <dt className="col-4">Last Event/Activity:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.last_event_activity !== null ? customerActivityDetails.last_event_activity : 'NA'}</dd>
            <dt className="col-4">Last Event/Activity Time:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.last_event_activity_time !== null ? moment(customerActivityDetails.last_event_activity_time).format('MM/DD/YYYY HH:mm') : 'NA'}</dd>
          </dl>
        </div>
      </div>
      <div className="col-12 col-lg-4 mb-lg-0 mb-3">
        <div className="card orderWhiteBox p-3 h-100">
          <dl className="row">
            <dt className="col-4">Dispatcher Name:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.dispatcher_name !== null ? customerActivityDetails.dispatcher_name : 'NA'}</dd>
            <dt className="col-4">Dispatcher Phone:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.dispatcher_phone !== null ? customerActivityDetails.dispatcher_phone : 'NA'}</dd>
            {/* <dt className="col-4">Driver Name:</dt> <dd className="col-8"><input value={driverDetails && driverDetails.driver_name?driverDetails.driver_name:''} disabled={carrierData.status==='Available'?true:carrierData.driverInformationEditable===1?false:true} type="text" placeholder="Driver's Name" className="form-control px-1 hgt-25 w-50 border rounded mt-1" name='driver_name' 
                        onChange={(e) => {setDetails(e)}} onBlur={(e) => {saveDriverDetails()}}/></dd> */}
            {/* <dt className="col-4">Driver Phone:</dt> <dd className="col-8"><input value={driverDetails && driverDetails.driver_phone?driverDetails.driver_phone:''} disabled={carrierData.status==='Available'?true:carrierData.driverInformationEditable===1?false:true} type="text" placeholder="Driver's Phone" className="form-control px-1 hgt-25 w-50 border rounded mt-1 mb-1" name='driver_phone'
                        onChange={(e) => {setDetails(e)}} onBlur={(e) => {saveDriverDetails()}}/></dd> */}
            {/* <dt className="col-4">Driver Phone:</dt> <dd className="col-8"><MaskedInput
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        value={driverDetails && driverDetails.driver_phone?driverDetails.driver_phone:''} disabled={carrierData.status==='Available'?true:carrierData.driverInformationEditable===1?false:true} type="text" placeholder="Driver's Phone" className="form-control px-1 hgt-25 w-50 border rounded mt-1 mb-1" name='driver_phone'
                        onChange={(e) => {setDetails(e)}} onBlur={(e) => {saveDriverDetails()}}/></dd>           */}


            <dt className="col-4">Driver Name: </dt> <dd className="col-8">
            <TextField
              variant='outlined'
              label='Driver Name'
              size='small'
              value={driverDetails && driverDetails.driver_name?driverDetails.driver_name:''} 
              disabled={carrierData.status==='Available'?true:carrierData.driverInformationEditable===1?false:true} 
              type="text" 
              // placeholder="Driver's Name" 
              className="" 
              name='driver_name'
              onChange={(e) => {setDetails(e)}} 
              onBlur={(e) => {saveDriverDetails()}}
            /></dd>
            <dt className="col-4">Driver Phone: </dt> <dd className="col-8">
            <InputMask
              mask="(999) 999 - 9999"
              maskChar=" "
              onChange={(e) => {setDetails(e)}}
              onBlur={(e) => {saveDriverDetails()}}
              className='mt-2'
              variant='outlined'
              label='Driver Phone'
              size='small'
              value={driverDetails && driverDetails.driver_phone?driverDetails.driver_phone:''}
              name='driver_phone'
              disabled={carrierData.status==='Available'?true:carrierData.driverInformationEditable===1?false:true}
              >
              {()=><TextField
                variant='outlined'
                className='mt-2'
                name='driver_phone'
                size='small'
                label='Driver Phone'
                value={driverDetails && driverDetails.driver_phone?driverDetails.driver_phone:''}
                disabled={carrierData.status==='Available'?true:carrierData.driverInformationEditable===1?false:true}
              />}
            </InputMask>
            </dd>
            


            <dt className="col-4">Truck:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.truck !== null ? customerActivityDetails.truck : 'NA'}</dd>
            <dt className="col-4">Trailer:</dt> <dd className="col-8">{customerActivityDetails && customerActivityDetails.trailer !== null ? customerActivityDetails.trailer : 'NA'}</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default CustomerActivityDetails
