import React, { useState, useEffect, useRef } from 'react'
import searchbtn from "./../../../../images/search-btn.svg";
import { NotificationManager } from "react-notifications";
import { fetchCarriersList } from "./../../../../Redux/Action/Dashboard";
import { changeTruckDetail } from "../../../../Redux/Action/TruckDetails";
import { connect } from "react-redux";

function Search({ changeTruckDetail, truckId, SearchCarrierName }) {

    const [hasType, SetType] = useState(false);
    const [carrierName, SetCarrier] = useState(SearchCarrierName ? SearchCarrierName : '');
    const [carrierList, SetCarrierList] = useState([]);
    const [isLoader, SetLoader] = useState(false);
    const [showCarrierList, SetShowCarrierList] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
            SetShowCarrierList(false);
            // SetCarrier('');
            SetCarrierList([]);
        }
    };

    const SetSearchValye = (value) => {

        SetType(true);
        if (!value) {
            SetShowCarrierList(false);
            SetCarrierList([]);
            SetCarrier("");
            changeTruckDetail(truckId, "", "", "");
        } else {
            SetCarrier(value);
        }
    }

    useEffect(() => {
        let controller = new AbortController();
        if (carrierName) {
            if (carrierName && carrierName.length >= 3) {
                if (hasType) {
                    SetShowCarrierList(true);
                    SetLoader((prevState) => prevState = true);
                    fetchCarriersList(carrierName,controller.signal).then((result) => {
                        if (result.message === "success") {
                            SetCarrierList((prevState) => prevState = result.data);
                            SetLoader((prevState) => prevState = false);
                        }
                    }).catch(error =>{
                        //  console.log(error)
                        });
                }
            }
        } else {
            SetCarrierList([]);
            return;
        }
        return()=>{
            controller.abort();
        }
    }, [carrierName])


    const SelectCarrier = (carrier) => {
        SetType(false);
        SetShowCarrierList(false);
        const carrierName = `${carrier.icc_mc_nbr} - ${carrier.name} ${carrier.carrier_abrv ? `(${carrier.carrier_abrv})` : ""}`;
        SetCarrier((prevProps) => prevProps = carrierName);
        changeTruckDetail(truckId, carrier.icc_mc_nbr, carrier.name, carrierName);
    }

    const OnEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            carrierList && carrierList.map((carrier) => {
                if (carrier.icc_mc_nbr === carrierName) {
                    SelectCarrier(carrier)
                } else {
                    SetCarrier('')
                }
            })
        }
    }

    return (
        <div className="col-12">
            <div className="formInlineCarrier h-100 d-flex align-items-center">
                <form className="d-flex position-relative w-100">
                    <input
                        autoComplete="off"
                        value={carrierName}
                        onKeyDown={(e) => OnEnterKeyPress(e)}
                        onChange={(e) => SetSearchValye(e.target.value)}
                        name="MCNo"
                        className="form-control carrier mr-sm-2"
                        type="search"
                        placeholder="Enter MC No/Carrier Name"
                        aria-label="Search"
                    />
                    <button className="btn my-2 my-sm-0 p-0" type="button">
                        <img src={searchbtn} alt="search" />
                    </button>
                    {showCarrierList && (
                        <ul ref={wrapperRef}>
                            {isLoader ? (
                                <li>Loading ...</li>
                            ) : carrierList ? (
                                carrierList.map((carrier, index) => (
                                    <li
                                        key={`${carrier.name}${index}`}
                                        onClick={() => SelectCarrier(carrier)}

                                    >
                                        {`${carrier.icc_mc_nbr} - ${carrier.name} ${carrier.carrier_abrv
                                            ? `(${carrier.carrier_abrv})`
                                            : ""
                                            }`}
                                    </li>
                                ))
                            ) : (
                                <li>No results found</li>
                            )}
                        </ul>
                    )}
                </form>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    changeTruckDetail
};

export default connect(null, mapDispatchToProps)(Search);
