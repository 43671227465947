import { rules } from "../../Utils/Validator";

export const fieldFilters = [
	{
		name: "id",
		type: "number",
		placeHolder: "User ID",
		default: ""
	},

	{
		name: "firstname",
		type: "text",
		placeHolder: "First Name",
		default: null
	},
	{
		name: "lastname",
		type: "text",
		placeHolder: "Last Name",
		default: ""
	},
	{
		name: "roles",
		filter: "select",
		type: "roles",
		placeHolder: "User Role",
		default: ""
	},
	{
		name: "division",
		type: "division",
		filter: "select",
		placeHolder: "Division",
		default: ""
	},
	{
		name: "email",
		type: "text",
		placeHolder: "Email",
		default: ""
	},
	// {
	// 	name: "state",
	// 	type: "text",
	// 	placeHolder: "State",
	// 	default: ""
	// },
	// {
	// 	name: "city",
	// 	type: "text",
	// 	placeHolder: "City",
	// 	default: ""
	// },
	// {
	// 	name: "zipcode",
	// 	type: "text",
	// 	placeHolder: "Zip Code",
	// 	default: ""
	// },
	{
		name: "phone_number",
		type: "text",
		placeHolder: "Phone Number",
		default: ""
	}
	// {
	// 	name: "extension",
	// 	type: "text",
	// 	placeHolder: "Extension",
	// 	default: ""
	// }
];

export const basicInfoFields = [
	{
		name: "firstname",
		type: "text",
		placeholder: "Enter First Name",
		label: "First Name"
	},
	{
		name: "lastname",
		type: "text",
		placeholder: "Enter Last Name",
		label: "Last Name"
	},
	{
		name: "roles",
		type: "select",
		label: "User Role",
		placeholder: "Enter User Role"
	},
	{
		name: "division",
		type: "select",
		label: "Division",
		placeholder: "Enter Division"
	},
	{
		name: "state",
		type: "select",
		placeholder: "Enter State",
		label: "State"
	},
	{
		name: "city",
		type: "text",
		placeholder: "Enter City",
		label: "City"
	},
	{
		name: "zipcode",
		type: "text",
		placeholder: "Enter Zip Code",
		label: "Zip Code",
		validation: [rules.required,rules.minLength5,rules.maxLength9]
	}
];

export const contactInfoFields = [
	{
		name: "phone_number",
		type: "text",
		placeholder: "Enter Phone Number",
		label: "Phone Number",
		validation: [rules.required, rules.PhoneNumber]
	},
	{
		name: "extension",
		type: "number",
		placeholder: "Enter Extension",
		label: "Extension"
	},
	{
		name: "email",
		type: "text",
		label: "Email",
		placeholder: "Enter Email",
		validation: [rules.required, rules.email]
	}
];
