import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { NotificationManager } from "react-notifications";
import { Field, reduxForm, change as changeFieldValue } from "redux-form";
import { renderField } from "../../Utils/Fields";
import { rules } from "../../Utils/Validator";
import { basicInfoFields, contactInfoFields, carrierInfoFields } from "./Helper";
import { updateActivePage } from "../../../Redux/Action/Auth";
import stateNames from './USStateNames.json'
import {
	fetchCarrierUserRoles,
	fetchCarrierUserDetails,
	fetchUserDivisions,
	updateField
} from "../../../Redux/Action/Carrieruser";
import PreloaderInner from "../../Utils/PreloaderInner";

class AddCarrierUser extends Component {
	state = {
		roles: [],
		division: [],
		state: [],
		submitting: false,
		loading: false
	};

	componentDidMount = () => {
		if (this.props.id) {
			this.props.fetchCarrierUserDetails(this.props.id);
			this.setState({ loading: true });
		}
		this.props.fetchCarrierUserRoles();
		this.props.fetchUserDivisions();
		this.props.updateActivePage("activePage", "carrieruser");

		let stateList = stateNames.map((state) => {
			return { value: state.Code, label: state.Code };
		});
		this.setState({ state: stateList });

	};

	componentDidUpdate = (prevProps) => {
		const { userRolesList, divisionsList, user } = this.props;
		if (!isEqual(userRolesList, prevProps.userRolesList) && userRolesList && userRolesList.data) {
			this.setState({ loading: false });
			let roles = userRolesList.data.map((role) => {
				return { value: role.id, label: role.display_name };
			});
			this.setState({ roles });
		}
		if (!isEqual(divisionsList, prevProps.divisionsList) && divisionsList && divisionsList.data) {
			this.setState({ loading: false });
			let division = divisionsList.data.map((division) => {
				return { value: division.id, label: division.division };
			});
			this.setState({ division });
		}
		if (this.props.id && user && user.data && !isEqual(user, prevProps.user)) {
			this.setState({ loading: false });
			let userDetails = { ...user.data };
			userDetails.roles = userDetails.role ? { value: userDetails.role.id, label: userDetails.role.display_name } : { value: 6, label: 'Carrier User' };
			userDetails.division = userDetails.division
				? { value: userDetails.division, label: userDetails.division_name }
				: null;
			// userDetails.city = userDetails.city
			// 	? { value: userDetails.division, label: userDetails.division_name }
			// 	: null;
			// userDetails.state = userDetails.state
			// 	? { value: userDetails.division, label: userDetails.division_name }
			// 	: null;
			basicInfoFields.forEach((field) => {
				this.props.changeFieldValue("carrierUserCreationForm", field.name, userDetails[field.name]);
			});
			contactInfoFields.forEach((field) => {
				this.props.changeFieldValue("carrierUserCreationForm", field.name, userDetails[field.name]);
			});
		}
	};

	componentWillUnmount = () => {
		this.props.updateField("details", {});
	};

	handleSubmit = (values) => {
		this.setState({ submitting: true });
		let payload = { ...values };
		payload.role_id = payload.roles.value;
		payload.division = payload.division.value;
		payload.state = payload.state.value;
		delete payload.roles;
		const successHandler = (res) => {
			res.json().then((values) => {
				this.setState({ submitting: false });
				if (values.description === "failure") {
					NotificationManager.error(values.errors, "", 5000);
				} else {
					this.props.history.push("/users");
				}
			});
			this.setState({ submitting: false });
		};
		if (this.props.id) {
			payload.id = this.props.id;
			//this.props.updateUser(payload, successHandler);
		} else {
			payload.base_url = `${window.location.origin}/set/password`;
			//this.props.createUser(payload, successHandler);
		}
	};

	renderField = (field) => (
		<Field
			name={field.name}
			key={field.label}
			component={renderField}
			type={field.type}
			label={field.label}
			isMulti={field.isMulti}
			placeholder={field.placeholder}
			validate={field.validation ? field.validation : field.name === "extension" ? null : rules.required}
			options={field.type === "select" ? this.state[field.name] : []}
		/>
	);

	GoBack(e){
        e.preventDefault()
		window.open('/carrierusers','_self')
	}

	render() {
		const { loading, submitting } = this.state;
		return (
			<div>
			<div className="user-new-container">
				<div className="user-new-header">
					<h4 className="m-0">{`${this.props.id ? "Register" : "Create New"} Carrier User`}</h4>
				</div>
				<PreloaderInner hidden={!loading} />
				<form className="create-user-form">
					<div className="title-info ">Basic Info</div>
					<div className="row additional-form-body mt-4">
						{basicInfoFields.map((field) => this.renderField(field))}
					</div>
					{/* <div className="title-info ">Contact Info</div>
					<div className="row additional-form-body mt-4">
						{contactInfoFields.map((field) => this.renderField(field))}
					</div> */}
					<div className="form-footer text-right">
						<button className="d-none" onClick={this.props.handleSubmit(this.handleSubmit)}>
							Submit
						</button>
						<button
							disabled={submitting}
							className="btn btn-danger mr-2"
							onClick={(e) => {this.GoBack(e)}}>
							Cancel
						</button>
						<button
							disabled={submitting || !this.props.anyTouched}
							className="btn btn-success d-none"
							onClick={this.props.handleSubmit(this.handleSubmit)}>
							{this.props.id ? "Register" : "Update"}
						</button>
					</div>
				</form>
			</div>
			{/* <UpdateCarrierValuesForm user={this.props.user}/> */}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	userRolesList: state.Carrieruser.roles,
	divisionsList: state.Carrieruser.divisions,
	user: state.Carrieruser.details
});

const mapDispatchToProps = {
	updateActivePage,
	fetchCarrierUserRoles,
	fetchUserDivisions,
	// createUser,
	// updateUser,
	fetchCarrierUserDetails,
	changeFieldValue,
	updateField
};

export default reduxForm({
	form: "carrierUserCreationForm",
	touchOnChange: true
})(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(AddCarrierUser)
);
