import React, { useEffect, useState } from "react";
import moment from 'moment';
import BasicTable from "./../../../Utils/BasicTable";
import PerfectScrollbar from 'react-perfect-scrollbar'
import data from "./../StaticValues.json";
import { fetchTruckPosted } from "./../../../../Redux/Action/Dashboard";
import PreloaderInner from "./../../../Utils/PreloaderInner";
import { NotificationManager } from "react-notifications";

const headers = [
    { accessor: "created_date", Header: "Created Date", sortField: true },
    { accessor: "broker", Header: "Broker", sortField: true },
    { accessor: "availability", Header: "Availability", sortField: true },
    { accessor: "truck_type", Header: "Truck Type", sortField: true },
    { accessor: "location", Header: "Location", sortField: true },
    { accessor: "no_of_loads_matching", Header: "Number Of Loads Matching", sortField: true }
]

export default function TruckPosted({ MCNo, latitude, longitude, truckPostedReload, truckId, filterDates, activeTabId, reloadTruckPosted, setReloadTruckPosted, carrierInfo }) {

    const [isLoader, SetLoader] = useState(false);
    const [truckPosted, SetTruckPosted] = useState([]);
    const [changedTruckId, setChangedTruckId] = useState('');
    const [sortData, setSortData] = useState(false);
    const [apiFieldName, setApiFieldName] = useState('');
    const [descending, setDescending] = useState(false);


    useEffect(() => {
        // SetLoader(true);
        if (MCNo && reloadTruckPosted === true) {
            fetchTruckPostedData();
            // setReloadTruckPosted(false);
        } else {
            // SetTruckPosted([]);
            setTimeout(()=>{SetLoader((prevState) => prevState = false);},5000)
            return;
        }
    }, [reloadTruckPosted])

    useEffect(() => {
        if (MCNo) {
            fetchTruckPostedData();
            // setReloadTruckPosted(false);
        } else {
            // SetTruckPosted([]);
            setTimeout(()=>{SetLoader((prevState) => prevState = false);},5000)
            return;
        }
    }, [activeTabId])

    useEffect(() => {
        if (MCNo) {
            fetchTruckPostedData();
            // SetLoader((prevState) => prevState = false);
        } else {
            // SetTruckPosted([]);
            setTimeout(()=>{SetLoader((prevState) => prevState = false);},5000)
            return;
        }
    }, [filterDates])


    useEffect(() => {
        if (MCNo) {
            fetchTruckPostedData();
        } else {
            SetTruckPosted([]);
            SetLoader((prevState) => prevState = false);
            return;
        }
    }, [MCNo])

    const fetchTruckPostedData = () => {
        SetLoader(true);
        const payload = {
            mc_number: MCNo,
            start_date: moment(carrierInfo.available_date_start).format('YYYY-MM-DD'),
            end_date: moment(carrierInfo.available_date_end).format('YYYY-MM-DD')
        }
        fetchTruckPosted(payload).then(result => {
            if (result.message === "success" && result && result.data) {
                SetTruckPosted(result.data);
                SetLoader((prevState) => prevState = false);
                setReloadTruckPosted(false)
            }
            else 
            if (result && result.data && result.data.length === 0) {
                NotificationManager.error('', result.description, 5000);
                SetLoader((prevState) => prevState = false);
                setReloadTruckPosted(false)
            }
            else 
            if (result && result.message === 'error') {
                NotificationManager.error('', result.description, 5000);
                SetLoader((prevState) => prevState = false);
                setReloadTruckPosted(false)
            }
        }).catch(error => {
            //  console.log(error)
            SetLoader((prevState) => prevState = false);
        });
    }

    const sortingData = (field) => {
        if (field === apiFieldName) {
            setDescending((prevState) => !prevState)
        } else {
            setDescending(false)
        }
        setApiFieldName(field);
        setSortData(true);
    }

    useEffect(() => {
        var sortTextData = ['created_date', 'availability', 'truck_type', 'location'];
        var sortNumberData = ['no_of_loads_matching']
        if (sortData) {
            if (!descending) {
                sortTextData.map((field) => {
                    if (field === apiFieldName) {
                        var sortedData = truckPosted.sort((a, b) => (a[field] ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
                        setSortData(false);
                        SetTruckPosted(sortedData);
                    }
                });
                sortNumberData.map((field) => {
                    if (field === apiFieldName) {
                        setSortData(false);
                        var sortedData = truckPosted.sort((a, b) => (a[field] !== null ? a[field] : 'zzz') - (b[field] !== null ? b[field] : 'zzz'));
                        SetTruckPosted(sortedData);
                    }
                });

            } else {
                sortTextData.map((field) => {
                    if (field === apiFieldName) {
                        var sortedData = truckPosted.sort((b, a) => (a[field] ? a[field] : 'zzz').localeCompare(b[field] !== null ? b[field] : 'zzz'));
                        setSortData(false);
                        SetTruckPosted(sortedData);
                    }
                });
                sortNumberData.map((field) => {
                    if (field === apiFieldName) {
                        setSortData(false);
                        var sortedData = truckPosted.sort((b, a) => (a[field] !== null ? a[field] : 'zzz') - (b[field] !== null ? b[field] : 'zzz'));
                        SetTruckPosted(sortedData);
                    }
                });
            }
        }
    }, [sortData])


    const renderTruckPostedTable = (tableHeader, tableData) => {
        return (<>
            <table className="table truck-table mb-0">
                <tbody>
                    <tr>
                        {tableHeader && tableHeader.map((data, index) => {
                            return data.width ? <th className={(data.accessor === "noOfLoadsMatching" || data.accessor === "availability") && "text-center"} key={index} width={data.width}>{data.Header}</th> : <th className={(data.accessor === "noOfLoadsMatching" || data.accessor === "availability") && "text-center"} key={index}>{data.Header}<div className={`d-inline-block ml-1`}><img src={`${truckPosted && data.accessor === apiFieldName ? descending ? 'images/ascending-arw.png' : 'images/descending-arw.png' : 'images/updown-arrow.png'}`} className={`${data.sortField ? '' : 'd-none'}`} onClick={() => sortingData(data.accessor)} /></div></th>
                        })}
                    </tr>

                    {tableData && tableData.map((data, index) => {
                        return <tr key={index}>
                            {tableHeader && tableHeader.map((hdata, hindex) => {
                                if (hdata.accessor === "availability") {
                                    return <td key={hindex} className="text-center">
                                        <span className=''>{moment(data.start_date).format('MM/DD/YYYY') + ' - ' + moment(data.end_date).format('MM/DD/YYYY')}</span>
                                    </td>
                                } else if (hdata.accessor === "number_of_loads_matching") {
                                    return <td key={hindex} className="text-center">
                                        <span className="badge badge-stops">{data[hdata.accessor]}</span>
                                    </td>
                                } else if (hdata.accessor === "created_date") {
                                    return <td key={hindex}
                                    >
                                        <span >{moment(data[hdata.accessor]).format('MM/DD/YYYY HH:mm')}</span>
                                    </td>
                                } else {
                                    return <td key={hindex}>
                                        {data[hdata.accessor]}
                                    </td>
                                }
                            })}
                        </tr>
                    })}
                    {tableData && tableData.length === 0 &&
                        <tr key={0} className="text-center text-danger"><td colSpan={tableHeader.length}>No Record Found</td></tr>}
                </tbody>
            </table>
        </>)
    }


    return <div className="table-responsive auto-scroll">
        <PreloaderInner hidden={!isLoader} />
        <PerfectScrollbar>
            {/* <BasicTable columns={headers} data={truckPosted} /> */}
            {renderTruckPostedTable(headers, truckPosted)}
        </PerfectScrollbar>
    </div>



    // <div className="table-responsive">
    //     <PreloaderInner hidden={!isLoader} />
    //     <BasicTable columns={headers} data={truckPosted} />
    //     {/* {renderTruckPostedTable(headers, truckPosted)} */}
    // </div>
}