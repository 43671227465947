export const fieldFilters = [
	{
		expander : true,
		name: "id",
		type: "number",
		placeHolder: "Order No",
		default: "",
		width: 100
	},
	{
		name: "status",
		type: "text",
		placeHolder: "Brocker",
		default: "",
		width: 100,
	},
	{
		name: "status",
		type: "text",
		placeHolder: "Status",
		default: "",
		width: 100
	},
	{
		name: "division",
		type: "text",
		placeHolder: "Division",
		default: "",
		width: 100
	},
	{
		name: "target_rate",
		type: "number",
		placeHolder: "Target Rate",
		default: "",
		width: 120
	},
	{
		name: "division",
		type: "text",
		placeHolder: "Difficulty",
		default: "",
		notFilterable: true,
		width: 100
	},
	{
		name: "division",
		type: "text",
		placeHolder: "Tags",
		default: "",
		notFilterable: true,
		width: 100
	},
	{
		name: "type_of_load",
		type: "text",
		placeHolder: "Load Type",
		default: "",
		width: 110
	},
	{
		name: "customer",
		type: "text",
		placeHolder: "Customer",
		default: "",
		width: 220
	},
	{
		name: "pickDateTime",
		type: "daterangeLocal",
		placeHolder: "Pick Date/Time",
		filter: "daterange",
		default: null,
		width: 150
	},
	{
		name: "pickLocation",
		type: "text",
		placeHolder: "Pick Location",
		default: "",
		width: 150
	},
	{
		name: "destination",
		type: "text",
		placeHolder: "Destination",
		default: "",
		width: 150
	},
	{
		name: "deliveryDateTime",
		type: "daterangeLocal",
		placeHolder: "Delivery Date/Time",
		filter: "daterange",
		default: null,
		width: 180
	}
];
