import { combineReducers } from "redux";
import Auth from "./Reducer/Auth";
import User from "./Reducer/User";
import Loads from "./Reducer/Loads";
import LoadDetails from "./Reducer/LoadDetails";
import TruckDetails from "./Reducer/TruckDetails";
import Carrieruser from "./Reducer/Carrieruser";
import { reducer as formReducer } from "redux-form";
import Case from "./Reducer/CaseFile";

export default combineReducers({
    form: formReducer,
    Auth,
    User,
    Loads,
	LoadDetails,
	Case,
    TruckDetails,
    Carrieruser
});
